export default {

    'footer.signature': 'epic.nic.it ©2017 Created by Registro.it',

    'header.user.signout': 'Sign Out',
    'header.search.placeholder': 'Perform a INFO domain name ....',

    'login.error.wrongCredentials': 'Wrong credentials',
    'login.form.message.username.required': 'Please input your username!',
    'login.form.message.username.placeholder': 'Username',
    'login.form.message.username.label': 'Username',
    'login.form.message.password.required': 'Please input your password!',
    'login.form.message.password.placeholder': 'Password',
    'login.form.message.password.label': 'Password',

    'domain.detail.domain': 'Domain',
    'domain.detail.registrant': 'Registrant',
    'domain.detail.adminContact': 'Admin Contact',
    'domain.detail.techsContact': 'Techs Contact',
    'domain.detail.dsRecords': 'Record DS',
    'domain.detail.registrar': 'Registrar',

    'contact.detail.contact': 'Contact',
    'contact.detail.organization': 'Organization',
    'contact.detail.name': 'Name',
    'contact.detail.consent4pub': 'Consent For Publish',
    'contact.detail.value': 'Agrees',
    'contact.detail.contacts': 'Contacts',
    'contact.detail.address': 'Address',

    'contact.detail.registrant': 'Registrant',
    'detail.registrant.nationalityCode': 'Nationality Code',
    'detail.registrant.entityType': 'Entity Type',
    'detail.registrant.regCode': 'Registrant Code',
    'detail.domain.hosts': 'Hosts',
    'detail.domain.nameserver': 'Name Server',
    'detail.domain.nameserver.title': 'Nameserver',
    'detail.domain.nameserver.ipv4': 'Indirizzo IP v4',
    'detail.domain.nameserver.ipv6': 'Indirizzo IP v6',
    'detail.domain.nameserver.toValidate': 'To validate',
    'detail.domain.nameserver.actual': 'Actual',

    'detail.domain.name': 'Name',
    'detail.domain.punycodeDomain': 'Dominio IDNA',
    'detail.contact.id': 'Id',
    'detail.domain.status': 'Status',
    'detail.domain.crDate': 'Creation',
    'detail.domain.exDate': 'Expire',
    'detail.domain.upDate': 'Last update',
    'detail.contact.organization': 'Organization',
    'detail.contact.address': 'Address',
    'detail.contact.address.city': 'City',
    'detail.contact.address.placeholder.city': 'Nome della città',
    'detail.contact.address.streets': 'Streets',
    'detail.contact.address.postalCode': 'Postal Code',
    'detail.contact.address.placeholder.postalCode': 'Codice di avviamento postale',
    'detail.contact.address.state': 'State',
    'detail.contact.address.countryCode': 'Country Code',
    'detail.contact.nationality': 'Nazionality',
    'detail.contact.phone': 'Telephone',
    'detail.contact.fax': 'Fax',
    'detail.contact.email': 'E-mail',
    'detail.contact.crDate': 'Creation',
    'detail.contact.upDate': 'Last Update',
    'detail.contact.name': 'Name',
    'detail.contact.statuses': 'Statuses',

    'detail.dsRecord.keyTag': 'Key Tag',
    'detail.dsRecord.alg': 'Alg',
    'detail.dsRecord.digestType': 'Digest Type',
    'detail.dsRecord.digest': 'Digest',
    'detail.dsRecord.toValidate': 'To validate',
    'detail.dsRecord.actual': 'Actual',

    'contact.detail.message.emptyDetail': 'No detail',
    'contact.detail.label.id': 'Id',
    'contact.detail.label.name': 'Name',
    'contact.detail.label.organization': 'Organization',
    'contact.detail.label.telephone': 'Tel.',
    'contact.detail.label.email': 'e-mail',
    'contact.detail.label.fax': 'fax.',
    'contact.detail.label.admin': 'Admin',

    'contact.info.form.title': 'Info contact',
    'contact.info.form.placeholder': 'Contact ID (ie: CCTL1)',
    'contact.info.inputContact.label': 'Contact',
    'contact.info.inputContact.extra': 'Insert a contact',
    'contact.info.input.field.contact.message': 'Please insert a contact',
    'contact.info.submit.button': 'INFO',
    'contact.info.form.button.reset': 'Cancel',

    'contact.action.modify': 'Edit',
    'contact.action.delete': 'Delete',

    'contact.detail.form.statuses': 'Status',
    'contact.detail.form.statuses.message': 'Please insert at least a status',
    'contact.detail.form.statuses.placeholder': 'Select preferred statuses',
    'contact.detail.form.name': 'Name',
    'contact.detail.form.address': 'Address',
    'contact.detail.form.address.streets': 'Streets',
    'contact.detail.form.address.streets.message': 'Please insert at least a street!',
    'contact.detail.form.address.streets.street': 'Street',
    'contact.detail.form.address.streets.street.message': 'Please input street\'s name or delete this field!',
    'contact.detail.form.address.streets.street.placeholder': 'Nome della via/strada/Piazza',
    'contact.detail.form.address.city': 'City',
    'contact.detail.form.address.city.message': 'Please insert a city!',
    'contact.detail.form.address.postalCode': 'Postal code',
    'contact.detail.form.address.postalCode.message': 'Please insert a postal code!',
    'contact.detail.form.address.countryCode': 'Country code',
    'contact.detail.form.address.countryCode.message': 'Please insert a country code!',
    'detail.contact.address.placeholder.countryCode': 'Codice nazione (es. IT)',
    'contact.detail.form.address.state': 'State',
    'contact.detail.form.address.state.message': 'Please insert a state!',
    'contact.detail.form.contactId': 'Id',
    'contact.detail.form.contactType': 'Tipologia contatto',

    'contact.detail.form.registrant': 'Registrante',
    'contact.detail.form.registrant.nationalityCode': 'Codice nazionale',
    'contact.detail.form.registrant.nationalityCode.message': 'Specifica un codice nazionale',
    'contact.detail.form.registrant.entityType': 'Entità',
    'contact.detail.form.registrant.entityType.message': 'Specifica un tipo entità',
    'contact.detail.form.registrant.regCode': 'Codice registrante',
    'contact.detail.form.registrant.regCode.message': 'Definisci un codice registrante',
    'contact.detail.form.checkOrganization.message': 'Il valore del campo Organizzazione deve coincidere con quello del campo Name',
    'contact.detail.form.registrant.placeholder.countryCode': 'Codice nazione (es. IT)',


    'contact.detail.form.name.message': 'Please insert a name!',
    'contact.detail.form.name.placeholder': 'Nome del nuovo contatto (es. Mario Rossi)',
    'contact.detail.form.organization': 'Organization',
    'contact.detail.form.organization.message': 'Please insert an organization!',
    'contact.detail.form.organization.placeholder': 'Nome organizzazione del nuovo contatto',
    'contact.detail.form.telephone': 'Phone',
    'contact.detail.form.telephone.message': 'Please insert a valid phone number',
    'contact.detail.form.telephone.placeholder': 'Numero di telefono valido (es. +39.05050000)',
    'contact.detail.form.fax': 'Fax',
    'contact.detail.form.fax.placeholder': 'Numero di fax valido (es. +39.05050001)',
    'contact.detail.form.fax.message': 'Please insert a fax number!',
    'contact.detail.form.email': 'Email',
    'contact.detail.form.email.invalid.message': 'E-Mail format is not valid',
    'contact.detail.form.email.placeholder': 'Email in formato valido',
    'contact.detail.form.email.message': 'Please insert a email!',
    'contact.detail.form.consentForPublish': 'Consent for publish',
    'contact.detail.form.consent': 'si',
    'contact.detail.form.notConsent': 'no',
    'contact.detail.form.organization.error.message.orgequalsname': 'EntityType = 1, l\'organizzazione deve essere uguale al nome',
    'contact.detail.form.id.error.message.idValid': 'ID contatto valido',
    'contact.detail.form.id.error.message.idNotValid': 'Contatto già esistente',
    'contact.detail.form.id.error.message.checkIderror': 'Errore nel controllo esistenza contatto',
    'contact.detail.form.id.error.message.idLess3chr': 'Inserire un ID contatto composto da almeno 3 caratteri',
    'contact.detail.form.id.placeholder': 'ID del nuovo contatto (almeno 3 caratteri)',
    'contact.detail.form.save': 'Save',
    'contact.detail.form.clear': 'Clear',
    'contact.detail.update.form.title': 'Update Contact',

    'contact.list.title': 'Contacts list',
    'contact.list.form.nameOrOrg': 'Name OR organization',
    'contact.list.form.name': 'Name',
    'contact.list.form.contactType': 'Type',
    'contact.list.form.org': 'Organization',
    'contact.list.form.contactId': 'Contact id',
    'contact.list.form.email': 'E-mail',
    'contact.list.form.city': 'City',
    'contact.list.form.search': 'Search',
    'contact.list.form.clear': 'Clear',
    'contact.list.form.expand': 'Expand',
    'contact.list.form.removeFilter': 'Remove filters',
    'contact.list.form.filterResult': 'Filter results',
    'contact.list.form.filter': 'Filter',
    'contact.list.form.message.empty': 'The field cannot be empty',

    'contact.list.header.contactId': 'Id contatto',
    'contact.list.header.name': 'Nome',
    'contact.list.header.organization': 'Organizzazione',
    'contact.list.header.email': 'E-mail',
    'contact.list.header.telephone': 'Telefono',
    'contact.list.header.address': 'Indirizzo',
    'contact.list.header.contactType': 'Tipo',
    'contact.list.header.state': 'Prov.',
    'contact.list.header.city': 'Città',
    'contact.list.header.country': 'Paese',
    'contact.list.message.noResults': 'No results',

    'filterbar.activefilter': 'Active filters',
    'filterbar.removefilters': 'clear all filters',
    'filterbar.contactType.contact': 'contatto',
    'filterbar.contactType.registrant': 'registrante',

    'contact.new.form.title': 'Nuovo contatto',



    'domain.detail.message.emptyDetail': 'No detail',
    'domain.detail.label.name': 'Name',
    'domain.detail.label.registrant': 'ContactDetailsBody',
    'domain.detail.label.status': 'Status',
    'domain.detail.label.techsid': 'TechsID',
    'domain.detail.label.admin': 'Admin',
    'domain.detail.signed': 'Signed',
    'domain.detail.signed.yes': 'yes',
    'domain.detail.signed.no': 'no',

    'domain.info.form.inputDomain.label': 'Domain',
    'domain.info.form.title': 'Info domains',
    'domain.info.form.button': 'INFO',

    'domain.info.form.authinfo.label': 'AuthInfo',
    'domain.info.form.authinfo.placeholder': 'Optional AuthInfo code',
    'domain.info.form.authinfo.rule.message': 'Please insert an optional AuthInfo code',
    'domain.info.form.button.info': 'INFO',
    'domain.info.form.button.reset': 'RESET',
    'domain.info.form.inputDomain.extra': 'Domain name (es. miodominio.it)',
    'domain.info.form.inputDomain.rule.message': 'Please insert a domain name to search!',
    'domain.info.form.inputAuhinfo.rule.message': 'Please insert an authinfo code',
    'domain.info.delete.title': 'Do you want delete domain \'{name}\'?',
    'domain.info.delete.content': 'L\'operazione  di cancellazione per il domnio \'{name}\' è irreversibile',
    'domain.info.delete.okText': 'Yes',
    'domain.info.delete.cancelText': 'No',
    'domain.info.delete.success': 'Richiesta di cancellazione per il dominio \'{name}\' eseguita con successo',
    'domain.info.delete.success.title': 'Richiesta di cancellazione',
    'domain.info.delete.failure': 'Richiesta di cancellazione per il dominio \'{name}\' non eseguita: {response}',
    'domain.info.delete.failure.title': 'Errore nella richiesta di cancellazione',

    'domain.info.action.menu.showAuthInfo': 'Show authinfo',
    'domain.info.action.menu.title': 'Actions',
    'modal.authinfo.title': 'Authinfo code',
    'domain.info.action.menu.delete': 'Delete',

    'searchBox.label.search': 'Search',
    'searchBox.placeholder': 'Insert term',

    'domains': 'Domini',
    'contacts': 'Contatti',
    'domain': 'Domain',
    'search': 'Search',
    'contact': 'Contact',
    'info': 'Info',
    'list': 'List',

    'Contact': 'Contact',
    'Registrant': 'Registrant',

    'eppOperations': 'EPP Operations ',
    'check': 'Check',

    'domain.check.title': 'Check domain',
    'domain.check.domainAvailable': 'Domain available',
    'domain.check.domainNotAvailable': 'Domain not available',
    'domain.check.inputDomain.label': 'Domain',
    'domain.check.inputDomain.placeHolder': 'Domain name (es. miodominio.it)',
    'domain.check.inputDomain.extra': 'Insert a domain',
    'domain.check.input.field.domain.message': 'Please input a domain to check!',


    //EntityTypeSelect
    'entityTypeSelect.1': '1 - Persone fisiche',
    'entityTypeSelect.2': '2 - Società/Imprese individuali',
    'entityTypeSelect.3': '3 - Liberi professionisti/Ordini professionali',
    'entityTypeSelect.4': '4 - Enti no profit',
    'entityTypeSelect.5': '5 - Enti pubblici',
    'entityTypeSelect.6': '6 - Altri soggetti',
    'entityTypeSelect.7': '7 - Soggetti stranieri escluso persone fisiche',

    //ContactTypeSelect
    'contactTypeSelect.contact': 'contact',
    'contactTypeSelect.registrant': 'registrant',

    //Contact-Check
    'contact.check.title': 'Check contatto',
    'contact.check.submit.button': 'CHECK',
    'contact.check.form.button.reset': 'Annulla',
    'contact.check.contactNotAvailable': 'Contatto non disponibile',
    'contact.check.contactAvailable': 'Contatto disponibile',
    'contact.check.inputContact.placeHolder': 'Id Contatto (es: CCTL1)',
    'contact.check.input.field.contact.message': 'È necessario inserire un contatto',

    // Configuration Wizard
    prev: 'Precedente',
    next: 'Successivo',
    done: 'Fatto',

    eppServerLabel: 'Server Server',
    eppServerMessage: 'Inserisci o seleziona una URL del Server Epp. La url non deve contenere spazi e deve essere https://<server>.it',
    eppServerPlaceholder: '',

    userNameLabel: 'Utente Epp',
    userNameMessage: 'Utente Epp deve essere specificato: non deve contenere spazi, maiuscolo e deve terminare con -REG',

    userNamePlaceholder: 'Utente Registrar Epp',

    eppPasswordLabel: 'Password Utente Epp',
    eppPasswordMessage: 'Password dell\'utente Epp deve essere specificata e non deve contenere spazi',
    eppPasswordPlaceholder: 'Password Registrar Epp',

    confirmEppPasswordLabel: 'Conferma Passord',
    confirmEppPasswordMessage: 'La password di conferma deve coincidere con il campo password',
    confirmEppPasswordMessage2: 'La password di conferma deve essere specificata e non deve contenere spazi',
    confirmEppPasswordPlaceholder: 'Conferma password utente Epp',

    adminNameLabel: 'Utente Amministratore',
    adminNameMessage: 'Utente Amministratore deve essere specificato e non deve contenere spazi',
    adminNamePlaceholder: 'Utente Amministratore del client EPIC',

    fullNameLabel: 'Nome Completo',
    fullNameMessage: 'Nome Completo dell\'Utente Amministratore',
    fullNamePlaceholder: 'Nome Completo deve essere specificato',


    adminEmailLabel: 'Email',
    adminEmailMessage: 'Email dell\'utente deve essere specificata',
    adminEmailPlaceholder: 'Email Utente Anministratore',

    adminPasswordLabel: 'Password',
    adminPasswordMessage: 'La password dell\'Utente Amministratore deve essere specificata. La lunghezza deve essere compresa tra 6 e 32 caratteri',
    adminPasswordPlaceholder: 'Password Utente Amministratore',

    confirmAdminPasswordLabel: 'Conferma Password',
    confirmAdminPasswordMessage: 'Password di conferme deve essere specificata e deve coincidere con quella del campo password',
    confirmAdminPasswordPlaceholder: 'Conferma della password dell\'Utente Amministratore',

    'userConfiguration.info.message': 'Configurazione Utente',
    'userConfiguration.info.description.': 'I dati dell\'utente saranno memorizzati',
    'userConfiguration.error.message': 'Configurazione Utente',
    'userConfiguration.error.description': 'Un errore si è verificato nel salvataggio dei dati dell\'utente. Utente già configurato',

    'userConfiguration.success.message': 'Configurazione Utente',
    'userConfiguration.success.description': 'Dati dell\'utente memorizzati',

    'serverConfiguration.info.message': 'Configurazione Server Epp',
    'serverConfiguration.info.description': 'La configurazione del server sarà memorizzata',

    'serverConfiguration.success.message': 'Configurazione Server Epp',
    'serverConfiguration.success.description': 'La configurazione del server memorizzata con successo',

    'serverConfiguration.error.message': 'Configurazione Server Epp',
    'serverConfiguration.error.description': 'Si è verificato un errore nel salvataggio della configurazione del server',
    //--- End Configuration Wizard --
    // Splash
    loadingConfiguration: 'Verifica Configurazione',
    errorOccurred: 'Si è verificato un errore nella comunicazione con il server.',
    //--- End Splash


    invalidDomainName: 'Nome a dominio non valido',
    invalidContactId: 'Contact id is not valid',
    invalidTelphoneNumber: 'Formato del numero di telefono non valido',

    'menu.admin': 'Configurazione',
    'admin.polling.title': 'Configurazione',

    'domain.missingDays': 'Il dominio scadrà tra {days} giorni',
    'domain.signed': 'Dominio firmato DNSSEC'

}

