//Pulisce tutti i campi presenti nella forma per permettere una nuova ricerca
import ItDomainValidator from "./validators/ItDomainValidator";
import ContactIdValidator from "./validators/ContactIdValidator";
import TelephoneValidator from "./validators/TelephoneValidator";
import CountryCodedValidator from "./validators/ConuntryCodeValidator";

export const handleFormReset = (form) => {
    const {getFieldsValue, setFieldsValue} = form;
    Object.keys(getFieldsValue()).forEach((key, index) => {
        setFieldsValue({[key]: ''});
    })
}

//Controlla se il nome a dominio rispetta i vincoli
export const checkDomainName = (rule, value, callback, formatMessage) => {
    if (value) {
        let dn = ItDomainValidator.validate(value, true);
        if (!dn.valid) {
            callback(new Error(formatMessage({id: "invalidDomainName"})));
        }
    }
    callback();
}

//Controlla se il nome a dominio rispetta i vincoli
export const checkContactId = (rule, value, callback, formatMessage) => {
    if (value) {
        let cId = ContactIdValidator.validate(value, true);
        if (!cId.valid) {
            callback(new Error(formatMessage({id: "invalidContactId"})));
        }
    }
    callback();
}

//Controlla se il numero di telefono rispetta i vincoli
export const checkTelephoneNumber = (rule, value, callback, formatMessage) => {
    if (value) {
        let tel = TelephoneValidator.validate(value, true);
        if (!tel.valid) {
            callback(tel.errors.map(e=>formatMessage({id:e})))
        }
    }
    callback();
}

export const checkCountryCode = (rule, value, callback, formatMessage) => {
    if (value) {
        let countryCode = CountryCodedValidator.validate(value, true);
        if (!countryCode.valid) {
            callback(new Error(formatMessage({id: countryCode.error})));
        }
    } else {
        callback(new Error('Required'));
    }
    callback();
}

const AUTH_INFO = {
    MIN: 8,
    MAX: 32
}
export const authInfoValidator = (rule, value, callback, formatMessage) => {
    if (value) {
        if (value.length <= AUTH_INFO.MAX && value.length >= AUTH_INFO.MIN) {
            callback()
        } else {
            callback(new Error(formatMessage({id: 'domain.new.Wizard.authInfoLen'}, {
                "length": value.length,
                "min": AUTH_INFO.MIN,
                "max": AUTH_INFO.MAX
            })));
        }
    } else {
        callback(new Error(formatMessage({id: 'domain.new.Wizard.emptyField'})))
    }
}
