import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Steps} from 'antd';
import {injectIntl} from 'react-intl';
import Error from '../../FeedBack/Error/Error';
import './Wizard.css';

const Step = Steps.Step;


function wrapControls(WrappedComponent) {


    class Wrapping extends Component {

        check = () => {
            // Trasformato come promise
            return new Promise((resolve) => {
                this.ref.getForm().validateFields(
                    (err, values) => {
                        let goOn = !err;
                        const {current, steps} = this.props;
                        // console.log("this.props.checkfields ",  this);
                        if (goOn && steps[current].checkFields) {
                            let fields = {...this.props.fields, values};
                            // console.log("checkFields: ", this.props);
                            goOn = steps[current].checkFields(fields, this.props);
                        }
                        if (goOn) {
                            this.props.onChange(values);
                            resolve("success");
                        } else {
                            resolve("error");
                        }
                    }
                )

            })
        }


        render() {
            const {steps, current} = this.props;
            const {formatMessage} = this.props.intl;
            const disabled = steps[current].error ? (!steps[current].error(this.props.fields, this.props).status) : false;

            return (
                <div>
                    <div className="steps-content">
                        <WrappedComponent
                            {...this.props}
                            ref={
                                (instance) => {
                                    this.ref = instance
                                }
                            }
                        />
                    </div>

                    <div className="steps-action">

                        {
                            this.props.onCancel &&
                            <Button
                                style={{marginRight: 30}}
                                onClick={
                                    () => {
                                        this.props.onCancel()
                                    }
                                }
                            >
                                {formatMessage({id: 'cancel'})}
                            </Button>
                        }
                        {
                            current > 0 &&
                            <Button
                                style={{marginRight: 8}}
                                onClick={() => this.props.prev()}
                            >
                                {formatMessage({id: 'prev'})}
                            </Button>
                        }

                        {
                            current < steps.length - 1
                            &&
                            <Button
                                type="primary"
                                onClick={() => {
                                    this.check()
                                        .then((result) => {
                                            if (result === "success") {
                                                this.props.next()
                                            }
                                        })
                                }}>
                                {formatMessage({id: 'next'})}
                            </Button>
                        }

                        {
                            current === steps.length - 1
                            &&
                            <Button type="primary"
                                    disabled={disabled}
                                    onClick={() => {
                                        this.check()
                                            .then((result) => {
                                                if (result === "success") {
                                                    this.props.onDone();
                                                }
                                            })
                                    }}>
                                {steps[current].doneButton || formatMessage({id: 'done'})}
                            </Button>
                        }

                    </div>
                </div>
            )
        }
    }

    return Wrapping;
}


class Wizard extends Component {

    constructor(props) {
        super(props);
        this.renderSteps = this.renderSteps.bind(this);
    }


    renderSteps(steps) {
        let s = [];
        steps.forEach((step, i) => {
            s.push(<Step key={i} title={step.title} description={step.description}/>)
        })
        return s;
    }

    render() {
        const {steps, current, error} = this.props;

        const ControlledComponent = wrapControls(steps[current].content, this.props);

        let errorCompoent = null;
        if (error) {
            errorCompoent = <div style={{margin: '20px'}}><Error error={error}/></div>
        }


        return (
            <div>
                {(steps.length > 1 &&
                    <div className="steps-header">
                        <Steps current={current}>
                            {this.renderSteps(steps)}
                        </Steps>
                    </div>
                )

                }
                {errorCompoent}
                <ControlledComponent {...this.props} />
            </div>

        )
    }

}

export default injectIntl(Wizard);


Wizard.propTypes = {

    fields: PropTypes.object,
    steps: PropTypes.array,
    current: PropTypes.number,
    next: PropTypes.func,
    prev: PropTypes.func,
    onDone: PropTypes.func,
    onChange: PropTypes.func,
    error: PropTypes.object

};
Wizard.defaultProps = {};