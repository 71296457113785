import React from "react";
import Login from "./authentication/components/Login";
import {Redirect, Route, Switch} from "react-router-dom";
import "../styles/App.css";
import Splash from "./commons/components/FeedBack/Splash/Splash";
import ConfigurationWizard from "./configuration/components/AppConfiguration/ConfigurationWizard";
import DomainWizard from "app/domains/components/Pages/DomainNew/DomainWizard";
import {withRouter} from "react-router";
import * as routes from "./routes";
import DomainInfoContainer from "./domains/containers/DomainInfoContainer";
import ContactListContainer from "./contacts/containers/ContactListContainer";

import Home from "./commons/components/Pages/Home";
import Email from "./configuration/components/EppConfiguration/Email/Email";
import Epp from "./configuration/components/EppConfiguration/Epp/Epp";
import ContactInfoContainer from "./contacts/containers/ContactInfoContainer";
import Polling from "./configuration/components/EppConfiguration/Polling/Polling"
import ContactNewContainer from "./contacts/containers/ContactNewContainer";
import DomainListContainer from "./domains/containers/DomainListContainer";
import EppPassword from "./configuration/components/EppConfiguration/EppPassword/EppPassword";
import {connect} from "react-redux";
import DomainTransferContainer from "./domains/containers/DomainTransferContainer";
import AuthRoute from "./authentication/components/AuthRoute";
import DefaultLayout from "./commons/containers/DefaultLayout";
import DomainCheckContainer from "./domains/containers/DomainCheckContainer";
import {configOperations} from "./configuration";
import ContactCheck from "./contacts/components/Pages/ContactCheck/ContactCheck";
import EppMessageListContainer from "./eppMessages/containers/EppMessageListContainer";
import NoMatch from "app/commons/components/Pages/NoMatch/NoMatch";
import countriesOp from "app/countries/operations";

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentWillMount() {
        // this.props.loadCountries();
        configOperations.getConfiguration()
            .then((response) => {
                let configuration = response.data;

                this.setState({needConfigurationWizard: ((!configuration.eppConfigExists || !configuration.userExists))})
                this.setState({eppConfigExists: configuration.eppConfigExists});
                this.setState({userExists: configuration.userExists});
                this.setState({configurationReady: true});

                //Inserisce nello store il valore del profilo della configurazione
                this.props.handleSetProfile({profile: configuration.profile})

                if (((!configuration.eppConfigExists || !configuration.userExists))) {
                    const params = new URLSearchParams(this.props.history.location.search);
                    let step = params.get("step");

                    if (!configuration.eppConfigExists) step = 1;
                    if (!configuration.userExists) step = 0;

                    let queryString = `eppConfigExists=${configuration.eppConfigExists}&userExists=${configuration.userExists}` + (step ? `&step=${step}` : '')
                    this.props.history.push(`/wizard?${queryString}`);
                }

            })
            .catch((error) => {
                this.setState({error: true, errorMessage: 'errorOccurred'});
                console.log("Error: ", error);
            })
    }

    redirectToList(route, page, size) {
        //Questo metodo serve ad impostare i corretti valori di default della lista qunad
        const {history} = this.props;
        let searchParams = new URLSearchParams(history.location.search);
        if (history.location.pathname === route.path &&
            (!searchParams.has('page') || !searchParams.has('size'))) {
            searchParams.set('page', page);
            searchParams.set('size', size);
            return (
                <Redirect to={{
                    pathname: route.path,
                    search: '?' + searchParams.toString()
                }}/>
            )
        }
    }

    render() {

        const {configurationReady, needConfigurationWizard, error, errorMessage} = this.state;

        let wizard = null;

        if (needConfigurationWizard) {
            wizard = (
                this.state.userExists ?
                    <AuthRoute path="/wizard" component={ConfigurationWizard}/> :
                    <Route path="/wizard" component={ConfigurationWizard}/>
            )
        }

        return (
            <span>
            {!configurationReady && <Splash message={(error && errorMessage) || 'loadingConfiguration'}/>}
                {configurationReady &&
                <div className="App">
                    <Switch>
                        {needConfigurationWizard && wizard}

                        {/* LOGIN */}
                        <Route exact path="/login" component={Login}/>

                        {/* HOME */}
                        <Redirect exact from="/" to={routes.HOME.url}/>
                        <DefaultLayout path={routes.HOME.path}
                                       component={Home}/>


                        {/*ADMIN*/}
                        <Redirect exact from={routes.ADMIN.path} to={routes.ADMIN_EPP.url}/>

                        <DefaultLayout path={routes.ADMIN_EPP_PASSWORD.path}
                                       component={EppPassword}/>

                        <DefaultLayout path={routes.ADMIN_POLLING.path}
                                       component={Polling}/>

                        <DefaultLayout path={routes.ADMIN_EMAIL.path}
                                       component={Email}/>

                        <DefaultLayout path={routes.ADMIN_EPP.path}
                                       component={Epp}/>


                        {/* DOMAIN */}
                        <DefaultLayout path={routes.DOMAIN_INFO.path}
                                       component={DomainInfoContainer}/>
                        {/*<Redirect exact from={routes.DOMAIN.url} to={routes.DOMAIN_INFO.url}/>*/}
                        <DefaultLayout path={routes.DOMAIN_CHECK.path}
                                       component={DomainCheckContainer}/>

                        <DefaultLayout path={routes.DOMAIN_NEW.path}
                                       component={DomainWizard}/>
                        <DefaultLayout path={routes.DOMAIN_UPDATE_HOST.path}
                                       component={DomainInfoContainer}/>
                        <DefaultLayout path={routes.DOMAIN_UPDATE_CONTACT.path}
                                       component={DomainInfoContainer}/>
                        <DefaultLayout path={routes.DOMAIN_UPDATE_AUTHINFO.path}
                                       component={DomainInfoContainer}/>
                        <DefaultLayout path={routes.DOMAIN_UPDATE_REGISTRANT.path}
                                       component={DomainInfoContainer}/>
                        <DefaultLayout path={routes.DOMAIN_UPDATE_STATUS.path}
                                       component={DomainInfoContainer}/>
                        <DefaultLayout path={routes.DOMAIN_TRANSFER.path}
                                       component={DomainInfoContainer}/>
                        <DefaultLayout path={routes.DOMAIN_TRANSFER_TRADE.path}
                                       component={DomainTransferContainer}/>
                        {this.redirectToList(routes.DOMAIN_LIST, 1, 20)}
                        <DefaultLayout path={routes.DOMAIN_LIST.path}
                                       component={DomainListContainer}/>

                        {/* CONTACT */}
                        <DefaultLayout exact strict path={routes.CONTACT_INFO.path}
                                       component={ContactInfoContainer}/>
                        <Redirect exact from={routes.CONTACT.url} to={routes.CONTACT_INFO.url}/>
                        <DefaultLayout exact path={routes.CONTACT_UPDATE.path}
                                       component={ContactInfoContainer}/>
                        <DefaultLayout exact path={routes.CONTACT_CHECK.path}
                                       component={ContactCheck}/>
                        <DefaultLayout exact path={routes.CONTACT_NEW.path}
                                       component={ContactNewContainer}/>
                        {this.redirectToList(routes.CONTACT_LIST, 1, 20)}
                        <DefaultLayout exact path={routes.CONTACT_LIST.path}
                                       component={ContactListContainer}/>

                        {/* EPP MESSAGE */}
                        {this.redirectToList(routes.EPP_MESSAGE_LIST, 1, 20)}
                        <DefaultLayout exact strict path={routes.EPP_MESSAGE_LIST.path}
                                       component={EppMessageListContainer}/>

                        {/* NO MATCH*/}
                        <DefaultLayout component={NoMatch}/>

                    </Switch>
                </div>}
          </span>
        );
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        handleSetProfile: (profile) => {
            dispatch(configOperations.setConfigurationProfile(profile))
        },
        loadCountries:
            () => {
                dispatch(countriesOp.loadCountryCodeOp())
            }
    };
}

export default withRouter(connect(null, mapDispatchToProps)(App));
