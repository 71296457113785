import React from 'react';
import PropTypes from 'prop-types';
import {Form, Input} from "antd";

const GovIpaCodeFormItem = (props) => {
    const {intl, form, formItemLayout, disabled, initialValue} = props;

    return (
        <Form.Item className="ant-row ant-from-item"
                   label={intl.formatMessage({id: 'contact.detail.form.registrant.uoCode'})}
                   {...formItemLayout}
                   hasFeedback
        >
            {form.getFieldDecorator('registrant.gov.uoCode', {
                initialValue: initialValue ,
            })(
                <Input
                    disabled={disabled}
                    //il campo una volta impostato, non è più modificabile
                />)
            }
        </Form.Item>
    );
};

GovIpaCodeFormItem.propTypes = {
    intl: PropTypes.object,
    form: PropTypes.object,
    formItemLayout: PropTypes.object,
    disabled: PropTypes.bool,
    initialValue: PropTypes.string,

};
GovIpaCodeFormItem.defaultProps = {
    disabled: false
};

export default GovIpaCodeFormItem;
