import React from 'react';
import {injectIntl} from "react-intl";
import ContactChecked from "../../DataDisplay/ContactChecked/ContactChecked";
import Error from "../../../../commons/components/FeedBack/Error/Error";
import {connect} from "react-redux";
import ContactCheckFormContainer from "../../../containers/EppOperationForm/ContactCheckFormContainer";
import EppOperationView from "app/commons/components/Pages/EppOperationView";

const ContactCheck = (props) => {

    const {intl, checkContactData} = props;
    const {formatMessage} = intl;
    return (
        <EppOperationView
            title={formatMessage({id: "contact.check.title"})}
            operationForm={<ContactCheckFormContainer/>}
            loading={props.loading}
        >
            {checkContactData.data &&
            <ContactChecked
                id={checkContactData.data.id}
                available={checkContactData.data.available}
                description={checkContactData.message}
            />
            }
            {checkContactData.error &&
            <Error error={checkContactData.error}/>
            }
        </EppOperationView>
    );
};

const mapStateToProps = (state) => {
    return {
        checkContactData: state.contact.checkReducer
    };
}


export default injectIntl(connect(mapStateToProps)(ContactCheck));
