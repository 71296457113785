import {Alert} from "antd";
import React from "react";
import {Link} from "react-router-dom";

const ConsentForPublishChecker = ({registrant}) => {

    const isConsentForPublishOK = !showErrorConsentForPublish(registrant);

    if (!isConsentForPublishOK)
        return <Alert
            type='error'
            message='Attenzione'
            showIcon
            style={{marginBottom: 20}}
            description={ErrorConsentForPublishText(registrant.id)}
        />

    return null;
}


// Se EntityType il consentForPublish è
export function showErrorConsentForPublish(contactDetails) {
    let res = false;
    if (!isUndefined(contactDetails)) {
        if (!isUndefined(contactDetails['registrant']) && parseInt(contactDetails['registrant'].entityType) !== 1) {
            res = !contactDetails.consentForPublish
        }
    } else {
        res = true;
    }

    return res;
}

export function isUndefined(value) {
    return !value;
}


export const checkRegistrantConsentForPublish = (registrant) => {
    if (!isUndefined(registrant) && showErrorConsentForPublish(registrant)) {
        return {status: false, msg: ErrorConsentForPublishText(registrant.id)};
    } else {
        return {status: true, msg: ''};
    }
}

export const ErrorConsentForPublishText = (id) => {
    return <div>
        Valore del campo <strong>consentForPublish </strong>(false) per il contatto registrante (<strong>{id}</strong>)
        non conforme alle linee guida 2.5.
        <Link to={"/contact/info/" + id}> Clicca qui </Link>
        per aggiornare e <strong>impostare a true il campo consentForPublish</strong> del contatto e quindi proseguire.
    </div>
}


export default ConsentForPublishChecker;
