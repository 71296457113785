import * as types from "../actionTypes";
import {newContact as apiNewContact} from "../api";

// ** NEW **

export function newContact(data) {
    console.log('NewContact: ', data);
    const response = apiNewContact(data)
    return {
        type: types.CONTACT_NEW,
        payload: response
    }
}

export function newContactStart() {
    return {
        type: types.CONTACT_NEW_START,
    }
}

export function newContactSuccess(response) {
    return {
        type: types.CONTACT_NEW_SUCCESSFUL,
        payload: response
    }
}

export function newContactFailure(error) {
    return {
        type: types.CONTACT_NEW_ERROR,
        payload: error
    }
}


export function resetNewContact() {
    return {
        type: types.CONTACT_NEW_RESET,
    }
}
