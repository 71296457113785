import React, {Component} from 'react';
import {AutoComplete, Form, Input, notification, Switch} from 'antd';

import {injectIntl} from 'react-intl';
import {changedPostStatus} from "../ConfigurationUtil";

const FormItem = Form.Item;

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 5},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 12},
    },
};

/*
host: obbligatorio, nome host dell'SMTP server
port: facoltativo, di default assume porta 25
auth: boolean (default false), attiva/disattiva l'autenticazione
username: nome utente visibile e obbligatorio se attiva autenticazione
password: password utente, visibile e obbligatorio se attiva autenticazione
startTLS: boolean (default false) che attiva/disattiva il TLS per l'invio delle emai
*/


//^([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*)+(\.([a-zA-Z0-9]+(-[a-zA-Z0-9‌​]+)*))*$

const EMAIL_PORTS = ["25", "465", "587"];


class EmailFormWrapper extends Component {

    componentWillReceiveProps(nextProps) {
        const {updateConfiguration} = nextProps;
        const {formatMessage} = nextProps.intl;


        if (updateConfiguration) {
            if (changedPostStatus(updateConfiguration, this.props.updateConfiguration)) {


                if (updateConfiguration.pending) {

                    notification.info({
                        message: formatMessage({id: 'admin.email.saveConf.info.message'}),
                        description: formatMessage({id: 'admin.email.saveConf.info.description'})
                    });


                } else if (updateConfiguration.rejected) {
                    let reason = updateConfiguration.reason.message;
                    notification.error({
                        message: formatMessage({id: 'admin.email.saveConf.error.message'}),
                        description: formatMessage({id: 'admin.email.saveConf.error.description'}, {reason: reason})
                    });
                } else if (updateConfiguration.fulfilled) {
                    notification.success({
                        message: formatMessage({id: 'admin.email.saveConf.success.message'}),
                        description: formatMessage({id: 'admin.email.saveConf.success.description'})
                    });
                }
            }
        }
    }


    render() {
        const {host, port, auth, username, password, startTLS} = this.props.configuration.value;


        const {getFieldDecorator, getFieldValue} = this.props.form;
        const {formatMessage} = this.props.intl;
        //const {authentication,starttls} = this.state;

        let authentication = getFieldValue("auth");

        return (
            <Form>
                <FormItem
                    {...formItemLayout}
                    label={formatMessage({id: 'admin.email.host'})}
                    style={{textAlign: 'left'}}
                    hasFeedback
                >
                    {getFieldDecorator(`host`, {
                        initialValue: host,
                        validateTrigger: ['onChange', 'onBlur'],
                        rules: [{
                            required: true,
                            message: formatMessage({id: 'admin.email.host'}),
                        }],
                    })(
                        <Input
                            placeholder={formatMessage({id: 'admin.email.host.placeholder'})}
                            onChange={(e) => {
                                this.props.onChangeForm({host: e.target.value})
                            }}
                        />
                    )}
                </FormItem>

                <FormItem
                    {...formItemLayout}
                    label={formatMessage({id: 'admin.email.port'})}
                    style={{textAlign: 'left'}}

                    hasFeedback
                >
                    {getFieldDecorator(`port`, {
                        initialValue: (port && port.toString())|| '25',
                        validateTrigger: ['onChange', 'onBlur'],
                        rules: [{
                            required: true,
                            message: formatMessage({id: 'notEmptyField'}),
                        }],
                    })(
                        <AutoComplete
                            dataSource={EMAIL_PORTS}
                            defaultValue={'25'}
                            placeholder={formatMessage({id: "admin.email.port.placeholder"})}
                            onChange={(val) => {
                                this.props.onChangeForm({port: val})
                            }}
                        />
                    )}
                </FormItem>

                <FormItem
                    {...formItemLayout}
                    label={formatMessage({id: 'admin.email.auth'})}
                    style={{textAlign: 'left'}}
                >
                    {getFieldDecorator(`auth`, {
                        valuePropName: 'checked',
                        initialValue: auth,
                        // validateTrigger: ['onChange', 'onBlur'],
                    })(
                        <Switch
                            checkedChildren={formatMessage({id: 'admin.enable'})}
                            unCheckedChildren={formatMessage({id: 'admin.disable'})}
                            onChange={() => {
                                this.props.onChangeForm({auth: auth})
                            }}/>
                    )}
                </FormItem>

                {authentication &&
                <FormItem
                    {...formItemLayout}
                    hasFeedback
                    label={formatMessage({id: 'admin.email.auth.username'})}>
                    {getFieldDecorator(`username`, {

                        validateTrigger: ['onChange', 'onBlur'],
                        initialValue: username,
                        rules: [{
                            required: authentication,
                            message: formatMessage({id: 'notEmptyField'})
                        }],
                    })(
                        <Input placeholder={formatMessage({id: 'admin.email.auth.username.placeholder'})}/>
                    )}
                </FormItem>
                }
                {authentication &&
                <FormItem
                    {...formItemLayout}
                    hasFeedback
                    label={formatMessage({id: 'admin.email.auth.password'})}>
                    {getFieldDecorator(`password`, {
                        rules: [{
                            required: authentication,
                            message: formatMessage({id: 'notEmptyField'})
                        }],
                        initialValue: password,
                        validateTrigger: ['onChange', 'onBlur'],
                    })(
                        <Input type='password'
                               placeholder={formatMessage({id: 'admin.email.auth.password.placeholder'})}/>
                    )}
                </FormItem>
                }
                <FormItem
                    {...formItemLayout}
                    label={formatMessage({id: 'admin.email.startTLS'})}
                    style={{textAlign: 'left'}}
                >
                    {getFieldDecorator(`startTLS`, {
                        valuePropName: 'checked',
                        initialValue: startTLS,
                        // validateTrigger: ['onChange', 'onBlur'],
                    })(
                        <Switch defaultChecked={startTLS}
                                checkedChildren={formatMessage({id: 'admin.enable'})}
                                unCheckedChildren={formatMessage({id: 'admin.disable'})}
                                onChange={() => {
                                    this.props.onChangeForm({startTLS: startTLS})
                                }}/>
                    )}
                </FormItem>

            </Form>

        )
    }
}

const EmailForm = Form.create()(injectIntl(EmailFormWrapper));
export default EmailForm;
