import React from 'react';
import PropTypes from 'prop-types';
import SelectEntityType from "app/commons/components/DataEntry/SelectEntityType/SelectEntityType";
import {Form} from "antd";

const RegEntityTypeFormItem = (props) => {
    // console.log("RegEntityTypeFormItem ",props)
    const {intl,form,initialValue,disabled} = props;
    return (
        <Form.Item className="ant-row ant-from-item"
                  label={intl.formatMessage({id: 'contact.detail.form.registrant.entityType'})}
                  {...props.formItemLayout}
            // hasFeedback
        >
            {form.getFieldDecorator('registrant.entityType', {
                initialValue: initialValue ,
                rules: [{
                    required: true,
                    message: intl.formatMessage({id: 'contact.detail.form.registrant.entityType.message'}),
                    type: 'number'
                }],
            })(
                <SelectEntityType
                    {...props}
                    // onChange={this.handleOnChange}
                    //il campo una volta impostato, non è più modificabile
                    disabled={disabled}
                    // disabled={!this.hasEntitypeEditPermission()}
                />
            )}
        </Form.Item>
    );
};

RegEntityTypeFormItem.propTypes = {
    isEdu : PropTypes.bool,
    isGov: PropTypes.bool,
    initialValue : PropTypes.number,
    disabled: PropTypes.bool
};
RegEntityTypeFormItem.defaultProps = {};

export default RegEntityTypeFormItem;
