import {digestTypes} from "app/domains/constants/common";
import _ from "lodash"


export const getDSRecordsValues = (datasource, fields) => {
    let dsRecords = [];
    datasource && datasource.forEach((ds, i) => {
        let digestT = fields[`digest-${ds}Type`];
        // console.log("fields[`digest-${ds}Type`] ", digestT, digestTypes[digestT])
        dsRecords.push(
            {
                key: i,
                keyTag: fields[`keyTag-${ds}`],
                alg: fields[`algorithm-${ds}`],
                digestType: digestTypes[digestT].id,
                digest: fields[`digest-${ds}`]
            }
        )
    })
    return dsRecords
}

//Costruisce l'array di dsRecordsToAdd, e' nuovo se non e' tra i rimossi e se non e' tra quelli da copiare
export const buildDsRecordsToAdd = (fields) => {
    return getDSRecordsValues(_.filter(fields.dsrecords,
        function (obj) {
            return !fields.dsRecordsToCopy.includes(obj) && !fields.dsRecordsToRemove.includes(obj)
        }
    ), fields);
}

/* ritorna una array contenente le labels uguali */
export function isTheSame(istance1, istance2) {

    let labels = ['keyTag', 'alg', 'digestType', 'digest']
    let result = labels.reduce((acc, label) => {
        return istance1[label].toString() !== istance2[label].toString() ? acc : acc.concat(label)
    }, []);

    return result.length === labels.length;
}

/* Ritorna un array di oggetti uguali risultato del confronto */
export function similarObjects(arrObj1, arrObj2) {
    let result = [];
    Object.keys(arrObj1).forEach(
        (kRem) => Object.keys(arrObj2).forEach((kAdd) => {
            if (isTheSame(arrObj1[kRem], arrObj2[kAdd])) {
                result.push(arrObj1[kRem]);
            }
        }));
    return result;
}

function markAsOutRecords(arr, value){
    return arr.map(function(el) {
        let o = Object.assign({}, el);
        o.out = value;
        return o;
    })
}

export function buildSummaryRecords(arrIn,arrOut){

    let arrayIn = markAsOutRecords(arrIn,false);
    let arrayOut = markAsOutRecords(arrOut,true);

    return _.concat(arrayIn,arrayOut);
}