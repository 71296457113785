import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {notificationError, notificationSuccess} from "../utils/notification";
import {printMessageError} from "../utils/common";
import {injectIntl} from "react-intl";
import Error from "../components/FeedBack/Error/Error";

class DomainBoxContainer extends Component {

    state = {
        message: this.props.message,
        error: this.props.error
    }

    componentWillMount() {
        this.props.reset && this.props.reset()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.message) {
            if (this.state.message !== nextProps.message) {
                this.setState({message: nextProps.message})
            }
        }

        if (nextProps.error) {
            if (this.state.error !== nextProps.error) {
                this.setState({error: nextProps.error})
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.state.message !== null && this.state.message !== prevState.message) {
            notificationSuccess(this.state.message)
        }
        if (this.state.error !== null && this.state.error !== prevState.error) {
            notificationError(this.props.intl.formatMessage(
                {id: 'domain.action.error'},
                {errorMessage: printMessageError(this.state.error)}))
        }
    }

    render() {
        return (
            <div>
                <Error error={this.props.error}/>
            </div>
        );
    }
}

DomainBoxContainer.propTypes = {
    error: PropTypes.object,
    message: PropTypes.string,
    reset: PropTypes.func //funzione per il reset delle notifche ed errori
};
DomainBoxContainer.defaultProps = {};

export default injectIntl(DomainBoxContainer);
