import React, {Component} from "react";

export const toolBarWithDetailRender = (ToolBar, WrappedComponent) => {
    return class ToolBarWithStateHOC extends Component {
        state = {
            viewType: 'tab',
        };

        static defaultProps = {
            onClick: () => null
        };

        onClick = event => {
            this.setState({viewType: event});
        };

        render() {
            return (
                <div>
                    <ToolBar {...this.props} onClick={this.onClick}/>
                    <div>
                        <WrappedComponent
                            {...this.props}
                            viewType={this.state.viewType}
                        />
                    </div>
                </div>
            )
        }
    }
}


