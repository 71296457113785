import {connect} from "react-redux";
import React from "react";
import {Redirect, withRouter} from "react-router-dom";
import {injectIntl} from "react-intl";
import {printMessageError} from "../../commons/utils/common";
import {authOperations} from "../index";
import LoginForm from "app/authentication/components/LoginForm";
import PropType from 'prop-types'

export class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            redirectToReferrer: false,
            values: null,
        }
        this.handleLogInSubmit = this.handleLogInSubmit.bind(this)
    }

    //SignUp
    handleLogInSubmit = (e, refForm) => {
        e.preventDefault();
        refForm.props.form.validateFields((err, values) => {
                if (!err) {
                    this.setState({values: values})
                }
                this.submitData(values);
            }
        )
    }

    submitData(values) {
        const username = values['userName'] && values['userName'].trim();
        const password = values['password'] && values['password'].trim();
        if (username && password) {
            this.props.login(username, password);
            // if (this.props.isAuthenticated) {
            //     this.setState({redirectToReferrer: true})
            // }
        }
    }

    //Estrae se c'è il messaggio di errore da passare alla form
    printError = (error) => {
        // if (error && error.payload.status === 401) {
        //     return <FormattedMessage id='login.error.wrongCredentials'/>
        // } else {
            return printMessageError(error.payload)
        // }
    }

    render() {

        const defaultPathname = '/home';
        const from = this.props.location.state.from || defaultPathname;
        const {redirectToReferrer} = this.state;
        const {loginFailed, loginError, isAuthenticated} = this.props;
        let refForm;
        // console.log("PROPS ", this.props,from,defaultPathname);
        //Se è già autenticato o appena autenticato allora redirigi to "from"
        if (redirectToReferrer || isAuthenticated) {
            return (
                <Redirect to={from}/>
            )
        }

        //Visualizza Login Form
        return (
            <LoginForm
                {...this.props}
                wrappedComponentRef={(ref) => refForm = ref}
                logIn={(e) => this.handleLogInSubmit(e, refForm)}
                loginFailed={loginFailed}
                error={loginError && this.printError(loginError)}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loginError: state.authReducer.error,
        loginFailed: state.authReducer.failed,
        isAuthenticated: state.authReducer.authenticated
    };
}

export const mapDispatchToProps = (dispatch) => {
    return {
        login: (username, password) => {
            dispatch(authOperations.doLogin(username, password))
        },
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl((Login))));

Login.propType = {
    login: PropType.func.isRequired,
}