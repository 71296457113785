import React from "react";
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import RegistrantForm from "./RegistrantForm";
import {connect} from "react-redux";
import DomainInfoUpdateBox from "../../Box/DomainInfoCustomableBox";


class DomainUpdateRegistrant extends React.Component {

    render() {
        const {infoDomainData, intl} = this.props;
        const {formatMessage} = intl;

        return (
            <div>
                <DomainInfoUpdateBox
                    title={formatMessage({id: "domain.update.registrant.form.title"})}
                    hasRegistrarPermission={this.props.hasRegistrarPermission}
                    infoData={this.props.infoDomainData.data}
                    error={this.props.updateDomainHostData.error}
                    loading={this.props.updateDomainHostData.loading}
                    message={this.props.updateDomainHostData.message}
                    reset={this.props.reset}
                >
                    <RegistrantForm
                        data={infoDomainData.data}
                        onCancel={this.props.onCancel.bind(this)}
                        onSubmit={this.props.onUpdateRegistrant}
                        submitLabel={formatMessage({id: "domain.update.registrant"})}
                    />
                </DomainInfoUpdateBox>
            </div>
        )
    }
}

DomainUpdateRegistrant.propTypes = {
    hasRegistrarPermission: PropTypes.bool,
    onCancel: PropTypes.func,
    reset: PropTypes.func,
    onUpdateRegistrant: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        infoDomainData: state.domain.infoReducer,
        updateDomainHostData: state.domain.updateReducer,
    };
}

export default connect(mapStateToProps)(injectIntl(DomainUpdateRegistrant));