import React, {Component} from 'react';
import DsRecordsDetail from "app/domains/components/DataDisplay/DsRecordsDetail/DsRecordsDetail";
import {Form} from "antd";
import {injectIntl} from "react-intl";
import PropTypes from "prop-types";
import {digestTypes} from '../../../../constants/common';
import _ from "lodash"

const FormItem = Form.Item;

class Step3DSSelection extends Component {

    constructor(props) {
        super(props);

        this.hanldeRowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                const {setFieldsValue, getFieldValue} = this.props.form;
                const {fields} = this.props;

                let idxDsRecordsToRemove = []
                let currentKey = parseInt(selectedRowKeys, 10);
                let dsrecords = getFieldValue('dsrecords');
                let idxDsRecordsToCopy = getFieldValue('dsRecordsToCopy');
                dsrecords.splice(currentKey, 1)
                // console.log("cKey ",currentKey)
                //Aggiunge chiave agli indici dei record da cancellare
                idxDsRecordsToRemove = idxDsRecordsToRemove.concat(currentKey)
                //Rimuove la chiave dagli indici dei record da mantenere "copiare" nei prossimi steps
                idxDsRecordsToCopy = idxDsRecordsToCopy.splice(currentKey, 1)

                // console.log(" idxDsRecordsToRemove",idxDsRecordsToRemove)
                // console.log(" idxDsRecordsToCopy",idxDsRecordsToCopy)

                //Creae l'array dei dsRecords che contiene il dsRecord con tutti i valori
                let dsRecordsToRemove = _.filter(fields.domainData.dsRecords,
                    function (obj, index) {
                        return idxDsRecordsToRemove.includes(index)
                    }
                )

                // console.log(" dsRecordsToRemove",dsRecordsToRemove)

                setFieldsValue({dsRecordsToRemove: dsRecordsToRemove}); //imposta i dsRecord da selezionare
                setFieldsValue({dsRecordsToremove: idxDsRecordsToRemove}); //imposta i dsRecord da selezionare
                setFieldsValue({dsrecords: dsrecords.splice(currentKey, 1)}); //imposta i dsRecord da selezionare
                setFieldsValue({dsRecordsToCopy: idxDsRecordsToCopy}); //imposta i dsRecord da selezionare
            }
        }

    }

    componentWillMount() {

        const {dsRecords} = this.props.fields.domainData;
        const {getFieldDecorator} = this.props.form;
        let dsRecordKeys = [];

        getFieldDecorator('dsRecordsToRemove', {initialValue: []}); //imposta i dsRecord da selezionare
        getFieldDecorator('dsRecordsToremove', {initialValue: []}); //imposta i dsRecord da selezionare

        //Esegue la scrittura dei campi da passare (di default) allo step successivo
        if (dsRecords) {

            // this.setState({dsRecordsToCopy: hosts.slice()});
            dsRecords.forEach((ds, k) => {
                let dsRecIndex = k;
                // console.log("index ", dsRecIndex)
                getFieldDecorator(`keyTag-${dsRecIndex}`, {initialValue: ds.keyTag})
                getFieldDecorator(`algorithm-${dsRecIndex}`, {initialValue: ds.alg})
                getFieldDecorator(`digest-${dsRecIndex}Type`, {initialValue: _.findKey(digestTypes, {id: ds.digestType})});
                getFieldDecorator(`digest-${dsRecIndex}`, {initialValue: ds.digest});
                dsRecordKeys = dsRecordKeys.concat(dsRecIndex)
            });
        }
        getFieldDecorator('dsRecordsToCopy', {initialValue: dsRecordKeys}); //imposta i dsRecord da selezionare
        getFieldDecorator('dsrecords', {initialValue: dsRecordKeys}); //imposta i dsRecord da selezionare

    }

    /* *****

     * ------ *
     * FIELDS *
     * ------ *

    - dsrecords []  = contiene gli indici (gli unici possibili sono 3,4 (non so perche' partono da 2) ) che corrispondo ai DS Record
                      associati al dominio (massimo 2 per questo sono solo 3 e 4)
                      Quando presenti, se passati allo step successivo via 'form' servono per recuperare i valori  del DSRecord associato:
                      Es. Per dsRecords[3] => esisteranno i campi {keyTag-3, algoritm-3, digest-3, ......}

    - dsRecordsToCopy [] = vorrebbe essere il parametro che contiene i dsRecords da visualizzare in readonly  allo step successivo.
    - dsRecordsToRemove [] = vorrebbe essere il parametro che contiene i dsRecords da visualizzare in readonly  allo step successivo.

    */

    render() {
        const {fields} = this.props;
        const {formatMessage} = this.props.intl;

        return (
            <div>
                <Form>
                    <FormItem>
                        {
                            fields.domainData.dsRecords && (
                                <div className="riquadro bordo with-title">
                                    <div
                                        className="title">{formatMessage({id: 'domain.update.host.step3.table.actual'})}</div>
                                    <DsRecordsDetail
                                        dsRecords={fields.domainData.dsRecords}
                                        onRowSelection={this.hanldeRowSelection}
                                    />
                                </div>
                            )
                        }
                    </FormItem>
                </Form>
            </div>
        );
    }
}

Step3DSSelection.propTypes = {
    fields: PropTypes.object
};
Step3DSSelection.defaultProps = {};

export default Form.create()(injectIntl(Step3DSSelection));
