import React, {Component} from 'react';
import {Button, Col, Row, Tooltip} from "antd";
import PropTypes from 'prop-types';

import "./EppMessageDetail.css"
import * as types from "../../constants/constants";
import {injectIntl} from "react-intl";
import BrokenDelegationMessage from "../EppMessagesType/BrokenDelegationMessage";
import ChangeStatusMessage from "../EppMessagesType/ChangeStatusMessage";
import CreditMessage from "../EppMessagesType/CreditMessage";
import DelayDebitRefoundMessage from "../EppMessagesType/DelayDebitRefoundMessage";
import BaseMessage from "../EppMessagesType/SimpleMessage";
import TransferMessage from "../EppMessagesType/TransferMessage";
import RefoundRenewsBulkTransferMessage from "../EppMessagesType/RefoundRenewsBulkTransferMessage";
import WrongNamespaceReminderMessage from "../EppMessagesType/WrongNamespaceReminderMessage";
import DnsErrorMessage from "../EppMessagesType/DnsErrorMessage";

const ButtonGroup = Button.Group;

const Header = (props) => {
    const {detail, intl} = props
    const {formatDate, formatTime,formatMessage} = intl
    const formattingDate = {
        year: 'numeric', day: 'numeric', month: 'short',
    };

    const formattingTime = {
        hour: 'numeric',
        minute: 'numeric',
    }

    let domain;

    if (detail && detail.domain) {
        domain = <span><i className="fal fa-globe" style={{marginRight: '5px'}}/>{detail.domain}</span>;
    }

    if (detail) {
        return (
            <div className='eppMessage-detail-header'>
                <Row className={'row'}>
                    <Col span={17}>
                        <div className='header-left'>
                            <span className='domain'>{formatMessage({id:detail.type})}</span>
                            <span className='type'>{detail.domain ? domain : ''}</span>
                        </div>
                    </Col>
                    <Col span={2}>
                        <div style={{float: 'right'}}>
                            <ButtonGroup>
                                <Tooltip title='Elimina'>
                                    <Button icon="delete" onClick={props.onDelete}/>
                                </Tooltip>
                                <Tooltip title={detail.read ? 'Segna come da leggere' : 'Segna come letto'}>
                                    <Button
                                        icon={props.read ? 'eye-o' : 'eye'}
                                        onClick={props.onMark}
                                    />
                                </Tooltip>
                            </ButtonGroup>
                        </div>
                    </Col>
                    <Col span={5}>
                        <div className='header-right'>
                            <span
                                className='date'>{formatDate(detail.date, formattingDate)} {formatTime(detail.date, formattingTime)}</span>
                        </div>
                    </Col>
                    {/*<Col span={1}>*/}
                        {/*<div className={`message-detail-icon ${detail.read ? 'read' : 'unread'}`}>*/}
                            {/*<i className={`fal ${detail.read ? 'fa-envelope-open' : 'fa-envelope'}`}/>*/}
                        {/*</div>*/}
                    {/*</Col>*/}
                </Row>
            </div>
        )
    }
    else return <span/>
}


const TypedLayout = ({detail}) => {
    switch (detail.type) {
        case types.BROKEN_DELEGATION_MESSAGE:
            return <BrokenDelegationMessage detail={detail}/>
        case types.CHANGE_STATUS_MESSAGE:
            return <ChangeStatusMessage detail={detail}/>;
        case types.CREDIT_MESSAGE:
            return <CreditMessage detail={detail}/>;
        case types.DELAYED_DEBIT_AND_REFUND_MESSAGE:
            return <DelayDebitRefoundMessage detail={detail}/>;
        case types.SIMPLE_MESSAGE:
            return <BaseMessage {...detail}/>;
        case types.TRANSFER_MESSAGE:
            return <TransferMessage detail={detail}/>;
        case types.REFUND_RENEWS_FOR_BULKTRANSFER_MESSAGE:
            return <RefoundRenewsBulkTransferMessage detail={detail}/>;
        case types.DNS_WARNING_MESSAGE:
            return <bold>aaa</bold>;
        case types.PASSWORD_REMINDER_MESSAGE:
            return <bold>aaa</bold>;
        case types.REMAPPED_IDN_MESSAGE:
            return <bold>aaa</bold>;
        case types.DNS_ERROR_MESSAGE:
            return <DnsErrorMessage detail={detail}/>;
        case types.WRONG_NAMESPACE_REMINDER_MESSAGE:
            return <WrongNamespaceReminderMessage detail={detail}/>;
        default:
            return (
                <BaseMessage {...detail}/>
            )
    }
}

const Body = (props) => {
    const {detail} = props;
    if (detail && detail.type) {
        return (
            <div className={'eppMessage-detail-body'}>
                <TypedLayout detail={detail}/>
            </div>
        )
    } else {
        return <span/>
    }
}


class EppMessageDetail extends Component {

    render() {
        return (
            <div>
                <Header
                    read={this.props.read}
                    detail={this.props.detail}
                    intl={this.props.intl}
                    onMark={this.props.onMarkMessage}
                    onDelete={this.props.onDeleteMessage}
                />
                <Body
                    detail={this.props.detail}
                />
            </div>
        );
    };
}

EppMessageDetail.propTypes = {
    detail: PropTypes.object,
    onDeleteMessage: PropTypes.func,
    onMarkMessage: PropTypes.func,

};
EppMessageDetail.defaultProps = {};

export default injectIntl(EppMessageDetail);
