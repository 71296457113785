import React from 'react';
import "./EppMessagesType.css"
import _ from "lodash"
import BaseMessage from "./BaseMessage";

const BrokenDelegationMessage = (props) => {

    function Detail({data}) {
        if (data) {
            return (
                <div className={'typedLayout-container'}>
                    {data &&
                    <div>
                        <div className={'typedLayout-data'}>
                            {data['names'] &&
                            <div className={'typedLayout-row'}>

                                <span className={'label'}>Nome a dominio:</span>
                                <span className={'content'}> {
                                    <ul className={'unstyled-list'}>{
                                        _.values(data['names']).map(function (value) {
                                                return <li>{value}</li>
                                            }
                                        )
                                    }</ul>
                                }</span>

                            </div>
                            }
                            {data['nameserver'] &&
                            <div className={'typedLayout-row'}>
                                <span className={'label'}>Nameserver:</span>
                                <span className={'content'}>{props.detail.data['nameserver']}</span>
                            </div>
                            }
                        </div>
                    </div>}
                </div>
            )
        }
        else {
            return <span/>
        }
    }
    return (
        <BaseMessage
            detail={props.detail}
            component={<Detail data={props.detail.data}/>}
        />
    );
};

BrokenDelegationMessage.propTypes = {};
BrokenDelegationMessage.defaultProps = {};

export default BrokenDelegationMessage;
