import React, {Component} from 'react';
import PropTypes from "prop-types";
import styled from 'styled-components'

const ToolbarContainer = styled.div`
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #ebedee;
    @media (max-width: 500px) {
      flex-direction: column;
    }
`;

const RightContainer = styled.div`
    display: flex;
    min-width: 10%;
    justify-content: flex-end;
    align-items: center;
    @media (max-width: 900px) {
      flex-wrap: wrap;
    }
`;

class DetailToolbar extends Component {
    onClick = event => {
        this.props.onClick(event)
    };

    render() {
        return (
            <ToolbarContainer>
                {this.props.statusBar}
                {(this.props.actionMenu && this.props.viewSelector) &&
                <RightContainer>
                    {
                        this.props.showActionMenu && this.props.actionMenu
                    }
                    {React.cloneElement(this.props.viewSelector, {onClick: this.onClick})}
                </RightContainer>}
            </ToolbarContainer>
        )
    }
}

export default DetailToolbar;

DetailToolbar.propTypes = {
    statusBar: PropTypes.object,
    viewSelector: PropTypes.object,
    actionMenu: PropTypes.object,
    showActionMenu: PropTypes.bool,
    onClick: PropTypes.func,
};
DetailToolbar.defaultProps = {
    showActionMenu: false,
};

