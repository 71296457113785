import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Pagination} from "antd";

class HalPagination extends Component {

    render() {

        const {size, totalElements, number} = this.props.HAL_page;
        return (
            <div style={{margin: 10}}>
                <Pagination
                    onChange={this.props.onShowSizeChange}
                    current={number + 1}
                    total={totalElements}
                    pageSize={size}
                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total}`}
                    showSizeChanger={true}
                    onShowSizeChange={this.props.onShowSizeChange}
                    pageSizeOptions={this.props.pageSizeOptions}
                />
            </div>
        );
    }
}

HalPagination.propTypes = {
    HAL_page: PropTypes.object.isRequired,
    onShowSizeChange: PropTypes.func,
    onChange: PropTypes.func,
    pageSizeOptions: PropTypes.array
};

HalPagination.defaultProps = {
    pageSizeOptions: ['20', '50', '100']
};


export default HalPagination;