import * as types from "../actionTypes";

const INITIAL_STATE = {
    loading: false, data: null, error: null, message: null
    // domainNotify: {message: null, error: null}
}
const updateReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        //UPDATE (Common)
        case types.DOMAIN_UPDATE_START: {
            return {
                ...state,
                loading: true,
                data: action.payload,
                message: null,
                error: null
            }
        }
        case types.DOMAIN_UPDATE_SUCCESSFUL: {
            return {
                ...state,
                data: action.payload.data,
                loading: false,
                error: null,
                message: action.payload.message
                // domainNotify: {message: 'Operazione eseguita'}
            }
        }
        case types.DOMAIN_UPDATE_ERROR : {
            return {
                ...state,
                loading: false,
                data: null,
                message: null,
                error: action.payload
                // domainNotify: {error: action.payload}
            }
        }
        case types.DOMAIN_UPDATE_RESET  : {
            return {
                ...state,
                loading: false,
                data: null,
                message: null,
                error: null
            }
        }

        //UPDATE HOST
        case types.DOMAIN_UPDATE_HOST: {
            return {
                ...state,
                loading: true,
                data: action.payload,
                message: null,
                error: null
            }
        }

        //UPDATE HOST
        case types.DOMAIN_UPDATE_REGISTRANT: {
            return {
                ...state,
                loading: true,
                data: action.payload,
                message: null,
                error: null
            }
        }

        //UPDATE SIMPLE
        case types.DOMAIN_UPDATE_SIMPLE: {
            return {
                ...state,
                loading: true,
                data: action.payload,
                message: null,
                error: null
            }
        }


        default:
            return state
    }
}

export default updateReducer;