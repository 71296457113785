import React from 'react';
import {Alert, Button, Col, Form, Row, Switch} from 'antd';
import ContactDetail from "../../../../contacts/components/DataDisplay/ContactDetail/ContactDetail";
import {withRouter} from "react-router-dom";
import {injectIntl} from "react-intl";
import PropTypes from "prop-types";
import AuthinfoFormItem from "../../../../commons/components/DataEntry/AuthinfoFormItem/AuthinfoFormItem";
import ContactLookUpFormItem
    from "app/commons/components/DataEntry/ContactLookUpFormItem/ContactLookUpFormItem";
import {isGovDomain} from "app/commons/utils/utils";
import GovValidationCodeFormItem from "app/domains/components/Pages/DomainNew/GovValidationCodeFormItem";

const FormItem = Form.Item;

const formAuthInfoLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 5},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 18},
    },
};

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 5},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 'contact'},
    },
};

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class TransferTradeFormWrapped extends React.Component {

    state = {
        error: undefined,
        newRegistrant: null,
        tradeDisabled: true,
        domainName: this.props.data.name,
    }

    onChange = (checked) => {
        this.setState({
            tradeDisabled: !checked,
        });
    }

    //Valida e se tutti i controlli sono ok esegue la submit
    validateAndSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
                const {domain} = this.props.match.params;
                if (this.state.tradeDisabled) {
                    this.props.onSubmit(domain, {});
                } else if (!err) {
                    const registrantId = values.id.trim();
                    let authInfo;
                    if (values.authInfo) {
                        authInfo = values.authInfo.trim();
                    }

                    if (registrantId) {
                        let data = {authinfo: authInfo, registrant: registrantId};
                        if (isGovDomain(domain)) {
                            let govValidationCode;
                            if (values.govValidationCode) {
                                govValidationCode = values.govValidationCode.trim();
                            }

                            data['govValidationCode'] = govValidationCode;
                        }

                        this.props.onSubmit(domain, data);
                    }
                }
            }
        )
    }

    isFieldModified = (field, initialValue) => {
        const {getFieldValue, getFieldError} = this.props.form;
        return (initialValue !== getFieldValue(field)) && getFieldValue(field) !== undefined && !getFieldError(field)
    }

    canBeSubmitted = () => {
        const {getFieldsError} = this.props.form;
        if (this.state.tradeDisabled) {
            return true
        } else if (!hasErrors(getFieldsError()))
            return (this.isFieldModified('id', this.props.data.adminId)
                && this.isFieldModified('authInfo', this.props.data.authinfo)
            && (isGovDomain(this.state.domainName) && (this.isFieldModified('govValidationCode', this.props.data.govValidationCode))))
        else
            return false
    }


    handleSelectRegistrant = (data) => {
        this.setState({newRegistrant: data})

        if (isGovDomain(this.state.domainName)) {
            if (data.registrant.gov === undefined) {
                this.setState({error: `Registrante non compatibile con dominio ${this.state.domainName}. Manca codice IPA`})
            }
        } else {

            if (data.registrant.gov !== undefined) {
                this.setState({error: `Registrante non compatibile con dominio ${this.state.domainName}. E' Registrante GOV.IT`})
            } else {

                this.setState({error: undefined})
            }
        }
    }


    render() {
        const {form, intl, data} = this.props
        const {formatMessage} = intl;
        const domainName = data.name;
        return (
            <Form onSubmit={this.validateAndSubmit} style={{'marginTop': '40px'}}>
                {this.state.error !== undefined &&
                <Alert style={{marginBottom: 20}}
                       message="Attenzione" type="error" description={this.state.error}
                       showIcon/>
                }
                <FormItem
                    label={'Trade'}
                    {...formItemLayout}
                    style={{textAlign: 'left'}}
                >
                    <Switch
                        checkedChildren={formatMessage({id: 'enabled'})}
                        unCheckedChildren={formatMessage({id: 'disabled'})}
                        onChange={this.onChange}
                    />

                </FormItem>

                {!this.state.tradeDisabled &&
                <div>
                    <Row gutter={8}>
                        <Col span={12}>
                            <div className="riquadro bordo with-title" style={{backgroundColor: '#f7f7f782'}}>
                                <div
                                    className="title">{formatMessage({id: 'domain.update.form.registrant.actual'})}</div>
                                <ContactDetail data={this.props.data.registrant} linkable={true}
                                               showRegistrantInfo={true}
                                               withBorderSpace={true}/>
                            </div>
                        </Col>
                        <Col span={12}>
                            {this.state.newRegistrant &&
                            <div className="riquadro bordo with-title" style={{backgroundColor: '#f2ffe2'}}>
                                <div
                                    className="title">{formatMessage({id: 'domain.update.form.registrant.new'})}</div>
                                <ContactDetail data={this.state.newRegistrant} linkable={true}
                                               showRegistrantInfo={true}
                                               withBorderSpace={true}
                                               error={this.state.error}
                                />
                            </div>
                            }
                        </Col>
                    </Row>

                    <div className="riquadro bordo with-title">
                        <div className="title">{formatMessage({id: 'domain.update.form.registrant.modify'})}</div>
                        <ContactLookUpFormItem form={form}
                                               formItemLayout={formItemLayout}
                                               onSelectedRegistrant={this.handleSelectRegistrant}
                                               showContactBar={false}
                                               domainName={this.state.domainName}
                                               labelContactId={formatMessage({id: 'domain.update.form.registrantId'})}
                        />
                        <AuthinfoFormItem
                            form={form}
                            formAuthInfoLayout={formAuthInfoLayout}
                        />

                        <GovValidationCodeFormItem
                            form={form}
                            domain={domainName}
                            intialValue={undefined}
                            formAuthInfoLayout={formItemLayout}
                        />

                    </div>
                </div>
                }

                <FormItem style={{borderTop: '1px solid #e9e9e9'}}>
                    <div style={{paddingTop: '10px',textAlign:'center'}}>
                        <Button style={{marginRight: '25px'}} onClick={this.props.onCancel}>
                            {formatMessage({id: "cancel"})}
                        </Button>
                        <Button
                            type='primary'
                            htmlType="submit"
                            disabled={!this.canBeSubmitted()}
                        >
                            {formatMessage({id: 'domain.transfer.submit'})}
                        </Button>
                    </div>
                </FormItem>
            </Form>
        );
    }
};


const TransferTradeForm = Form.create()(withRouter(injectIntl(TransferTradeFormWrapped)));

export default (TransferTradeForm);

TransferTradeForm.propTypes = {
    submitLabel: PropTypes.string.isRequired,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    data: PropTypes.object

}