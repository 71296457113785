import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import ContactActionMenu from "../components/ContactActionMenu/ContactActionMenu";
import {Modal} from "antd";
import {injectIntl} from "react-intl";
import * as routes from "../../routes";
import {contactsOperations} from "../index";

const confirm = Modal.confirm;

class ContactActionMenuContainer extends Component {

    handleDeleteContact = (contact) => {
        const {formatMessage} = this.props.intl;
        const {deleteContactAction} = this.props;
        confirm({
            title: formatMessage({id: 'contact.action.delete.title'}, {name: contact}),
            content: formatMessage({id: 'contact.action.delete.content'}, {name: contact}),
            okText: formatMessage({id: 'contact.action.delete.okText'}),
            okType: 'danger',
            cancelText: formatMessage({id: 'domain.info.delete.cancelText'}),

            onOk() {
                deleteContactAction(contact)
            },
            onCancel() {
            },
        });
    }

    handleEditContact = () => {
        const {contact} = this.props.match.params;
        this.props.resetNotifications()
        this.props.history.push({pathname: `${routes.CONTACT_UPDATE.url}/${contact}`})
    }

    render() {
        return <ContactActionMenu
            contactData={this.props.contactData}
            onModify={this.handleEditContact.bind(this)}
            onDelete={this.handleDeleteContact.bind(this)}
        />
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {

        deleteContactAction: (contactId) => {
            dispatch(contactsOperations.deleteContactOp(contactId,
                () => (ownProps.history.push({pathname: `${routes.CONTACT_INFO.url}`}))
                )
            )
        },
        resetNotifications: () => {
            dispatch(contactsOperations.resetNotificationsInfoContact())
        }

    };
};

const mapStateToProps = (state) => {
    return {
        contactData: state.contact.infoReducer,
    };
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ContactActionMenuContainer)));
