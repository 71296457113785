import React from 'react';
import {Tag, Icon, Popover} from "antd";
import ContactDetail from '../../../../contacts/components/DataDisplay/ContactDetail/ContactDetail';
import "./DomainContacts.css";
import PropTypes from "prop-types";

const ContactInfoBar = (props) =>{
  let org = (props.data.org && props.data.name !== props.data.org)?<span><i className="fal fa-building"/> <Tag>{props.data.org}</Tag></span>:'';
  return (
      <Popover overlayClassName="contactPopover"
               content={<ContactDetail data={props.data} showRegistrantInfo={props.showRegistrant}/>}>
      <span className="contact-info-alignment"><Icon className="contact-info-bar" type="user"/><Tag>{props.data.name}</Tag>{org}</span>
    </Popover>
  )

}

export default ContactInfoBar;


ContactInfoBar.propTypes = {
    data: PropTypes.object,
    showRegistrant: PropTypes.bool
}
