import React, {Component} from 'react';
import {Form, Input} from 'antd';

import {injectIntl} from 'react-intl';
import {checkDomain} from "../../../api";
import AuthinfoFormItem from "../../../../commons/components/DataEntry/AuthinfoFormItem/AuthinfoFormItem";
import itDomainValidator from "../../../../commons/utils/validators/ItDomainValidator";
import PropTypes from "prop-types";
import GovValidationCodeFormItem from "./GovValidationCodeFormItem";

const FormItem = Form.Item;


const AUTH_INFO = {
    MIN: 3,
    MAX: 32
}

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 5},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 12},
    },
};
const formAuthInfoLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 5},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 18},
    },
};

const isEduDomain = (domain) => (domain.endsWith('.edu.it'));
const isReserved = (message) => {
    let result = false;
    const testWords = ['reserved','riservato'];
    testWords.forEach( word => message.includes(word)?result = true:'')
    return result;
};


class DomainNameWrapped extends Component {
    state = {
        domainName: this.props.fields['domainName']
    }


    validateDomainName = (role, value, callback) => {
        const {formatMessage} = this.props.intl;


        if (value) {
            let isValid = itDomainValidator.validate(value);

            if (isValid && isValid.valid) {
                // Controllo se il dominio è stato registrato o meno
                checkDomain(value)
                    .then((result) => {
                        if (result.data.available) {
                            callback();
                        } else {
                            /**
                             * Controllo aggiuntivo inserito per il .edu.it
                             * Se il dominio è .edu.it non deve più essere controllato il stato di 'availabity'
                             * cosa che verrà demandata esclusivamente all'eppserver.
                             * Questo perchè o domini riservati per definizione (ad es. italia.edu.it)
                             * non possono essere diversificati da quelli riservati per il .edu (ad es. scuolatest.edu.it)
                              */
                            if (isEduDomain(value) && isReserved(result.data.message)) {

                                callback();
                            }
                            callback(new Error(result.data.message));
                        }
                    })
                    .catch((error) => {
                        callback(new Error("Errore connessione con il server. Controlla "));
                    })
            } else {
                callback(new Error(formatMessage({id: isValid.error})));
            }
        } else {

            callback(new Error(formatMessage({id: 'notEmptyField'})));
        }

    }


    authInfoValidator = (rule, value, callback) => {
        const {formatMessage} = this.props.intl;
        if (value) {
            if (value.length <= AUTH_INFO.MAX && value.length >= AUTH_INFO.MIN) {
                callback()
            } else {
                callback(new Error(formatMessage({id: 'domain.new.Wizard.authInfoLen'}, {
                    "length": value.length,
                    "min": AUTH_INFO.MIN,
                    "max": AUTH_INFO.MAX
                })));
            }
        } else {
            callback(new Error(formatMessage({id: 'domain.new.Wizard.emptyField'})))
        }

    }

    render() {

        const {getFieldDecorator} = this.props.form;

        const {formatMessage} = this.props.intl;
        const {fields} = this.props;
        //const readOnly = steps[current].readOnly;

        return (
            <Form id='DomainNameForm' >
                <FormItem
                    {...formItemLayout}
                    label={formatMessage({id: 'domainNameLabel'})}
                    hasFeedback
                >
                    {getFieldDecorator('domainName', {
                        initialValue: fields['domainName'],
                        rules: [{
                            required: true,
                            message: formatMessage({id: 'domainNameMessage'})
                        }, {validator: this.validateDomainName}],
                    })(<Input placeholder={formatMessage({id: 'domainNamePlaceholder'})} onChange={(e) => {this.setState({domainName: e.target.value})}}/>)}
                </FormItem>
                <AuthinfoFormItem
                    form={this.props.form}
                    intialValue={this.props.fields['authInfo']}
                    formAuthInfoLayout={formAuthInfoLayout}
                />
                <GovValidationCodeFormItem
                    form={this.props.form}
                    domain={this.state.domainName}
                    intialValue={this.props.fields['govValidationCode']}
                    formAuthInfoLayout={formItemLayout}
                />
            </Form>
        )
    }

}


const DomainName = Form.create()(injectIntl(DomainNameWrapped));
export default DomainName;
DomainName.propTypes = {
    fields: PropTypes.any
}

DomainName.defaultProps = {
    fields: {}
}