import React, {Component} from 'react';
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";

class ContactFormEppOperationContainer extends Component {

    componentWillMount() {
        // console.log("DATA ", this.props)
        this.fetchData()
        const {contact} = this.props.match.params;
        if (!contact) {
            this.props.resetOperation();
        }
    }

    /** capisce se necessario chiamare il comando EPP */
    isFetchingNeeded() {
        const {contact: contactId} = this.props.match.params;
        return contactId && (!this.props.data || contactId !== this.props.data.id);
    }

    /** recupero i dati*/
    fetchData() {
        const authinfo = new URLSearchParams(this.props.location.search.slice(1)).get('authinfo');
        const {contact} = this.props.match.params;
        // console.log("this.isFetchingNeeded() ", this.isFetchingNeeded())
        if (this.isFetchingNeeded()) {
            this.props.eppOperation(contact, authinfo);
        }
    }

    render() {
        return (
            <div>{this.props.component}</div>
        )
    }
}

ContactFormEppOperationContainer.propTypes = {
    resetOperation: PropTypes.func,
    eppOperation: PropTypes.func,
    data: PropTypes.object,
    component: PropTypes.object
};
ContactFormEppOperationContainer.defaultProps = {};

export default withRouter(ContactFormEppOperationContainer);
