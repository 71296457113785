import * as status from "./constants/statuses";

export function isDeletable(statuses) {
    const notIn = [
        status.PENDING_DELETE,
        status.CLIENT_DELETE_PROHIBITED,
        status.SERVER_DELETE_PROHIBITED,
        status.SERVER_HOLD,
        status.CLIENT_HOLD
    ];
    let reason = statuses.filter(x => notIn.includes(x))
    return !(reason.length > 0)
}

export function isEditable(statuses) {
    const notIn = [status.PENDING_DELETE];
    let reason = statuses.filter(x => notIn.includes(x))
    return !(reason.length > 0)
}

export function isContactEditable(statuses) {
    const notIn = [
        status.CLIENT_UPDATE_PROHIBITED,
        status.SERVER_UPDATE_PROHIBITED,
        status.SERVER_HOLD,
        status.CLIENT_HOLD
    ];
    let reason = statuses.filter(x => notIn.includes(x))
    return !(reason.length > 0)
}

export function isHostEditable(statuses) {
    const notIn = [
        status.CLIENT_UPDATE_PROHIBITED,
        status.SERVER_UPDATE_PROHIBITED,
        status.SERVER_HOLD,
        status.CLIENT_HOLD
    ];
    let reason = statuses.filter(x => notIn.includes(x))
    return !(reason.length > 0)
}

export function isRegistrantEditable(statuses) {
    const notIn = [status.CLIENT_UPDATE_PROHIBITED, status.CLIENT_HOLD, status.SERVER_UPDATE_PROHIBITED, status.SERVER_HOLD];
    let reason = statuses.filter(x => notIn.includes(x))
    return !(reason.length > 0)
}

export function isAuthinfoEditable(statuses) {
    const notIn = [
        status.CLIENT_UPDATE_PROHIBITED,
        status.SERVER_UPDATE_PROHIBITED,
        status.SERVER_HOLD,
        status.CLIENT_HOLD,
        status.REDEMPTION_PERIOD
    ];
    let reason = statuses.filter(x => notIn.includes(x))
    return !(reason.length > 0)
}

export function isStatusEditable(statuses) {
    const notIn = [status.PENDING_UPDATE];
    let reason = statuses.filter(x => notIn.includes(x))
    return !(reason.length > 0)
}

export function isRestorable(statuses) {
    const isIn = [status.NO_REGISTRAR, status.REDEMPTION_PERIOD];
    let reason = statuses.filter(x => isIn.includes(x))
    return (reason.length > 0)
}

export function isTransferable(statuses) {
    const isNotIn = [
        status.PENDING_TRANSFER,
        status.PENDING_UPDATE,
        status.PENDING_DELETE,
        status.CLIENT_HOLD,
        status.CLIENT_TRANSFER_PROHIBITED,
        status.SERVER_TRANSFER_PROHIBITED,
        status.SERVER_HOLD,
        status.REVOKED,
        status.TO_BE_REASSIGNED
    ];
    let reason = statuses.filter(x => isNotIn.includes(x))
    return !(reason.length > 0)
}

export function isTransferRejctable(statuses) {
    // const isIn = [status.NO_REGISTRAR, status.REDEMPTION_PERIOD];
    // let reason = statuses.filter(x => isIn.includes(x))
    // console.log("reason Restorable", reason, statuses)
    return true
}

export function isTransferCancellabe(statuses) {
    // const isIn = [status.NO_REGISTRAR, status.REDEMPTION_PERIOD];
    // let reason = statuses.filter(x => isIn.includes(x))
    // console.log("reason Restorable", reason, statuses)
    return true
}

export function isTransferApprovable(statuses) {
    // const isIn = [status.NO_REGISTRAR, status.REDEMPTION_PERIOD];
    // let reason = statuses.filter(x => isIn.includes(x))
    // console.log("reason Restorable", reason, statuses)
    return true
}

export function isTransferRequestable(statuses) {
    const isIn = [status.NO_REGISTRAR, status.REDEMPTION_PERIOD];
    let reason = statuses.filter(x => isIn.includes(x))
    return (reason.length > 0)
}

