import React, {Component} from 'react';
import EppMessageListTable from "../EppMessageListTable/EppMessageListTable";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import Error from "../../../commons/components/FeedBack/Error/Error";
import {injectIntl} from "react-intl";
import EppMessaggeListFilterBar from "../EppMessageListFilterBar/EppMessageListFilterBar";
import EppOperationView from "app/commons/components/Pages/EppOperationView";
import PollingStatus from "app/eppMessages/components/Page/PollingStatus";

class EppMessageList extends Component {

    render() {
        const {formatMessage} = this.props.intl;
        const {data, loading, error} = this.props.eppMessages

        return (
            <EppOperationView
                title={<div>{formatMessage({id: 'eppMessage.list.title'})}<PollingStatus enabled={false}/></div>}
                operationForm={<EppMessaggeListFilterBar/>}
                loading={loading}
            >
                {(!loading && !error) &&
                <EppMessageListTable
                    data={data}
                    onShowSizeChange={this.props.onShowSizeChange}
                    onReadMessage={this.props.onReadMessage}
                    onDeleteMessage={this.props.onDeleteMessage}
                />
                }
                <Error error={error}/>
            </EppOperationView>
        );
    }
}

EppMessageList.propTypes = {
    onShowSizeChange: PropTypes.func,
    onReadMessage: PropTypes.func,
    onDeleteMessage: PropTypes.func,
};
EppMessageList.defaultProps = {};

const mapStateToProps = (state) => {
    return {
        eppMessages: state.eppMessage,
    };
}

export default connect(mapStateToProps)(injectIntl(EppMessageList));
