import React from 'react';
import {Button, Col, Form, Input, Row} from 'antd';
import {injectIntl} from "react-intl";
import PropTypes from "prop-types";
import uuid from 'uuid/v4';
import {authInfoValidator} from "../../../../commons/utils/form";

const FormItem = Form.Item;


class AuthinfoFormItem extends React.Component {

    authInfoCheckerWrapped = (rule, value, callback) => {
        const {formatMessage} = this.props.intl;
        authInfoValidator(rule, value, callback, formatMessage)
    }

    render() {
        const {form, intl} = this.props
        const {formatMessage} = intl;
        const {getFieldDecorator} = form;
        return (
            <Row>
                <Col span={10} offset={2} style={{paddingLeft: "4.7%"}}>
                    <FormItem
                        {...this.props.formAuthInfoLayout}
                        label={formatMessage({id: 'authInfoLabel'})}
                        hasFeedback
                    >
                        {getFieldDecorator('authInfo', {
                            // initialValue: this.props.initialValue,
                            rules: [
                                {required: true,},
                                {validator: this.authInfoCheckerWrapped},
                                {
                                    validator: (rule, value, cb) => (
                                        (value === this.props.initialValue ?
                                            cb(new Error(formatMessage({id: 'authInfo.equals'}))) :
                                            cb()))
                                }
                            ],
                        })(<Input placeholder={formatMessage({id: 'authInfoPlaceholder'})}/>)}
                    </FormItem>
                </Col>
                <Col span={2} style={{paddingTop: 5}}>
                    <Button size="small" type="primary" onClick={() => {
                        let UUID = uuid();
                        UUID = UUID.replace(/-/g, '');
                        this.props.form.setFieldsValue({authInfo: UUID});
                    }}>{formatMessage({id: 'generateUUID'})}</Button>
                </Col>
            </Row>
        );
    };
}

export default injectIntl(AuthinfoFormItem);

AuthinfoFormItem.propTypes = {
    initialValue: PropTypes.string,
    form: PropTypes.object.isRequired,
    formAuthInfoLayout: PropTypes.object
};