import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import * as routes from "../../../routes";
import {injectIntl} from "react-intl";
import ContactFormEppOperationContainer from "./ContactFormEppOperationContainer";
import ContactEppActionForm from "../../components/ContactEppActionForm/ContactEppActionForm";
import {contactsOperations} from "../../index";

/**
 *
 * Container che gestisce il form Domain INFO Form
 *
 * Gestisce il routing e l'invocazione del comando associato, EPP INFO
 *
 */


class ContactInfoFormContainer extends React.Component {

    /** Submit che effettuata la form */
    handleSubmitInfo = (contact, authinfo) => {
        //Resetto i dati nel redux e richiamo il fetchData in maniera da obbligare il caricamento
        this.props.resetInfoContactAction()
        let pathname;
        if (authinfo) {
            let sp = new URLSearchParams();
            sp.set('authinfo', authinfo);
            pathname = sp.toString()
        }

        //Modifico la URL
        return this.props.history.push({
            pathname: `${routes.CONTACT_INFO.url}/${contact}`,
                search: pathname
            }
        )
    }

    /** Pulisco la form e modifco la URL*/
    handleResetButton = () => {
        // this.props.resetInfoContactAction()
        this.props.history.push({pathname: `${routes.CONTACT_INFO.url}`})
    }

    render() {
        const {formatMessage} = this.props.intl;

        return (
            <ContactFormEppOperationContainer
                eppOperation={this.props.infoContactAction.bind(this)}
                resetOperation={this.props.resetInfoContactAction.bind(this)}
                component={
                    <ContactEppActionForm
                        submitLabel={formatMessage({id: 'contact.info.submit.button'})}
                        resetLabel={formatMessage({id: 'cancel'})}
                        onReset={this.handleResetButton.bind(this)}
                        onSubmit={this.handleSubmitInfo.bind(this)}/>
                }
                data={this.props.infoContact.data}
            />
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetInfoContactAction: () => {
            dispatch(contactsOperations.resetInfoContact())
        },
        infoContactAction: (domain, authinfo) => {
            dispatch(contactsOperations.infoContactOp(domain, authinfo))
        },
    };
}

const mapStateToProps = (state) => {
    return {
        infoContact: state.contact.infoReducer,
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ContactInfoFormContainer)));

