import React from 'react';
import {Alert, Button, Col, Form, Row} from 'antd';
import ContactDetail from "../../../../contacts/components/DataDisplay/ContactDetail/ContactDetail";
import {withRouter} from "react-router-dom";
import {injectIntl} from "react-intl";
import PropTypes from "prop-types";
import ContactLookUpFormItem
    from "../../../../commons/components/DataEntry/ContactLookUpFormItem/ContactLookUpFormItem";
import AuthinfoFormItem from "../../../../commons/components/DataEntry/AuthinfoFormItem/AuthinfoFormItem";
import {
    ErrorConsentForPublishText,
    showErrorConsentForPublish
} from "app/domains/components/Pages/ConsentForPublishChecker";
import {isGovDomain} from "../../../../commons/utils/utils";
import GovValidationCodeFormItem from "app/domains/components/Pages/DomainNew/GovValidationCodeFormItem";

const FormItem = Form.Item;

const formAuthInfoLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 5},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 18},
    },
};

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 5},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 'contact'},
    },
};

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class RegistrantFormWrapped extends React.Component {

    state = {
        newRegistrant: undefined,
        error: undefined,
    }




    //Valida e se tutti i controlli sono ok esegue la submit
    validateAndSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {

                const registrantId = values.id.trim();
                let govValidationCode;
                if (values.govValidationCode) {
                    govValidationCode = values.govValidationCode.trim();
                }
                const {domain} = this.props.match.params;
                let authInfo;
                if (values.authInfo) {
                    authInfo = values.authInfo.trim();
                }
                if (registrantId) {
                    this.props.onSubmit(domain, {authinfo: authInfo, registrant: registrantId, govValidationCode: govValidationCode});
                }
            }
        })
    }

    isFieldModified = (field, initialValue) => {
        const {getFieldValue, getFieldError} = this.props.form;
        // console.log(" FIELD", field)
        // console.log(" initialValue !==", initialValue !== getFieldValue(field))
        // console.log(" isFieldTouched", getFieldValue(field) !== undefined)
        // console.log(" getFieldError", !getFieldError(field))
        return (initialValue !== getFieldValue(field)) && getFieldValue(field) !== undefined && !getFieldError(field)
    }

    canBeSubmitted = () => {
        const {getFieldsError} = this.props.form;
        if (!hasErrors(getFieldsError()) && this.state.error === undefined)
            return (this.isFieldModified('id', this.props.data.adminId)
                && this.isFieldModified('authInfo', this.props.data.authinfo))
        else
            return false
    }

    handleSelectRegistrant = (data) => {
        this.setState({newRegistrant: data})


        if (showErrorConsentForPublish(data)) {
            this.setState({error: ErrorConsentForPublishText(data.id)})
        } else {

            if (isGovDomain(this.props.data.name)) {
                if (data.registrant.gov === undefined) {
                    this.setState({error: `Registrante non compatibile con dominio ${this.props.data.name}. Manca codice IPA`})
                }
            } else {

                if (data.registrant.gov !== undefined) {
                    this.setState({error: `Registrante non compatibile con dominio ${this.props.data.name}. E' Registrante GOV.IT`})
                } else {

                    this.setState({error: undefined})
                }
            }
        }

    }

    render() {
        const {form, intl, data} = this.props
        const domainName = data.name;
        const {formatMessage} = intl;

        return (
            <Form onSubmit={this.validateAndSubmit} style={{'marginTop': '40px'}}>
                {this.state.error !== undefined &&
                <Alert style={{marginBottom: 20}}
                    message="Attenzione" type="error" description={this.state.error}
                    showIcon/>
                }
                <Row gutter={8}>
                    <Col span={12}>
                        <div className="riquadro bordo with-title" style={{backgroundColor: '#f7f7f782'}}>
                            <div className="title">{formatMessage({id: 'domain.update.form.registrant.actual'})}</div>
                            <ContactDetail data={this.props.data.registrant} linkable={true} showRegistrantInfo={true}
                                           withBorderSpace={true}/>
                        </div>
                    </Col>
                    <Col span={12}>
                        {this.state.newRegistrant &&
                        <div className="riquadro bordo with-title" style={{backgroundColor: '#f2ffe2'}}>
                            <div className="title">{formatMessage({id: 'domain.update.form.registrant.new'})}</div>
                            <ContactDetail data={this.state.newRegistrant} linkable={true} showRegistrantInfo={true}
                                           withBorderSpace={true}
                                            error={this.state.error}/>
                        </div>
                        }
                    </Col>
                </Row>

                <div className="riquadro bordo with-title">
                    <div className="title">{formatMessage({id: 'domain.update.form.registrant.modify'})}</div>
                    <ContactLookUpFormItem form={form}
                                           formItemLayout={formItemLayout}
                                           onSelectedRegistrant={this.handleSelectRegistrant}
                                           showContactBar={false}
                                           labelContactId={formatMessage({id: 'domain.update.form.registrantId'})}
                                           domainName={domainName}
                    />
                    <AuthinfoFormItem
                        form={form}
                        formAuthInfoLayout={formAuthInfoLayout}
                    />

                    <GovValidationCodeFormItem
                        form={this.props.form}
                        domain={domainName}
                        intialValue={undefined}
                        formAuthInfoLayout={formItemLayout}
                    />


                </div>
                <FormItem style={{borderTop: '1px solid #e9e9e9'}}>
                    <div style={{paddingTop: '10px'}}>
                        <Button style={{marginRight: '25px'}} onClick={this.props.onCancel}>
                            {formatMessage({id: "cancel"})}
                        </Button>
                        <Button
                            type='primary'
                            htmlType="submit"
                            disabled={!this.canBeSubmitted()}
                        >
                            {this.props.submitLabel}
                        </Button>
                    </div>
                </FormItem>
            </Form>
        );
    }
};


const RegistrantForm = Form.create()(withRouter(injectIntl(RegistrantFormWrapped)));

export default (RegistrantForm);

RegistrantForm.propTypes = {
    submitLabel: PropTypes.string.isRequired,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    data: PropTypes.object

}