import React from 'react';
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import CardDetailRow from "../../../../commons/components/DataDisplay/CardDetailRow/CardDetailRow";
import BooleanDetail from "../../../../commons/components/DataDisplay/BooleanDetail/BooleanDetail";

const formattingDate = {
    year: 'numeric', day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric', second: 'numeric'
};

const ContactShortDetail = (props) => {
    const {formatMessage, formatTime} = props.intl;
    const {name, org, crDate, upDate, id, consentForPublish, statuses} = props.data;
    return (
        <div>
            <CardDetailRow label={formatMessage({id: "detail.contact.id"})}
                           content={id}/>
            <CardDetailRow label={formatMessage({id: "detail.contact.name"})}
                           content={name}/>
            {org && <CardDetailRow label={formatMessage({id: "detail.contact.organization"})}
                                   content={org}/>}
            <CardDetailRow label={formatMessage({id: "detail.contact.statuses"})}
                           content={statuses.join(" / ")}/>
            <CardDetailRow label={formatMessage({id: "detail.contact.crDate"})}
                           content={formatTime(crDate, formattingDate)}/>
            {upDate &&
            <CardDetailRow label={formatMessage({id: "detail.contact.upDate"})}
                           content={formatTime(upDate, formattingDate)}/>
            }
            <CardDetailRow label={formatMessage({id: "contact.detail.consent4pub"})}
                           content={<BooleanDetail checked={consentForPublish}/>}/>

        </div>
    );
};

ContactShortDetail.propTypes = {
    data: PropTypes.any.isRequired,
};

export default injectIntl(ContactShortDetail);
