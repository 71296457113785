export const INFO_DOMAIN_SUCCESS = 'INFO_DOMAIN_SUCCESS'
export const INFO_DOMAIN = 'INFO_DOMAIN'
export const INFO_DOMAIN_LOADER = 'INFO_DOMAIN_LOADER'
export const RESET_INFO_DOMAIN = 'RESET_INFO_DOMAIN'
export const INFO_DOMAIN_ERROR = 'INFO_DOMAIN_ERROR'
export const SET_AUTHINFO = 'SET_AUTHINFO'

export const CHECK_DOMAIN = 'CHECK_DOMAIN'
export const CHECK_DOMAIN_START = 'CHECK_DOMAIN_START'
export const CHECK_DOMAIN_SUCCESS = 'CHECK_DOMAIN_SUCCESS'
export const CHECK_DOMAIN_ERROR = 'CHECK_DOMAIN_ERROR'
export const CHECK_DOMAIN_RESET = 'CHECK_DOMAIN_RESET'

export const DELETE_DOMAIN = 'DELETE_DOMAIN'
export const DELETE_DOMAIN_START = 'DELETE_DOMAIN_START'
export const DELETE_DOMAIN_SUCCESS = 'DELETE_DOMAIN_SUCCESS'
export const DELETE_DOMAIN_ERROR = 'DELETE_DOMAIN_ERROR'
export const RESET_DELETE_DOMAIN = 'RESET_DELETE_DOMAIN'

export const DOMAIN_LIST = 'DOMAIN_LIST'
export const DOMAIN_LIST_START = 'DOMAIN_LIST_START'
export const DOMAIN_LIST_RESULT = 'DOMAIN_LIST_RESULT'
export const DOMAIN_LIST_ERROR = 'DOMAIN_LIST_ERROR'

export const RESTORE_DOMAIN = 'RESTORE_DOMAIN'
export const RESTORE_DOMAIN_START = 'RESTORE_DOMAIN_START'
export const RESTORE_DOMAIN_SUCCESS = 'RESTORE_DOMAIN_SUCCESS'
export const RESTORE_DOMAIN_ERROR = 'RESTORE_DOMAIN_ERROR'
export const RESET_RESTORE_DOMAIN = 'RESET_RESTORE_DOMAIN'

export const DOMAIN_UPDATE_START = 'DOMAIN_UPDATE_START'
export const DOMAIN_UPDATE_HOST = 'DOMAIN_UPDATE_HOST'
export const DOMAIN_UPDATE_SIMPLE = 'DOMAIN_UPDATE_SIMPLE'
export const DOMAIN_UPDATE_REGISTRANT = 'DOMAIN_UPDATE_REGISTRANT'
export const DOMAIN_UPDATE_STATUS = 'DOMAIN_UPDATE_STATUS'
export const DOMAIN_UPDATE_SUCCESSFUL = 'DOMAIN_UPDATE_SUCCESSFUL'
export const DOMAIN_UPDATE_ERROR = 'DOMAIN_UPDATE_ERROR'
export const DOMAIN_UPDATE_RESET = 'DOMAIN_UPDATE_RESET'

export const TRANSFER_STATUS_DOMAIN = 'TRANSFER_STATUS_DOMAIN'
export const TRANSFER_STATUS_DOMAIN_START = 'TRANSFER_STATUS_DOMAIN_START'
export const TRANSFER_STATUS_DOMAIN_RESULT = 'TRANSFER_STATUS_DOMAIN_RESULT'
export const TRANSFER_STATUS_DOMAIN_SUCCESS = 'TRANSFER_STATUS_DOMAIN_SUCCESS'
export const TRANSFER_STATUS_DOMAIN_ERROR = 'TRANSFER_STATUS_DOMAIN_ERROR'
export const TRANSFER_STATUS_DOMAIN_RESET = 'TRANSFER_STATUS_DOMAIN_RESET'

export const TRANSFER_REQUEST_DOMAIN = 'TRANSFER_REQUEST_DOMAIN'
export const TRANSFER_REQUEST_DOMAIN_START = 'TRANSFER_REQUEST_DOMAIN_START'
export const TRANSFER_REQUEST_DOMAIN_RESULT = 'TRANSFER_REQUEST_DOMAIN_RESULT'
export const TRANSFER_REQUEST_DOMAIN_SUCCESS = 'TRANSFER_REQUEST_DOMAIN_SUCCESS'
export const TRANSFER_REQUEST_DOMAIN_ERROR = 'TRANSFER_REQUEST_DOMAIN_ERROR'
export const TRANSFER_REQUEST_DOMAIN_RESET = 'TRANSFER_REQUEST_DOMAIN_RESET'

export const DOMAIN_TRANSFER_APPROVE = 'DOMAIN_TRANSFER_APPROVE'
export const DOMAIN_TRANSFER_REJECT = 'DOMAIN_TRANSFER_REJECT'
export const DOMAIN_TRANSFER_DELETE = 'DOMAIN_TRANSFER_DELETE'
export const CLEAN_NOTIFICATIONS_INFO_DOMAIN = 'CLEAN_NOTIFICATIONS_INFO_DOMAIN'


