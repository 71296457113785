import TagNoCursor from "app/commons/components/DataDisplay/TagNoCursor/TagNoHover";
import React from "react";
import {injectIntl} from "react-intl";

const PollingStatus = ({enabled,intl}) => {
    if (!enabled) {
        return <TagNoCursor
            style={{marginLeft: '10px'}}
            color='orange'>{intl.formatMessage({id: 'eppMessage.pollReq.disabled'})}
        </TagNoCursor>
    } return <div></div>
}

export default injectIntl(PollingStatus)