import {hanldeErrorDispatching} from "../commons/utils/utils";
import actions from "./actions"

function loadCountryCodeOp(name, all) {
    return function (dispatch) {
        dispatch(actions.loadCountriesStart())
        dispatch(actions.loadCountries(name, all))
            .then((response) => {
                if (!response.error) {
                    dispatch(actions.countriesLoadingSuccess(response.payload))
                } else {
                    dispatch(hanldeErrorDispatching(response.payload, actions.countriesLoadingFailure))
                }
            })
    }
}

export default {loadCountryCodeOp}