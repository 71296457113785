import React from 'react';
import PropTypes from 'prop-types';

const BooleanDetail = (props) => {
    return (
        <div><span>{props.checked ? 'si' : 'no'}</span></div>
    );
};

BooleanDetail.propTypes = {
    checked: PropTypes.bool
};
BooleanDetail.defaultProps = {};

export default BooleanDetail;
