import React from 'react';
import PropTypes from 'prop-types';
import {Tag} from "antd";
import {injectIntl} from "react-intl";
import {CONTACT_TYPE} from "app/contacts/constants/const";

const ContactTypeTag = (props, intl) => {

    let label = '';
    let color = props.color
    let contactColors = {registrant: '#108ee9', contact: '#009688',registrantEdu: '#108ee9', gov: '#f50' }

    switch (props.type) {
        case CONTACT_TYPE.registrant :
            label = props.intl.formatMessage({id: 'Registrant'})
            color = contactColors[props.type]
            break;
        case CONTACT_TYPE.registrantEdu :
            label = props.intl.formatMessage({id: 'RegistrantEdu'})
            color = contactColors[props.type]
            break;
        case CONTACT_TYPE.registrantGov :
            label = props.intl.formatMessage({id: 'RegistrantGov'})
            color = contactColors[props.type]
            break;
        case CONTACT_TYPE.contact:
            label = props.intl.formatMessage({id: 'Contact'})
            color = contactColors[props.type]
            break;
        default:
            console.warn("Tipologia registrante non corretta ", props.type)
    }

    return (
        <Tag color={color} style={{'pointerEvents': 'none', 'cursor': 'default', textAlign: 'center', float:'left'}}>
            {label}
        </Tag>
    );
};

ContactTypeTag.propTypes = {
    color: PropTypes.string,
    type: PropTypes.oneOf([CONTACT_TYPE.registrant, CONTACT_TYPE.contact,CONTACT_TYPE.registrantEdu, CONTACT_TYPE.registrantGov]).isRequired
};
ContactTypeTag.defaultProps = {};

export default injectIntl(ContactTypeTag);
