import React from 'react';
import {Button, Form} from 'antd';
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import {withRouter} from "react-router-dom";
import * as statuses from "../../../constants/statuses";
import {CLIENT_UPDATE_PROHIBITED} from "../../../constants/statuses";
import StatusFormItem from "../../../../commons/components/DataEntry/StatusFormItem/StatusFormItem";

const FormItem = Form.Item;

const formAuthInfoLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 5},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 18},
    },
};

class StatusFormWrapped extends React.Component {

    //Valida e se tutti i controlli sono ok esegue la submit
    validateAndSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {domain} = this.props.match.params;
                let statusesToAdd, statusesToRemove;

                if (values.statusesToRemove) {
                    statusesToRemove = values.statusesToRemove;
                }
                if (values.statusesToAdd) {
                    statusesToAdd = values.statusesToAdd;
                }

                if (domain) {
                    this.props.onSubmit(domain, {statusesToRemove: statusesToRemove, statusesToAdd: statusesToAdd});
                }
            }
        })
    }


    canBeSubmitted = () => {
        return (this.props.form.getFieldValue('statusesToAdd') || this.props.form.getFieldValue('statusesToRemove'))
    }

    render() {
        const {form, intl} = this.props
        const {formatMessage} = intl;


        const managedStatusList = this.props.data.statuses.includes(CLIENT_UPDATE_PROHIBITED) ?
            [statuses.CLIENT_UPDATE_PROHIBITED] :
            [statuses.CLIENT_TRANSFER_PROHIBITED, statuses.CLIENT_DELETE_PROHIBITED, statuses.CLIENT_UPDATE_PROHIBITED, statuses.CLIENT_HOLD];


        return (
            <Form onSubmit={this.validateAndSubmit} style={{'marginTop': '40px'}}>
                <div className="riquadro bordo with-title">
                    <div className="title">{formatMessage({id: 'domain.update.status.form.new'})}</div>
                    <StatusFormItem
                        form={form}
                        initialValue={this.props.data && this.props.data.statuses}
                        formItemLayout={formAuthInfoLayout}
                        managedStatusList={managedStatusList}
                        onChange={(e)=> console.log("e ",e)}
                    />

                </div>
                <FormItem style={{borderTop: '1px solid #e9e9e9'}}>
                    <div style={{paddingTop: '10px'}}>
                        <Button style={{marginRight: '25px'}}
                                onClick={this.props.onCancel}
                        >
                            {formatMessage({id: "cancel"})}
                        </Button>
                        <Button
                            type='primary'
                            htmlType="submit"
                            disabled={!this.canBeSubmitted()}
                        >
                            {formatMessage({id: "domain.update.status"})}
                        </Button>
                    </div>
                </FormItem>
            </Form>
        );
    }
};


const StatusForm = Form.create()(withRouter(injectIntl(StatusFormWrapped)));

export default (StatusForm);
StatusForm.propTypes = {
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    data: PropTypes.object
}