import React, {Component} from 'react';
import PropTypes from 'prop-types'
import BaseContactForm from "app/contacts/components/BaseContactForm/BaseContactForm";

export class BaseContactFormContainer extends Component {
    constructor(props) {
        super(props)
        this.handleOnChangeEntityTpe = this.handleOnChangeEntityTpe.bind(this)
        this.handleOnChangeName = this.handleOnChangeName.bind(this)
        this.handleOnChangeNationality = this.handleOnChangeNationality.bind(this)
        this.state = {
            entityType: (props.items && props.items.registrant && props.items.registrant.entityType) || null,
            name: null,  //Todo rimuovere ??
            nationality: null, //TODO rimuovere??
        }
    }

    handleOnChangeEntityTpe(value, form) {
        this.setState({entityType: value})
        if (value === 1) {
            this.setState({name: form.getFieldValue('name')});
            //Campo org Coincide con il valore del campo Nome nel caso in cui il Registrante sia una persona fisica (EntityType = 1)
            form.setFieldsValue({'org': form.getFieldValue('name')})

        }

        if (value !== 1 ){
            //Campo registrant.nationalityCopde Coincide con il valore del campo address.countryCode nel caso in cui il Registrante non sia
            // una persona Fisica (EntityType <> 1)
            form.setFieldsValue({'registrant.nationalityCode': form.getFieldValue('address.countryCode')})
            form.validateFields(['registrant.nationalityCode'])
            form.setFieldsValue({'consentForPublish': true})
        }
    }


    handleOnChangeName(e, form) {
        if (this.props.isRegistrant && this.state.entityType === 1) {
            form.setFieldsValue({'org': e.target.value})

        }
    }

    handleOnChangeNationality(e, form) {
        if (this.props.isRegistrant && this.state.entityType !== 1 && !this.props.isGov) {
            form.setFieldsValue({'registrant.nationalityCode': e})
            form.validateFields(['registrant.nationalityCode']);
        }
    }

    render() {
        const {items} = this.props;
        const isNewContact = !(items && items.id);
        return (
            <BaseContactForm
                {...this.props}
                {...this.state}
                onChangeEntityType={this.handleOnChangeEntityTpe}
                onChangeName={this.handleOnChangeName}
                onChangeNationality={this.handleOnChangeNationality}
                isNewContact={isNewContact}
            />
        );
    }
}

BaseContactFormContainer.propTypes = {
    handleSubmit: PropTypes.func,
    onChangeEntityType: PropTypes.func,
    items: PropTypes.object,
    isRegistrant: PropTypes.bool,
    isEdu: PropTypes.bool,
    isGov: PropTypes.bool,
};