import React, {Component} from 'react';
import {injectIntl} from "react-intl";
import PropTypes from 'prop-types'
import Spinner from "../../../../commons/components/FeedBack/Spinner/Spinner";
import Error from "../../../../commons/components/FeedBack/Error/Error";
import {printMessageError} from "../../../../commons/utils/common";
import {notificationError} from "../../../../commons/utils/notification";
import CardDetail from "../../../../commons/components/DataDisplay/CardDetail/CardDetail";
import EppOperationView from "app/commons/components/Pages/EppOperationView";
import ContactFormContainer from "app/contacts/components/ContactForm/ContactFormContainer";

class ContactNewPage extends Component {

    constructor(props) {
        super(props);
        this.handleContactTypeChange = this.handleContactTypeChange.bind(this)
        this.state = {
            contactType: 'contact'
        }
    }

    handleContactTypeChange(contactType) {
        this.setState({contactType: contactType})
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.newContactData.error) {
            notificationError(this.props.intl.formatMessage(
                {id: 'contact.create.error'},
                {errorMessage: printMessageError(nextProps.newContactData.error)}))
        }
    }


    render() {
        const {formatMessage} = this.props.intl;
        return (
            <EppOperationView
                title={formatMessage({id: "contact.new.form.title"})}
            >
                <Error error={this.props.newContactData.error}/>
                <CardDetail label={formatMessage({id: this.state.contactType})}>
                    {/*<ContactForm onSubmit={this.props.onSubmit} onCancel={this.props.onReset}/>*/}
                    <ContactFormContainer
                        onSubmit={this.props.onSubmit}
                        onCancel={this.props.onCancel}
                        onContactTypeChange={this.handleContactTypeChange}
                    />
                    <Spinner loading={this.props.newContactData.loading}/>
                </CardDetail>
            </EppOperationView>
        );
    }
};

ContactNewPage.propTypes = {
    newContactData: PropTypes.object.isRequired,
    onSubmit: PropTypes.func,
    onReset: PropTypes.func,
    onCancel: PropTypes.func,
};
ContactNewPage.defaultProps = {};

export default injectIntl(ContactNewPage);
