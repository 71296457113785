import React from "react";

const NoMatch = () => (
    <div>

        <img style={{marginTop: '8%'}} src="/logo-epic.svg" alt="Epic (Ep(p) i(nterface) c(lient))"/>
        <div style={{
            width: '50%',
            border: ' 1px solid #B3B3B3',
            margin: '0 auto',
            marginTop: '3%',
            backgroundColor: 'white'
        }}>
            <div style={{fontSize: '40px', margin: '10px'}}><i className="fal fa-exclamation"/> Errore</div>
            <p style={{fontSize: '20px'}}>La pagina non esiste</p>
        </div>
    </div>
)

export default NoMatch