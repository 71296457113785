import React, {Component} from 'react';
import {Button, Form, Icon, Input, Select} from 'antd';

import {injectIntl} from 'react-intl';
import {algorithms, digestTypes} from '../../../constants/common';

import WizardComponentHeader from './WizardComponentHeader';

import './Fields.css';


const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 5},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 12},
    },
};

const CONF = {
    MAX_DSRECORD: 2,
    MIN_DSRECORD: 0
}

let uuid = 2;

class DNSSecWrapper extends Component {

    remove = (k) => {
        const {form} = this.props;
        // can use data-binding to get
        const dsrecords = form.getFieldValue('dsrecords');
        // We need at least one passenger

        // can use data-binding to set
        form.setFieldsValue({
            dsrecords: dsrecords.filter(key => key !== k),
        });
    }

    add = () => {
        uuid++;
        const {form} = this.props;
        // can use data-binding to get
        const dsrecords = form.getFieldValue('dsrecords');
        const nextKeys = dsrecords.concat(uuid);
        // can use data-binding to set
        // important! notify form to detect changes
        form.setFieldsValue({
            dsrecords: nextKeys,
        });
    }


    validateKeyTag = (role, value, callback) => {
        const {formatMessage} = this.props.intl;
        if (value >= 0 && value <= 65535) {
            callback();
        } else {
            callback(new Error(formatMessage({id: 'keyTagRangeError'})));
        }
    }

    validateDigest = (role, value, callback) => {
        const {getFieldValue} = this.props.form;
        const {formatMessage} = this.props.intl;

        const digest = role.field;
        let selectedDigestType = getFieldValue(`${digest}Type`);

        if (!selectedDigestType) {
            callback(new Error("Seleziona prima il tipo di digest"))
        } else {

            let isHex = value.match("^[0-9A-Fa-f]+$");

            if (!isHex) {
                callback(new Error("Valori non validi per digest: numeri compresi tra 0 e 9 e lettere (anche maiuscole) tra a (A) ed f (F)"))
            }
            // console.log("digestTypes[selectedDigestType].length ", digestTypes[selectedDigestType])
            // console.log("value,digestTypes,selectedDigestType ", value, digestTypes, selectedDigestType)
            // console.log("value.length", value.length)

            if (value.length !== digestTypes[selectedDigestType].length) {

                // let values = {length: value.length, required: digestTypes[selectedDigestType].length}
                callback(new Error(formatMessage({id: 'digestValueError'}, {
                    "length": value.length,
                    "required": digestTypes[selectedDigestType].length
                })));
            } else {
                callback();
            }
        }

    }

    renderDSRecords = (dsrecords, algorithmOptions, digestTypeOptions, dsRecordsReadOnly
                       // , dsRecordsToRemove
    ) => {
        const {formatMessage} = this.props.intl;
        const {getFieldDecorator} = this.props.form;
        const {fields} = this.props;

        let dsComponents = [];
        let selected = false
        // let toRemove = false


        dsrecords.forEach((ds, i) => {

            selected = (dsRecordsReadOnly && dsRecordsReadOnly.includes(ds)) || false;
            // toRemove = (dsRecordsToRemove && dsRecordsToRemove.includes(ds)) || false;

            dsComponents.push(
                <div className="riquadro bordo with-title" key={i}>
                    <div className="title">{`Record DS ${i + 1}`} </div>
                    <div className="close-icon">
                        <Icon
                            className="close-button"
                            type="cross-circle-o"
                            onClick={() => this.remove(ds)}
                        />
                    </div>
                    <FormItem
                        {...formItemLayout}
                        label={formatMessage({id: 'keyTag'})}
                        hasFeedback
                    >
                        {getFieldDecorator(`keyTag-${ds}`, {
                            initialValue: fields[`keyTag-${ds}`],
                            rules: [{
                                required: true,
                                message: formatMessage({id: 'keyTagMessage'})
                            }, {validator: this.validateKeyTag}],
                        })(<Input
                            placeholder={formatMessage({id: 'keyTagPlaceholder'})}
                            disabled={selected}
                        />)}
                    </FormItem>

                    <FormItem
                        {...formItemLayout}
                        label={formatMessage({id: 'algorithm'})}
                        hasFeedback
                    >
                        {getFieldDecorator(`algorithm-${ds}`, {
                            initialValue: fields[`algorithm-${ds}`],
                            rules: [{
                                required: true,
                                message: formatMessage({id: 'algorithmMessage'})
                            }],
                        })(<Select
                            placeholder={formatMessage({id: 'keyTagPlaceholder'})}
                            disabled={selected}
                        >{algorithmOptions}</Select>)}
                    </FormItem>


                    <FormItem
                        {...formItemLayout}
                        label={formatMessage({id: 'digestType'})}
                        hasFeedback
                    >
                        {getFieldDecorator(`digest-${ds}Type`, {
                            initialValue: fields[`digest-${ds}Type`],
                            rules: [{
                                required: true,
                                message: formatMessage({id: 'digestTypeMessage'})
                            }],
                        })(<Select
                            disabled={selected}
                            placeholder={formatMessage({id: 'digestTypePlaceholder'})}>{digestTypeOptions}</Select>)}
                    </FormItem>


                    <FormItem
                        {...formItemLayout}
                        label={formatMessage({id: 'digest'})}
                        hasFeedback
                    >
                        {getFieldDecorator(`digest-${ds}`, {
                            initialValue: fields[`digest-${ds}`],
                            rules: [{
                                required: true,
                                message: formatMessage({id: 'digestMessage'})
                            }, {validator: this.validateDigest}],
                        })(<Input
                            placeholder={formatMessage({id: 'digestTypePlaceholder'})}
                            disabled={selected}
                        />)}
                    </FormItem>
                </div>
            )

        })
        return (dsComponents);
    }


    componentWillMount() {
        const {getFieldDecorator} = this.props.form;
        const {fields} = this.props;
        getFieldDecorator('dsRecordsToRemove', {initialValue: fields.dsRecordsToRemove || []}); //imposta i dsRecord da selezionare
        getFieldDecorator('dsRecordsToCopy', {initialValue: fields.dsRecordsToCopy || [] }); //imposta i dsRecord da selezionare
    }


    render() {
        const {getFieldDecorator, getFieldValue} = this.props.form;

        const {formatMessage} = this.props.intl;
        const {fields} = this.props;


        getFieldDecorator('dsrecords', {initialValue: fields.dsrecords ? fields.dsrecords : []});
        const dsrecords = getFieldValue('dsrecords');


        let algorithmOptions = [];

        algorithms.forEach((algorithm, i) => {
            algorithmOptions.push(<Option key={algorithm.id}
                                          text={algorithm.id}>{`${algorithm.id} [${algorithm.name}]`}</Option>)
        });


        let digestTypeOptions = [];
        let digestOptions;
        Object.keys(digestTypes).forEach((digest, i) => {
            digestOptions = digestTypes[digest]
            digestTypeOptions.push(<Option key={digestOptions.id}
                                           value={digest}>{`${digestOptions.id} [${digest}]`}</Option>)
        })


        let dsRecordComponents =
            this.renderDSRecords(dsrecords, algorithmOptions, digestTypeOptions, fields['dsRecordsToCopy'], fields['dsRecordsToremove'])


        return (
            <div className="wizard-component-header">
                <WizardComponentHeader {...this.props} />
                <Form>
                    {dsRecordComponents}
                    <FormItem>
                        <Button size="small" disabled={(dsrecords.length >= CONF.MAX_DSRECORD)} type="dashed"
                                onClick={this.add}>
                            <Icon type="plus"/>{formatMessage({id: 'addDSRecord'})}
                        </Button>
                    </FormItem>
                </Form>
            </div>
        )
    }
}

const DNSSec = Form.create()(injectIntl(DNSSecWrapper));
export default DNSSec;
