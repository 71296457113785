import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Tooltip} from 'antd';
import TetherComponent from 'react-tether'
import FilterList from "./FilterList";
import RenderFiltersBar from "./RenderFiltersBar";
import OptionList from "./OptionList";
import _ from "lodash"
import "./Filters.css"


//  ESEMPI DI FORMATO OPTIONS
//
// const filtersOptions = {
//     domain: {
//         label: 'Dominio',
//         icon: 'fa-globe',
//         options: {
//             list: {
//                 paperino: {icon: '', label: 'Paperino'},
//                 pluto: {icon: '', label: 'Pluto'},
//             },
//             textSearch: true,
//             placeholder: 'Nome dominio valido',
//         },
//
//     },
//     /*configurazione minima */
//     last: {
//         label: 'Last',
//         icon: '',
//         options: {
//             // list:{}
//         }
//     },
//     message: {
//         label: 'Messaggio contiene',
//         icon: 'fa-envelope',
//         options: {
//             textSearch: true,
//             placeholder: 'Testo...'
//         },
//
//     },
//     /* Campo boolean */
//     read: {
//         label: 'Letto',
//         icon: 'fa-star',
//         options: {
//             booleanField: true,
//             labelTrue: 'Già letto',
//             labelFalse: 'Da leggere'
//         },
//         textSearch: false,
//     },
//     type: {
//         label: 'Tipo',
//         icon: 'fa-tag',
//         options: {
//             list: {
//                 CHANGE_STATUS_MESSAGE: {
//                     icon: 'fa-exchange',
//                     label: 'CHANGE_STATUS_MESSAGE'
//                 },
//                 CREDIT_MESSAGE: {
//                     icon: 'fa-dollar-sign',
//                     label: 'CREDIT_MESSAGE'
//                 },
//                 SIMPLE_MESSAGE: {
//                     icon: 'fa-envelope',
//                     label: 'SIMPLE_MESSAGE'
//                 }
//             },
//         },
//     }
// }

class FiltersBar extends Component {

    constructor(props) {
        super(props);
        const realFilters = _.omit(
            this.props.initialFilters,
            _.difference(_.keys(this.props.initialFilters), _.keys(this.props.filtersOptions)
            )
        )//Contiene solo i filtri applicati che macthano con quelli in filtersOptions
        this.state = {
            filters: realFilters,
            isOpen: false,      //Theter isOpen
        }
    }

    /* aggiunge il filtro specificato */
    addFilter(filter) {
        this.setState(prevState => ({
            filters: {
                ...prevState.filters,
                [filter]: {}
            }
        }))
    }

    /* associa un opzione al filtro specificato */
    addOption(filter, option) {
        this.setState(prevState => ({
            filters: {
                ...prevState.filters,
                [filter]: option
            }
        }))
    }

    /* rimuove il filtro specificato */
    removeFilter(filter) {
        var tmpFilter = this.state.filters;
        delete tmpFilter[filter];
        this.setState({filters: tmpFilter})
    }

    removeAllFilters() {
        this.setState({filters: {}})
    }

    handleOnClick() {
        this.setState({isOpen: !this.state.isOpen})
    }

    handleOnChangeFilter = (filter) => {
        if (filter && filter.key) {
            this.addFilter(filter.key);
        }
    }

    handleRemoveOption = (filter) => {
        // console.log("Remove option ", filter)
        this.removeFilter(filter)
        this.setState({isOpen: false})
    }

    handleOnBooleanConfirm = (filter, option) => {
        this.addOption(filter, option)
    }

    handleOnChangeOption = (option) => {
        if (option) {
            this.addOption(this.current(), option)
        }
    }

    handleRemoveAllFilters = () => {
        this.removeAllFilters();
    }

    handleOnCloseOption = (filter, option) => {
        // console.log("Removing .... ", filter, option)
        this.removeFilter(filter)
    }

    handleOnClickFilterButton = () => {
        //Se nn e' stata impostata l'opzione per il filtro corrente, viene rimpossa
        if (_.isEmpty(this.state.filters[this.current()])) {
            this.removeFilter(this.current())
        }

        this.setState({isOpen: false})
        this.props.onSubmit(this.state.filters) //Omette i filtri con valore vuoto
    }

    /* ritorna il Filtro corrente */
    current() {
        // console.log(" this.state.filters", isEmpty(this.state.filters), this.state.filters)
        if (!_.isEmpty(this.state.filters)) {
            const filterKeys = Object.keys(this.state.filters);
            return filterKeys[filterKeys.length - 1];
        }
        return null;
    }

    /* controllo per definire se mostrare la finestre Filtri o la finestra Options */
    showFilterList() {
        return !_.isEmpty(this.state.filters[this.current()])
    }

    render() {
        const {filtersOptions} = this.props;
        const {filters, isOpen} = this.state
        const current = this.current();
        const showFilter = this.showFilterList();
        //
        // console.log(" FILTERS ", filters)
        // console.log(" CURRENT ", current)
        // console.log(" OPTIONS ", filtersOptions[current])

        const RenderFiltersOptionsList = () => {
            if (isOpen) {
                if (!showFilter && current) { // Visualizza le Options
                    return (
                        <OptionList
                            options={filtersOptions[current].options}
                            onClick={this.handleOnChangeOption}
                            onCancel={() => this.handleRemoveOption(current)}
                            onBooleanConfirm={(option) => this.handleOnBooleanConfirm(current, option)}
                        />
                    )
                } else { //Visualizza i filtri
                    const filteredFilterList = _.omit(filtersOptions, Object.keys(filters));
                    return (
                        <FilterList
                            filters={filteredFilterList}
                            onClick={this.handleOnChangeFilter}
                        />
                    )
                }
            } else {
                return <span/>
            }
        }

        return (
            <div className='filter-bar-container'>
                <div
                    className='filter-bar-left'
                    onClick={() => this.handleOnClick()}
                >
                    {
                        _.isEmpty(this.state.filters) ?
                            <span style={{paddingLeft: '10px'}}> Clicca per selezionare un filtro...</span> : ''
                    }
                    <TetherComponent
                        attachment="bottom right"
                        targetOffset='10px 0px'
                        constraints={[{
                            to: 'scrollParent',
                            attachment: 'together'
                        }]}
                    >
                        <div style={{display: 'inline-block'}}>
                            {
                                <RenderFiltersBar
                                    filters={filters}
                                    filtersOptions={filtersOptions}
                                    onCloseOption={this.handleOnCloseOption}
                                />
                            }
                        </div>
                        {<RenderFiltersOptionsList/>}
                    </TetherComponent>

                </div>
                {
                    _.isEmpty(this.state.filters) ? <span/> :
                        <Tooltip title="Rimuovi tutti i filtri">
                            <div
                            className='filter-bar-right'
                            onClick={this.handleRemoveAllFilters}
                        >
                            <i className="fal fa-times"/>
                        </div>
                        </Tooltip>
                }
                <div>
                    <Button
                        type="primary"
                        onClick={this.handleOnClickFilterButton}
                    >Filtra</Button>
                </div>
            </div>

        );
    }
}

FiltersBar.propTypes = {
    filtersOptions: PropTypes.object,
    initialFilters: PropTypes.object,
    onSubmit: PropTypes.func
};
FiltersBar.defaultProps = {};

export default FiltersBar;
