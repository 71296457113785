
export const PUBTEST = 'pubtest';
export const DEVEL = 'devel';
export const PROD = 'prod';

export const EX_DATE_EXPIRED = 'expired';
export const EX_DATE_EXPIRE = 'expire';
export const EX_DATE_ALERT = 'alert';
export const EX_DATE_INFO = 'info';

export const GOV_SUFFIX = '.gov.it';