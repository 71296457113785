import React from 'react';
import "./EppMessagesType.css"
import BaseMessage from "./BaseMessage";

const RefoundRenewsBulkTransferMessage = (props) => {

    function Detail({data}) {
        if (data) {
            return (
                <div className={'typedLayout-container'}>
                    {data &&
                    <div>
                        <div className={'typedLayout-data'}>
                            {data['domainsNum'] &&
                            <div className={'typedLayout-row'}>
                                <span className={'label'}>Numero domini:</span>
                                <span className={'content'}> {data['domainsNum']}</span>
                            </div>
                            }

                            {data['bulkTransferId'] &&
                            <div className={'typedLayout-row'}>
                                <span className={'label'}>Id bulk transfer:</span>
                                <span className={'content'}> {data['bulkTransferId']}</span>
                            </div>
                            }
                            {data['amount'] &&
                            <div className={'typedLayout-row'}>
                                <span className={'label'}>Importo:</span>
                                <span className={'content'}> {data['amount']}</span>
                            </div>
                            }
                        </div>
                    </div>}
                </div>
            )
        }
        else {
            return <span/>
        }
    }

    return (
        <BaseMessage
            detail={props.detail}
            component={<Detail data={props.detail.data}/>}
        />
    );
};

RefoundRenewsBulkTransferMessage.propTypes = {};
RefoundRenewsBulkTransferMessage.defaultProps = {};

export default RefoundRenewsBulkTransferMessage;
