import React from 'react';
import PropTypes from 'prop-types';
import {CONTACT_TYPE} from "app/contacts/constants/const";
import {SimpleContactForm} from "app/contacts/components/ContactForm/SimpleContactForm";
import {RegistrantContactForm} from "app/contacts/components/ContactForm/RegistrantContactForm";
import {RegistrantEduContactForm} from "app/contacts/components/ContactForm/RegistrantEduContactForm";
import {RegistrantGovContactForm} from "app/contacts/components/ContactForm/RegistrantGovContactForm";
function RenderContactForm(props) {
    const {contactType, handleSetForm} = props;

    if (contactType) {
        switch (contactType) {
            case CONTACT_TYPE.contact:
                return (
                    <SimpleContactForm
                        wrappedComponentRef={handleSetForm}
                        {...props}
                    />
                )
            case CONTACT_TYPE.registrant:
                return (
                    <RegistrantContactForm
                        wrappedComponentRef={handleSetForm}
                        {...props}
                    />
                )
            case CONTACT_TYPE.registrantEdu:
                return (
                    <RegistrantEduContactForm
                        wrappedComponentRef={handleSetForm}
                        {...props}
                    />
                )
            case CONTACT_TYPE.registrantGov:
                return (
                    <RegistrantGovContactForm
                        wrappedComponentRef={handleSetForm}
                        {...props}
                    />
                )
            default:
                return (
                    <></>
                )
        }
    }
    return <></>
}

RenderContactForm.propTypes = {
    contactType: PropTypes.string,
    handleSetForm: PropTypes.func,
};
RenderContactForm.defaultProps = {};

export default RenderContactForm;
