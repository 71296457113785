import React from 'react';
import "./EppMessagesType.css"
import BaseMessage from "./BaseMessage";

const CreditMessage = (props) => {

    function Detail({data}) {
        if (data) {
            return (
                <div className={'typedLayout-container'}>
                    {data &&
                    <div>
                        <div className={'typedLayout-data'}>
                            {data['credit'] &&
                            <div className={'typedLayout-row'}>
                                <span className={'label'}>Credito:</span>
                                <span className={'content'}> {data['credit']}</span>
                            </div>
                            }

                            {data['creditNeeded'] &&
                            <div className={'typedLayout-row'}>
                                <span className={'label'}>Credito necessario:</span>
                                <span className={'content'}> {data['creditNeeded']}</span>
                            </div>
                            }
                            {data['untilDate'] &&
                            <div className={'typedLayout-row'}>
                                <span className={'label'}>Fino a:</span>
                                <span className={'content'}> {data['untilDate']}</span>
                            </div>
                            }
                        </div>
                    </div>}
                </div>
            )
        }
        else {
            return <span/>
        }
    }

    return (
        <BaseMessage
            detail={props.detail}
            component={<Detail data={props.detail.data}/>}
        />
    );
};

CreditMessage.propTypes = {};
CreditMessage.defaultProps = {};

export default CreditMessage;
