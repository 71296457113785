import { connect } from 'react-redux'
import { IntlProvider } from 'react-intl'
import React from 'react';
import {LocaleProvider} from "antd";

const IntlProviderContainer = (props) => {
    return (
        <LocaleProvider locale={props.antdLocale}>
            <IntlProvider locale={props.locale} messages={props.messages}>
                {props.children}
            </IntlProvider>
        </LocaleProvider>
    );
};

function defaultSelector(state) {
    return state.intlReducer
}

const mapStateToProps = (state, { intlSelector = defaultSelector }) => {
    const intl = intlSelector(state)
    return {
        ...intl,
        locale: intl.locale,
        messages: intl.messages,
        antd: intl.antdLocale
    }
}

export default connect(mapStateToProps)(IntlProviderContainer)

