export default class SerializeVisitor {
  constructor(result) {
    this.result = result;
  }

  visitAddress(address) {
    this.result['address'] = {};
    this.result['address']['city']=address.city;
    this.result['address']['state']=address.state;
    this.result['address']['postalCode']=address.postalCode;
    this.result['address']['countryCode']=address.countryCode;
    this.result['address']['streets']=address.streets;
  }

  visitRegistrant(registrant) {
    console.log('Visit Registrant: ', registrant);
    this.result['registrant'] = {};
    this.result['registrant']['regCode']=registrant.regcode;
    this.result['registrant']['schoolCode']=registrant.schoolCode;
    if (registrant.gov !== undefined) {
        this.result['registrant']['gov'] = {};
        this.result['registrant']['gov']['ipaCode'] = registrant.gov.ipaCode;
        if (registrant.gov.uoCode !== undefined) {
            this.result['registrant']['gov']['uoCode'] = registrant.gov.uoCode;
        }
    }
    this.result['registrant']['nationalityCode']=registrant.nationalityCode;
    this.result['registrant']['entityType']=registrant.entityType;
  }

  visitRegistrantContact(registrantContact) {

    //this.visitContact(registrantContact);

    registrantContact.registrant.accept(this);

    this.result['clId']=registrantContact.clId;
    this.result['crId']=registrantContact.crId;
    this.result['roid']=registrantContact.roid;
  }

  visitContact(contact) {
    contact.address.accept(this);
    this.result['crDate']=contact.crDate;
    this.result['name']=contact.name;
    this.result['org']=contact.org;
    this.result['voice']=contact.voice;
    this.result['fax']=contact.fax;
    this.result['email']=contact.email;
    this.result['id']=contact.id;
    this.result['statuses']=contact.statuses;
    this.result['consentForPublish']=contact.consentForPublish;

  }

  toJSon() {
    return this.result;
  }

}
