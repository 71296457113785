import styled from "styled-components";
import {Link} from "react-router-dom";
import LabelWithIcon from "app/commons/components/Layout/LabelWithIcon/LabelWithIcon";
import React from "react";

const StyledMenuItemLink = styled(Link)`
        font-size: 16px;
        font-weight: 600;
`;

export const MenuItemLink = ({linkTo, type, idMessage, isMenuItem, onlyIcon}) =>
    isMenuItem ?
        <StyledMenuItemLink to={linkTo}>
            <LabelWithIcon type={type} idMessage={idMessage} onlyIcon={onlyIcon}/>
        </StyledMenuItemLink> :
        <Link to={linkTo}>
            <LabelWithIcon type={type} idMessage={idMessage} onlyIcon={onlyIcon}/>
        </Link>;
