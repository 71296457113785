import * as types from "./actionTypes";
import * as api from "./api";

function loadCountries() {
    return {
        type: types.COUNTRY_CODE_LOADING,
        payload: api.countries()
    }
}

export function loadCountriesStart() {
    return {
        type: types.COUNTRY_CODE_LOADING_START,
    }
}

function countriesLoadingSuccess(response) {
    return {
        type: types.COUNTRY_CODE_LOADING_SUCCESS,
        payload: response
    }
}

function countriesLoadingFailure(error) {
    return {
        type: types.COUNTRY_CODE_LOADING_ERROR,
        payload: error
    }
}

export default {loadCountries, countriesLoadingSuccess, countriesLoadingFailure, loadCountriesStart}