export const changedPostStatus = (nextStatus, prevStatus) => {
  let changed = false;
  
  if (nextStatus && prevStatus) {
    if (nextStatus.pending) {
      changed=true;
    } else {
      changed = (nextStatus.settled !== prevStatus.settled);
    }
  } else {
    changed = (nextStatus) && (!prevStatus);
  }

  return changed;
}
