export const formItemLayout = {
    labelCol: {
        // xs: {span: 24},
        sm: {span: 7},
        md: {span: 7},
        lg: {span: 4},

    },
    wrapperCol: {
        // xs: {span: 24},
        sm: {span: 24},
        md: {span: 24},
        lg: {span: 20},
    },
};
export const tailFormItemLayout = {
    wrapperCol: {
        // xs: {
        //     span: 24,
        //     offset: 0,
        // },
        sm: {
            span: 14,
            offset: 3,
        },
    },
};




export const SubformItemLayout = {
    labelCol: {
        // xs: {span: 24},
        md: {span: 7},
        xl: {span: 5},
    },
    wrapperCol: {
        // xs: {span: 24},
        md: {span: 24},
        xl: {span: 19},
    },
};