import React from 'react';
import {Menu} from 'antd';
import MenuItemRow from "./MenuItemRow";

const FilterList = ({filters, onClick}) => {
    return (
        <Menu
            onClick={onClick}
            className='filter-div'
        >
            {Object.keys(filters).map(function (key, index) {
                return (
                    <Menu.Item key={key} className='filter-menu-item'>
                        <MenuItemRow {...filters[key]} />
                    </Menu.Item>
                )
            })}
        </Menu>
    )
}

export default FilterList