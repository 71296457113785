import React, {Component} from 'react';
import Wizard from "../../../commons/components//Navigation/Wizard/Wizard";
import {steps} from "./index";
import {withRouter} from 'react-router';
import {connect} from "react-redux";
import "./ConfigurationWizard.css";
import ConfigurationWizardTemplate from "./ConfigurationWizardTemplate";
import {injectIntl} from "react-intl";
import PropTypes from 'prop-types';
import {insertServer} from "../../operations";
import {configOperations} from "../../index";

class ConfigurationWizard extends Component {

    constructor(props) {
        super(props);
        this.fields = {};

        const params = new URLSearchParams(props.history.location.search);
        let step = null;

        let userExistsValue = params.get("userExists");
        let userExists = (userExistsValue === 'true');


        if (userExists && params.get("step"))
            step = parseInt(params.get("step"), 10);


        this.state = {
            configurationReady: false,
            needConfigurationWizard: false,
            error: null,
            errorMessage: '',
            current: step || 0,
            steps: steps
        }
    }

    next = () => {
        const {steps, current} = this.state;

        if (!steps[current].readOnly && steps[current].stepActions) {
            steps[current].stepActions[0](this, 0)
        } else {
            this.setState({current: current + 1})
        }
    }

    prev = () => {
        const current = this.state.current - 1;
        // se siamo nella condizione di
        if (current === 0) {
            let steps = this.state.steps;
            steps[0].readOnly = true;
            this.setState({steps: steps});
        }

        this.setState({current: current})
    }


    componentWillMount() {
        const params = new URLSearchParams(this.props.history.location.search);
        let config = {};
        config['eppConfigExists'] = params.get("eppConfigExists");
        config['userExists'] = params.get("userExists");
        this.setState({configurationStatus: config});
    }


    handleOnDone() {
        const {current, configurationStatus} = this.state;
        if (steps[current].id === 'serverConfiguration' && !(configurationStatus.eppConfigExists === 'true')) {
            insertServer(this, 2);
        } else {
            this.context.store.dispatch(configOperations.eppConfiguration(this));
            this.props.history.push("/");
        }
    }

    handleOnChange = (fields) => {
        this.fields = {...this.fields, ...fields};
    }

    render() {
        return (
            <ConfigurationWizardTemplate>
                <Wizard fields={this.fields}
                        steps={this.state.steps}
                        current={this.state.current}
                        next={this.next}
                        prev={this.prev}
                        onDone={this.handleOnDone.bind(this)}
                        onChange={this.handleOnChange}
                        error={this.state.error}
                />
            </ConfigurationWizardTemplate>
        )
    }
}


ConfigurationWizard.contextTypes = {
    store: PropTypes.object
};


function mapStateToProps(state) {
    return {
        isAuthenticated: state.authReducer.authenticated
    };
}

export default withRouter(connect(mapStateToProps)(injectIntl(ConfigurationWizard)));
