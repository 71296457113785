import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {Form} from "antd";
import DomainHostDetail from "app/domains/components/DataDisplay/DomainHostDetail/DomainHostDetail";

const FormItem = Form.Item;

class Step1HostSelection extends Component {

    constructor(props) {
        super(props);

        this.state = {
            hostsToCopy: []
        };

        this.rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                const {setFieldsValue, getFieldValue, getFieldDecorator} = this.props.form;
                const {hostsToCopy} = this.state;
                let originalKey = getFieldValue('keys');

                let toCopy = new Array(hostsToCopy.length).fill(true);



                setFieldsValue({deletedDns: selectedRows});


                selectedRowKeys.forEach((index) => {
                    this.clearDNSField(index);
                })

                let disabled = {};

                for (let k = 0; k < hostsToCopy.length; k++) {
                    disabled[`disabled-${k}`] = false;
                }

                selectedRowKeys.forEach((key) => {
                    toCopy[key] = false;
                    disabled[`disabled-${key}`] = false;

                })



                let keys = 0;
                toCopy.forEach((copy, k) => {
                    if (copy) {
                        let host = {};
                        let address = {};


                        host[`names-${keys}`] = hostsToCopy[k].name;
                        disabled[`disabled-${keys}`] = true;
                        if (hostsToCopy[k].addresses) {
                            hostsToCopy[k].addresses.forEach((addr) => {
                                address[`ip${addr.type}-${keys}`] = addr.address;
                                if (!getFieldValue(`ip${addr.type}-${keys}`)) {
                                    getFieldDecorator(`ip${addr.type}-${keys}`, {initialValue: addr.address});
                                }
                            });

                            setFieldsValue(address);
                        }

                        setFieldsValue(host);
                        keys++;
                    }
                });


                setFieldsValue(disabled);


                if (keys >= 2 && (originalKey-keys) >0) {
                    for (let i=0; i<(originalKey-keys); i++) {
                        this.clearDNSField(keys+i);
                    }
                }

                // Se il valore delle chiavi è < 2 (è 1)
                if (keys < 2) {
                    this.clearDNSField(1);
                }

                setFieldsValue({keys: Math.max(keys, 2)});

            },
            getCheckboxProps: record => ({}),
        }

    }

    clearDNSField = (index) => {

        const {getFieldValue, setFieldsValue} = this.props.form;
        let field = {};
        field[`names-${index}`] = '';
        if (getFieldValue(`ipv4-${index}`)) {
            field[`ipv4-${index}`] = '';
        }
        if (getFieldValue(`ipv6-${index}`)) {
            field[`ipv6-${index}`] = '';
        }

        setFieldsValue(field);
    }


    componentDidMount() {


        const {name, hosts} = this.props.fields.domainData;
        const {getFieldDecorator} = this.props.form;
        if (hosts) {
            this.setState({hostsToCopy: hosts.slice()});
        }

        getFieldDecorator('domainName', {initialValue: name})
        getFieldDecorator('dnsEmbedded', {initialValue: true})
        getFieldDecorator('selectedDnsIndex', {initialValue: []});
        getFieldDecorator('deletedDns', {initialValue: []});


        if (hosts) {
            hosts.forEach((host, k) => {
                getFieldDecorator(`names-${k}`, {initialValue: host.name});
                getFieldDecorator(`disabled-${k}`, {initialValue: true});
                if (host.addresses) {
                    host.addresses.forEach((addr) => {
                        getFieldDecorator(`ip${addr.type}-${k}`, {initialValue: addr.address});
                    });
                }
            });
        }
        getFieldDecorator('keys', {initialValue: (hosts?hosts.length:0)});
    }

    render() {
        const {fields} = this.props;
        const {formatMessage} = this.props.intl;
        return (

            <Form>

                {
                    fields.domainData.hostsToValidate && (
                    <div className="riquadro bordo with-title">
                        <div className="title">{formatMessage({id: 'domain.update.host.dnsHold'})}</div>
                        <DomainHostDetail
                            hosts={fields.domainData.hostsToValidate}
                        />
                    </div>
                    )
                }
                    <FormItem>
                    <div className="riquadro bordo with-title">
                        <div className="title">{formatMessage({id: 'domain.update.host.step1.table.title'})}</div>
                        <DomainHostDetail
                            rowsSelectionHandler={this.rowSelection}
                            hosts={fields.domainData.hosts}
                        />

                    </div>

                </FormItem>
            </Form>
        )
    }


}


export default Form.create()(injectIntl(Step1HostSelection));