import actions from "./actions"
import {checkContact, checkContactFailure, checkContactStart, checkContactSuccess} from "./actions/checkActions";
import {hanldeErrorDispatching} from "../commons/utils/utils";
import {deleteContact} from "./actions/deleteActions";
import {newContact, newContactFailure, newContactStart, newContactSuccess} from "./actions/newActions";
import {infoContact, infoContactFailure, infoContactStart, infoContactSuccess,} from "./actions/infoActions";
import {updateContact, updateContactFailure, updateContactStart} from "./actions/updateActions";
import {contactList, contactListFailure, contactListStart, contactListSuccess} from "./actions/listActions";
import {notificationSuccess} from "../commons/utils/notification";

function infoContactOp(contactId) {
    return function (dispatch) {
        dispatch(infoContactStart())
        dispatch(infoContact(contactId))
            .then((response) => {
                if (!response.error) {
                    dispatch(infoContactSuccess(response.payload))
                } else {
                    dispatch(hanldeErrorDispatching(response.payload, infoContactFailure))
                }
            })
    }
}

function checkContactOp(contactId) {
    return function (dispatch) {
        dispatch(checkContactStart())
        dispatch(checkContact(contactId))
            .then((response) => {
                if (!response.error) {
                    dispatch(checkContactSuccess(response))
                } else {
                    dispatch(hanldeErrorDispatching(response.payload, checkContactFailure))
                }
            })
    }
}

function deleteContactOp(contact, onSuccess) {
    return function (dispatch) {
        dispatch(infoContactStart())
        dispatch(deleteContact(contact))
            .then((response) => {
                if (!response.error) {
                    dispatch(infoContactSuccess(response))
                    notificationSuccess('Contatto eliminato con successo')
                    onSuccess()
                } else {
                    dispatch(hanldeErrorDispatching(response.payload, infoContactFailure))
                }
            })
    }
}

function newContactOp(data, onSuccess) {
    return function (dispatch) {
        dispatch(newContactStart())
        dispatch(newContact(data))
            .then((response) => {
                if (!response.error) {
                    dispatch(newContactSuccess(response.payload))
                    notificationSuccess('Contatto creato con successo')
                    onSuccess()
                } else {
                    dispatch(hanldeErrorDispatching(response.payload, newContactFailure))
                }
            })
    }
}

function listContactOp(searchParams) {
    return function (dispatch) {
        dispatch(contactListStart())
        dispatch(contactList(searchParams))
            .then((response) => {
                if (!response.error) {
                    dispatch(contactListSuccess(response.payload))
                } else {
                    dispatch(hanldeErrorDispatching(response.payload, contactListFailure))
                }
            })
    }
}


function updateContactOp(contactId, data, onSuccess) {
    return function (dispatch) {
        dispatch(updateContactStart())
        dispatch(updateContact(contactId, data))
            .then((response) => {
                if (!response.error) {
                    dispatch(infoContactOp(contactId))
                    notificationSuccess('Contatto modificato con successo')
                    onSuccess()
                } else {
                    dispatch(hanldeErrorDispatching(response.payload, updateContactFailure))
                }
            })
    }
}

const resetInfoContact = () => (actions.info.resetInfoContact())
const resetNotificationsInfoContact = () => (actions.info.resetNotificationsInfoContact())
export default {
    updateContactOp,
    listContactOp,
    newContactOp,
    resetNotificationsInfoContact,
    infoContactOp,
    checkContactOp,
    deleteContactOp,
    resetInfoContact
}