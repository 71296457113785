import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from "react-intl";
import debounce from 'lodash.debounce';
import {checkContactId as checkCID} from "../../../../contacts/api";
import ContactIdFormItem from "app/commons/components/DataEntry/ContactIdFormItem/ContactIdFormItem";
import ContactIdValidator from "app/commons/utils/validators/ContactIdValidator";


class ContactIdFormItemContainer extends React.Component {

    constructor(props) {
        super(props);
        this.debouncedCheckIdContact = debounce(this.checkIdContact, 400);
        this.checkContactIdConstraints = this.checkContactIdConstraints.bind(this);
    }

    /**
     * cerca il contatto se il contatto è available o non available.
     * Se hasSuccessIfAvailable (true) e il contatto e in stato available allora chiama callBack() cioè successo
     * Se hasSuccessIfAvailable (false) e il contatto e in stato available allora chiama callBack() cioè successo
     *
     */
    available

    checkIdContact = (hasSuccessIfAvailable, value, callback) => {
        const {formatMessage} = this.props.intl;
        return checkCID(value)
            .then(result => {
                if (result.data.available && hasSuccessIfAvailable) {
                    callback()
                } else if (!result.data.available && !hasSuccessIfAvailable) {
                    callback()
                } else if (!result.data.available) {
                    callback([
                        new Error(formatMessage({id: 'contact.detail.form.id.error.message.idNotValid'}))
                    ])
                } else {
                    callback([
                        new Error(formatMessage({id: 'contact.detail.form.id.error.message.idNotPresent'}))
                    ])
                }
            })
            .catch(err => {
                callback([
                    new Error(formatMessage({id: 'contact.detail.form.id.error.message.checkIderror'},
                        {error: err}))
                ])
            })
    };

    //Controlla se l'id contatto rispetta i vincoli
    checkContactIdConstraints = (rule, value, callback) => {
        const {formatMessage} = this.props.intl;
        //Controlla se il nome a dominio rispetta i vincoli
        if (value) {
            let cId = ContactIdValidator.validate(value, true);
            if (!cId.valid) {
                callback([new Error(formatMessage({id: cId.error}))])
            }
        } else {
            callback([new Error(formatMessage({id: "requiredContactId"}))])

        }
        this.debouncedCheckIdContact(this.props.hasSuccessIfAvailable, value, callback)
    };

    render() {
        return (
            <ContactIdFormItem
                {...this.props}
                initialValue={this.props.items && this.props.items['id']}
                hasFeedback
                onChange={this.handleOnChange}
                validator={this.checkContactIdConstraints}
            />
        );
    }
}

ContactIdFormItemContainer.propTypes = {
    form: PropTypes.object,
    items: PropTypes.object,
    initialValue: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    placeHolder: PropTypes.string,
    formItemLayout: PropTypes.object,
    hasSuccessIfAvailable: PropTypes.bool, // se true e il contatto non esiste (available=false) allora l'input da flag ok
                                       //Se false e il contatto non esiste (available=false) allora l'input da flag ok
}

ContactIdFormItemContainer.default = {
    hasSuccessIfAvailable: true
}

export default injectIntl(ContactIdFormItemContainer);
