import React from 'react';
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import DomainHostDetail from "../DomainHostDetail/DomainHostDetail";

const NameServerDetail = (props) => {
    const {formatMessage} = props.intl;


    return (
        <span>
            {props.hosts &&
            <span>
                <DomainHostDetail
                    hosts={props.hosts}
                    title={formatMessage({id: "detail.domain.nameserver.actual"})}
                />

            </span>
            }

            {props.hostsToValidate &&
            <DomainHostDetail
                hosts={props.hostsToValidate}
                title={formatMessage({id: "detail.domain.nameserver.toValidate"})}
            />
            }
        </span>
    );
};

NameServerDetail.propTypes = {
    hosts: PropTypes.array,
    hostsToValidate: PropTypes.array,
    title: PropTypes.string,
};

export default injectIntl(NameServerDetail);
