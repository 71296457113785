import React from 'react';
import PropTypes from 'prop-types';
import {Form, Input} from 'antd';
import {injectIntl} from "react-intl";

const FormItem = Form.Item;

class ContactIdFormItem extends React.Component {

    render() {
        const {getFieldDecorator} = this.props.form;
        const {formatMessage} = this.props.intl;
        return (
            <FormItem
                {...this.props.formItemLayout}
                label={formatMessage({id: 'contact.detail.form.contactId'})}
                hasFeedback
            >
                {getFieldDecorator('id', {
                    initialValue: this.props.initialValue,
                    rules: [{
                        required: true,
                    }, {
                        validator: this.props.validator,
                    }]
                })(
                    //il campo non è modificabile
                    <Input disabled={this.props.disabled}
                           onChange={this.props.onChange}
                           placeholder={formatMessage({id: 'contact.detail.form.id.placeholder'})}
                           addonAfter={this.props.addonAfter}
                    />
                )}
            </FormItem>
        );
    }
}

export default injectIntl(ContactIdFormItem)

ContactIdFormItem.propTypes = {
    form: PropTypes.object,
    initialValue: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    placeHolder: PropTypes.string,
    formItemLayout: PropTypes.object,
    validateStatus: PropTypes.string,
    help: PropTypes.string,
    validator: PropTypes.func,
}



