import * as status from "./constants/statuses"
import {CLIENT_UPDATE_PROHIBITED} from "./constants/statuses"

export function isUpdatable(statuses) {
    return !(statuses.includes(statuses.includes(status.SERVER_UPDATE_PROHIBITED)));
}

export function isDeletable(statuses) {
    return (
        !(
            statuses.includes(status.CLIENT_DELETE_PROHIBITED) ||
            statuses.includes(status.SERVER_DELETE_PROHIBITED) ||
            statuses.includes(status.LINKED)
        ));
}

export function isClientUpdateProhibited(statuses) {
    return statuses.includes(CLIENT_UPDATE_PROHIBITED)
}
