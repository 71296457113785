import React, {Component} from 'react';
import {Menu} from "antd";
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import * as selector from "./../../selectors";
import ActionMenu from "../../../commons/components/Navigation/ActionMenu/ActionMenu";

const MODIFY = 'modify'
const DELETE = 'delete'

class ContactInfoActionMenu extends Component {

    render() {

        const {formatMessage} = this.props.intl;
        const {contactData, onModify, onDelete} = this.props;
        const {data} = contactData;
        if (data) {
            const {statuses} = data;

            let hasModifyPermission = (selector.isUpdatable(statuses));
            let hasDeletePermission = (selector.isDeletable(statuses));

            function handleMenuClick(e) {
                switch (e.key) {
                    case MODIFY:
                        onModify();
                        break;
                    case DELETE:
                        onDelete(data.id, onDelete);
                        break;
                    default:
                        console.warn("Operazione non definita ", e.key)
                }
            }

            const buildContactInfoMenu = (
                <Menu onClick={handleMenuClick.bind(this)}>
                    <Menu.Item key={MODIFY} disabled={!hasModifyPermission}>
                        {formatMessage({id: 'contact.action.modify'})}
                    </Menu.Item>
                    <Menu.Item key={DELETE}
                               disabled={!hasDeletePermission}>
                        {formatMessage({id: 'contact.action.delete'})}
                    </Menu.Item>
                </Menu>
            );

            return (
                <div>
                    <ActionMenu overlay={buildContactInfoMenu}
                                title={formatMessage({id: 'domain.info.action.menu.title'})}
                    />
                </div>
            );
        }

        return <span/>
    }
}

ContactInfoActionMenu.propTypes = {
    contactData: PropTypes.object,
    onDelete: PropTypes.func,
    onModify: PropTypes.func,
};
ContactInfoActionMenu.defaultProps = {};

export default injectIntl(ContactInfoActionMenu);
