import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import ContactList from "../components/Pages/ContactList/ContactList";
import {contactsOperations} from "../index";

const mapDispatchToProps = (dispatch) => {
    return {
        onLoadList: (searchParams) => {
            dispatch(contactsOperations.listContactOp(searchParams))
        },
    };
};

const mapStateToProps = (state) => {
    return {
        contactsList: state.contact.listReducer
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactList));