import React, {Component} from 'react';
import {Form, Input} from 'antd';

import {injectIntl} from 'react-intl';

import {checkPassword, checkPasswordLen} from '../../../../commons/utils/validators/FormValidator';

const FormItem = Form.Item;

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 5},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 4},
    },
};

const MIN_PASSWORD_LEN = 8;
const MAX_PASSWORD_LEN = 16;

class EppPasswordFormWrapper extends Component {


    passwordLengthChecker = (rule, value, callback) => {
        if (!value) {
            callback()
        } else {
            callback(checkPasswordLen(value, MIN_PASSWORD_LEN, MAX_PASSWORD_LEN, this.props.intl));
        }
    }

    passwordChecker = (rule, value, callback) => {

        const password = this.props.form.getFieldValue('password');
        if (password && password.length > 0) {
            callback(checkPassword(password, value, this.props.intl));
        }
        else
            callback()
    }

    render() {
        const {intl} = this.props;
        const {getFieldDecorator} = this.props.form;
        return (
            <Form>
                <FormItem
                    {...formItemLayout}
                    hasFeedback
                    label={intl.formatMessage({id: 'admin.epp.password'})}
                    style={{textAlign: 'left'}}
                >
                    {getFieldDecorator('password',
                        {
                            rules: [
                                {
                                    required: true
                                }, {validator: this.passwordLengthChecker}
                            ]
                        })(
                        <Input placeholder={intl.formatMessage({id: 'admin.epp.password.placeholder'})}
                               type="password"/>
                    )
                    }
                </FormItem>

                <FormItem
                    {...formItemLayout}
                    hasFeedback
                    label={intl.formatMessage({id: 'admin.epp.password.confirm'})}
                    style={{textAlign: 'left'}}
                >
                    {getFieldDecorator('confirmEppPassword',
                        {
                            rules: [{
                                required: true
                            }, {validator: this.passwordChecker}],
                        })(<Input placeholder={intl.formatMessage({id: 'admin.epp.password.confirm.placeholder'})}
                                  type="password"/>)}
                </FormItem>
            </Form>
        )
    }
}

const EppPasswordForm = Form.create({
    onFieldsChange(props, changedFields) {
        props.onFieldsChange(changedFields)
    }
})(injectIntl(EppPasswordFormWrapper));

export default EppPasswordForm;