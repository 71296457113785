import {connect} from "react-redux";
import {Redirect, withRouter} from "react-router-dom";
import React, {Component} from 'react';
import * as routes from "../../routes";
import ContactUpdate from "../components/Pages/ContactUpdate/ContactUpdate";
import ContactInfo2 from "../components/Pages/ContactInfo/ContactInfo";
import {contactsOperations} from "../index";

export const RouteTo = (props) => {
    const {comp, match, contactData} = props
    const {contact} = match.params
    const baseurl = routes.CONTACT_INFO.url;
    if (contact) {
        if (contactData.data) {
            return comp
        } else {
            return <Redirect to={`${baseurl}/${contact}`}/>
        }
    } else {
        return <Redirect to={`${baseurl}`}/>
    }
};

class ContactInfoContainer extends Component {

    constructor(props) {
        super(props);
        this.back = this.back.bind(this)
    }

    back = () => {
        this.props.history.push({
            pathname: `${routes.CONTACT_INFO.url}/${this.props.contactData.data.id}`
        })
    }

    render() {
        switch (this.props.match.path) {
            case routes.CONTACT_UPDATE.path:
                return (
                    <RouteTo {...this.props}
                             comp={
                                 <ContactUpdate
                                     contactData={this.props.contactData}
                                     error={this.props.updatedContactData.error}
                                     onUpdate={this.props.updateContactAction}
                                     onCancel={this.back}
                                 />
                             }
                    />
                );

            default:
                return <ContactInfo2
                    notificationsReset={contactsOperations.resetInfoContact}
                />

        }
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        updateContactAction: (contactId, data) => {
            dispatch(contactsOperations.updateContactOp(
                contactId,
                data,
                () => (ownProps.history.push({pathname: `${routes.CONTACT_INFO.url}/${contactId}`}))
            ))
        },
    };
};

const mapStateToProps = (state) => {
    return {
        contactData: state.contact.infoReducer,
        newContactData: state.contact.newReducer,
        updatedContactData: state.contact.updateReducer,
        deletedContactData: state.contact.deleteReducer,
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactInfoContainer));


