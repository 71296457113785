import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {authOperations} from "../../authentication/index";
import {intlOperations} from "../../intl/index";
import Header from "app/commons/components/Layout/Header/Header";

const mapStateToProps = (state) => {
    return {
        locale: state.intlReducer.locale,
        authenticated: state.authReducer.authenticated,
        userData: state.authReducer.userInfo,
        confData: state.confReducer,
    };
}

const mapDispatchToProps = (dispatch, history) => {
    return {
        handleLogout: (e) => {
            e.key === 'logout' &&
            dispatch(authOperations.doLogout(history));
        },
        changeLang: (lang) => {
            dispatch(intlOperations.updateIntl(lang));
        },
        tokenExpired: () => {
            dispatch(authOperations.notAuthenticated())
        }
    };
}


/* Inject auth state and dispatch() into props */
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
