import React, {Component} from 'react';
import {Form} from 'antd';
import {injectIntl} from 'react-intl';
import {EppFormItems} from "./FormItems";
import PropTypes from "prop-types";


class EppForm extends Component {

    render() {
        return <EppFormItems {...this.props}/>
    }
}

EppForm.propTypes = {
    profile: PropTypes.object,
    username: PropTypes.string,
    dnsSecEnabled: PropTypes.bool,
    language: PropTypes.string,
    onlyLabels: PropTypes.bool, //Visualizza la form senza controlli input ma mostrando solo labels
    onChange: PropTypes.func,
    formItemLayout: PropTypes.object,
};

EppForm.defaultProps = {
    onlyLabels: false,
}
export default EppForm = Form.create()(injectIntl(EppForm));
