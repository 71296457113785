// @flow
import React from 'react';
import "../../../../../styles/DetailPanel.css"
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import {Link} from "react-router-dom";
import * as routes from "../../../../routes";
import RegistrantDetail from "../RegistrantDetail/RegistrantDetail";
import CardDetailRow from "../../../../commons/components/DataDisplay/CardDetailRow/CardDetailRow";
import styled from 'styled-components';
import BooleanDetail from "../../../../commons/components/DataDisplay/BooleanDetail/BooleanDetail";

const StyledCardDetail = styled.div`
    text-align: left;

${props => props.withBorderSpace && {
    paddingTop: '0.5em',
    paddingBottom: '0.5em',
    paddingLeft: '1em',
    overflow: 'hidden',
    // background: 'red',
}}`;

const formattingDate = {
    year: 'numeric', day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric', second: 'numeric'
};

function renderStreets(streets){
    if (streets.length > 0) {
        return streets.map((street, key) => (<div key={key}>{street}</div>))
    }
}

const ContactDetailsBody = (props) => {

    const {formatMessage, formatTime} = props.intl;
    const {name, org, address, voice, fax, email, crDate, id, registrant,consentForPublish} = props.data;

    return (
        <StyledCardDetail {...props}>
            <CardDetailRow label={formatMessage({id: "detail.contact.id"})}
                           content={props.linkable ?
                               <Link to={routes.CONTACT_INFO.url + "/" + id}>{id}</Link> : id}/>
            <CardDetailRow label={formatMessage({id: "detail.contact.name"})}
                           content={name}/>
            {org && <CardDetailRow label={formatMessage({id: "detail.contact.organization"})}
                                   content={org}/>}
            <CardDetailRow label={formatMessage({id: "detail.contact.address.city"})}
                           content={address.city}/>
            <CardDetailRow label={formatMessage({id: "detail.contact.address.streets"})}
                           content={renderStreets(address.streets)}/>
            <CardDetailRow label={formatMessage({id: "detail.contact.address.state"})}
                           content={address.state}/>
            <CardDetailRow label={formatMessage({id: "detail.contact.address.postalCode"})}
                           content={address.postalCode}/>
            <CardDetailRow label={formatMessage({id: "detail.contact.address.countryCode"})}
                           content={address.countryCode}/>
            <CardDetailRow label={formatMessage({id: "detail.contact.phone"})}
                           content={voice}/>
            {fax && <CardDetailRow label={formatMessage({id: "detail.contact.fax"})}
                                   content={fax}/>}
            <CardDetailRow label={formatMessage({id: "detail.contact.email"})}
                           content={email}/>
            <CardDetailRow label={formatMessage({id: "detail.contact.crDate"})}
                           content={formatTime(crDate, formattingDate)}/>
            <CardDetailRow label={formatMessage({id: "contact.detail.consent4pub"})}
                           content={<BooleanDetail checked={consentForPublish}/>}/>
            {props.showRegistrantInfo && registrant &&
            <span>
                <br/>
                <RegistrantDetail data={registrant}/>
            </span>
            }
        </StyledCardDetail>
    );
};

ContactDetailsBody.propTypes = {
    data: PropTypes.any.isRequired,
    linkable: PropTypes.bool,
    showRegistrantInfo: PropTypes.bool,
    withBorderSpace: PropTypes.bool
};

function renderBody(props) {
    if (props.data instanceof Array) {
        return props.data.map((contact, index) => (
                <ContactDetailsBody key={index}
                                    data={contact}
                                    intl={props.intl}
                                    linkable={props.linkable}
                                    showRegistrantInfo={props.showRegistrantInfo}
                                    withBorderSpace={props.withBorderSpace}
                />
            )
        )
    }
    else {
        return <ContactDetailsBody data={props.data}
                                   intl={props.intl}
                                   linkable={props.linkable}
                                   showRegistrantInfo={props.showRegistrantInfo}
                                   withBorderSpace={props.withBorderSpace}
        />
    }
}

const ContactDetail = (props) => {
    return (
        <div>
            {renderBody(props)}
            <div
                style={{display: 'flex',
                    flexDirection: 'row',
                    gap: 10,
                    fontSize: '12pt',
                    fontWeight: 700,
                    color: 'red',
                    alignItems: 'center'
                }}>
                {props.error && <i className="fal fa-exclamation-triangle"/>}
                    {props.error}
                </div>
        </div>
    );
};

ContactDetail.propTypes = {
    data: PropTypes.any.isRequired,
    linkable: PropTypes.bool,
    showRegistrantInfo: PropTypes.bool,
    withBorderSpace: PropTypes.bool,
    error: PropTypes.string
};

ContactDetail.defaultType = {
    linkable: true,
    showRegistrantInfo: false,
    withBorderSpace: false
}

export default injectIntl(ContactDetail);
