// api-connector.js
import { connect } from 'react-refetch'

function appendAuthorizationHeader() {
    return {headers: {'Authorization': localStorage.getItem("token")}}
}

export function catched(reason, meta) {
  return '/login'
}

export default connect.defaults({
  buildRequest: function (mapping) {
    const {headers} =mapping;
    let authHeader = appendAuthorizationHeader().headers;
    let newHeader = {...headers, ...authHeader};
    let url = mapping.url;
    const options = {
      method: mapping.method,
      cache: 'force-cache',
      referrer: mapping.referrer,
      headers: newHeader,
      body: mapping.body,
      andCatch: catched
    }

    return new Request(url, options)
  }/*,
  handleResponse: function(response) {
    if (response.headers.get('content-length') === '0' || response.status === 204) {
      return
    }

    const json = response.json();

    if (response.status >= 200 && response.status < 300) {
      return json;
    } else {
      switch(response.status) {
        case 401:
        case 403:
        console.log("Errore mo vado nella login...wait please");
        // Bisogna fare redirect alla login page..
        return json.redirect('/login');
        break;
        default:
        return json.then(cause => Promise.reject(new Error(cause)))
      }
      // Condizioni di errore..

    }
  }
*/
});
