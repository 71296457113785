import * as types from "../actionTypes";

const INITIAL_STATE = {
    loading: false, data: null, message: null, error: null
}

const updateReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {



        //UPDATE
        case types.CONTACT_UPDATE: {
            return {
                ...state,
                loading: true,
                data: action.payload,
                message: null,
                error: null
            }
        }
        //UPDATE
        case types.CONTACT_UPDATE_START: {
            return {
                ...state,
                loading: true,
                data: action.payload,
                message: null,
                error: null
            }
        }
        case types.CONTACT_UPDATE_SUCCESSFUL: {
            return {
                ...state,
                loading: false,
                data: action.payload.data,
                message: 'Contatto aggiornato correttamente',
                error: null
            }
        }
        case types.CONTACT_UPDATE_ERROR : {
            return {
                ...state,
                loading: false,
                data: null,
                message: null,
                error: action.payload
            }
        }
        case types.CONTACT_UPDATE_RESET  : {
            return {
                ...state,
                loading: false,
                data: null,
                message: null,
                error: null
            }
        }


        default:
            return state
    }

}

export default updateReducer;