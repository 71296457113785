import React from 'react';
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import "./DomainDetail.css"
import punycode from 'punycode';
import DomainName from "../DomainName/DomainName";
import CardDetailRow from "../../../../commons/components/DataDisplay/CardDetailRow/CardDetailRow";
import BooleanDetail from "../../../../commons/components/DataDisplay/BooleanDetail/BooleanDetail";
import ExpireDateContainer from "app/commons/components/DataDisplay/ExpiredDate/ExpireDateContainer";

const formattingDate = {
    year: 'numeric', day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric', second: 'numeric'
};


function punyCodedDomain(domainName) {
    const punycodedDomain = punycode.toASCII(domainName);
    if (punycodedDomain !== domainName) {
        return punycodedDomain
    }
}

const DomainDetail = (props) => {
    const {formatMessage, formatTime} = props.intl;

    const {name, statuses, crDate, exDate, upDate, clId} = props.data;

    const signed = (!!props.data.dsRecords)

    const Signed = <BooleanDetail checked={signed}/>

    return (
        <div className="panel-body">

            <CardDetailRow label={formatMessage({id: "detail.domain.name"})}
                           content={<DomainName name={name} signed={signed}/>}/>
            {punyCodedDomain(name) &&
            <CardDetailRow label={formatMessage({id: "detail.domain.punycodeDomain"})}
                           content={punyCodedDomain(name)}/>
            }
            <CardDetailRow label={formatMessage({id: "detail.domain.status"})}
                           content={statuses.join(' / ')}/>
            {
                props.dnsSecEnabled &&
                <CardDetailRow
                    label={formatMessage({id: "domain.detail.signed"})}
                    content={Signed}
                />
            }
            {!props.isRegistrar &&
            <CardDetailRow label={formatMessage({id: "domain.detail.registrar"})}
                           content={clId}/>
            }
            <CardDetailRow label={formatMessage({id: "detail.domain.crDate"})}
                           content={formatTime(crDate, formattingDate)}/>
            <CardDetailRow label={formatMessage({id: "detail.domain.exDate"})}
                           content={<ExpireDateContainer exDate={exDate}/>}
            />
            <CardDetailRow label={formatMessage({id: "detail.domain.upDate"})}
                           content={upDate ? formatTime(upDate, formattingDate) : '-'}/>
        </div>
    );
};


DomainDetail.propTypes = {
    data: PropTypes.any.isRequired,
    isRegistrar: PropTypes.bool,
    dnsSecEnabled: PropTypes.bool
};
DomainDetail.defaultTypes = {
    isRegistrar: true
}
export default injectIntl(DomainDetail);
