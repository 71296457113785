import React from 'react';
import {Tag} from "antd";
import TagNoCursor from "../../DataDisplay/TagNoCursor/TagNoHover";
import PropTypes from 'prop-types';
import _ from "lodash"
import {injectIntl} from "react-intl";

const Option = ({name, label, onClose}) => {
    if (label || name) {
        return (
            <Tag
                color="blue"
                closable
                onClose={onClose}
            >{label || name}</Tag>
        )
    } else return <span/>
}



const RenderFiltersBar = ({filters: obj, onCloseOption, filtersOptions,intl}) => {
    // console.log("filters to render ", obj)
    const {formatMessage} = intl;
    const handleClose = (filter, option) => (
        onCloseOption(filter, option)
    )

    function getLabel(filter, optionValue) {
        // console.log("Filter optionValue", filter, optionValue)
        if (
            filter && filter['options'] &&
            !_.isEmpty(filter.options.list) &&
            Object.keys(filter.options.list).includes(optionValue)
        ) {
            let option = filter.options.list[optionValue];
            return formatMessage({id:option.label});
        } else if (filter['options'] && filter['options']['booleanField']) {
            if (!optionValue) {
                return null
            } else {
                return optionValue ? filter['options']['labelFalse'] : filter['options']['labelTrue']
            }
        }
    }

    return <div>
        {_.keys(obj).map(function (key, index) {
            // console.log("key da stampare", key)

            let filter = filtersOptions[key];
            let optionValue = _.isEmpty(obj[key]) ? '' : obj[key]
            return (
                <span key={key}>
                    <TagNoCursor>{filter.label}</TagNoCursor>
                    <Option
                        name={optionValue}
                        label={getLabel(filter, optionValue)}
                        onClose={handleClose.bind(this, key, obj[key])}
                    />
                </span>
            )
        })}
    </div>
}


RenderFiltersBar.propTypes = {
    onCloseOption: PropTypes.func, //Ritorna function(filter,option)
    filters: PropTypes.object,
};
RenderFiltersBar.defaultProps = {};

export default injectIntl(RenderFiltersBar);