import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from "react-intl";
import {EX_DATE_EXPIRED} from "app/commons/constants/constants";
import withTooltip from "app/commons/hocs/withTooltip";
import {getExDateDetail} from "app/commons/utils/utils";
import ExpireDate from "app/commons/components/DataDisplay/ExpiredDate/ExpireDate";

/* exDate formato data => 2017-11-15T23:59:59+01:00  */

const ExpireDateContainer = ({exDate, intl}) => {
        const {formatDate, formatMessage} = intl;
        const date = formatDate(exDate, {year: 'numeric', day: 'numeric', month: 'short'})
        const {days, type} = getExDateDetail(exDate);
        let message;

        if (type === EX_DATE_EXPIRED) {
            message = formatMessage({id: 'domain.expiredDays'}, {days: Math.abs(days)})
        } else {
            message = formatMessage({id: 'domain.missingDays'}, {days: Math.abs(days)})
        }

        const DateToShowWithTooltip = withTooltip(ExpireDate, {title: message, placement: 'right'});
        return <DateToShowWithTooltip date={date} days={days} type={type}/>
    }
;

ExpireDateContainer.propTypes = {
    exDate: PropTypes.string.isRequired  //Expired date
};

ExpireDateContainer.defaultProps = {};

export default injectIntl(ExpireDateContainer);
