import React, {Component} from 'react';
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import {Menu} from 'antd';
import {PENDING_TRANSFER} from "../../../constants/statuses";
import ActionMenu from "../../../../commons/components/Navigation/ActionMenu/ActionMenu";
import * as domain from "../../../selectors";

const SubMenu = Menu.SubMenu;


const TRANSFER = 'TRANSFER';
const TRANSFER_SATUS = 'TRANSFER_SATUS';
const EDIT = 'EDIT';
const EDIT_SIMPLE = 'EDIT_SIMPLE';
const EDIT_HOST = 'EDIT_HOST';
const EDIT_STATUS = 'EDIT_STATUS';
const EDIT_REGISTRANT = 'EDIT_REGISTRANT';
const EDIT_AUTHINFO = 'EDIT_AUTHINFO';
const DELETE = 'DELETE';
const RESTORE = 'RESTORE';
const AUTHINFO = 'AUTHINFO';
const TRANSFER_TRADE = 'TRANSFER_TRADE'

class DomainActionMenu extends Component {

    render() {
        const {
            statuses, hasRegistrarPermission,
            onShowAuthInfo, onDeleteDomain, onRestoreDomain,
            onEditHost, onEditStatus, onTransferDomainManage,
            onEditSimple, onEditAuthInfo, onEditRegistrant, onTransferTrade
        } = this.props;
        const {formatMessage} = this.props.intl;


        function handleMenuClick(e) {
            switch (e.key) {
                case AUTHINFO:
                    onShowAuthInfo();
                    break;
                case DELETE:
                    onDeleteDomain();
                    break;
                case RESTORE:
                    onRestoreDomain();
                    break;
                case EDIT_AUTHINFO:
                    onEditAuthInfo();
                    break;
                case EDIT_REGISTRANT:
                    onEditRegistrant();
                    break;
                case EDIT_HOST:
                    onEditHost();
                    break;
                case EDIT_STATUS:
                    onEditStatus();
                    break;
                case EDIT_SIMPLE:
                    onEditSimple();
                    break;
                case TRANSFER_SATUS:
                    onTransferDomainManage();
                    break;
                case TRANSFER_TRADE:
                    onTransferTrade();
                    break;
                default :
                    console.error("Nessuna operazione!!! ",)
            }
        }

        const hasDeletePermission = (onDeleteDomain && hasRegistrarPermission && domain.isDeletable(statuses));
        const hasRestorePermission = (onRestoreDomain && hasRegistrarPermission && domain.isRestorable(statuses));

        //&& hasRegistrarPermission ??? va visto se vi vuole questo permesso
        const hasTransferPermission = (domain.isTransferable(statuses) && !hasRegistrarPermission);

        const hasShowAuthinfoPermission = (onShowAuthInfo && hasRegistrarPermission);
        const hasEditPermission = ((onEditHost || onEditSimple) && domain.isEditable(statuses) && hasRegistrarPermission);


        let statusItem = null;

        if (!statuses.includes(PENDING_TRANSFER)) {
            //non pendingTransfer e mio , abilitare
            if (hasRegistrarPermission) {
                statusItem = <Menu.Item key={TRANSFER_SATUS}
                                        disabled={!hasRegistrarPermission}
                >
                    {formatMessage({
                        id: 'domain.info.action.menu.transferStatus'
                    })}
                </Menu.Item>
            } else {
                //non pendingTransfer e di altro registrar, disattivare
                statusItem = <Menu.Item key={TRANSFER_SATUS}
                                        disabled={!hasRegistrarPermission}
                >
                    {formatMessage({
                        id: 'domain.info.action.menu.transferManage'
                    })}
                </Menu.Item>
            }
        } else {
            //in pending transfer, abilitare
            statusItem = <Menu.Item key={TRANSFER_SATUS}>
                {formatMessage({
                    id: 'domain.info.action.menu.transferManage'
                })}
            </Menu.Item>
        }


        const buildDomainInfoActionMenu = (
            <Menu onClick={handleMenuClick.bind(this)}>
                <Menu.Item key={AUTHINFO} disabled={!hasShowAuthinfoPermission}>
                    {formatMessage({id: 'domain.info.action.menu.showAuthInfo'})}
                </Menu.Item>

                <SubMenu key={EDIT}
                         title={formatMessage({id: 'domain.info.action.menu.edit'})}
                         disabled={!hasEditPermission}>
                    <Menu.Item key={EDIT_SIMPLE}
                               disabled={!domain.isContactEditable(statuses)}>
                        {formatMessage({id: 'domain.info.action.menu.editSimple'})}</Menu.Item>
                    <Menu.Item key={EDIT_AUTHINFO}
                               disabled={!domain.isAuthinfoEditable(statuses)}>
                        {formatMessage({id: 'domain.info.action.menu.editAuthinfo'})}</Menu.Item>
                    <Menu.Item key={EDIT_HOST}
                               disabled={!domain.isHostEditable(statuses)}>
                        {formatMessage({id: 'domain.info.action.menu.editHost'})}</Menu.Item>
                    <Menu.Item key={EDIT_STATUS}
                               disabled={!domain.isStatusEditable(statuses)}>
                        {formatMessage({id: 'domain.info.action.menu.editStatus'})}</Menu.Item>
                    <Menu.Item key={EDIT_REGISTRANT}
                               disabled={!domain.isRegistrantEditable(statuses)}>
                        {formatMessage({id: 'domain.info.action.menu.editRegistrant'})}</Menu.Item>
                </SubMenu>

                <SubMenu key={TRANSFER}
                         title={formatMessage({id: 'domain.info.action.menu.transfer'})}
                >
                    {statusItem}
                    <Menu.Item key={TRANSFER_TRADE}
                               disabled={!hasTransferPermission}>
                        {formatMessage({id: 'domain.info.action.menu.transferRequest'})}</Menu.Item>
                </SubMenu>

                <Menu.Item key={RESTORE}
                           disabled={!hasRestorePermission}>
                    {formatMessage({id: 'domain.info.action.menu.restore'})}
                </Menu.Item>

                <Menu.Item key={DELETE}
                           disabled={!hasDeletePermission}>
                    {formatMessage({id: 'domain.info.action.menu.delete'})}
                </Menu.Item>


            </Menu>
        );

        return (
            <div>
                <ActionMenu overlay={buildDomainInfoActionMenu}
                            title={formatMessage({id: 'domain.info.action.menu.title'})}
                />
            </div>
        );
    }
}

export default injectIntl(DomainActionMenu);

DomainActionMenu.propTypes = {
    statuses: PropTypes.array,
    hasRegistrarPermission: PropTypes.bool,
    onShowAuthInfo: PropTypes.func,
    onDeleteDomain: PropTypes.func,
    onRestoreDomain: PropTypes.func,
    onEditHost: PropTypes.func,
    onEditAuthInfo: PropTypes.func,
    onEditRegistrant: PropTypes.func,
    onEditStatus: PropTypes.func,
    onTransferStatus: PropTypes.func,
    onTransferManage: PropTypes.func,
    onTransferTrade: PropTypes.func
};

DomainActionMenu.defaultProps = {
    onShowAuthInfo: null,
    onDelete: null,
    onRestore: null,
    hasRegistrarPermission: true
};

