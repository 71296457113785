import * as types from "./actionTypes";

function loginRequest() {
    return {
        type: types.LOGIN_REQUEST,
    }
}

const loginFailure = (err) => {
    return{
    type: types.LOGIN_FAILED,
    payload: err
}};

function checkAuthentication() {
    return {
        type: types.CHECK_AUTHENTICATION,
    }
}

function logout() {
    return {
        type: types.LOGOUT,
    }
}

function notAuthenticated() {
    return {
        type: types.IS_NOT_AUTHENTICATED,
    }
}

function authenticated(response) {
    return {
        type: types.AUTHENTICATED_USER_INFO,
        payload: response.data
    }
}

function loginSuccessful(data) {
    localStorage.removeItem("token")
    localStorage.setItem("token", data.authorization);
    return {
        type: types.LOGIN_SUCCESS,
        payload: data,
    }
}

export default {
    checkAuthentication,
    loginFailure,
    authenticated,
    logout,
    loginRequest,
    notAuthenticated,
    loginSuccessful
}