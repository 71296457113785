//TRANSFER (STATUS)

import * as types from "../actionTypes";
import * as api from "../api";

export function transferStatusDomainStart() {
    return {
        type: types.TRANSFER_STATUS_DOMAIN_START,
    }
}


export function transferStatusDomain(domain, authinfo) {
    const response = api.transferStatus(domain, authinfo)
    return {
        type: types.TRANSFER_STATUS_DOMAIN_RESULT,
        payload: response
    }
}

export function transferStatusDomainSuccess(response) {
    return {
        type: types.TRANSFER_STATUS_DOMAIN_SUCCESS,
        payload: response
    }
}

export function transferStatusDomainFailure(error) {
    return {
        type: types.TRANSFER_STATUS_DOMAIN_ERROR,
        payload: error
    }
}

//TRANSFER (REQUEST)

export function transferRequestDomainStart() {
    return {
        type: types.TRANSFER_REQUEST_DOMAIN_START,
    }
}


export function transferRequestDomain(domain, authinfo, data) {
    const response = api.transferRequest(domain, authinfo, data)
    return {
        type: types.TRANSFER_REQUEST_DOMAIN_RESULT,
        payload: response
    }
}

export function transferRequestDomainSuccess(response, message) {
    return {
        type: types.TRANSFER_REQUEST_DOMAIN_SUCCESS,
        payload: {response, message: message}
    }
}

export function transferRequestDomainFailure(error) {
    return {
        type: types.TRANSFER_REQUEST_DOMAIN_ERROR,
        payload: error
    }
}

export function transferRequestDomainReset() {
    return {
        type: types.TRANSFER_REQUEST_DOMAIN_RESET,
        payload: {data: null, error: null, loading: false}
    }
}


// ** TRANSFER APPROVE **

export function approveDomainTransfer(domain, authinfo) {
    const response = api.transferDomainApprove(domain, authinfo)
    return {
        type: types.DOMAIN_TRANSFER_APPROVE,
        payload: response
    }
}


// ** TRANSFER REJECT **

export function rejectDomainTransfer(domain, authinfo) {
    const response = api.transferDomainReject(domain, authinfo)
    return {
        type: types.DOMAIN_TRANSFER_REJECT,
        payload: response
    }
}


// ** TRANSFER DELETE **

export function deleteDomainTransfer(domain, authinfo) {
    const response = api.transferDomainDelete(domain, authinfo)
    return {
        type: types.DOMAIN_TRANSFER_DELETE,
        payload: response
    }
}

