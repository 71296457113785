import DomainValidator from './DomainValidator';

const CONF = {
    'MIN_LENGTH': '6',
    'TLD': 'it'
}

export default class ItDomainValidator extends DomainValidator {


    static validate(domain, onlyIt = true) {
        let result = super.validate(domain);

        if (!result.valid) {
            return result;
        }

        if (domain.length < CONF.MIN_LENGTH) {
            return {"valid": false, "error": "domainNameTooShort"};
        }


        if (onlyIt) {
            var tokens = domain.split(".");
            if (!(tokens[tokens.length - 1] === CONF.TLD)) {
                return {"valid": false, "error": "domainInvalidTLD"};
            }
        }
        return {"valid": true};
    }
}
