import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import countriesOperations from "app/countries/operations";
import RegistrantFormItem from "app/contacts/components/BaseContactForm/FormITems/RegistrantFormItem";

class RegistrantFormItemContainer extends React.Component {

    constructor(props) {
        super(props);
        // this.setToUpperCase = this.setToUpperCase.bind(this);
        this.handleCountryNationalityInitialValue = this.handleCountryNationalityInitialValue.bind(this);
        this.handleEntityTypeOnChange = this.handleEntityTypeOnChange.bind(this);
        this.handleNationalityCodeOnChange = this.handleNationalityCodeOnChange.bind(this);
        this.state = {
            entityType: this.props.items && this.props.items.registrant && this.props.items.registrant.entityType,
            nationalityCode: this.props.items && this.props.items.registrant && this.props.items.registrant.nationalityCode
        }
    }

    // isInitialFieldValueEmpty(field) {
    //     if (this.props.items && this.props.items.registrant) {
    //         return (this.props.items && this.props.items.registrant[field] === undefined) || (this.props.items && this.props.items.registrant[field] === undefined)
    //     }
    //     return true
    // }

    handleEntityTypeOnChange = (value) => {
        this.setState({entityType: value});
        this.props.form.resetFields(['registrant.regCode','registrant.schoolCode','registrant.nationalityCode']);
        this.props.onChangeEntityType(value)

    };

    handleNationalityCodeOnChange = (value) => {
        this.props.form.resetFields(['registrant.regCode']);
        this.setState({nationalityCode: value});
    };

    // //TODO rimuovere?
    // setToUpperCase = (event) => {
    //     if (event.target.value) {
    //         this.props.form.setFieldsValue({'registrant.regCode': event.target.value.toUpperCase()});
    //     }
    // };

    handleCountryNationalityInitialValue = () =>{

        if (this.props.items && this.props.items.registrant &&
            this.props.items.registrant.nationalityCode){
            return this.props.items.registrant.nationalityCode
        }

        if (this.props.isEdu) {
            return 'IT';
        }

        if (this.props.isGov) {
            return 'IT';
        }

        if (this.props.natInitialValue) {
            return this.props.natInitialValue;
        }

        return undefined;
    }

    handleDisable(){
        return !!(this.props.items && this.props.items['registrant']);
    }

    render() {
        return (

            <RegistrantFormItem
                {...this.props}
                natInitialValue={this.handleCountryNationalityInitialValue()}
                natDisabled={(this.state.entityType && this.state.entityType !== 1) || this.props.isEdu || this.props.isGov}
                entityType={this.state.entityType && this.state.entityType}
                nationalityCode={this.state.nationalityCode}
                entityTypeOnChange={this.handleEntityTypeOnChange}
                nationalityCodeOnChange={this.handleNationalityCodeOnChange}
                disabled={this.handleDisable()}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        countries: state.countryReducer
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadCountryCode: () => dispatch(countriesOperations.loadCountryCodeOp())
    };
}


/* Inject auth state and dispatch() into props */
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegistrantFormItemContainer));

RegistrantFormItemContainer.propTypes = {
    onChangeEntityType: PropTypes.func,
    contactType: PropTypes.string,
    isEdu: PropTypes.bool,
    isGov: PropTypes.bool,
    natInitialValue: PropTypes.string,
};

RegistrantFormItemContainer.defaultProps = {};
