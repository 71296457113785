import React from 'react';
import "../../../../../styles/DetailPanel.css"
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import {Table} from "antd";
import {printDSRecordAlg, printDSRecordDigestType} from '../../../constants/common';
import RecordInIcon from "app/commons/components/DataDisplay/RecordInIcon/RecordInIcon";
import RecordOutIcon from "app/commons/components/DataDisplay/RecordOutIcon/RecordOutIcon";

const DsRecords = (props) => {
    const {formatMessage} = props.intl;
    let columns = []
    const tmpColumns = [
        {
            title: formatMessage({id: "detail.dsRecord.keyTag"}),
            dataIndex: 'keyTag',
            key: 'keyTag',
            width: '70px',
        },
        {
            title: formatMessage({id: "detail.dsRecord.alg"}),
            dataIndex: 'alg',
            key: 'alg',
            render: (text) => {
                return printDSRecordAlg(text)
            }
        }, {
            title: formatMessage({id: "detail.dsRecord.digestType"}),
            dataIndex: 'digestType',
            key: 'digestType',
            render: (text) => {
                return printDSRecordDigestType(text)
            }
        },
        {
            title: formatMessage({id: "detail.dsRecord.digest"}),
            dataIndex: 'digest',
            key: 'digest',
        },
    ]

    if (props.summary) {
        columns.push({
            title: '',
            dataIndex: 'out',
            key: 'out',
            width: '3%',
            render: (value) => {
                return !value ? <RecordInIcon/> : <RecordOutIcon/>
            }
        },)
        tmpColumns.forEach(c => columns.push(c));
    } else {
        tmpColumns.forEach(c => columns.push(c));
    }

    const handleSummaryRowColor = (r) => {
        if (props.summary) {
            return r.out ? 'red-row' : 'green-row'
        }
    }

    return (
        <Table
            className={props.className}
            columns={columns}
            dataSource={props.dataSource}
            pagination={props.pagination}
            bordered={props.border}
            size={props.size}
            title={props.title}
            style={props.style}
            rowSelection={props.rowSelection}
            rowClassName={handleSummaryRowColor}
        />
    );
};


export default injectIntl(DsRecords);

DsRecords.propTypes = {
    dataSource: PropTypes.any,
    rowSelection: PropTypes.any,
    title: PropTypes.any,
    size: PropTypes.string,
    border: PropTypes.bool,
    pagination: PropTypes.bool,
    style: PropTypes.object,
    summary: PropTypes.bool,
};

DsRecords.defaultProps = {
    size: 'middle',
    border: true,
    pagination: false,
    summary: false
}
