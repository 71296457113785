import React from 'react';
import {Link} from "react-router-dom";
import styled from "styled-components";

const Logo = styled.img`
    width: 100px;
`;

const EpicLogo = () => {
    return (
        <Link to="/">
            <Logo alt="Epic web logo" src="/logo-epic.svg"/>
        </Link>
    );
};


export default EpicLogo;
