import {Modal, notification} from "antd";
import React from 'react'


export function showLabelValueModal(title, authInfo) {
    Modal.info({
        title: title,
        content: (
            <div>
                <p>{authInfo}</p>
            </div>
        ),
        onOk() {
        },
    });
}


export const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

export function printMessageError(error) {

    function printErrorData(errorData) {
        if (errorData) {
            let messages = [];

            errorData.reason &&
            messages.push(`Reason: (${errorData.reason.code}) ${errorData.reason.message}`);

            errorData.wrongValue &&
            messages.push(`WrongValue: [ ${errorData.wrongValue.element}: ${errorData.wrongValue.value} ] ${errorData.wrongValue.namespace}`);

            errorData.message &&
            messages.push(errorData.message);

            return `${printStatusResponse} - ${messages.join(', ')}`;
        }
    }


    let errorStringfied = JSON.stringify(error, null, " ");

    let statusResponse = `${error.status ? error.status : ''}${error.statusText ? ', ' + error.statusText : ''}`;

    const printStatusResponse = statusResponse && `[ ${statusResponse} ]`;

    if (error.data) {
        return printErrorData(error.data);
    }

    if (error.response.data) {
        return printErrorData(error.response.data);
    }

    if (error) {
        return `${printStatusResponse}`;
    }

    return `${errorStringfied}`
}
