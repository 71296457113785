import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {injectIntl} from "react-intl";
import ErrorAndNotification from "../../../commons/containers/ErrorAndNotification";
import Spinner from "../../../commons/components/FeedBack/Spinner/Spinner";
import DetailToolbar from "app/commons/components/Layout/DetailToolbar/DetailToolbar";
import DomainStatusBar from "app/domains/components/DataDisplay/DomainStatusBar/DomainStatusBar";
import EppOperationView from "app/commons/components/Pages/EppOperationView";
import DomainInfoFormContainer from "app/domains/containers/DomainInfoFormContainer";

class DomainInfoUpdateBox extends Component {

    render() {

        const {infoData} = this.props;
        return (
            <EppOperationView
                title={this.props.title}
                loading={false}
                operationForm={<DomainInfoFormContainer/>}
            >
                {this.props.infoData &&
                <div>
                    <DetailToolbar statusBar={
                        <DomainStatusBar
                            state={infoData.statuses}
                            name={infoData.name}
                            expDate={infoData.exDate}
                            signed={infoData.signed}
                        />
                    }/>
                    <ErrorAndNotification
                        error={this.props.error}
                        message={this.props.message}
                        reset={this.props.reset}
                    />
                    {this.props.children}
                    <Spinner loading={this.props.loading}/>
                </div>}
            </EppOperationView>
        );
    }
};

DomainInfoUpdateBox.propTypes = {
    title: PropTypes.string,
    infoData: PropTypes.object,
    error: PropTypes.object,
    loading: PropTypes.bool,
    message: PropTypes.string,
    hasRegistrarPermission: PropTypes.bool,
    reset: PropTypes.func,
};
DomainInfoUpdateBox.defaultProps = {};

export default injectIntl(DomainInfoUpdateBox);
