import React, {Component} from 'react';
import {Button, Form, Icon, Input, InputNumber, notification, Switch} from 'antd';
import {injectIntl} from 'react-intl';
import {changedPostStatus} from "../ConfigurationUtil";

const FormItem = Form.Item;

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 5},
    },
    wrapperCol: {
        // xs: { span: 24 },
        sm: {span: 6},
    },
};

const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: {span: 24, offset: 5},
        sm: {span: 12, offset: 5},
    },
};

const MIN_TOS = 1;
const MAX_TOS = 6;

class PollingFormWrapper extends Component {

    // Spudoratamente copiato ed adattato: https://ctrlq.org/code/19985-parse-email-address
    validateEmail = (role, value, callback) => {

        // 1.  Lorenzo Luconi <lorenzo.luconi@iit.cnr.it>
        // 2. lorenzo.luconi@iit.cnr.it

        var extract = {name: null, email: null};


        if (!value)
            callback(new Error("Email non valida"));
        else {


            var emails = value.match(/[^@<\s]+@[^@\s>]+/g);

            if (emails && emails.length > 1) {
                callback(new Error("Email non valida"));
            }

            if (emails) {
                // Spudoratamente copiato: https://regex101.com/r/mX1xW0/1
                let checkedEmail = emails[0].match(/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/);


                if (checkedEmail) {
                    extract.email = checkedEmail[0];
                }
            }

            var names = value.split(/\s+/);


            if (names.length > 1) {
                names.pop();
                extract.name = names.join(" ").replace(/"/g, "");
            }


            if (extract.email) {
                callback()
            } else {
                callback(new Error("Email non valida"));
            }

        }


    }

    add = () => {

        const {form} = this.props;
        // can use data-binding to get
        const tos = form.getFieldValue('emailTo');
        if (tos < MAX_TOS) {

            form.setFieldsValue({
                emailTo: tos + 1,
            });
        }
    }

    remove = (id, field) => {

        const {setFieldsValue, getFieldValue} = this.props.form;

        const tos = getFieldValue(`${field}`);

        if (tos > MIN_TOS) {

            let emailTo = {}


            let newIndex = 0;
            for (let i = 0; i < tos; i++) {
                //console.log("Valore email ", getFieldValue(`${field}-${i}`), " indice ", i);
                if (i !== id) {
                    emailTo[`${field}-${newIndex}`] = getFieldValue(`${field}-${i}`);
                    newIndex++;
                }

            }

            setFieldsValue(emailTo);

            let newValue = {};
            newValue[`${field}`] = tos - 1;

            setFieldsValue(newValue);
        }
    }


    componentWillReceiveProps(nextProps, nextState) {


        const {postResponse} = nextProps;
        const {formatMessage} = nextProps.intl;

        let prevPostResponse = this.props.postResponse;

        if (postResponse) {

            if (changedPostStatus(postResponse, prevPostResponse)) {

                if (postResponse.pending) {

                    notification.info({
                        message: formatMessage({id: 'admin.polling.saveConf.info.message'}),
                        description: formatMessage({id: 'admin.polling.saveConf.info.description'})
                    });


                } else if (postResponse.rejected) {
                    notification.error({
                        message: formatMessage({id: 'admin.polling.saveConf.error.message'}),
                        description: formatMessage({id: 'polling.saveConf.error.description'})
                    });
                } else if (postResponse.fulfilled) {
                    notification.success({
                        message: formatMessage({id: 'admin.polling.saveConf.success.message'}),
                        description: formatMessage({id: 'admin.polling.saveConf.success.description'})
                    });
                }
            }
        }

    }

    reachedMax = () => {
        const tos = this.props.form.getFieldValue('emailTo');
        return tos === MAX_TOS;
    }

    emailEnabled() {
        const {getFieldValue} = this.props.form;
        const emailsTo = getFieldValue('emailsTo');
        const emailFrom = getFieldValue('emailFrom');
        console.log("emailsTo ", emailsTo)
        console.log("emailFrom ", emailFrom)
        return !!((emailsTo && emailsTo.length > 0) || emailFrom);
    }

    render() {
        const {enabled, delay, maxMessages, emailsTo, emailFrom} = this.props.configuration.value;

        const {getFieldDecorator, getFieldValue} = this.props.form;
        const {formatMessage} = this.props.intl;
        let emailEnabled = this.emailEnabled()
        console.log("email ", emailEnabled);
        let toAddresses = [];
        let newToAddresses = [];
        let delayInMin = 5;

        getFieldDecorator('fetchedEmailTo', {initialValue: emailsTo ? emailsTo.length : 0});
        getFieldDecorator('emailTo', {initialValue: emailsTo ? 0 : 1});
        const tos = getFieldValue('emailTo');
        const fetchedTos = getFieldValue('fetchedEmailTo');


        if (delay) {
            delayInMin = (delay / 1000) / 60;
        }

        if (emailEnabled) {
            for (let k = 0; k < fetchedTos; k++) {

                toAddresses.push(
                    <span key={k}>
        <FormItem
            {...formItemLayout}
            label={formatMessage({id: 'admin.polling.emailTo'})}
            required={emailEnabled}

        >
          {getFieldDecorator(`fetchedEmailTo-${k}`, {
              initialValue: emailsTo[k],
              validateTrigger: ['onChange', 'onBlur'],
              rules: [{validator: this.validateEmail}],
          })(
              <Input placeholder="Email To"/>
          )}
        </FormItem>
        <Icon style={{fontSize: '12pt', top: '-50px'}}
              className="dynamic-delete-button"
              type="minus-circle-o"

              onClick={() => this.remove(k, 'fetchedEmailTo')}
        />
              </span>
                )
            }
        }

        if (emailEnabled) {

            for (let k = 0; k < tos; k++) {
                newToAddresses.push(
                    <span key={k}>
          <FormItem
              {...formItemLayout}
              label={formatMessage({id: 'admin.polling.emailTo'})}
              required={emailEnabled}
          >
          {getFieldDecorator(`emailTo-${k}`, {
              validateTrigger: ['onChange', 'onBlur'],
              rules: [{validator: this.validateEmail}],
          })(
              <Input placeholder="Email To"/>
          )}

            </FormItem>
            <Icon style={{fontSize: '12pt', top: '-50px'}}
                  className="dynamic-delete-button"
                  type="minus-circle-o"

                  onClick={() => this.remove(k, 'emailTo')}
            />
                  </span>
                )
            }
            newToAddresses.push(<FormItem {...formItemLayoutWithOutLabel} key={tos}>
                <Button size="small" type="dashed" disabled={this.reachedMax()} onClick={this.add}>
                    <Icon type="plus"/>{formatMessage({id: 'admin.polling.addEmailTo'})}</Button>
            </FormItem>)
        }

        return (
            <Form>
                <FormItem
                    {...formItemLayout}
                    label={formatMessage({id: 'admin.polling.enablePolling'})}
                    style={{textAlign: 'left'}}
                >
                    {getFieldDecorator(`enabled`, {
                        valuePropName: 'checked',
                        initialValue: enabled,
                        // validateTrigger: ['onChange', 'onBlur'],
                        rules: [{
                            required: true,
                            message: formatMessage({id: 'notEmptyField'}),
                        }],
                    })(
                        <Switch
                            checkedChildren={formatMessage({id: 'admin.enable'})}
                            unCheckedChildren={formatMessage({id: 'admin.disable'})}
                            onChange={(checked) => {
                                //Notifica il cambiamento alla Form
                                this.props.onChangeForm({enabled: checked})
                            }
                            }/>
                    )}
                </FormItem>

                {this.props.form.getFieldsValue(['enabled']).enabled &&
                <span>
        <FormItem
            {...formItemLayout}
            label={formatMessage({id: 'admin.polling.delay'})}
            style={{textAlign: 'left'}}
        >
          {getFieldDecorator(`delay`, {
              initialValue: delayInMin,
              validateTrigger: ['onChange', 'onBlur'],
              rules: [{
                  required: true,
                  message: formatMessage({id: 'notEmptyField'}),
              }],
          })(
              <InputNumber onChange={this.props.onChangeForm}/>
          )}
        </FormItem>

        <FormItem
            {...formItemLayout}
            label={formatMessage({id: 'admin.polling.maxMessages'})}
            style={{textAlign: 'left'}}
        >
          {getFieldDecorator(`maxMessages`, {
              initialValue: maxMessages,
              validateTrigger: ['onChange', 'onBlur'],
              rules: [{
                  required: true,
                  message: formatMessage({id: 'notEmptyField'}),
              }],
          })(
              <InputNumber
                  onChange={(value) =>
                      this.props.onChangeForm({maxMessages: value})
                  }
              />
          )}
        </FormItem>

        <FormItem
            {...formItemLayout}
            label={formatMessage({id: 'admin.polling.enableEmail'})}
            style={{textAlign: 'left'}}
        >
          {getFieldDecorator(`enableEmail`, {
              valuePropName: 'checked',
              initialValue: emailEnabled,
              // validateTrigger: ['onChange', 'onBlur'],
          })(
              <Switch
                  checkedChildren={formatMessage({id: 'admin.enable'})}
                  unCheckedChildren={formatMessage({id: 'admin.disable'})}
                  onChange={() => {
                      this.props.form.setFieldsValue({emailsTo: ' '}); //Trucco per abilitare emailEnabled
                      this.props.onChangeForm({emailFrom: !emailEnabled}) //notifica la modifica alla form

                  }}/>
          )}
        </FormItem>

                    {
                        this.props.form.getFieldsValue(['enableEmail']).enableEmail &&
                        <span><FormItem
                            {...formItemLayout}
                            label={formatMessage({id: 'admin.pollig.emailFrom'})}
                            required={emailEnabled}
                            style={{textAlign: 'left'}}
                        >
                            {getFieldDecorator(`emailFrom`, {
                                initialValue: emailFrom,
                                validateTrigger: ['onChange', 'onBlur'],
                                rules: [{validator: this.validateEmail}],
                            })(
                                <Input placeholder="Email From" onChange={(value) =>
                                    this.props.onChangeForm({emailFrom: value})
                                }/>
                            )}
                        </FormItem>
                            {toAddresses}
                            {newToAddresses}
                        </span>
                    }


        </span>
                }
            </Form>
        )
    }


}

const PollingForm = Form.create()(injectIntl(PollingFormWrapper));
export default PollingForm;
