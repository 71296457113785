import React from 'react';
import {Select} from 'antd';
import PropTypes from 'prop-types';
import {injectIntl} from "react-intl";

const Option = Select.Option;

class SelectEntityType extends React.Component {

    render() {
        const {onChange, disabled, isEdu, isGov, ...props} = this.props;
        const {formatMessage} = this.props.intl;
        return (
            <Select {...props}
                    onChange={onChange}
                    disabled={disabled}
            >
                {!isEdu && !isGov && <Option value={1}>
                    {formatMessage({id: 'entityTypeSelect.1'})}
                </Option>}
                {!isGov && <Option value={2}>
                    {formatMessage({id: 'entityTypeSelect.2'})}
                </Option>
                }
                {!isEdu && !isGov && <Option value={3}>
                    {formatMessage({id: 'entityTypeSelect.3'})}
                </Option>}
                {!isGov && <Option value={4}>
                    {formatMessage({id: 'entityTypeSelect.4'})}
                </Option>
                }
                <Option value={5}>
                    {formatMessage({id: 'entityTypeSelect.5'})}
                </Option>
                {!isEdu && !isGov && <Option value={6}>
                    {formatMessage({id: 'entityTypeSelect.6'})}
                </Option>}
                {!isEdu &&  !isGov && <Option value={7}>
                    {formatMessage({id: 'entityTypeSelect.7'})}
                </Option>}
            </Select>
        );
    };
}

export default injectIntl(SelectEntityType);

SelectEntityType.propTypes = {
    isEdu: PropTypes.bool,
    isGov: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
}

SelectEntityType.default = {
    isEdu: false,
    isGov: false,
    disabled: false,
}