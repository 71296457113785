import React from 'react';
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import {Table} from "antd";
import "./DomainHostDetail.css"
import RecordInIcon from "app/commons/components/DataDisplay/RecordInIcon/RecordInIcon";
import RecordOutIcon from "app/commons/components/DataDisplay/RecordOutIcon/RecordOutIcon";

const DomainHostDetail = (props) => {
    const {formatMessage} = props.intl;
    let columns = []
    const tmpColumns = [
        {
            title: formatMessage({id: "detail.domain.nameserver.title"}),
            dataIndex: 'name',
            key: 'name',
            width: '40%',
        },
        {
            title: formatMessage({id: "detail.domain.nameserver.ipv4"}),
            dataIndex: 'addresses',
            key: 'ip4',
            width: '30%',
            render: (value) => {
                if (value && value.length > 0) {
                    let ipv4 = value.filter(val => (val.type === "v4"))
                    if (ipv4.length > 0) {
                        return ipv4[0].address
                    }
                }
            },
        }, {
            title: formatMessage({id: "detail.domain.nameserver.ipv6"}),
            dataIndex: 'addresses',
            key: 'ip6',
            width: '30%',
            render: (value) => {
                if (value && value.length > 0) {
                    let ipv6 = value.filter(val => (val.type === "v6"))
                    if (ipv6.length > 0) {
                        return ipv6[0].address
                    }
                }
            },
        },
    ]

    if (props.summary) {
        columns.push({
            title: '',
            dataIndex: 'out',
            key: 'out',
            width: '3%',
            render: (value) => {
                return !value ? <RecordInIcon/> : <RecordOutIcon/>
            },
        },)
        tmpColumns.forEach(c => columns.push(c));
    } else {
        tmpColumns.forEach(c => columns.push(c));
    }

    function displayTitle(string) {
        if (string) {
            return () => string
        }
        return null
    }

    const handleSummaryRowColor = (r) => {
        if (props.summary) {
            return r.out ? 'red-row' : 'green-row'
        }
    }

    return (
        <Table
            rowSelection={props.rowsSelectionHandler}
            className='hosts-table' bordered={true}
            columns={columns}
            dataSource={props.hosts}
            pagination={false}
            size='middle'
            title={displayTitle(props.title)}
            style={props.style}
            rowClassName={handleSummaryRowColor}
        />
    );
};

DomainHostDetail.propTypes = {
    hosts: PropTypes.array,
    title: PropTypes.string,
    style: PropTypes.object,
    rowsSelectionHandler: PropTypes.any,
    summary: PropTypes.bool,
};

DomainHostDetail.default = {
    title: null,
    summary: false
};
export default injectIntl(DomainHostDetail);
