import axios from "axios/index";
import * as api from "./constants/api";

let instance = axios.create({
    timeout: 20000,
    // headers: {'Authorization': localStorage.getItem("token")}
});

function appendAuthorizationHeader() {
    return {headers: {'Authorization': localStorage.getItem("token")}}
}

export function eppMessages(searchParams) {
    let apiParams = new URLSearchParams(searchParams);
    // //sottrae uno al numero di pagina per adattarlo alla numerazione dell'api
    apiParams.set('page', apiParams.has("page") ? apiParams.get("page") - 1 : '')

    return instance.get(`${api.EPP_MESSAGES}?sort=date,desc&${apiParams}`, appendAuthorizationHeader());
}

export function markMessage(message, read) {
    return instance.patch(`${message}`, {"read": read}, appendAuthorizationHeader());
}

export function deleteMessage(message) {
    return instance.delete(`${message}`, appendAuthorizationHeader());
}