import {Form, Input} from "antd";
import PropTypes from "prop-types";
import React from "react";

export const ContactOrgFormItem = ({formItemLayout, intl, initialValue, form, items, required, disabled, placeholder, onChange,checkOrganization}) => {
    return <Form.Item
        {...formItemLayout}
        label={intl.formatMessage({id: 'contact.detail.form.organization'})}
        hasFeedback
    >
        {form.getFieldDecorator('org', {
            initialValue: initialValue,
            rules: [{
                message: intl.formatMessage({id: 'contact.detail.form.organization.message'}),
                required: required,
                // whitespace: true,
            }, {
                validator: checkOrganization,
            }],
        })(
            //il campo Organizzazione, una volta impostato, non è più modificabile;
            <Input
                disabled={disabled}
                placeholder={placeholder}
            />
        )}
    </Form.Item>
}

ContactOrgFormItem.propTypes = {
    formItemLayout: PropTypes.object,
    initialValue: PropTypes.string,
    intl: PropTypes.object,
    form: PropTypes.object,
    items: PropTypes.object,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    checkOrganization: PropTypes.func, /* abilita al controllo che se il se il Registrante è una persona fisica
    (cioè EntityType = 1) il valore del campo Organizzazione deve essere uguale al campo Nome; */
}