import React from 'react';
import PropTypes from 'prop-types';
import {Menu} from "antd";
import {injectIntl} from "react-intl";
import styled from "styled-components";
import LabelWithIcon from "app/commons/components/Layout/LabelWithIcon/LabelWithIcon";

const MenuItemGroup = Menu.ItemGroup;
const SubMenu = Menu.SubMenu;

const menuHorizontal = 'ant-menu-submenu-active'

const StyledMenu = styled(Menu)`
  border-bottom: 0px!important;
   .${menuHorizontal} {
    border-bottom: 2px solid #5fbcd3!important;
  }
`;


const UserMenu = ({userData, confData, onLogout}) => (
    <StyledMenu mode="horizontal" onClick={onLogout}>
        <SubMenu className='user-menu-subMenu'
                 title={
                     <LabelWithIcon type={"fal fa-user"} text={userData.username}/>
                 }>

            {
                confData.username &&
                <MenuItemGroup key="g1" title={
                    <LabelWithIcon type="fal fa-building" text={confData.username}/>
                }/>
            }
            {
                confData.dnsSecEnabled &&
                <MenuItemGroup key="g2" title={
                    <LabelWithIcon type="fal fa-lock" idMessage={'header.user.signed'}/>
                }/>
            }
            <Menu.Item key="logout" style={{
                borderTop: '1px solid #ececec'
            }}>
                <LabelWithIcon type="fal fa-sign-out-alt" idMessage={'header.user.signout'}/>
            </Menu.Item>
        </SubMenu>
    </StyledMenu>
)

UserMenu.propTypes = {
    userData: PropTypes.object,
    confData: PropTypes.object,
    onLogout: PropTypes.func
};
UserMenu.defaultProps = {};

export default injectIntl(UserMenu);



