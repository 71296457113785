import React from 'react';
import {Form, Input} from 'antd';
import {injectIntl} from "react-intl";
import PropTypes from "prop-types";
import {authInfoValidator} from "../../../../commons/utils/form";
import {GOV_SUFFIX} from "../../../../commons/constants/constants";

const FormItem = Form.Item;


class GovValidationCodeFormItem extends React.Component {

    authInfoCheckerWrapped = (rule, value, callback) => {
        const {formatMessage} = this.props.intl;
        authInfoValidator(rule, value, callback, formatMessage)
    }

    render() {
        const {form, intl, domain} = this.props

        const isGov = domain && domain.endsWith(GOV_SUFFIX);
        const {formatMessage} = intl;
        const {getFieldDecorator} = form;

        return (
<>
            {isGov &&
            <FormItem
                {...this.props.formAuthInfoLayout}
                label={formatMessage({id: 'govValidationCodeLabel'})}
                hasFeedback
            >
                {getFieldDecorator('govValidationCode', {
                    // initialValue: this.props.initialValue,
                    rules: [
                        {required: true,},
                        {
                            validator: (rule, value, cb) => (
                                (value === this.props.initialValue ?
                                    cb(new Error(formatMessage({id: 'govValidationCode.equals'}))) :
                                    cb()))
                        }
                    ],
                })(<Input placeholder={formatMessage({id: 'govValidationCodePlaceholder'})}/>)}
            </FormItem>
    }
    </>
        );
    };
}

export default injectIntl(GovValidationCodeFormItem);

GovValidationCodeFormItem.propTypes = {
    initialValue: PropTypes.string,
    form: PropTypes.object.isRequired,
    formAuthInfoLayout: PropTypes.object
};