import React from 'react';

import PropTypes from 'prop-types'
import {injectIntl} from "react-intl";
import {Popover, Table, Tag} from "antd";
import ContactDetail from "../../../../contacts/components/DataDisplay/ContactDetail/ContactDetail";
import RecordOutIcon from "app/commons/components/DataDisplay/RecordOutIcon/RecordOutIcon";
import RecordInIcon from "app/commons/components/DataDisplay/RecordInIcon/RecordInIcon";


export const ADMIN = 'Amministratore'
export const TECH = 'Tecnico'


//Unisce i contatti Tech con quelli Admin
export function mergeTechsAdminContacts(techs, admin) {

    let result = []
    admin['type'] = ADMIN;
    techs.map((obj) => {
        obj.type = TECH;
        return obj;
    });
    result = result.concat(admin);
    result = result.concat(techs);

    return result;
}

const DomainContactsTable = (props) => {

    const colors = {
        Amministratore: 'blue',
        Tecnico: 'green'
    }

    let columns = []
    const tmpColumns = [
        {
            // title: formatMessage({id: "detail.domain.nameserver.title"}),
            title: 'ID contatto',
            dataIndex: 'id',
            key: 'id',
            width: '15%',
            render: (value, row, index) => {

                return <Popover overlayClassName="contactPopover"
                                autoAdjustOverflow={true}
                                placement={props.position}
                                content={<ContactDetail data={row} showRegistrantInfo={false}/>}>
                    <span className="contact-info-alignment">  <Tag><i className={'fal fa-info-circle'} style={{
                        paddingRight: '5px',
                        fontSize: '10pt'
                    }}/> {value}</Tag></span>
                </Popover>
            },
        },
        {
            // title: formatMessage({id: "detail.domain.nameserver.ipv4"}),
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',

        },
        {
            // title: formatMessage({id: "detail.domain.nameserver.ipv4"}),
            title: 'Organizzazione',
            dataIndex: 'org',
            key: 'org',
        },
        {
            // title: formatMessage({id: "detail.domain.nameserver.ipv6"}),
            title: 'Tipo contatto',
            dataIndex: 'type',
            key: 'type',
            width: '15%',
            render: (value) => {
                return <Tag color={colors[value]}>{value}</Tag>
            }
        }
    ]

    if (props.summary) {
        columns.push({
            title: '',
            dataIndex: 'out',
            key: 'out',
            width: '3%',
            render: (value) => {
                return value ? <RecordInIcon/> : <RecordOutIcon/>
            },
        },)
        tmpColumns.forEach(c => columns.push(c));
    } else {
        tmpColumns.forEach(c => columns.push(c));
    }


    function displayTitle(string) {
        if (string) {
            return () => string
        }
        return null
    }

    const handleSummaryRowColor = (r) => {
        if (props.summary) {
            return r.out ? 'green-row' : 'red-row'
        }
    }

    return (
        <span>
                <Table
                    rowSelection={props.rowsSelectionHandler}
                    className='hosts-table'
                    bordered={true}
                    columns={columns}
                    dataSource={props.contacts}
                    pagination={false}
                    size='middle'
                    title={displayTitle(props.title)}
                    style={props.style}
                    rowClassName={handleSummaryRowColor}
                />

            </span>
    );
};


DomainContactsTable.defaultProps = {
    title: null,
    position: 'right',
    summary: false,
};

DomainContactsTable.propTypes = {
    contacts: PropTypes.array,
    title: PropTypes.string,
    style: PropTypes.object,
    rowSelection: PropTypes.func,
    position: PropTypes.string,
    summary: PropTypes.bool
};

export default injectIntl(DomainContactsTable);