import React, {Component} from 'react';
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import Error from "../../../../commons/components/FeedBack/Error/Error";
import CardDetail from "../../../../commons/components/DataDisplay/CardDetail/CardDetail";
import ContactStatusForm from "./ContactStatusForm";
import {notificationError} from "../../../../commons/utils/notification";
import {printMessageError} from "../../../../commons/utils/common";
import {isClientUpdateProhibited} from "../../../selectors";
import DetailToolbar from "app/commons/components/Layout/DetailToolbar/DetailToolbar";
import ContactStatusBar from "app/contacts/components/DataDisplay/ContactStatusBar/ContactStatusBar";
import styled from "styled-components";
import EppOperationView from "app/commons/components/Pages/EppOperationView";
import ContactInfoFormContainer from "app/contacts/containers/EppOperationForm/ContactInfoFormContainer";
import ContactFormContainer from "app/contacts/components/ContactForm/ContactFormContainer";
import {contactType} from "app/commons/utils/utils";

const StyledDetail = styled(CardDetail)`margin-top: 20px!important`;

class ContactUpdate extends Component {

    render() {
        const {contactData, error, onUpdate, intl, onCancel} = this.props;
        const {formatMessage} = this.props.intl;
        const {data} = contactData;
        return (
            <div>
                <EppOperationView
                    loading={contactData.loading}
                    title={formatMessage({id: "contact.detail.update.form.title"})}
                    infoContainer={<ContactInfoFormContainer/>}
                >
                    <div>
                        <DetailToolbar statusBar={<ContactStatusBar contactData={data}/>}/>
                        <Error error={error}/>
                        <StyledDetail label={intl.formatMessage({id: contactType(data)})}>
                            {isClientUpdateProhibited(data.statuses) ?
                                <ContactStatusForm
                                    data={data}
                                    onSubmit={onUpdate}
                                    onReset={onCancel}
                                /> :
                                <div>
                                    {/*<ContactForm*/}
                                    {/*items={data}*/}
                                    {/*onSubmit={onUpdate}*/}
                                    {/*onCancel={onCancel}/>*/}
                                    {/*<div style={{backgroundColor: 'red'}}>*/}
                                    <ContactFormContainer
                                        items={data}
                                        onSubmit={onUpdate}
                                        onCancel={onCancel}
                                    />
                                </div>
                                // </div>
                            }
                        </StyledDetail>
                    </div>
                </EppOperationView>
                {error &&
                notificationError(intl.formatMessage(
                    {id: 'contact.update.error'},
                    {errorMessage: printMessageError(error)}))
                }
            </div>
        );
    };
}

ContactUpdate.propTypes = {
    onUpdate: PropTypes.func,
    contactData: PropTypes.object.isRequired,
    onCancel: PropTypes.func,
};
export default injectIntl(ContactUpdate);