import React from 'react';
import PropTypes from 'prop-types';
import "./EppMessagesType.css"
import SimpleMessage from "./SimpleMessage";

const BaseMessage = ({detail, component}) => {


    return (
        <div className={'body-container'}>
            <SimpleMessage {...detail}/>
            <div
                className={'typedLayout-header'}
                // onMouseOver={() => console.log("aaa ",)}
            >
                {/*<Popover*/}
                    {/*placement="topLeft"*/}
                    {/*title={'Dettaglio originale'}*/}
                    {/*content={content}*/}
                {/*>*/}
                    <i className='fal fa-file-alt'/> Dettagli
                {/*</Popover>*/}
            </div>
            {component}
        </div>
    );
};

BaseMessage.propTypes = {
    detail: PropTypes.object,
    component: PropTypes.object
};
BaseMessage.defaultProps = {};

export default BaseMessage;
