import React from 'react';
import {Form, Select, Tag} from 'antd';
import {injectIntl} from "react-intl";
import PropTypes from "prop-types";
import {CLIENT_HOLD} from "app/commons/constants/statuses";

const FormItem = Form.Item;
const Option = Select.Option;

class StatusFormItem extends React.Component {

    state = {
        removed: [],
        initial: this.props.initialValue.filter(x => this.props.managedStatusList.includes(x)),
        selected : null
    }

    componentDidMount() {
        const {getFieldDecorator} = this.props.form;
        getFieldDecorator('statusesToRemove', {initialValue: []})
        getFieldDecorator('statusesToAdd', {initialValue: []})
    }

    handleChange(value) {
        const {setFieldsValue} = this.props.form;
        this.setState({selected:value});
        this.props.onChange && this.props.onChange(value);
        const initial = this.state.initial;

        let statusesToRemove = initial.filter(x => !value.includes(x));
        let statusesToAdd = value.filter(x => !initial.includes(x))

        this.setState({removed: statusesToRemove})
        setFieldsValue({statusesToRemove: statusesToRemove})
        setFieldsValue({statusesToAdd: statusesToAdd})
    }

    render() {
        const {form, intl} = this.props
        const {formatMessage} = intl;
        const {getFieldDecorator} = form;

        return (
            <span>
            <FormItem
                {...this.props.formItemLayout}
                label={this.props.label}
            >
                {getFieldDecorator('statuses', {
                    initialValue: this.state.initial,
                    rules: [
                        {
                            required: false,
                            message: formatMessage({id: 'contact.detail.form.statuses.message'}),
                            type: 'array'
                        },
                    ],
                })(
                    <Select mode="multiple"
                            placeholder={formatMessage({id: 'contact.detail.form.statuses.placeholder'})}
                            onChange={this.handleChange.bind(this)}
                    >
                        {this.props.managedStatusList.map((opt, index) => <Option key={index}
                                                                                  value={opt}>{opt}</Option>)}
                    </Select>
                )}
                {this.state.removed.length > 0 &&
                <div style={{float: 'left'}}>{this.state.removed.map(r => <Tag color='red'>{r}</Tag>)}</div>
                }
                {
                    (this.state.selected && this.state.selected.includes(CLIENT_HOLD)) &&
                    <div style={{float: 'left'}}>
                        <span style={{color: 'red'}}>Attenzione selezionado lo stato  {CLIENT_HOLD} il dominio verrà bloccato e non sarà più delegato nel dns.</span>
                    </div>
                }

            </FormItem>
            </span>
        );
    };
}

export default injectIntl(StatusFormItem);

StatusFormItem.propTypes = {
    initialValue: PropTypes.array,
    form: PropTypes.object.isRequired,
    formItemLayout: PropTypes.object,
    managedStatusList: PropTypes.array,
    label: PropTypes.string,
};

StatusFormItem.defaultProps = {
    label: 'Status'
}