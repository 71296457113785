import React, {Component} from 'react';
import PropTypes from 'prop-types'
import _ from "lodash"
import {contactType, difference} from "app/commons/utils/utils";
import RenderContactForm from "app/contacts/components/ContactForm/RenderContactForm";
import {ContactForm} from "app/contacts/components/ContactForm/ContactForm";
import {setAddressStreets} from "app/contacts/components/BaseContactForm/FormITems/Address.utils";
import {notificationError} from "app/commons/utils/notification";

const pickedFields = ['id', 'name', 'org', 'voice', 'email', 'consentForPublish', 'fax',
    'address.streets', 'address.city', 'address.postalCode', 'address.countryCode', 'address.state',
    'registrant.nationalityCode', 'registrant.entityType', 'registrant.regCode', 'registrant.schoolCode', 'registrant.gov.ipaCode', 'registrant.gov.uoCode',
    'statusesToAdd', 'statuses', 'statusesToRemove']

const garbageFields = ['street_0', 'street_1', 'street_2', 'streetkeys', 'contactType', 'statuses']

class ContactFormContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contactType: ((this.props.items && this.props.items.id) && contactType(this.props.items)) || null,
            form: null,
            dirty: false
        };
        this.handleContactTypeOnChange = this.handleContactTypeOnChange.bind(this);
        this.handleCancelCreate = this.handleCancelCreate.bind(this);
        this.handleSetForm = this.handleSetForm.bind(this);
    }

    isNewContact = () => !(this.props.items && this.props.items.id);

    handleContactTypeOnChange(e) {
        this.setState({contactType: e.target.value})
        this.props.onContactTypeChange(e.target.value);
    }

    handleCancelCreate = () => {
        this.setState({contactType: (!this.isNewContact() && contactType(this.props.items)) || null, dirty: false});
        this.props.onCancel();
    }

    purge(values) {
        let result = values
        garbageFields.forEach(field => (delete result[field]))
        return result
    }

    handleSubmit = () => {
        const {items} = this.props;
        setAddressStreets(this.state.form);
        this.state.form.validateFieldsAndScroll(
            ((err, values) => {

                if (!err) {

                    //TODO inserisci messaggio nessuna modifica

                    let touchedValues = difference(_.pick(values, pickedFields), items || {});
                    // console.log("touchedValues  ", touchedValues);

                    if (Object.keys(touchedValues).includes('address')) {

                        touchedValues['address'] = this.purge(values['address'])
                    }
                    // console.log("values  ", values);
                    // console.log("touchedValues  ", touchedValues);

                    let alert = true;
                    Object.keys(touchedValues)
                        .forEach(function eachKey(key) {
                            if (Object.keys(touchedValues[key].toString()).length > 0  || touchedValues[key].length > 0){
                                alert=false
                            }
                        });
                    if (alert){
                        notificationError('Attenzione, non sono state apportate modifiche al contatto!');
                        this.setState({contactType: (!this.isNewContact() && contactType(this.props.items)) || null, dirty: false});
                    }else {
                        this.props.onSubmit((!this.isNewContact() ? items['id'] : null), touchedValues)
                    }
                } else {
                    console.error("Form error ", err)
                }
            })
        );
    };

    handleSetForm(ref) {
        if (ref !== null)
            this.setState({form: ref.props.form})
    }

    handleOnFieldsChange = (props) => {
        this.setState({dirty: true})
    }

    render() {
        const {contactType, form} = this.state;
        return (
            <ContactForm
                items={this.props.items}
                form={form}
                contactType={contactType}
                isNewContact={this.isNewContact()}
                handleContactTypeOnChange={this.handleContactTypeOnChange}
                handleSubmit={this.handleSubmit}
                handleCancelCreate={this.handleCancelCreate}
                dirty={this.state.dirty}
            >
                <RenderContactForm
                    {...this.props}
                    contactType={contactType}
                    handleSetForm={this.handleSetForm}
                    onChange={this.handleOnFieldsChange}
                />
            </ContactForm>
        );
    }
}

export default ContactFormContainer;

ContactFormContainer.propTypes = {
    items: PropTypes.object,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    contactType: PropTypes.func,
    onContactTypeChange: PropTypes.func,
};






