import axios from "axios";
import * as api from "./constants/api";

// axios.interceptors.response.use(
//     response => {
//         // do someting on response
//         return response
//     },
//     error => {
//         // do someting on errir
//         // console.log("EE ",error)
//         return Promise.reject(error.response) // => gives me the server resonse
//     }
// )
// let instance = axios.create({
//     timeout: 20000,
//     // headers: {'Authorization': localStorage.getItem("token")}
// });
//
// /**
//  * Axios Request Instance (Move to config later)
//  */
// export const requestInstance = axios.create({
//     baseURL: SERVER_URL,
//     timeout: 20000,
//     headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
// })

function appendAuthorizationHeader() {
    return {headers: {'Authorization': localStorage.getItem("token")}}
}


export function doLogin(username, password) {
    return axios.post(api.AUTHENTICATE, {
        'username': username, 'password': password
    })
}


//LOGIN AND USER
export function userInfo() {
    //qui va lasciato appendAuthorizationHeader() perche' la UserInfo necessita del nuovo token e nell'API nn ce ancora
    return axios.get(api.USER_INFO, appendAuthorizationHeader())
}

