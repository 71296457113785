/*
enabled: boolean (default false), attiva/disattiva la gestione della coda di polling

delay: long, default 60.000 ed è anche il valore minimo, rappresenta il numero di millisecondi tra una esecuzione e l'altra. L'utente lo imposterà in secondi (quindi 6). Nota bene: la modifica di questo parametro richiede il restart dell'applicazione. In qualche maniera va reso noto.

maxMessages: long, numero massimo di messaggi da leggere in una unica sessione. Se 0 vengono letti tutti i messaggi. Valore di default impostato: 5


Seguono poi i parametri relativi all'email. Non esiste una parametro per abilitare l'invio di email, ad ogni modo a livello di interfaccia aggiungerei uno switch per attivare/disattivare l'invio di email e se attivo compaino i seguenti dati che diventano obbligatori:



emailsTo: array contenente l'elenco degli indirizzi di destinazione. E' quindi una lista

emailFrom: indirizzo del mittente (Esempio: Lorenzo Luconi <lorenzo.luconi@iit.cnr.it>, o anche solo lorenzo.luconi@iit.cnr.it).

URL:
/config/epp/pollreq

*/

import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router';
import {Button, Card, Col, Row} from 'antd';
import PageTitle from '../../../../commons/components/Layout/PageTitle/PageTitle'
import connect from '../../../../commons/utils/api-connector';
import PollingForm from './PollingForm';
import Spinner from "../../../../commons/components/FeedBack/Spinner/Spinner";
import Error from "../../../../commons/components/FeedBack/Error/Error";
import {ADMIN_POLLING} from "../../../constants/api";

class Polling extends Component {

    state = {
        isFormTouched: false
    }

    convertValues = (values) => {

        const {value} = this.props.configuration;

        let newValues = {};

        let delay = (values.delay ? values.delay * 1000 * 60 : value.delay);
        let maxMessages = (values.maxMessages ? values.maxMessages : value.maxMessages);

        newValues['delay'] = delay;
        newValues['enabled'] = values.enabled;
        newValues['maxMessages'] = maxMessages;

        if (values.enableEmail) {
            newValues['emailFrom'] = values.emailFrom;

            let emailsTo = [];

            for (let i = 0; i < values.fetchedEmailTo; i++) {
                emailsTo.push(values[`fetchedEmailTo-${i}`])
            }

            for (let i = 0; i < values.emailTo; i++) {
                emailsTo.push(values[`emailTo-${i}`]);
            }

            newValues['emailsTo'] = emailsTo;
        }
        //return ({...values, ...newValues});
        return (newValues);
    }


    handleOnChangeForm = (props) => {
        this.setState({isFormTouched: true})
    }

    handleResetOnClick = () => {
        this.setState({isFormTouched: false})
        this.ref.getForm().resetFields()
    }


    render() {
        const {configuration} = this.props;
        const {formatMessage} = this.props.intl;
        const {location} = this.props;

        let component = null;

        if (configuration.pending) {
            component = <Spinner embedded={true}/>
        } else if (configuration.rejected) {
            let error = {};
            if (configuration.reason.cause) {

                error['status'] = configuration.reason.cause.status;
                error['data'] = configuration;
            } else {
                error['status'] = 500;
                error['data'] = {message: formatMessage({id: 'server.500'})};
            }
            component = <Error error={error} from={location.pathname}/>

        } else if (configuration.fulfilled) {
            component = <PollingForm
                onChangeForm={this.handleOnChangeForm}
                ref={(instance) => {
                    this.ref = instance
                }}
                {...this.props}
            />
        }


        return (
            <div>
                <PageTitle
                    title={formatMessage({id: 'admin.polling.title'})}
                />
                <Card>
                    {component}
                    {configuration.fulfilled &&
                    <Row>
                        <Col xs={{span: 24, offset: 5}}
                             sm={{span: 12, offset: 5}}>
                            <Button
                                disabled={!this.state.isFormTouched}
                                onClick={this.handleResetOnClick}
                                style={{marginRight: '25px'}}
                            >
                                {formatMessage({id: 'cancel'})}
                            </Button>
                            <Button type="primary"
                                    disabled={!this.state.isFormTouched}
                                    onClick={() => {
                                        this.ref.getForm().validateFields(
                                            (err, values) => {
                                                if (!err) {
                                                    let configuration = this.convertValues(values);
                                                    this.props.postConfiguration(JSON.stringify(configuration));

                                                    //Forse ha piu senso fare il reload della pagina per ricaricare le nuove impostazioni salvate?
                                                    this.setState({isFormTouched: false})
                                                } else {
                                                    console.log("Errore: ", err);
                                                }
                                            }
                                        )

                                    }}
                            >
                                {formatMessage({id: 'admin.polling.update'})}
                            </Button>
                        </Col>
                    </Row>

                    }
                    {
                        this.props.postResponse &&
                        <Spinner loading={this.props.postResponse.pending}/>
                    }
                </Card>

            </div>
        )
    }
}


export default connect(props => ({
    configuration: `${ADMIN_POLLING}`,
    postConfiguration: configuration => ({
        postResponse: {
            url: `${ADMIN_POLLING}`,
            method: 'PUT',
            body: configuration
        }
    })
}))(withRouter(injectIntl(Polling)))
