import {Button, Form, Icon, Input} from 'antd';
import React from 'react';
import "./StreetsFormItems.css"
import {injectIntl} from "react-intl";
import {SubFormItemBox} from "app/contacts/components/BaseContactForm/FormITems/RegistrantFormItem";

const FormItem = Form.Item;

let uuid = 0;

//TODO  Togliere il css di StreetsFormItem e sotituiore con StyledComponents


class StreetsFormItems extends React.Component {

    remove = (k) => {
        const {form} = this.props;
        // can use data-binding to get
        const keys = form.getFieldValue('address.streetkeys');
        // We need at least one street
        if (keys.length === 1) {
            return;
        }
        // can use data-binding to set
        form.setFieldsValue({
            'address.streetkeys': keys.filter(key => key !== k),
        });
    }

    add = () => {
        uuid++;
        const {form} = this.props;
        // can use data-binding to get
        const keys = form.getFieldValue('address.streetkeys');
        const nextKeys = keys.concat(uuid);
        // can use data-binding to set
        // important! notify form to detect changes
        form.setFieldsValue({
            'address.streetkeys': nextKeys,
        });
    }

    render() {

        const {formatMessage} = this.props.intl;
        const {getFieldDecorator, getFieldValue} = this.props.form;
        const streets = (this.props.items && this.props.items.address && this.props.items.address.streets) || [];
        const STREETS_MAX = 3;


        const formItemLayout = {
            labelCol: {
                // xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                // xs: {span: 24},
                sm: {span: 20},
            },
        };

        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                // xs: {span: 24, offset: 12},
                sm: {span: 24, offset: 9},
            },
        };

        function fillKeysArrayWithIndexs(indexs) {
            if (indexs === 0) {
                indexs = 1 //Imposta almeno un campo strada
            }
            let arr = Array.apply(null, new Array(indexs));
            return arr.map(function (x, i) {
                return i
            });
        }

        getFieldDecorator('address.streetkeys', {
            initialValue: fillKeysArrayWithIndexs(streets.length)
        })


        const keys = getFieldValue('address.streetkeys');
        const formItems = keys.map((k, index) => {
            return (
                <FormItem
                    {...(index >= 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                    label={index >= 0 ?
                        index + 1 : ''}
                    required={true}
                    key={k}
                >

                    {getFieldDecorator(`address.street_${k}`, {
                        initialValue: streets[k],
                        validateTrigger: ['onChange', 'onBlur'],
                        rules: [{
                            required: true,
                            whitespace: true,
                            message: formatMessage({id: 'contact.detail.form.address.streets.street.message'}),
                        }],
                    })(
                        <Input
                            placeholder={formatMessage({id: 'contact.detail.form.address.streets.street.placeholder'})}
                            style={{float: 'left', width: '90%', marginRight: 8, marginLeft: 8}}
                            type="string"/>
                    )}
                    {keys.length > 1 ? (
                        <Icon
                            className="dynamic-delete-button"
                            type="minus-circle-o"
                            disabled={keys.length === 1}
                            onClick={() => {
                                this.props.onChange()
                                this.remove(k)
                            }}
                        />
                    ) : null}
                </FormItem>
            );
        });
        return (
            <SubFormItemBox styled={{textAlign: 'center'}}>
                {formItems}
                <FormItem
                    {...formItemLayoutWithOutLabel}>
                    {keys.length < STREETS_MAX && <Button type="dashed" onClick={this.add} style={{width: '25%'}}>
                        <Icon type="plus"/>
                    </Button>

                    }
                </FormItem>
            </SubFormItemBox>
        );
    }
}


export default injectIntl(StreetsFormItems);