import React, { Component } from 'react';
import { Form, Input } from 'antd';
import {connect} from "react-redux";
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

const FormItem = Form.Item;


const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};


class UserAdminWrapped extends Component {

  constructor(props) {
    super(props);
    const params  = new URLSearchParams(props.history.location.search);
    let config = {};
    config['eppConfigExists']=params.get("eppConfigExists");
    config['userExists']=params.get("userExists");
    this.state = {configurationStatus:config};

  }


  passwordChecker = (rule, value, callback) => {
    const { formatMessage } = this.props.intl;
    const password = this.props.form.getFieldValue('adminPassword');

    if (password !== value) {
      callback(new Error(formatMessage({id:'confirmAdminPasswordLabel'})));
    } else {
      callback();
    }
  }

  componentDidMount() {
    const {fields} = this.props;
    const { setFieldsValue } = this.props.form;

    setFieldsValue({
      adminName:fields["adminName"],
      fullName:fields['fullName'],
      adminEmail:fields['adminEmail'],
      adminPassword:fields['adminPassword'],
      confirmAdminPassword:fields['confirmAdminPassword']
    })


  }

  render() {
    const { configurationStatus } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { formatMessage } = this.props.intl;
    const {steps, current, fields} = this.props;
    const readOnly = steps[current].readOnly;

    const userExists = (configurationStatus.userExists === 'true');

    const passwordField = ((configurationStatus.userExists === 'false')?fields['adminPassword']:'****');

    let adminName = fields['adminName'];
      
    if (readOnly) {
      adminName = this.props.userInfo.username;
    }

    return (

    <Form>
      <FormItem
        {...formItemLayout}
        hasFeedback
        label={formatMessage({id:'adminNameLabel'})}
        >
        {getFieldDecorator('adminName', {
          rules: [{ required: !readOnly,
                    message: formatMessage({id:'adminNameMessage'}) }],
        })((!readOnly && <Input placeholder={formatMessage({id:'adminNamePlaceholder'})}/>)|| (readOnly && <span>{adminName}</span>))}
      </FormItem>


      { configurationStatus && (! userExists) &&
      <FormItem
        {...formItemLayout}
        hasFeedback
        label={formatMessage({id:'fullNameLabel'})}
        >
        {getFieldDecorator('fullName', {
          rules: [{ required: !readOnly,
                    message:formatMessage({id:'fullNameMessage'}) }],
        })((!readOnly && <Input placeholder={formatMessage({id:'fullNamePlaceholder'})}/>) || (readOnly && <span>{fields['fullName']}</span>))}
      </FormItem>
    }


    { configurationStatus && (! userExists) &&
      <FormItem
        {...formItemLayout}
        hasFeedback
        label={formatMessage({id:'adminEmailLabel'})}
        >
        {getFieldDecorator('adminEmail', {
          rules: [{ required: !readOnly,
                    type:'email', // builtin type
                    message:formatMessage({id:'adminEmailMessage'}) }],
        })((!readOnly && <Input placeholder={formatMessage({id:'adminEmailPlaceholder'})}/>) || (readOnly && <span>{fields['adminEmail']}</span>))}
      </FormItem>
    }


      <FormItem
        {...formItemLayout}
        hasFeedback
        label={formatMessage({id:'adminPasswordLabel'})}
        >
          {getFieldDecorator('adminPassword', {
            rules: [{ required: !readOnly, min:6, max:32, message: formatMessage({id:'adminPasswordMessage'}) }],
        })((!readOnly && <Input placeholder={formatMessage({id:'adminPasswordPlaceholder'})} type="password"/>) || (readOnly && <span>{passwordField}</span>))}
      </FormItem>

      { configurationStatus && (! userExists) &&
      <FormItem
        {...formItemLayout}
        hasFeedback
        label={formatMessage({id:'confirmAdminPasswordLabel'})}
        >
        {getFieldDecorator('confirmAdminPassword', {
          rules: [{ required: !readOnly, message: formatMessage({id:'confirmAdminPasswordMessage'})}, {validator:this.passwordChecker}, ],
        })((!readOnly && <Input placeholder={formatMessage({id:'confirmAdminPasswordPlaceholder'})}  type="password"/>) || (readOnly && <span>{fields['confirmAdminPassword']}</span>))}
      </FormItem>
    }
    </Form>
    )
  }


}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.authReducer.authenticated,
        userInfo:state.authReducer.userInfo
    };
}

const UserAdmin = Form.create()(withRouter(connect(mapStateToProps)(injectIntl(UserAdminWrapped))));
//const UserAdmin = Form.create()(connect(mapStateToProps)(withRouter(injectIntl(UserAdminWrapped))));
//const UserAdmin = Form.create()(withRouter(injectIntl(UserAdminWrapped)));
export default UserAdmin;
