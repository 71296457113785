import React, {Component} from 'react';
import { Table} from "antd";
import * as routes from "../../../../routes";
import {injectIntl} from "react-intl";
import {Link} from "react-router-dom";
import "./DomainListTable.css"
import PropTypes from 'prop-types';
import DomainName from "../../../../domains/components/DataDisplay/DomainName/DomainName";
import HalPagination from "app/commons/components/Navigation/HalPagination/HalPagination";
import ExpireDateContainer from "app/commons/components/DataDisplay/ExpiredDate/ExpireDateContainer";
import styled from "styled-components";

class DomainListTable extends Component {

    render() {
        const {intl, data} = this.props;
        const {formatMessage, formatDate} = intl;
        const formattingDate = {
            year: 'numeric', day: 'numeric', month: 'short'
        };

        const columns = [
            {
                title: formatMessage({id: 'domain.list.header.domain'}),
                dataIndex: 'domain',
                key: 'domain',
                render: (text, record) => {
                    const domain = record.domainIDN ? record.domainIDN : record.domain
                    return <Link to={`${routes.DOMAIN_INFO.url}/${domain}`}><DomainName
                        name={domain} signed={JSON.parse(record.signed)}/></Link>
                },
            },
            {
                title: formatMessage({id: 'domain.list.header.created'}),
                dataIndex: 'created',
                key: 'created',
                render: (text, record) => formatDate(text, formattingDate),
            },
            {
                title: formatMessage({id: 'domain.list.header.expire'}),
                dataIndex: 'expire',
                key: 'expire',
                render: (text, record) =>
                    <ExpireDateContainer exDate={text}/>,
            },
            {
                title: formatMessage({id: 'domain.list.header.status'}),
                dataIndex: 'status',
                key: 'status',
            }
        ];

        const DisplayTable = () => (
            <div className='domains-list'>
                <StyledTable columns={columns}
                             dataSource={data.elements}
                             pagination={false}
                             rowKey={(record) => record.domain}
                             bordered
                             scroll={{x:700}}
                             size="small"
                />
                <HalPagination
                    HAL_page={data.page}
                    onShowSizeChange={this.props.onChangePage}
                />
            </div>
        )

        return (
            <span>
                {(data && data.elements) ?
                    <DisplayTable/> :
                    formatMessage({id: 'domain.list.message.noResults'})
                }
            </span>
        );
    }
}

DomainListTable.propTypes = {
    data: PropTypes.object,
    onChangePage: PropTypes.func
};
DomainListTable.defaultProps = {};

export default injectIntl(DomainListTable);

export const StyledTable = styled(Table)`
    td { white-space: nowrap; }
`;
