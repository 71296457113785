import React from "react";
import {Layout} from "antd";
import {FormattedMessage} from "react-intl";
import "./Footer.css";
let packageJson = require('../../../../../../package.json');

const FooterBar = () => (
    <Layout.Footer className="epic-footer">
        <div>
            <FormattedMessage id='footer.signature' values={{year: (new Date()).getFullYear()}}/>
            <a className="footer-link" href="http://www.nic.it">Registro<img role="presentation"
                                                                             src="/logo-it-bianco.svg"
                                                                             className="itlogo-footer"
                                                                             alt="registro.it"/></a>
            - v{packageJson.version}
        </div>
    </Layout.Footer>
)

export default FooterBar
