import React from 'react';
import PropTypes from 'prop-types';
import {Icon, Tooltip} from "antd";
import styled from 'styled-components'

const LIST = 'list';
const TAB = 'tab';
const GRID = 'grid';

const ViewerSelectorBox = styled.div`
    text-align : ${props => props.align} ;
    border-left: 1px solid #ebedee;
    margin-left: 10px;
    padding-left: 10px;
`;

const StyledIcon = styled(Icon)`
    font-size: 18pt;
    margin-right: 5px;
    color: ${props => props.selected ? '#1890ff' : 'gray'}
    
    &:hover{      
      font-weight: bold;
       cursor: pointer ;
    }`;

const SelectorIcon = ({type, selected}) => (
    <StyledIcon selected={selected} type={type}/>
);

const Selector = ({type, onClick, selected, tooltipText}) => (
    <Tooltip title={tooltipText}>
        <span onClick={onClick}>
            <SelectorIcon type={type} selected={selected}/>
        </span>
    </Tooltip>
);

class ViewSelector extends React.Component {

    state = {
        selected: this.props.default
    };

    onClick(selector, action) {
        this.setState({selected: selector});
        action(selector)
    }

    render() {
        const {props} = this;
        return (
            <ViewerSelectorBox align={props.align}>
                <Selector
                    type={'bars'}
                    onClick={this.onClick.bind(this, LIST,props.onClick)}
                    selected={this.state.selected === LIST}
                    tooltipText={'Visualizza come lista'}
                />
                <Selector
                    type={'appstore-o'}
                    onClick={this.onClick.bind(this, GRID,props.onClick)}
                    selected={this.state.selected === GRID}
                    tooltipText={'Visualizza come griglia'}
                />
                <Selector
                    type={'folder'}
                    onClick={this.onClick.bind(this, TAB,props.onClick)}
                    selected={this.state.selected === TAB}
                    tooltipText={'Visualizza come tab'}
                />
            </ViewerSelectorBox>
        );
    };
}

ViewSelector.propTypes = {
    onClick: PropTypes.func,
    default: PropTypes.oneOf([GRID, LIST, TAB]),
    align: PropTypes.string
};
ViewSelector.defaultProps = {
    default: TAB,
    align: 'right'
};

export default ViewSelector;
