import React from 'react';
import "../../../../../styles/DetailPanel.css"
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import "./DsRecordsDetail.css"
import DsRecords from "app/domains/components/DataDisplay/DsRecordsDetail/DsRecords";

const DsRecordsDetail = (props) => {
    const {formatMessage} = props.intl;

    return (
        <span>
            {props.dsRecords &&
            <DsRecords
                className='dsRecordsDetail-table'
                dataSource={props.dsRecords}
                title={() => formatMessage({id: "detail.dsRecord.actual"})}
                rowSelection={props.onRowSelection}
            />
            }

            {props.dsRecordsToValidate &&
            <DsRecords
                dataSource={props.dsRecordsToValidate}
                title={() => formatMessage({id: "detail.dsRecord.toValidate"})}/>
            }
        </span>
    );
};


DsRecordsDetail.propTypes = {
    dsRecords: PropTypes.any,
    dsRecordsToValidate: PropTypes.any,
    onRowSelection: PropTypes.any
};

export default injectIntl(DsRecordsDetail);
