import {combineReducers} from "redux";
import infoReducer from "./info";
import checkReducer from "./check";
import deleteReducer from "./delete";
import restoreReducer from "./restore";
import updateReducer from "./update";
import transferReducer from "./transfer";
import listReducer from "./list";

export default combineReducers({
    infoReducer,
    checkReducer,
    deleteReducer,
    restoreReducer,
    updateReducer,
    listReducer,
    transferReducer,
})
