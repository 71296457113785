import React from "react";
import styled from "styled-components";
import FAIcon from "app/commons/components/Layout/FAIcon/FAIcon";
import PropTypes from 'prop-types';
import {DEVEL, PROD, PUBTEST} from "app/commons/constants/constants";


const bgColorChooser = (profileType) => {
    switch (profileType) {
        case PUBTEST:
            return '#f50'
        case DEVEL:
            return '#87d068'
        case PROD:
            return '#2db7f5'
        default:
            return "gray"
    }
};

const ProfileName = styled.div`
    background-color: ${props => bgColorChooser(props.profileType)};
    display: flex;
    justify-content: space-between;
    border: 0px solid #d9d9d9;
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 1;
    padding: 5px 20px 5px 20px;
    border-radius: 15px 50px;
    font-size: 12px;
    font-weight: bold;
    color: white;
    align-items: baseline;
`;

const Name = styled.span`
  margin-left: 5px;
`;

const ClientProfile = ({type}) => {
    if (type) {
        return (
                <ProfileName profileType={type}>
                    <FAIcon type={'fal fa-code-branch'}/>
                    <Name>{type}</Name>
                </ProfileName>
        )
    }
    return <span/>
}

export default ClientProfile;

ClientProfile.propTypes = {
    /* tipo di profilo da visualizzare */
    type : PropTypes.oneOf([PUBTEST,PROD,DEVEL]).isRequired
};
