import React from 'react';
import PropTypes from 'prop-types';
import "./PageTitle.css"

function PageTitle(props) {
    return (
        <div className="div-title">
            <h1 className="page-title">{props.title}</h1>
        </div>
    );
}

PageTitle.propTypes = {
    title: PropTypes.any.isRequired,
};

export default PageTitle;
