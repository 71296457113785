import React from 'react';
import {Card} from "antd";
import PropTypes from "prop-types";

function SearchBarCard(props) {
    return (
        <Card
            style={{textAlign: 'left', marginBottom: '10px',}}
            bodyStyle={{paddingBottom: '0px'}}
            bordered={props.bordered}>
            {props.children}
        </Card>
    );
}

SearchBarCard.propTypes = {
    bordered: PropTypes.bool,
    children: PropTypes.element.isRequired
};
SearchBarCard.defaultProps = {
    bordered: false
};

export default SearchBarCard;
