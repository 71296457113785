import React from 'react';
import "../../../../../styles/DetailPanel.css"
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import CardDetailRow from "../../../../commons/components/DataDisplay/CardDetailRow/CardDetailRow";

const ContactDetail = (props) => {
    const {formatMessage} = props.intl;

    const {voice, fax, email} = props.data;
    return (
        <div>
            <CardDetailRow label={formatMessage({id: "detail.contact.phone"})}
                           content={voice}/>
            {fax && <CardDetailRow label={formatMessage({id: "detail.contact.fax"})}
                                   content={fax}/>}
            <CardDetailRow label={formatMessage({id: "detail.contact.email"})}
                           content={email}/>
        </div>
    );
};

ContactDetail.propTypes = {
    data: PropTypes.any.isRequired,
};

export default injectIntl(ContactDetail);
