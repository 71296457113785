export const PASSWORD_REMINDER_MESSAGE = 'PASSWORD_REMINDER_MESSAGE';
export const TRANSFER_MESSAGE = 'TRANSFER_MESSAGE';
export const WRONG_NAMESPACE_REMINDER_MESSAGE = 'WRONG_NAMESPACE_REMINDER_MESSAGE';
export const CREDIT_MESSAGE = 'CREDIT_MESSAGE';
export const REMAPPED_IDN_MESSAGE = 'REMAPPED_IDN_MESSAGE';
export const DNS_ERROR_MESSAGE = 'DNS_ERROR_MESSAGE';
export const DNS_WARNING_MESSAGE = 'DNS_WARNING_MESSAGE';
export const CHANGE_STATUS_MESSAGE = 'CHANGE_STATUS_MESSAGE';
export const SIMPLE_MESSAGE = 'SIMPLE_MESSAGE';
export const DELAYED_DEBIT_AND_REFUND_MESSAGE = 'DELAYED_DEBIT_AND_REFUND_MESSAGE';
export const BROKEN_DELEGATION_MESSAGE = 'BROKEN_DELEGATION_MESSAGE';
export const REFUND_RENEWS_FOR_BULKTRANSFER_MESSAGE = 'REFUND_RENEWS_FOR_BULKTRANSFER_MESSAGE';