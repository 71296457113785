import React, {Component} from 'react';
import {Button, Form, Icon, Input} from 'antd';

import {injectIntl} from 'react-intl';

import ItDomainValidator from '../../../../commons/utils/validators/ItDomainValidator';
import IPv4Validator from '../../../../commons/utils/validators/IPv4Validator';
import IPv6Validator from '../../../../commons/utils/validators/IPv6Validator';

import WizardComponentHeader from './WizardComponentHeader';

import './Fields.css';

const FormItem = Form.Item;

let uuid = 1;
const CONF = {
    MIN_NAMESERVER: 2,
    MAX_NAMESERVER: 6
}


const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 6},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 12},
    },
};


const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: {span: 24, offset: 5},
        sm: {span: 12, offset: 5},
    },
};


/*
Nome: Brecher William
Organizzazione: 2000NET S.R.L.
Indirizzo: VIA XXV APRILE, 47
Città: BORGOSESIA
Provincia: VC
CAP: 13011
Nazione: Italy
Telefono: +39.0163209111
E-Mail: MNT@2000net.i
*/


class DomainHostsWrapper extends Component {

    remove = (k) => {

        const {setFieldsValue, getFieldValue} = this.props.form;
        // can use data-binding to get

        let disabled = this.props.fields[`disabled-${k}`];


        if (disabled) {
            return;
        }

        let keys = getFieldValue('keys');
        // We need at least one passenger
        //  console.log("KEY to remove: ", k);

        if (keys <= CONF.MIN_NAMESERVER) {
            return;
        }
        // Ricopio i valori dei nameserver eccetto quello che voglio cancellare
        /*
            let index=0;let name={};
            keys.forEach((key)=> {
              if (key!==k) {
                name[`names-${index}`]=getFieldValue(`names-${key}`) ||  fields[`names-${key}`] || "";
                setFieldsValue(name);
                index++;
              }
            })
        */
        let newIndex = 0;
        let names = {};
        for (let i = 0; i < keys; i++) {

            if (i !== k) {
                names[`names-${newIndex}`] = getFieldValue(`names-${i}`);
                newIndex++;
            }
        }

        setFieldsValue(names);

//  console.log("NEWVALUES: ", names);

        // can use data-binding to set
        setFieldsValue({
            keys: keys - 1
        });


    }

    add = () => {
        uuid = uuid + 1;
        const {form} = this.props;
        // can use data-binding to get
        const keys = form.getFieldValue('keys');
        const nextKeys = keys + 1;
        // can use data-binding to set
        // important! notify form to detect changes
        form.setFieldsValue({
            keys: nextKeys,
        });
    }

    validateIPv4 = (role, value, callback) => {
        //console.log("validateIpv4 ", value);
        const {formatMessage} = this.props.intl;
        if (value) {
            if (IPv4Validator.validate(value)) {
                callback()
            } else {
                callback(new Error(formatMessage({id: 'invalidIPV4'})));
            }
        } else {
            callback(new Error(formatMessage({id: 'notEmptyField'})));
        }
    }

    // Da ricordare a futura memoria: setFieldsValue si può usare solo se i campi
    // sono già definiti e quindi in questo caso solo dopo componentDidMount.
    componentDidMount() {
        const {fields} = this.props;
        const {keys} = this.props.fields;
        const {setFieldsValue} = this.props.form;

        let name = {}, ipv4 = {}, ipv6 = {};
        if (keys) {
            for (let k = 0; k < keys; k++) {

                name[`names-${k}`] = fields[`names-${k}`];

                setFieldsValue(name);


                if (fields[`ipv4-${k}`]) {
                    ipv4[`ipv4-${k}`] = fields[`ipv4-${k}`];
                    setFieldsValue(ipv4)
                }

                if (fields[`ipv6-${k}`]) {
                    ipv6[`ipv6-${k}`] = fields[`ipv6-${k}`];
                    setFieldsValue(ipv6);
                }
            }
        }
    }


    validateIPv6 = (role, value, callback) => {
        const {formatMessage} = this.props.intl;
        if (value) {
            if (IPv6Validator.validate(value)) {
                callback()
            } else {
                callback(new Error(formatMessage({id: 'invalidIPV6'})));
            }
        }
        callback();
    }

    /*  showIPv4(k) {
        const {getFieldValue, setFieldsValue} = this.props.form;
        let value = getFieldValue(`names-${k}`);
        let shouldShow = false;
        if (value && this.props.fields && this.props.fields.domainName) {
          let domainName = this.props.fields.domainName;
          shouldShow = ((value.indexOf('.'+domainName) !== -1) && (!value.startsWith('.')))
        }

        return shouldShow;
        }
        */
    showIPv4(dns, name) {
//      console.log("dns: ", dns, " name: ", name);
        let shouldShow = false;
        if (dns && name) {
            shouldShow = ((dns.indexOf('.' + name) !== -1) && (!dns.startsWith('.')))
        }
        return shouldShow;
    }


    validateDnsName = (role, value, callback) => {


        const {getFieldValue} = this.props.form;
        const {formatMessage} = this.props.intl;
        if (!value) {
            callback(new Error(formatMessage({id: "emptyDNSName"})));
        } else {
            let dns = ItDomainValidator.validate(value, false);
            if (!dns.valid) {
                callback(new Error(formatMessage({id: "invalidDNSName"})));
            }
            // Controllo che il dns non sia tra quelli già configurati
            let keys = getFieldValue('keys');
            let names = {};
            for (let k = 0; k < keys; k++) {
                const name = getFieldValue(`names-${k}`);
                if (!names[name]) {
                    names[name] = name;
                } else {
                    callback(new Error(formatMessage({id: 'duplicateDNS'})));
                }
            }
            callback();
        }

    }

    render() {
        const {getFieldDecorator, getFieldValue} = this.props.form;

        const {formatMessage} = this.props.intl;
        const {fields} = this.props;


        getFieldDecorator('keys', {initialValue: fields.keys ? fields.keys : 2});
        const keys = Math.max(getFieldValue('keys'), 2);

        let formItems = [];

        for (let k = 0; k < keys; k++) {
            let index = k;
            formItems.push(
                <div key={k} className="riquadro bordo with-title">
                    <div className="title">{`Nameserver ${index + 1}`}</div>
                    <div className="close-icon">
                        <Icon
                            className="close-button"
                            type="cross-circle-o"
                            disabled={keys <= 2 || fields[`disabled-${k}`]}
                            onClick={this.remove.bind(this, k)}
                        />
                    </div>
                    <FormItem
                        {...formItemLayout}
                        label={formatMessage({id: 'nameserver'})}
                        required={index < 2}
                        key={k}
                        hasFeedback
                    >
                        {getFieldDecorator(`names-${k}`, {
                            //initialValue: fields[`names-${k}`],
                            validateTrigger: ['onChange', 'onBlur'],
                            rules: [{

                                message: formatMessage({id: 'insertValidDomainOrIP'}),
                            }, {validator: this.validateDnsName}],
                        })(
                            <Input placeholder="DNS Host" style={{width: '100%'}} disabled={fields[`disabled-${k}`]}/>
                        )}
                    </FormItem>

                    {this.showIPv4(getFieldValue(`names-${k}`) || fields[`names-${k}`], fields['domainName']) && ( <div>

                        <FormItem  {...formItemLayout} label='IPv4' required={true} hasFeedback>
                            {getFieldDecorator(`ipv4-${k}`, {
                                validateTrigger: ['onChange', 'onBlur'],

                                rules: [{
                                    required: true,
                                    initialValue: fields[`ipv4-${k}`],
                                    message: formatMessage({id: 'insertValidIPv4'}),
                                }, {validator: this.validateIPv4}],
                            })(
                                <Input placeholder="Valid IPv4" style={{width: '100%'}}
                                       disabled={fields[`disabled-${k}`]}/>
                            )}
                        </FormItem>

                        <FormItem  {...formItemLayout} label='IPv6' required={false} hasFeedback>
                            {getFieldDecorator(`ipv6-${k}`, {
                                validateTrigger: ['onChange', 'onBlur'],
                                rules: [{
                                    initialValue: fields[`ipv6-${k}`]
                                }, {validator: this.validateIPv6}],
                            })(
                                <Input placeholder="Valid IPv6" style={{width: '100%'}}
                                       disabled={fields[`disabled-${k}`]}/>
                            )}
                        </FormItem></div>)
                    }


                </div>
            )
        }

        return (
            <div className="wizard-component-header">
                {
                    !this.props.fields.dnsEmbedded &&
                    <WizardComponentHeader {...this.props} />
                }
                <Form>
                    {formItems}

                    <FormItem {...formItemLayoutWithOutLabel}>
                        <Button size="small" disabled={(keys >= CONF.MAX_NAMESERVER)} type="dashed" onClick={this.add}>
                            <Icon type="plus"/>{formatMessage({id: 'addDNS'})}</Button>
                    </FormItem>
                </Form>
            </div>
        )
    }
}

const DomainHosts = Form.create()(injectIntl(DomainHostsWrapper));
export default DomainHosts;
