import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import * as routes from "../../../../routes";
import FiltersBar from "../../../../commons/components/Navigation/FiltersBar/FiltersBar";


const filtersOptions = {
    domain: {
        label: 'Dominio',
        icon: 'fa-globe',
        options: {
            textSearch: true,
            placeholder: 'Nome dominio valido',
        },

    },
    registrantId: {
        label: 'ID Registrante',
        icon: 'fa-key',
        options: {
            textSearch: true,
            placeholder: 'ID Registrante valido',
        },

    },
    adminId: {
        label: 'ID Amministratore',
        icon: 'fa-key',
        options: {
            textSearch: true,
            placeholder: 'ID Amministratore valido',
        },

    },
    /* Campo boolean */
    host: {
        label: 'Host',
        icon: 'fa-server',
        options: {
            textSearch: true,
            placeholder: 'Valore host',
        },
    },
    status: {
        label: 'Stato',
        icon: 'fa-tag',
        options: {
            select: {
                bulk: {
                    label: 'bulk'
                }, challenged: {
                    label: 'challenged'
                }, dnsHold: {
                    label: 'dnsHold'
                }, inactive: {
                    icon: '',
                    label: 'inactive'
                }, noRegistrar: {
                    label: 'noRegistrar'
                }, notRenewed: {
                    label: 'notRenewed'
                },
                ok: {
                    label: 'ok'
                }, pendingDelete: {
                    label: 'pendingDelete'
                }, pendingTransfer: {
                    label: 'pendingTransfer'
                }, pendingUpdate: {
                    label: 'pendingUpdate'
                }, rejectedRequest: {
                    label: 'rejectedRequest'
                }, revoked: {
                    label: 'revoked'
                }

            },
        },
    }
}

class DomainListFilterBar extends Component {

    state = {
        filters: {}
    }

    //Estraee dalla barra indirizzo i parametri e li mette nello stato
    componentWillMount() {
        let searchParams = new URLSearchParams(this.props.location.search.slice(1));
        let res = {};
        for (let pair of searchParams.entries()) {
            res[pair[0]] = pair[1]
        }
        this.setState({filters: res})
    }


    handleOnSubmit = (filters) => {
        let queryString = new URLSearchParams(filters);
        this.setState({filters: filters}); //Modifica l'attuale stato dei filtri
        //Reindirizza al'indirizzo con i filtri modificati
        this.props.history.push({
            pathname: routes.DOMAIN_LIST.url,
            search: `?${queryString}`
        })
    }

    render() {
        return (
            <FiltersBar
                initialFilters={this.state.filters}
                filtersOptions={filtersOptions}
                onSubmit={this.handleOnSubmit}
            />
        );
    }
}

DomainListFilterBar.propTypes = {};
DomainListFilterBar.defaultProps = {};

export default withRouter(DomainListFilterBar);
