import React from 'react';
import "./EppMessagesType.css"
import BaseMessage from "./BaseMessage";

const DelayDebitRefoundMessage = (props) => {

    function Detail({data}) {
        if (data) {
            return (
                <div className={'typedLayout-container'}>
                    {data &&
                    <div>
                        <div className={'typedLayout-data'}>
                            {data['credit'] &&
                            <div className={'typedLayout-row'}>
                                <span className={'label'}>Credito:</span>
                                <span className={'content'}> {data['credit']}</span>
                            </div>
                            }

                            {data['name'] &&
                            <div className={'typedLayout-row'}>
                                <span className={'label'}>Dominio:</span>
                                <span className={'content'}> {data['name']}</span>
                            </div>
                            }
                            {data['amount'] &&
                            <div className={'typedLayout-row'}>
                                <span className={'label'}>Importo:</span>
                                <span className={'content'}> {data['amount']}</span>
                            </div>
                            }
                        </div>
                    </div>}
                </div>
            )
        }
        else {
            return <span/>
        }
    }

    return (
        <BaseMessage
            detail={props.detail}
            component={<Detail data={props.detail.data}/>}
        />
    );
};

DelayDebitRefoundMessage.propTypes = {};
DelayDebitRefoundMessage.defaultProps = {};

export default DelayDebitRefoundMessage;
