import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import styled from "styled-components";
import FAIcon from "app/commons/components/Layout/FAIcon/FAIcon";

const StyledMenuItemLabel = styled.span`
  margin-left: 10px;
`;

const LabelWithIcon = ({type, idMessage, onlyIcon,text}) =>
    <span>
        <FAIcon type={type}/>
        {!onlyIcon && <StyledMenuItemLabel>
            {text && text}
            {idMessage && <FormattedMessage id={idMessage}/>}
        </StyledMenuItemLabel>
        }
    </span>;

LabelWithIcon.propTypes = {
    /* indica il nome dell'icona Font Awesome da inserire */
    type: PropTypes.string,
    /* indica la chiave del messaggio da inserire */
    idMessage: PropTypes.string,
    /* indica se visualizzare la sola icona, usato per formatazioni più compatte */
    onlyIcon: PropTypes.bool
};
LabelWithIcon.defaultProps = {
    onlyIcon: false
};

export default LabelWithIcon;
