import React from "react";
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import DomainUpdateHostWizard from "./DomainUpdateHostWizard";
import {connect} from "react-redux";
import DomainInfoUpdateBox from "../../Box/DomainInfoCustomableBox";

class DomainUpdateHost extends React.Component {

    render() {
        const {infoDomainData, intl} = this.props;
        const {formatMessage} = intl;

        return (
            <div>
                <DomainInfoUpdateBox
                    title={formatMessage({id: "domain.update.host.form.title"})}
                    hasRegistrarPermission={this.props.hasRegistrarPermission}
                    infoData={this.props.infoDomainData.data}
                    error={this.props.updateDomainHostData.error}
                    loading={this.props.updateDomainHostData.loading}
                    message={this.props.updateDomainHostData.message}
                    reset={this.props.reset}
                >
                    <DomainUpdateHostWizard
                        fields={{domainData: infoDomainData.data}}
                        onDone={this.props.onUpdateHost}
                        onCancel={this.props.onCancel}
                    />
                </DomainInfoUpdateBox>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        infoDomainData: state.domain.infoReducer,
        updateDomainHostData: state.domain.updateReducer
    };
}

export default connect(mapStateToProps)(injectIntl(DomainUpdateHost));

DomainUpdateHost.propTypes = {
    hasRegistrarPermission: PropTypes.bool,
    onCancel: PropTypes.func,
    onUpdateHost: PropTypes.func,
    reset: PropTypes.func,
};
