import UserAdmin from './UserAdmin';
import EppConfiguration from './EppConfiguration';
import {configOperations} from "../../index";


export const steps = [
    {
        id: 'adminUser',
        title: 'Utenti',
        description: 'Utente Admin',
        readOnly: false,
        content: UserAdmin,
        stepActions: [configOperations.insertAdmin, configOperations.authenticate]
    },

    {
        id: 'serverConfiguration',
        title: 'Configurazione',
        description: 'Impostare parametri EPP',
        readOnly: false,
        content: EppConfiguration,
        stepActions: [configOperations.insertServer]
    },
];
