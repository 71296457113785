import React from 'react';
import "./EppMessagesType.css"
import RegistersInvolved from "../../../domains/components/Pages/DomainTransfer/RegistersInvolved";
import TransferStatus from "../../../domains/components/Pages/DomainTransfer/TransferStatus";
import BaseMessage from "./BaseMessage";

const TransferMessage = (props) => {

    function Detail({data}) {
        if (data) {
            return (
                <div className={'typedLayout-container'} style={{width: '100%'}}>
                    {data &&
                    <div>
                        <div className={'typedLayout-data'}>
                            {data['transferData'] &&
                            <div>
                                <RegistersInvolved {...data['transferData']} />
                                <TransferStatus {...data['transferData']}/>
                            </div>
                            }
                        </div>
                    </div>}
                </div>
            )
        }
        else {
            return <span/>
        }
    }

    return (
        <BaseMessage
            detail={props.detail}
            component={<Detail data={props.detail.data}/>}
        />
    );
};

TransferMessage.propTypes = {};
TransferMessage.defaultProps = {};

export default TransferMessage;
