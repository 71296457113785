import React, {Component} from 'react';
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";

class DomainFormEppOperationContainer extends Component {

    componentWillMount() {
        this.fetchData()
        const {domain} = this.props.match.params;
        if (!domain) {
            this.props.resetOperation();
        }
    }

    /** capisce se necessario chiamare il comando EPP */
    isFetchingNeeded() {
        const {domain: domainName} = this.props.match.params;
        return domainName && (!this.props.data || domainName !== this.props.data.name);
    }

    /** recupero i dati*/
    fetchData() {
        const {domain} = this.props.match.params;

        if (this.isFetchingNeeded()) {
            this.props.eppOperation(domain);
        }
    }

    render() {
        return (
            <div>{this.props.component}</div>
        )
    }
}

DomainFormEppOperationContainer.propTypes = {
    resetOperation: PropTypes.func,
    eppOperation: PropTypes.func,
    data: PropTypes.object,
    component: PropTypes.object
};
DomainFormEppOperationContainer.defaultProps = {};

export default withRouter(DomainFormEppOperationContainer);
