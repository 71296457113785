// ** DELETE **

import * as types from "../actionTypes";
import * as api from "../api";

export function deleteDomainStart() {
    return {
        type: types.DELETE_DOMAIN_START,
    }
}

export function deleteDomain(domain) {
    const response = api.deleteDomain(domain)
    return {
        type: types.DELETE_DOMAIN,
        payload: response
    }
}

export function deleteDomainSuccess(response) {
    return {
        type: types.DELETE_DOMAIN_SUCCESS,
        payload: response
    }
}

export function deleteDomainFailure(error) {
    return {
        type: types.DELETE_DOMAIN_ERROR,
        payload: error
    }
}


export function resetDeleteDomain() {
    return {
        type: types.RESET_DELETE_DOMAIN,
    }
}