import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";

const StyledDetailPane = styled.div`
  padding: 20px;
  min-height: 100%;
  background: white;
  text-align: left;
`;

const DetailPane = (props) => {
    return (
        <StyledDetailPane>
            {props.children}
        </StyledDetailPane>
    );
};

DetailPane.propTypes = {
    children: PropTypes.any.isRequired
};
DetailPane.defaultProps = {};

export default DetailPane;
