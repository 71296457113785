import React, {Component} from 'react';
import {injectIntl} from "react-intl";
import {Alert, Form} from "antd";
import DomainHostDetail from "../../../DataDisplay/DomainHostDetail/DomainHostDetail";
import DsRecords from "app/domains/components/DataDisplay/DsRecordsDetail/DsRecords";
import {
    buildDsRecordsToAdd,
    buildSummaryRecords,
    getDSRecordsValues
} from "app/domains/components/Pages/DomainUpdateHost/utils";

class SummaryHostUpdateSummary extends Component {

    addAddress = (address, type) => {
        let addressObject = null
        if (address) {
            addressObject = {};
            addressObject['address'] = address;
            addressObject['type'] = type;
        }
        return addressObject;
    }

    buildNamesHash = (fields) => {
        const {hosts} = fields.domainData;
        let hash = {};
        if (hosts) {
            hosts.forEach((host) => {
                hash[host.name] = host;
            })
        }
        return hash;

    }

    compareAddress = (address, ipv4, ipv6) => {
        let res = true;


        switch (address.type) {
            case 'v4': {
                res = address.address === ipv4;
                break;
            }
            case 'v6': {
                res = address.address === ipv6
                break;
            }

            default :
                break;
        }

        return res;
    }

    buildChangedHosts = (fields) => {
        const {keys} = fields;

        let originalDnsTable = this.buildNamesHash(fields);


        let address;
        let changedHosts = [];
        let unchangedHosts = []

        for (let k = 0; k < keys; k++) {
            let dns = {};
            let addresses = [];
            let hostName = fields[`names-${k}`];
            dns['name'] = hostName;

            address = this.addAddress(fields[`ipv4-${k}`], 'v4')
            if (address) addresses.push(address);

            address = this.addAddress(fields[`ipv6-${k}`], 'v6');
            if (address) addresses.push(address);

            if (addresses.length > 0)
                dns['addresses'] = addresses;

            if (!originalDnsTable[hostName]) {
                changedHosts.push(dns);
            } else {
                let changed = false;
                let dnsOrig = originalDnsTable[hostName];
                let origAddresses = dnsOrig.addresses;

                if (origAddresses && origAddresses.length > 0) {
                    let ipv4 = fields[`ipv4-${k}`];
                    let ipv6 = fields[`ipv6-${k}`];

                    if (origAddresses.length !== addresses.length) {
                        changed = true;
                    } else {
                        origAddresses.forEach((address) => {
                            changed = changed || !this.compareAddress(address, ipv4, ipv6);
                        })
                    }
                }
                if (changed) {
                    changedHosts.push(dns);
                } else {
                    unchangedHosts.push(dns);
                }
            }
        }
        return {changedHosts, unchangedHosts};
    }


    evaluateDifferences = (role, value, callback) => {
        callback();
    }

    componentWillMount() {
        const {getFieldDecorator} = this.props.form;

        let hostInfo = this.buildChangedHosts(this.props.fields);

        getFieldDecorator('modifiedHosts', {initialValue: hostInfo.changedHosts}, {rules: [{validator: this.evaluateDifferences}]});
        getFieldDecorator('unchangedHosts', {initialValue: hostInfo.unchangedHosts}, {rules: [{validator: this.evaluateDifferences}]});
        getFieldDecorator('dsRecordsToAdd', {initialValue: buildDsRecordsToAdd(this.props.fields)});
    }


    render() {
        const {fields, steps, current} = this.props;
        let propsStep = this.props.steps.filter(s => s.id === 'summary')
        const {error} = steps[current];
        let errorInfo = {status: true, msg: ''};
        if (error) {
            errorInfo = error(fields, this.props);
        }

        const {getFieldValue} = this.props.form;
        const {formatMessage} = this.props.intl;

        let changedHost = getFieldValue('modifiedHosts');
        let unchangedHosts = getFieldValue('unchangedHosts');

        let dsRecordsToRemove = fields.dsRecordsToRemove || [];
        let dsRecordsToAdd = buildDsRecordsToAdd(fields);

        let dsRecordsPreview = getDSRecordsValues(this.props.fields.dsrecords || [], fields)


        let summaryHost = buildSummaryRecords(changedHost, fields.deletedDns);
        let summaryDsRecords = buildSummaryRecords(dsRecordsToAdd, dsRecordsToRemove);

        return (
            <div>
                <Form>
                    {/*Preview*/}
                    {!errorInfo.status && (
                        <div style={{marginBottom: '40px'}}>
                            <Alert description={errorInfo.msg}
                                   message='Errore'
                                   type="error"
                                   showIcon={true}
                                   className='error-box'/>
                        </div>
                    )}


                    {
                        fields.domainData.hostsToValidate && (
                            <div className="riquadro bordo with-title">
                                <div className="title">{formatMessage({id: 'domain.update.host.dnsHold'})}</div>
                                <DomainHostDetail
                                    hosts={fields.domainData.hostsToValidate}
                                />
                            </div>
                        )
                    }

                    <div className="riquadro bordo with-title">
                        <div
                            className="title">{formatMessage({id: `domain.update.host.summary.table.host.preview`})}</div>
                        <DomainHostDetail
                            hosts={unchangedHosts.concat(changedHost)}
                        />

                        {propsStep[0].dnssec &&
                        <DsRecords
                            title={() => 'Record DS'}
                            dataSource={dsRecordsPreview}
                        />
                        }

                    </div>
                    {/*Edited*/}
                    {
                        (summaryHost.length > 0) &&
                        <div className="riquadro bordo with-title">
                            <div
                                className="title">{formatMessage({id: `domain.update.host.summary.table.host.edited`})}</div>
                            {
                                <DomainHostDetail
                                    hosts={summaryHost}
                                    summary={true}
                                />
                            }
                        </div>
                    }

                    {
                        (summaryDsRecords.length > 0) &&
                        <div className="riquadro bordo with-title">
                            <div
                                className="title">{formatMessage({id: `domain.update.host.summary.table.dsRecords.edited`})}</div>

                            <DsRecords
                                style={{marginBottom: '20px'}}
                                dataSource={summaryDsRecords}
                                summary={true}
                            />
                        </div>
                    }
                </Form>
            </div>
        )
    }
}

export default Form.create()(injectIntl(SummaryHostUpdateSummary));