const CONF = {
    'MIN_LENGTH': '3',
    'MAX_LENGTH': '16',
}

export default class ContactIdValidator {

    static validate(id) {

        if (!id || 0 === id.length) {
            return {"valid": false, "error": "contactIdEmpty"};
        }

        if (id.length < CONF.MIN_LENGTH) {
            return {"valid": false, "error": "contactIdTooShort"};
        }

        if (id.length > CONF.MAX_LENGTH) {
            return {"valid": false, "error": "contactIdTooLong"}
        }

        return {"valid": true};
    }
}