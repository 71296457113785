import React from 'react';
import {Col, Row} from "antd";
import "./DomainTransferStatus.css"
import * as routes from "../../../../routes";
import TagNoCursor from "../../../../commons/components/DataDisplay/TagNoCursor/TagNoHover";
import {injectIntl} from "react-intl";
import {Link} from "react-router-dom";

const TradeData = (props) => {

    const {data} = props.transferData;
    const {formatMessage} = props.intl;
    if (data.trade) {
        return (
            <div>
                <div className={'domain-status-box'}>
                    <Row className={'domain-status-box-header'}>
                        <Col span={8}/>
                        <Col span={8}> </Col>
                        <Col span={8} pull={2}>{formatMessage({id: 'trade'})}
                        </Col>
                    </Row>
                    <Row className='domain-status-box-date'>
                        <Col span={8}/>
                        <Col span={8}><TagNoCursor color="#87d068">New</TagNoCursor></Col>
                        <Col span={8} pull={2}><span style={{float: 'left'}}> <strong>Authinfo:</strong> {data.trade.newAuthinfo}</span></Col>
                    </Row>
                    <Row className={'domain-status-box-date'}>
                        <Col span={8}/>
                        <Col span={8}/>
                        <Col span={8} pull={2}>
                            <div><span
                                style={{float: 'left'}}> <strong>Registrante:</strong> <Link
                                to={routes.CONTACT_INFO.url + "/" + data.trade.newRegistrant}>{data.trade.newRegistrant}</Link></span>
                            </div>
                        </Col>

                    </Row>
                </div>
            </div>
        )
    } else {
        return <span/>
    }
}

export default injectIntl(TradeData)