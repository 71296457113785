import React from 'react';
import "./EppMessagesType.css"
import BaseMessage from "./BaseMessage";

const ChangeStatusMessage = (props) => {

    function Detail({data}) {
        if (data) {
            return (
                <div className={'typedLayout-container'}>
                    {data &&
                    <div>
                        <div className={'typedLayout-data'}>
                            {data['name'] &&
                            <div className={'typedLayout-row'}>
                                <span className={'label'}>Dominio:</span>
                                <span className={'content'}> {data['name']}</span>
                            </div>
                            }
                            {data['statuses'] &&
                            <div className={'typedLayout-row'}>
                                <span className={'label'}>Stato:</span>
                                <span className={'content'}>{props.detail.data['statuses'].join('/')}</span>
                            </div>
                            }
                        </div>
                    </div>}
                </div>
            )
        }
        else {
            return <span/>
        }
    }

    return (
        <BaseMessage
            detail={props.detail}
            component={<Detail data={props.detail.data}/>}
        />
    );
};

ChangeStatusMessage.propTypes = {};
ChangeStatusMessage.defaultProps = {};

export default ChangeStatusMessage;
