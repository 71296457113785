import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import DomainList from "../components/Pages/DomainList/DomainList";


import React, {Component} from 'react';
import {listDomainOp} from "../operations";
import * as routes from "../../routes";

class DomainListContainer extends Component {

    componentWillMount() {
        this.props.onLoadList(this.props.location.search.slice(1));
    }

    resetPageNumber() {
        let searchParams = new URLSearchParams(this.props.location.search.slice(1));
        searchParams.set('page', 1);
    }

    handleOnChangePage(page, size) {
        let searchParams = new URLSearchParams(this.props.location.search.slice(1));
        // console.log(" page,size", page, size)
        // console.log(" searchParams", searchParams)

        searchParams.set('page', page);
        searchParams.set('size', size);

        this.props.history.push({
            pathname: routes.DOMAIN_LIST.url,
            search: '?' + searchParams.toString()
        })
    }

    handleFilterSubmit(params) {
        this.props.history.push({
            pathname: routes.DOMAIN_LIST.url,
            search: `?${params}`
        })
    }

    handleResetAllFilters() {
        this.resetPageNumber()
        this.props.history.push({
            pathname: routes.DOMAIN_LIST.url,
            search: ''
        })
    }

    handleRemoveFilter(filterKey) {
        if (filterKey) {
            let searchParams = new URLSearchParams(this.props.location.search.slice(1));
            searchParams.delete(filterKey)
            this.resetPageNumber()
            this.props.history.push({
                pathname: routes.DOMAIN_LIST.url,
                search: '?' + searchParams.toString()
            })
        }
    }

    render() {
        return (
            <DomainList
                domainsData={this.props.domainsList}
                onLoadList={this.props.onLoadList}
                onChangePage={this.handleOnChangePage.bind(this)}
                onCloseFilter={this.handleRemoveFilter.bind(this)}
                onResetFilters={this.handleResetAllFilters.bind(this)}
            />
        );
    }
}

DomainListContainer.propTypes = {};
DomainListContainer.defaultProps = {};

const mapDispatchToProps = (dispatch) => {
    return {
        onLoadList: (searchParams) => {
            dispatch(listDomainOp(searchParams))
        },
    };
};

const mapStateToProps = (state) => {
    return {
        domainsList: state.domain.listReducer,
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DomainListContainer));