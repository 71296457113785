import axios from "axios/index";
import * as api from "./constants/api";

function appendAuthorizationHeader() {
    return {headers: {'Authorization': localStorage.getItem("token")}}
}

export const loadEppConfiguration = () => {
    return axios.get(`${api.EPP_CONFIGURATION}`, appendAuthorizationHeader());
}

export const updateEppConfiguration = (username, serverURI, deletedServerURI, password, language, dnsSecEnabled) => {
    return axios.put(`${api.ADMIN_EPP}`, {
        username: username,
        // serverURI:serverURI,
        // deletedServerURI:deletedServerURI,
        password: password,
        language: language,
        dnsSecEnabled: dnsSecEnabled
    }, appendAuthorizationHeader())
}
