import React, {Component} from 'react';
import * as routes from "../../../../routes";
import ContactListTable from "./ContactListTable";
import {injectIntl} from "react-intl";
import ContactListFiltersBar from "./ContactListFiltersBar";
import Error from "app/commons/components/FeedBack/Error/Error";
import EppOperationView from "app/commons/components/Pages/EppOperationView";

class ContactList extends Component {

    componentWillMount() {
        this.props.onLoadList(this.props.location.search.slice(1));
    }

    resetPageNumber() {
        let searchParams = new URLSearchParams(this.props.location.search.slice(1));
        searchParams.set('page', 1);
    }

    handleFilterSubmit(params) {
        this.resetPageNumber()
        this.props.history.push({
            pathname: routes.CONTACT_LIST.url,
            search: `?${params}`
        })
    }

    handleResetAllFilters() {
        this.resetPageNumber()
        this.props.history.push({
            pathname: routes.CONTACT_LIST.url,
            search: ''
        })
    }

    handleRemoveFilter(filterKey) {

        if (filterKey) {

            let searchParams = new URLSearchParams(this.props.location.search.slice(1));
            searchParams.delete(filterKey)
            searchParams.set('page', 1)
            this.props.history.push({
                pathname: routes.CONTACT_LIST.url,
                search: '?' + searchParams.toString()
            })
        }
    }

    onChangePage(page, size) {
        let searchParams = new URLSearchParams(this.props.location.search.slice(1));
        searchParams.set('page', page);
        searchParams.set('size', size);

        this.props.history.push({
            pathname: routes.CONTACT_LIST.url,
            search: '?' + searchParams.toString()
        })
    }

    render() {
        const {intl, contactsList} = this.props;
        const {formatMessage} = intl;
        const {data, error, loading} = contactsList;

        return (
            <EppOperationView
                title={formatMessage({id: 'contact.list.title'})}
                operationForm={<ContactListFiltersBar/>}
                loading={loading}
            >
                {(!loading && !error) &&
                <ContactListTable data={data} onChangePage={this.onChangePage.bind(this)}/>
                }
                <Error error={error}/>
            </EppOperationView>

        );
    }
}

ContactList.propTypes = {};
ContactList.defaultProps = {};

export default injectIntl(ContactList);
