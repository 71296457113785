import {Form, Input, Radio, Switch} from 'antd';
import React from 'react';

import {checkPassword, checkPasswordLen} from '../../../../commons/utils/validators/FormValidator';
import TagNoCursor from "../../../../commons/components/DataDisplay/TagNoCursor/TagNoHover";
import EppProfileInfo from "./EppProfileInfo/EppProfileInfo";

const FormItem = Form.Item;

export const EppUserFormItem = (props) => {
    const {form, intl, initialValue, formItemLayout} = props;
    const {formatMessage} = intl;

    return (
        <FormItem
            {...formItemLayout}
            hasFeedback
            label={formatMessage({id: 'admin.epp.username'})}
            style={{textAlign: 'left'}}
        >
            {form.getFieldDecorator('username',
                {
                    initialValue: initialValue,
                    type: 'string',
                    rules: [
                        {
                            required: true,
                            //TODO controllare la regularExpression, non funziona
                            // pattern: /[A-Z^\b]{2,14}(^[A-Za-z])+\-REG$/,
                            pattern: /[A-Z]+-REG$/,
                            message: formatMessage({id: 'userNameMessage'})

                        }, {
                            min: 4,
                            max: 16,
                            message: formatMessage({id: 'notCorrectLength'})
                        }
                    ],
                })(
                <Input placeholder={formatMessage({id: "admin.epp.username.placeholder"})}/>
            )}
        </FormItem>
    )
}

export const EppPasswordFormItem = (props) => {
    const {form, intl, formItemLayout} = props;
    const {formatMessage} = intl;
    const MIN_PASSWORD_LEN = 6;
    const MAX_PASSWORD_LEN = 16;

    const passwordLengthChecker = (rule, value, callback) => {
        console.log(" insied",value)
        if (!value) {
            callback(new Error('Campo obbligatorio'))
        } else {
            form.validateFields(['confirmEppPassword'], {force: true});
            callback(checkPasswordLen(value, MIN_PASSWORD_LEN, MAX_PASSWORD_LEN, intl));
        }
    }

    const passwordChecker = (rule, value, callback) => {
        const password = form.getFieldValue('password');

        if (password && password.length > 0) {
            callback(checkPassword(password, value, intl));
        }
        else
            callback()
    }

    return (
        <div>
            <FormItem
                {...formItemLayout}
                hasFeedback
                label={formatMessage({id: 'admin.epp.password'})}
                style={{textAlign: 'left'}}
                required
            >
                {form.getFieldDecorator('password',
                    {
                        rules: [{},
                            {validator: passwordLengthChecker},
                        ]
                    })(
                    <Input
                        placeholder={formatMessage({id: 'admin.epp.password.placeholder'})}
                        type="password"
                    />)
                }
            </FormItem>
            <FormItem
                {...formItemLayout}
                hasFeedback
                label={formatMessage({id: 'admin.epp.password.confirm'})}
                style={{textAlign: 'left'}}
                required
            >
                {form.getFieldDecorator('confirmEppPassword',
                    {
                        rules: [{}, {validator: passwordChecker}],
                    })(<Input placeholder={formatMessage({id: 'admin.epp.password.confirm.placeholder'})}
                              type="password"/>)}
            </FormItem>
        </div>
    )
}


export const DNSSecFormItem = (props) => {
    const {form, intl, formItemLayout, initialValue} = props;
    const {formatMessage} = intl;
    return (
        <FormItem
            label={formatMessage({id: 'admin.epp.dnssec'})}
            {...formItemLayout}
            style={{textAlign: 'left'}}
        >
            {form.getFieldDecorator('dnsSecEnabled', {
                valuePropName: 'checked',
                initialValue: initialValue
            })(
                <Switch
                    checkedChildren={formatMessage({id: 'admin.epp.dnssec.enabled'})}
                    unCheckedChildren={formatMessage({id: 'admin.epp.dnssec.disabled'})}
                    onChange={props.onChange}
                />
            )}
        </FormItem>
    )
}

export const EPPLanguageFormItem = (props) => {
    const {form, intl, formItemLayout, initialValue} = props;
    const {formatMessage} = intl;
    return (
        <FormItem
            {...formItemLayout}
            label={formatMessage({id: 'admin.epp.language'})}
            style={{textAlign: 'left'}}
        >
            {form.getFieldDecorator('language', {
                initialValue: initialValue || 'it',
                rules: [{required: true, message: formatMessage({id: 'notEmptyField'})}],
            })(
                <Radio.Group>
                    <Radio.Button value="it">Italiano</Radio.Button>
                    <Radio.Button value="en">English</Radio.Button>
                </Radio.Group>
            )
            }
        </FormItem>
    )
}

export const EppFormLabels = (props) => {
    const {formItemLayout} = props;
    const {dnsSecEnabled, language, username} = props;
    const {formatMessage} = props.intl;

    return (<div>
        <EppProfileInfo
            formItemLayout={formItemLayout}
            {...props}
            profile={props.profile}
        />

        <FormItem
            {...formItemLayout}
            label={formatMessage({id: 'admin.epp.username'})}
            style={{textAlign: 'left'}}
        >
            <TagNoCursor> {username} </TagNoCursor>
        </FormItem>

        <FormItem
            {...formItemLayout}
            label={formatMessage({id: 'admin.epp.dnssec'})}
            style={{textAlign: 'left'}}
        >
            <TagNoCursor> {dnsSecEnabled ? 'Si' : 'No'} </TagNoCursor>
        </FormItem>

        <FormItem
            {...formItemLayout}
            label={formatMessage({id: 'admin.epp.language'})}
            style={{textAlign: 'left'}}
        >
            <TagNoCursor> {language} </TagNoCursor>
        </FormItem>
    </div>)
}

export const EppFormItems = (props) => {
    const {formItemLayout} = props;
    const {dnsSecEnabled, language, username} = props;

    return (
        <Form
            onChange={props.onChange.bind(this, props)}
        >
            <EppProfileInfo
                formItemLayout={formItemLayout}
                {...props}
                profile={props.profile}
            />

            <EppUserFormItem
                formItemLayout={formItemLayout}
                initialValue={username}
                {...props}
            />

            <EppPasswordFormItem
                formItemLayout={formItemLayout}
                {...props}
            />

            <DNSSecFormItem
                formItemLayout={formItemLayout}
                initialValue={dnsSecEnabled}
                {...props}
            />

            <EPPLanguageFormItem
                formItemLayout={formItemLayout}
                initialValue={language}
                {...props}
            />
        </Form>
    )
}