/* UI settings */
export const MIN_WIDTH_SIDER = 80;
export const MAX_WIDTH_SIDER = 200;

export const HEIGHT_HEADER = 64;


//Soglie per definire la categoria della date relativa all'expired date del dominio
export const EXPIRED_SIZE = 0;
export const EXPIRING_SIZE = -7;
export const ALERT_SIZE = -30;
export const INFO_SIZE = -60;