import React from 'react';
import {injectIntl} from "react-intl";

const MenuItemRow = (props) => {
    const {formatMessage} = props.intl;
    const {icon} = props
    const {label} = props
    const showIcon = icon ? <i className={`fal ${icon} filter-menu-icon`}/> : <span/>
    return (
        <div className='filter-menu-row'>
            {showIcon}
            <span className={`filter-menu-text`}>{formatMessage({id:label})}</span>
        </div>
    )
}

export default injectIntl(MenuItemRow);