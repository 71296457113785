import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import DomainCheck from "../components/Pages/DomainCheck/DomainCheck";
import * as routes from "../../routes";
import {checkDomainOp} from "../operations";
import {resetCheckDomain} from "../actions/checkActions";

class DomainCheckContainer extends React.Component {

    componentWillMount() {
        this.handleFetchData();
    }

    handleFetchData() {
        const {match, handleCheckDomain, onReset} = this.props;
        const {domain} = match.params;

        if (!domain) {
            onReset();
        } else if (domain) {
            handleCheckDomain(domain);
        }
    }

    handleSubmit = (domain) => (
        this.props.history.push({pathname: `${routes.DOMAIN_CHECK.url}/${domain}`})
    )

    handleCancelCheck = () => (
        this.props.history.push({pathname: `${routes.DOMAIN_CHECK.url}`})
    )

    render() {
        return (
            <DomainCheck
                checkDomainData={this.props.checkDomainData}
                onSubmitCheck={this.handleSubmit}
                onCancelCheck={this.handleCancelCheck}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        checkDomainData: state.domain.checkReducer
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleCheckDomain: (domain) => {
            dispatch(checkDomainOp(domain))
        },
        onReset: () => {
            dispatch(resetCheckDomain())
        },
    };
};

/* Inject auth state and dispatch() into props */
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DomainCheckContainer));

