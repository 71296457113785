// ** LIST **

import * as api from "../api";
import * as types from "../actionTypes";

export function domainList(searchParams) {
    const response = api.registryDomains(searchParams)
    return {
        type: types.DOMAIN_LIST,
        payload: response
    }
}

export function domainListStart() {
    return {
        type: types.DOMAIN_LIST_START,
    }
}

export function domainListSuccess(response) {
    return {
        type: types.DOMAIN_LIST_RESULT,
        payload: response
    }
}

export function domainListFailure(error) {
    return {
        type: types.DOMAIN_LIST_ERROR,
        payload: error
    }
}
