import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router';

import {Button, Card, Col, notification, Row} from 'antd';

import PageTitle from '../../../../commons/components/Layout/PageTitle/PageTitle'
import connect from '../../../../commons/utils/api-connector';
import {changedPostStatus} from "../ConfigurationUtil";
import Error from "../../../../commons/components/FeedBack/Error/Error";
import EppPasswordForm from "./EppPasswordForm";
import {isEmpty} from "../../../../commons/utils/validators/FormValidator";
import {EPP_PASSWORD} from "../../../constants/api";
import Spinner from "app/commons/components/FeedBack/Spinner/Spinner";

class EppPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buttonDisabled: true,
            buttonLoading: false,
            isUpdating: false,
            error: null,
            savingData: null
        }
    }


    convertValues = (values) => {
        let newValues = {};

        if (values['password'] !== null) {
            newValues['password'] = values['password'];
        }
        return newValues;
    }

    componentWillReceiveProps(nextProps) {
        const {postResponse} = nextProps;
        const {formatMessage} = nextProps.intl;

        let prevPostResponse = this.props.postResponse;

        if (postResponse) {
            if (changedPostStatus(postResponse, prevPostResponse)) {

                if (postResponse.pending) {
                    this.setState({buttonLoading: true, buttonDisabled: true})
                } else if (postResponse.rejected) {

                    let status = postResponse.meta.response.status;
                    let message = (status !== 500 ? postResponse.reason.message : postResponse.response.statusText);

                    this.setState({error: {status: status, message: message}});
                    this.setState({buttonDisabled: true, buttonLoading: false})
                } else if (postResponse.fulfilled) {
                    notification.success({
                        duration: 10,
                        message: formatMessage({id: 'admin.epp.saveConf.info.message'}),
                        description: formatMessage({id: 'admin.epp.saveConf.success.description'})
                    })
                    this.setState({buttonDisabled: true, buttonLoading: false});
                }
            }
        }

    }

    handleResetOnClick = () => {
        this.setState({error: null});
        this.ref.getForm().resetFields()
        this.setState({buttonDisabled: true})
    }

    handleOnClick = () => {
        const {formatMessage} = this.props.intl;

        this.setState({error: null});
        this.ref.getForm().validateFields(
            (err, values) => {
                if (!err) {

                    let configuration = this.convertValues(values);

                    if (!isEmpty(configuration)) {

                        this.props.saveConfiguration(this.ref.getForm(), JSON.stringify(configuration), this.state);

                        this.setState({savingData: values});
                    } else {

                        this.setState({buttonDisabled: true});
                        notification.warning({
                            message: formatMessage({id: 'admin.epp.saveConf.info.message'}),
                            description: 'Nessuna modifica'
                        })

                    }
                    //this.setState({buttonDisabled:true, buttonLoading:true});
                    this.ref.getForm().resetFields();
                } else {
                    // Gestione dell'errore
                    console.log("Errore..")
                }
            }
        )

    }


    render() {
        const {formatMessage} = this.props.intl;
        const {buttonDisabled, buttonLoading} = this.state;

        const component = (
            <span>
                <EppPasswordForm ref={(instance) => {
                    this.ref = instance
                }} onFieldsChange={(field) => {
                    this.setState({error: null})
                    this.setState({buttonDisabled: false});
                }}  {...this.props}/>
            </span>
        );

        return (
            <div>
                <PageTitle title={formatMessage({id: "admin.epp.update.password.title"})}/>
                <Card>
                    {
                        this.state.error &&
                        <div style={{margin: '20px'}}>
                            <Error error={this.state.error}/>
                        </div>
                    }
                    {component}
                    <Row>
                        <Col xs={{span: 24, offset: 5}}
                             sm={{span: 12, offset: 5}}>
                            <Button
                                disabled={buttonDisabled}
                                onClick={this.handleResetOnClick}
                                style={{marginRight: '25px'}}
                            >
                                {formatMessage({id: 'cancel'})}
                            </Button>
                            <Button
                                type="primary"
                                disabled={buttonDisabled}
                                loading={buttonLoading}
                                onClick={this.handleOnClick}>
                                {formatMessage({id: 'admin.epp.update.password'})}
                            </Button>
                        </Col>
                    </Row>

                    {
                        this.props.postResponse &&
                        this.props.postResponse.pending &&
                        <Spinner loading={true}/>
                    }
                </Card>
            </div>
        )
    }
}

export default connect(props => ({
    saveConfiguration: (form, configuration, state) => {
        return ({
            postResponse: {
                url: `${EPP_PASSWORD}`,
                method: 'POST',
                body: configuration
            }
        })
    }
}))(withRouter(injectIntl(EppPassword)))