import React, {Component} from 'react';
import {injectIntl} from 'react-intl'
import Wizard from './Wizard';
import PropTypes from "prop-types";

class DefaultWizard extends Component {
    constructor(props) {
        super(props);
        this.fields = this.props.fields;
        this.state = {
            current: props.current || 0,
            steps: props.steps,
        }
    }

    next = () => {
        const {steps, current} = this.state;

        if (!steps[current].readOnly && steps[current].stepActions) {
            steps[current].stepActions[0](this, 0)
        } else {
            this.setState({current: current + 1})
        }
    }

    prev = () => {
        const current = this.state.current - 1;
        // se siamo nella condizione di
        if (current === 0) {
            let steps = this.state.steps;
            steps[0].readOnly = true;
            this.setState({steps: steps});
        }

        this.setState({current: current});
    }

    render() {

        return (
            <Wizard fields={this.fields}
                    next={this.next}
                    prev={this.prev}
                    current={this.state.current}
                    steps={this.state.steps}
                    onChange={(fields) => {
                        this.fields = {...this.fields, ...fields};
                    }}
                    onCancel={this.props.onCancel}
                    onDone={()=>this.props.onDone(this.fields)}
            />
        )

    }
}

export default injectIntl(DefaultWizard);

DefaultWizard.propTypes = {
    fields: PropTypes.object.isRequired,
    current: PropTypes.number,
    steps: PropTypes.array.isRequired,
    onCancel: PropTypes.func,
    onDone: PropTypes.func.isRequired
}
