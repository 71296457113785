export const checkPassword = (value, checkValue, intl) => {

  //const password = this.props.form.getFieldValue('password');
  const { formatMessage } = intl;

  if (value !== checkValue) {
    return (new Error(formatMessage({id:'confirmEppPasswordMessage'})));
  } else {
    return;
  }
}

export const checkPasswordLen = (value, min, max, intl) => {
  const {formatMessage} = intl;
  let condition = (value && value.length >=min && value.length <= max);
  if (condition) {
    return;
  } else {
    return (new Error(formatMessage({id:'passwordLength'}, {len:value.length, min:min,max:max})));
  }
}

export const isEmpty = (object) => {
  return Object.keys(object).length === 0
}
