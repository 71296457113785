import {checkTelephoneNumber} from "app/commons/utils/form";
import {Form, Input} from "antd";
import React from "react";

export const ContactTelephoneFormItem = ({formItemLayout, intl, form, items}) => {

    return <Form.Item
        {...formItemLayout}
        label={intl.formatMessage({id: 'contact.detail.form.telephone'})}
        hasFeedback
    >
        {form.getFieldDecorator('voice', {
            initialValue: items && items.voice,
            rules: [{
                required: true,
                message: intl.formatMessage({id: 'contact.detail.form.telephone.message'})
            }, {
                //Controlla se il numero di telefono rispetta i vincoli
                validator: (rule, value, callback) => checkTelephoneNumber(rule, value, callback, intl.formatMessage)
            }],
        })(
            <Input placeholder={intl.formatMessage({id: 'contact.detail.form.telephone.placeholder'})}/>
        )}
    </Form.Item>
}

export default ContactTelephoneFormItem