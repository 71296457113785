import * as types from "./actionTypes";

function updateIntl(lang) {
    return {
        type: types.UPDATE_INTL,
        payload: lang
    }
}

export default {
    updateIntl
}