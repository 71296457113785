import React from 'react';
import PropTypes from 'prop-types';
import {Form} from 'antd';
import TagNoCursor from "../../../../../commons/components/DataDisplay/TagNoCursor/TagNoHover";

const FormItem = Form.Item;

const EppProfileInfo = (props) => {
    const {profile} = props
    const {formItemLayout} = props;
    const {formatMessage} = props.intl;
    return (
        <div>
            {profile && profile.name &&
            <FormItem
                {...formItemLayout}
                label={formatMessage({id: 'profile'})}
                style={{textAlign: 'left'}}
            >
                <TagNoCursor>{props.profile.name} </TagNoCursor>
            </FormItem>
            }
            {profile && profile.eppServerUri &&
            <FormItem
                {...formItemLayout}
                label={formatMessage({id: 'eppServerLabel'})}
                style={{textAlign: 'left'}}
            >
                <TagNoCursor> {props.profile.eppServerUri} </TagNoCursor>
            </FormItem>}

            {profile && profile.eppDeletedServerUri &&
            <FormItem
                {...formItemLayout}
                label={formatMessage({id: 'eppServerDeletedLabel'})}
                style={{textAlign: 'left'}}
            ><TagNoCursor>{props.profile.eppDeletedServerUri}</TagNoCursor>
            </FormItem>
            }
        </div>
    );
};

EppProfileInfo.propTypes = {
    formItemLayout: PropTypes.object,
    profile: PropTypes.object,

};
EppProfileInfo.defaultProps = {};

export default EppProfileInfo;
