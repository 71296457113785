import * as types from "../actionTypes";

const INITIAL_STATE = {
    loading: false, data: null, error: null, message: null
}
const deleteReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        //DELETE DOMAIN
        case types.DELETE_DOMAIN : {
            return {
                ...state,
                loading: true, error: null, message: null
            }
        }
        case types.DELETE_DOMAIN_START : {
            return {
                ...state,
                loading: true, error: null, message: null
            }
        }
        case types.DELETE_DOMAIN_SUCCESS : {
            return {
                ...state,
                data: action.payload.data, error: null, loading: false,
                message: 'Dominio eliminato con successo'
            }
        }
        case types.DELETE_DOMAIN_ERROR : {
            return {
                ...state,
                loading: false, data: null, error: action.payload, message: null
            }
        }
        case types.RESET_DELETE_DOMAIN : {
            return {
                ...state,
                loading: false, data: null, error: null, message: null
            }

        }


        default:
            return state
    }
}

export default deleteReducer;