import React from 'react';
import PropTypes from "prop-types";

import styled from 'styled-components';

const StyledLabel = styled.span`
    font-weight: bold;
    display: inline-block;
    width: 200px;
    text-align: right;
    vertical-align: top;
    word-wrap: break-word;
    @media (max-width: 900px) {
      text-align: left;
    }
`;

const StyledContent = styled.span`
    display: inline-block;
    margin-left: 5px;
    text-align: left;
    word-wrap: break-word;
    @media (max-width: 900px) {
    margin-left: 0px;
    }
`;

const CardDetailRow = ({label, content}) => (
    <div>
        <StyledLabel>{label}:</StyledLabel>
        <StyledContent>{content}</StyledContent>
    </div>
)

CardDetailRow.propTypes = {
    label: PropTypes.string,
    content: PropTypes.any,
};

CardDetailRow.defaultProps = {};

export default CardDetailRow;
