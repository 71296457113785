import React from 'react';
import PropTypes from 'prop-types'
import "./DomainName.css"
import {Icon, Tooltip} from "antd";
import {injectIntl} from "react-intl";

const DomainName = (props) => {
    return (
        <span className='domain-name'>{props.name}
            {props.signed &&
            <Tooltip title={props.intl.formatMessage({id: 'domain.signed'})}>
                <Icon className='icon-domain-signed' type="lock"/>
            </Tooltip>
            }
        </span>
    );
};

DomainName.propTypes = {
    name: PropTypes.string,
    signed: PropTypes.bool
};

DomainName.defaultProps = {
    signed: false
};

export default injectIntl(DomainName);
