import React from 'react';
import PropTypes from 'prop-types';
import {Form} from "antd";
import get from 'lodash/get';
import {formItemLayout as fIL, SubformItemLayout} from "app/contacts/components/BaseContactForm/const";
import styled from "styled-components";
import RegEntityTypeFormItem from "app/contacts/components/BaseContactForm/FormITems/RegEntityTypeFormItem";
import CountryFormItemContainer from "app/countries/components/CountryFormItemContainer";
import RegCodeFormItem from "app/contacts/components/BaseContactForm/FormITems/RegCodeFormItem";
import EduSchoolCodeFormItem from "app/contacts/components/BaseContactForm/FormITems/EduSchoolCodeFormItem";
import GovIpaCodeFormItem from "app/contacts/components/BaseContactForm/FormITems/GovIpaCodeFormItem";
import GovUOCodeFormItem from "app/contacts/components/BaseContactForm/FormITems/GovUOCodeFormItem";

const RegistrantFormItem = (props) => {
    const {intl, entityType, disabled, entityTypeOnChange, nationalityCodeOnChange, nationalityCode, countries, natInitialValue, natDisabled, isEdu, isGov, items} = props;

    const initialValue = isGov !== undefined && isGov ? 5 : items && items.registrant && items.registrant['entityType'];
    const nationalityInitialValue = isGov !== undefined && isGov ? 'IT' : natInitialValue;
    const initialIPA = get(items, 'registrant.gov.ipaCode');
    const initialUO = get(items, 'registrant.gov.uoCode');

    return (
        <Form.Item
            {...fIL}
            label={intl.formatMessage({id: 'contact.detail.form.registrant'})}
            required
        >


            <SubFormItemBox>

                <RegEntityTypeFormItem
                    {...props}
                    formItemLayout={SubformItemLayout}
                    initialValue={initialValue}
                    onChange={entityTypeOnChange}
                    disabled={isGov}
                />

                <CountryFormItemContainer
                    {...props}
                    formItemLayout={SubformItemLayout}
                    data={countries.list.filter(item => item.enabled)} //filtrato per nationalityCode true
                    label={intl.formatMessage({id: 'contact.detail.form.registrant.nationalityCode'})}
                    fieldDecorator={'registrant.nationalityCode'}
                    // initialValue={this.handleCountryNationalityIntialValue()}
                    initialValue={nationalityInitialValue}
                    disabled={disabled || natDisabled}
                    placeholder={intl.formatMessage({id: 'contact.detail.form.registrant.placeholder.countryCode'})}
                    required={true}
                    nationalityCodeFilter={false}
                    onChange={nationalityCodeOnChange}
                />

                <RegCodeFormItem
                    {...props}
                    formItemLayout={SubformItemLayout}
                    entityType={entityType}
                    nationalityCode={nationalityCode}
                    initialValue={items && items.registrant && items.registrant['regCode']}
                    disabled={disabled || natDisabled}
                />

                {
                    isEdu &&
                    <EduSchoolCodeFormItem
                        {...props}
                        formItemLayout={SubformItemLayout}
                        initialValue={items && items.registrant && items.registrant['schoolCode']}
                        disabled={items && items.registrant && items.registrant['schoolCode']}
                    />

                }
                {
                    isGov &&
                        <GovIpaCodeFormItem
                            {...props}
                            formItemLayout={SubformItemLayout}
                            initialValue={initialIPA}
                            disabled={disabled}
                            required={true}
                        />
                }
                {
                    isGov &&
                    <GovUOCodeFormItem
                        {...props}
                        formItemLayout={SubformItemLayout}
                        initialValue={initialUO}
                        disabled={disabled}
                    />
                }
            </SubFormItemBox>
        </Form.Item>);
};

RegistrantFormItem.propTypes = {
    intl: PropTypes.object,
    items: PropTypes.object,
    natDisabled: PropTypes.bool, //Nationality diasbilità
    isEdu: PropTypes.bool,
    isGov: PropTypes.bool,
    entityType: PropTypes.number,
    entityTypeOnChange: PropTypes.func,
    nationalityCode: PropTypes.string,
    nationalityCodeOnChange: PropTypes.func,
    natInitialValue: PropTypes.string, //Nationonalty valore iniziale,
    disabled: PropTypes.bool,
};
RegistrantFormItem.defaultProps = {
    disabled: false
};

export default RegistrantFormItem;

export const SubFormItemBox = styled.div`
    border: 1px solid #ccc;
    padding: 20px;
    @media (max-width: 1400px) {
      .ant-row{
          .ant-form-item-label {
              text-align: left!important;
              
              //background-color: red;
          }
      } 
    }

`;
