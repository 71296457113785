import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {withRouter} from 'react-router';

import DomainName from './DomainName';
import DomainContacts from './DomainContacts';
import DomainHosts from './DomainHosts';
import DNSSec from './DNSSec';
import Summary from './Summary';


import {Card, notification} from 'antd';
import {newDomain} from "../../../api";
import Wizard from "../../../../commons/components/Navigation/Wizard/Wizard";
import PageTitle from "../../../../commons/components/Layout/PageTitle/PageTitle";
import Splash from "../../../../commons/components/FeedBack/Splash/Splash";
import {digestTypes} from "app/domains/constants/common";
import {checkRegistrantConsentForPublish} from "app/domains/components/Pages/ConsentForPublishChecker";
import {GOV_SUFFIX} from "../../../../commons/constants/constants";

const wizardSteps = [

    {
        id: 'domainName',
        title: <FormattedMessage id='domainCreation.domain.title'/>,
        description: <FormattedMessage id="domainCreation.domain.description"/>,
        readOnly: false,
        showHeader: false,
        content: DomainName
    },


    {
        id: 'contacts',
        title: <FormattedMessage id="domainCreation.contacts.title"/>,
        description: <FormattedMessage id="domainCreation.contacts.description"/>,
        readOnly: false,
        showHeader: true,
        content: DomainContacts
    },

    {
        id: 'dnsConfiguration',
        title: <FormattedMessage id="domainCreation.dns.title"/>,
        description: <FormattedMessage id="domainCreation.dns.description"/>,
        readOnly: false,
        showHeader: true,
        content: DomainHosts
    },

];

let dnssecStep = {
    id: 'dnssecSettings',
    title: <FormattedMessage id="domainCreation.domain.dnssecTitle"/>,
    description: <FormattedMessage id="domainCreation.domain.dnssecDescription"/>,
    readOnly: false,
    content: DNSSec
}

let summary = {
    id: 'summary',
    title: 'Riepilogo',
    description: 'Riepilogo impostazioni del nuovo dominio',
    readOnly: true,
    content: Summary,
    error: (fields, props) => {
        if (fields[`registrantContactId-Full`]) {
            //Controllo se il registrant (con enttyType != 1) possiede il consentForPublish a true nel caso segnalo errore
            let checkedRegistrantError = checkRegistrantConsentForPublish(fields[`registrantContactId-Full`]);
            return checkedRegistrantError;
        }
    }
}

class DomainWizard extends Component {

    constructor(props) {
        super(props);

        this.fields = {};
        this.state = {
            steps: wizardSteps,
            current: 0,
            isLoadingEppConfig: true
        }
    }

    next = () => {
        const {steps, current} = this.state;

        if (!steps[current].readOnly && steps[current].stepActions) {
            steps[current].stepActions[0](this, 0)
        } else {
            this.setState({current: current + 1})
        }
    }

    prev = () => {
        const current = this.state.current - 1;
        // se siamo nella condizione di
        if (current === 0) {
            let steps = this.state.steps;
            steps[0].readOnly = true;
            this.setState({steps: steps});
        }

        this.setState({current: current})
    }


    errorMessage = (error) => {
        let reasonStr = "";
        if (error.response && error.response.data) {
            const {reason, wrongValue} = error.response.data;
            const theReason = reason !== undefined ? reason.message : undefined;
            reasonStr += (theReason !== undefined ? theReason : 'Errore ');
            reasonStr += (wrongValue ? ", " + wrongValue.element + ": " + wrongValue.value : "");
        } else {
            reasonStr = error;
        }


        return reasonStr;
    }


    convertFields = (fields) => {
        let res = {};
        res['name'] = fields.domainName;
        res['authinfo'] = fields.authInfo;
        res['hosts'] = [];

        for (let k = 0; k < fields.keys; k++) {
            let host = {};
            host['name'] = fields[`names-${k}`];


            let addresses = [];

            if (fields[`ipv4-${k}`]) {
                let address = {};
                address['address'] = fields[`ipv4-${k}`];
                address['type'] = 'v4';
                //  console.log("address: ", fields[`ipv4-${k}`], " type: v4");
                addresses.push(address);
            }

            if (fields[`ipv6-${k}`]) {
                let address = {};
                address['address'] = fields[`ipv6-${k}`];
                address['type'] = 'v6';
                //console.log("address: ", fields[`ipv6-${k}`], " type: v6");
                addresses.push(address);
            }

            if (addresses.length > 0) host['addresses'] = addresses;

            res['hosts'].push(host);

        }

        res['registrantId'] = fields['registrantContactId'];
        res['adminId'] = fields['adminContactId'];
        if (fields.domainName.endsWith(GOV_SUFFIX)) {
            res['govValidationCode'] = fields['govValidationCode']
        }
        let techsId = [];

        fields.techIds.forEach((tech, index) => {
            if (fields[`techId-${tech}`]) {
                techsId.push(fields[`techId-${tech}`])
            }
        })


        if (techsId.length > 0)
            res['techsId'] = techsId;

        if (fields.dsrecords) {
            res['dsRecords'] = [];

            fields.dsrecords.forEach((ds) => {
                let digestT = fields[`digest-${ds}Type`];
                res['dsRecords'].push(
                    {
                        keyTag: fields[`keyTag-${ds}`],
                        alg: fields[`algorithm-${ds}`],
                        digestType: digestTypes[digestT].id,
                        digest: fields[`digest-${ds}`]
                    }
                )
            })

        }

        return res;
    }

    componentWillMount() {
        // Devo controllare se la configurazione epp prevede di impostare dnssec

        let currentSteps = [];

        wizardSteps.forEach((step) => {
            currentSteps.push(step);
        })

        if (currentSteps.length === 3) {
            if (this.props.dnssec) {
                currentSteps.push(dnssecStep);
            }

            currentSteps.push(summary);
            this.setState({steps: currentSteps});
            this.setState({isLoadingEppConfig: false});

        } else {
            this.setState({isLoadingEppConfig: false})
        }

    }

    componentWillUnmount() {
        this.setState({steps: wizardSteps})
    }


    render() {
        const {formatMessage} = this.props.intl;
        const {isLoadingEppConfig} = this.state;

        return (
            <div>
                <PageTitle
                    title={formatMessage({id: "domain.new.wizard.title"})}
                />
                {(isLoadingEppConfig && <Splash message={'loadingConfiguration'}/>) ||
                (!isLoadingEppConfig &&
                    <Card>
                        <Wizard fields={this.fields} steps={this.state.steps} current={this.state.current}
                                next={this.next} prev={this.prev}
                                onDone={() => {

                                    let converted = this.convertFields(this.fields);

                                    newDomain(converted)
                                        .then((result) => {
                                            notification.success({
                                                message: formatMessage({id: 'domainCreation.result.success.message'}),
                                                description: formatMessage({id: 'domainCreation.result.success.description'}, {name: this.fields.domainName}),
                                            })


                                            this.props.history.push(`/domain/info/${this.fields.domainName}`);

                                        })
                                        .catch((error) => {
                                            notification.error({
                                                message: formatMessage({id: 'domainCreation.result.error.message'}),
                                                description: this.errorMessage(error)
                                            })
                                            console.log("Error", error);

                                        })

                                }}

                                onChange={(fields) => {
                                    //console.log("Fields da salvare: ", fields);
                                    this.fields = {...this.fields, ...fields};
                                    //console.log("Change Fields: ", this.fields, JSON.stringify(this.fields));
                                }}
                        />
                    </Card>
                )}
            </div>
        )
    }

}


function mapStateToProps(state) {

    return {
        isAuthenticated: state.authReducer.authenticated,
        serverURI: state.confReducer.serverURI,
        deletedServerURI: state.confReducer.deletedServerURI,
        username: state.confReducer.username,
        password: state.confReducer.password,
        language: state.confReducer.language,
        loadingConfiguration: state.confReducer.loadingConfiguration,
        dnssec: state.confReducer.dnsSecEnabled
    };
}

export default withRouter(connect(mapStateToProps)(injectIntl(DomainWizard)));


//export default withRouter(injectIntl(DomainWizard));
