import ContactSelector from "app/contacts/components/ContactForm/ContactSelector";
import DetailPane from "app/commons/components/DataDisplay/DetailPane/DetailPane";
import {Button} from "antd";
import React from "react";
import styled from "styled-components";
import PropTypes from 'prop-types'
import {injectIntl} from "react-intl";

const StyledDetail = styled.div`margin-top: 0px!important`;
const MainDiv = styled.div`display: flex;justify-content: center;flex-wrap: wrap`;
const SubMainDiv = styled.div`border:solid 1px white;padding: 20px;width: 1200px;`;
const ButtonsDiv = styled.div`border:solid 1px white;padding: 20px;width:100%;text-align: center`;
const StyledButton = styled(Button)`margin:5px`;

const ContactFormWrapped = (props) => {
    const {contactType, isNewContact, handleContactTypeOnChange, children, dirty, form, handleSubmit, handleCancelCreate, intl} = props;
    return (
        <MainDiv>
            <SubMainDiv>
                {
                    isNewContact && !contactType &&
                    <ContactSelector
                        onChange={handleContactTypeOnChange}
                        value={contactType}
                    />
                }
                {
                    contactType &&
                    <DetailPane>
                        <StyledDetail>
                            {children}
                        </StyledDetail>
                    </DetailPane>
                }
            </SubMainDiv>
            {
                form && contactType &&
                <ButtonsDiv>
                    <StyledButton
                        onClick={handleCancelCreate}>
                        {intl.formatMessage({id: 'contact.detail.form.clear'})}
                    </StyledButton>
                    <StyledButton
                        onClick={handleSubmit} type="primary"
                        disabled={!dirty}>
                        {intl.formatMessage({id: 'contact.detail.form.save'})}
                    </StyledButton>
                </ButtonsDiv>
            }
        </MainDiv>
    );
}

export const ContactForm = injectIntl(ContactFormWrapped)

ContactForm.propTypes = {
    isNewContact: PropTypes.bool,
    contactType: PropTypes.string,
    handleContactTypeOnChange: PropTypes.func,
    children: PropTypes.object,
    form: PropTypes.object,
    handleSubmit: PropTypes.func,
    handleCancelCreate: PropTypes.func,
    intl: PropTypes.object
};
