import React from "react";
import {Card, Tabs} from "antd";
const TabPane = Tabs.TabPane;

export const TabView = ({listContents, cards}) => {
    return <Tabs>
        {cards.map((c, index) => (
            listContents.hasOwnProperty(c.key) &&
            <TabPane tab={c.title} key={index}>
                <Card bordered={false}>
                    {listContents[c.key]}
                </Card>
            </TabPane>)
        )}
    </Tabs>
}

