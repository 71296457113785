import React, {Component} from "react";
import {Layout} from "antd";
import HeaderBar from "../../../containers/HeaderContainer";
import styled from "styled-components";
import SiderMenu from "app/commons/components/Layout/SiderMenu/SiderMenu";
import PropTypes from 'prop-types';
import {HEIGHT_HEADER, MAX_WIDTH_SIDER, MIN_WIDTH_SIDER} from "app/commons/constants/settings";
import FooterBar from "app/commons/components/Layout/Footer/FooterBar";

const sidertrigger = 'ant-layout-sider-trigger';
const menuCollapsed = 'ant-menu-inline-collapsed';

const SiderBar = styled(Layout.Sider).attrs({
    sidertrigger
})`
  position: fixed !important;/* Fixed Sidebar (stay in place on scroll) */
  z-index: 1!important;/* Stay on top */
  top: ${HEIGHT_HEADER}px!important; /* Stay at the top */
  left: 0!important;
  margin-top:0px;
  background: #eaeaea!important;
  min-width: ${props => props.collapsed ? MIN_WIDTH_SIDER : MAX_WIDTH_SIDER}px!important  ;
  height: 100%;
 
  > .${sidertrigger} {
    
    position: inherit!important;
    background-color: #5fbcd3!important;
    height: 36px;
  }
  
  
  
  > .${menuCollapsed} {
  margin-bottom: 10px;
  text-align: center;
  padding-left: 10px!important;
  padding-right: 10px !important;
  height: 70px!important;
  color: black !important;
  font-size: 15pt !important;
  padding-top: 15px !important;
  }
`

const MainLayout = styled(Layout)`
    min-height: 100vh;
`;

const StyledContent = styled(Layout.Content)`
  margin-left: ${props => props.collapse ? MIN_WIDTH_SIDER : MAX_WIDTH_SIDER}px!important  ;
  padding-left: 20px!important;
  padding-right: 20px!important;
`;


class Template extends Component {


    hanldeCollapse = () =>  this.props.menuCollapsed || this.props.mediaCollapse
    // hanldeCollapse = () =>  this.props.menuCollapsed
    // hanldeCollapse = () =>  false

    render() {
        // console.log("PROPS ", this.hanldeCollapse());
        return (
            <MainLayout>
                <HeaderBar collapsed={this.hanldeCollapse()}/>
                <Layout style={{marginTop: HEIGHT_HEADER + 'px'}}>
                    <SiderBar id='siderbar'
                              collapsible
                              collapsed={this.hanldeCollapse()}
                              onCollapse={this.props.onCollapse}
                    >
                        <SiderMenu collapsed={this.hanldeCollapse()}/>
                    </SiderBar>
                    <StyledContent collapse={this.hanldeCollapse()}>
                        <main>{this.props.children}</main>
                    </StyledContent>
                </Layout>
                <FooterBar/>
            </MainLayout>
        );
    }
}

Template.propTypes = {
    children: PropTypes.any,
    menuCollapsed: PropTypes.bool,
    onCollapse: PropTypes.func,
};
Template.defaultProps = {
    menuCollapsed: false
};

export default Template;
