import {combineReducers} from "redux";
import infoReducer from "./info";
import deleteReducer from "./delete";
import listReducer from "./list";
import newReducer from "./new";
import updateReducer from "./update";
import checkReducer from "./check";

export default combineReducers({
    infoReducer,
    deleteReducer,
    listReducer,
    newReducer,
    updateReducer,
    checkReducer,
})
