const regexp = /^(?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])$/
const privateNets = /(^127\.)|(^192\.168\.)|(^10\.)|(^172\.1[6-9]\.)|(^172\.2[0-9]\.)|(^172\.3[0-1]\.)/;

export default class IPv4Validator {
    static validate(ip, acceptPrivateNet = false) {
        if (acceptPrivateNet) {
            return regexp.test(ip);
        } else {
            return regexp.test(ip) && !privateNets.test(ip);
        }
    }
}
