import React from 'react';
import PropTypes from 'prop-types';
import {EX_DATE_ALERT, EX_DATE_EXPIRE, EX_DATE_EXPIRED, EX_DATE_INFO} from "app/commons/constants/constants";
import styled from "styled-components";
import FAIcon from "app/commons/components/Layout/FAIcon/FAIcon";

function themeChooser(dateType){
    switch (dateType) {
        case EX_DATE_ALERT:
            return {border: '1px solid #fff3cf', color: '#ffa340'};
        case EX_DATE_EXPIRE:
            return {border: '1px solid #fcd0ef', color: '#fe0d4a'};
        case EX_DATE_INFO:
            return {border: '1px solid #d2eafb', color: '#54affd'};
        default:
            return {border: '1px solid gray', color: 'gray'}
    }
};

const CountDown = styled.div`
  ${props => themeChooser(props.type)};
  font-size: 10px;
  padding: 2px;
  border-radius: 15px;
  width: 22px;
  text-align: center;
  display: inline-block;
`;

const DateBox = styled.span``;

const SpanDate = styled.span`
  color : ${props => props.type === EX_DATE_EXPIRED ? 'red' : ''};
  margin-right: 5px;
`;

const ExpireDate = ({date, type, days, onMouseEnter, onMouseLeave, onFocus, onClick}) =>
    <DateBox onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}
             onFocus={onFocus} onClick={onClick}>
        <SpanDate type={type}>{date}</SpanDate>
        {[EX_DATE_ALERT, EX_DATE_INFO, EX_DATE_EXPIRE].includes(type) ?
            <CountDown type={type}>{days}</CountDown> :
            type === EX_DATE_EXPIRED ? <FAIcon style={{color: 'red'}} /> : ''
        }
    </DateBox>;

ExpireDate.propTypes = {
    date: PropTypes.string,
    type: PropTypes.oneOf([EX_DATE_EXPIRED, EX_DATE_INFO, EX_DATE_EXPIRE, EX_DATE_ALERT,'']),
    days: PropTypes.number
};

ExpireDate.defaultProps = {};

export default ExpireDate;
