import * as types from "../actionTypes";
import {checkContactId} from "../api";

// ** CHECK **

export function checkContact(contactId) {
    const response = checkContactId(contactId)
    return {
        type: types.CHECK_CONTACT,
        payload: response
    }
}

export function checkContactStart() {
    return {
        type: types.CHECK_CONTACT_START,
    }
}


export function checkContactSuccess(response) {
    return {
        type: types.CHECK_CONTACT_SUCCESS,
        payload: response.payload
    }
}

export function checkContactFailure(error) {
    return {
        type: types.CHECK_CONTACT_ERROR,
        payload: error
    }
}

export function resetCheckContact() {
    return {
        type: types.CHECK_CONTACT_RESET,
    }
}
