import React from "react";
import {TabView} from "app/commons/components/Navigation/ViewSelector/TabView/TabView";
import {ListView} from "app/commons/components/Navigation/ViewSelector/ListView/ListView";
import {GridView} from "app/commons/components/Navigation/ViewSelector/GridView/GridView";
import DomainDetail from "app/domains/components/DataDisplay/DomainDetail/DomainDetail";
import ContactDetail from "app/contacts/components/DataDisplay/ContactDetail/ContactDetail";
import DsRecordsDetail from "app/domains/components/DataDisplay/DsRecordsDetail/DsRecordsDetail";
import NameServerDetail from "app/domains/components/DataDisplay/NameServerDetail/NameServerDetail";

function domainContentsGenerator(data,isRegistrar,dnsSecEnabled) {
    let contents = {}

    if (data) {
        const {registrant, admin, techs, dsRecords, dsRecordsToValidate, hosts,hostsToValidate} = data;

        contents['domain'] =
            <DomainDetail data={data} isRegistrar={isRegistrar} dnsSecEnabled={dnsSecEnabled}/>

        if (registrant) {
            contents['registrant'] =
                <ContactDetail data={registrant} linkable={isRegistrar} showRegistrantInfo={true}/>
        }
        if (admin) {
            contents['admin'] =
                <ContactDetail data={admin} linkable={isRegistrar}/>
        }
        if (techs) {
            contents['tech'] =
                <ContactDetail data={techs} linkable={isRegistrar} withBorderSpace={true}/>
        }
        if (dsRecords || dsRecordsToValidate) {
            contents['dsRecords'] =
                <DsRecordsDetail dsRecords={dsRecords} dsRecordsToValidate={dsRecordsToValidate}/>
        }

        if (hosts ||hostsToValidate) {
            contents['nameserver'] = <NameServerDetail hosts={hosts} hostsToValidate={hostsToValidate}/>
        }
    }
    return contents
}



export const renderDomainView = ({intl, data, viewType,isRegistrar,dnsSecEnabled}) => {

    const {formatMessage} = intl;
    const cards = [
        {
            key: 'domain',
            title: formatMessage({id: "domain.detail.domain"})
        }, {
            key: 'registrant',
            title: formatMessage({id: "domain.detail.registrant"})
        }, {
            key: 'admin',
            title: formatMessage({id: "domain.detail.adminContact"})
        }, {
            key: 'tech',
            title: formatMessage({id: "domain.detail.techsContact"})
        }
        , {
            key: 'nameserver',
            title: formatMessage({id: "detail.domain.nameserver"})

        }, {
            key: 'dsRecords',
            title: formatMessage({id: "domain.detail.dsRecords"})
        }
    ]
    const listContents = domainContentsGenerator(data,isRegistrar,dnsSecEnabled);

    switch (viewType) {
        case 'list':
            return <ListView listContents={listContents} cards={cards}/>
        case 'grid':
            return <GridView listContents={listContents} cards={cards}/>
        default: //tab
            return <TabView listContents={listContents} cards={cards}/>
    }
}



