export function composeAddressStreets(form) {
    /* Recupero le strade dalla form AddressForm per aggiungerle all'indirizzo del contatto,
    'address.streetkeys' corrisponde al valore degli indici delle strade aggiunte o rimosse */
    const streets = [];
    const keys = form.getFieldValue('address.streetkeys');
    keys.forEach((k) => (streets.push(form.getFieldValue(`address.street_${k}`))))
    return streets;
}

export function setAddressStreets(form) {
    /* Crea l'oggetto address e ci aggiungo le strade all'indirizzo */
    const address = form.getFieldValue('address')
    address.streets = composeAddressStreets(form)
    form.setFieldsValue({"address": address})
}