import * as types from "./actionTypes";
import * as locales from "../commons/components/locales";

//ritorna il locale preso dal browser o quello di default impostato
function getLocale() {
    let browserLanguage = navigator.language || navigator.browserLanguage;
    let locale = browserLanguage.split('-')[0] || 'it';
    locale ='it'; //TODO - RIMUOVERE
    return locale;
}

const INITIAL_STATE = {
    locale: getLocale(),
    messages: locales[getLocale()],
    antdLocale: locales['antd' + getLocale()],
    formats: null //TODO implementare come per messages and antdLocale
}

const intlReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case types.UPDATE_INTL: {
            return {
                ...state,
                locale: action.payload,
                messages: locales[action.payload],
                antdLocale: locales['antd' + action.payload],
                formats: null
            }
        }

        default:
            return state
    }
}

export default intlReducer;