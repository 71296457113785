import * as types from "../actionTypes";
import {deleteContact as apiDeleteContact} from "../api";

// ** DELETE **

export function deleteContact(contact) {
    const response = apiDeleteContact(contact)
    return {
        type: types.DELETE_CONTACT,
        payload: response
    }
}

export function deleteContactStart() {
    return {
        type: types.DELETE_CONTACT_START,
    }
}

export function deleteContactSuccess(response) {
    return {
        type: types.DELETE_CONTACT_SUCCESS,
        payload: response
    }
}

export function deleteContactFailure(error) {
    return {
        type: types.DELETE_CONTACT_ERROR,
        payload: error
    }
}


export function resetDeleteContact() {
    return {
        type: types.RESET_DELETE_CONTACT,
    }
}