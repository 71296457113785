import {notification} from "antd";

export const notificationError = (message) => {

    // console.log("message ",message)
    notification.error({
        message: message,
    });
};


export const notificationSuccess = (message, callback) => {
    notification.success({
        message: message,
    });
    callback && callback()
};