import React from "react";
import {connect} from "react-redux";
import {Redirect, withRouter} from "react-router-dom";
import * as routes from "../../routes";
import {injectIntl} from "react-intl";
import DomainInfo from "../components/Pages/DomainInfo/DomainInfo";
import DomainUpdateHost from "../components/Pages/DomainUpdateHost/DomainUpdateHost";
import DomainUpdateContact from "../components/Pages/DomainUpdateContact/DomainUpdateContact";
import DomainUpdateAuthinfo from "../components/Pages/DomainUpdateAuthinfo/DomainUpdateAuthinfo";
import DomainUpdateRegistrant from "../components/Pages/DomainUpdateRegistrant/DomainUpdateRegistrant";
import DomainUpdateStatus from "../components/Pages/DomainUpdateStatus/DomainUpdateStatus";
import DomainTransferContainer from "./DomainTransferContainer";
import {
    infoDomainOp,
    updateDomain_Simple,
    updateDomainOp_Host,
    updateDomainOp_Registrant,
    updateDomainOp_Status
} from "../operations";
import {resetInfoDomain} from "../actions/infoActions";
import {resetUpdateDomain} from "../actions/updateActions";

/**
 *
 * Container che gestisce il caricamento della INFO Domain e dei vari UPDATE (Host, Semplice,....)
 *
 * Questo componente gestisce un suo routing interno decidendo se renderizzare la INFO o uno degli  UPDATE possibili
 *
 */


export const RouteTo = (props) => {
    const {comp, match, infoDomain} = props
    const {domain} = match.params
    const baseurl = routes.DOMAIN_INFO.url;
    if (domain) {
        if (infoDomain.data) {
            return comp
        } else {
            // history.push({pathname: `${baseurl}/${domain}`});
            // return <span/>
            return <Redirect to={`${baseurl}/${domain}`}/>
        }
    } else {
        // history.push({pathname: `${baseurl}`});
        // return <span/>
        return <Redirect to={`${baseurl}`}/>
    }
};

class DomainInfoContainer extends React.Component {

    isTheSameRegistrar() {
        const {configuration, infoDomain} = this.props;
        if (configuration.username && infoDomain.data && infoDomain.data.clId) {
            return (configuration.username === infoDomain.data.clId)
        } else {
            return false
        }
    }

    back() {
        this.props.history.push({
            pathname: `${routes.DOMAIN_INFO.url}/${this.props.infoDomain.data.name}`
        })
    }

    render() {
        switch (this.props.match.path) {
            case routes.DOMAIN_UPDATE_HOST.path:
                return (
                    <RouteTo {...this.props}
                             comp={
                                 <DomainUpdateHost
                                     onUpdateHost={this.props.updateDomainHostAction.bind(this)}
                                     onCancel={this.back.bind(this)}
                                     reset={this.props.resetUpdatedDomainAction}
                                 />
                             }
                    />
                );
            case routes.DOMAIN_UPDATE_CONTACT.path:
                return (
                    <RouteTo {...this.props}
                             comp={
                                 <DomainUpdateContact
                                     onUpdateContact={this.props.updateDomainSimpleAction}
                                     onCancel={this.back.bind(this)}
                                     reset={this.props.resetUpdatedDomainAction}
                                 />
                             }
                    />
                );
            case routes.DOMAIN_UPDATE_AUTHINFO.path:
                return (
                    <RouteTo {...this.props}
                             comp={
                                 <DomainUpdateAuthinfo
                                     onAuthinfoUpdate={this.props.updateDomainSimpleAction}
                                     hasRegistrarPermission={this.isTheSameRegistrar()}
                                     onCancel={this.back.bind(this)}
                                     reset={this.props.resetUpdatedDomainAction}
                                 />
                             }
                    />
                );
            case routes.DOMAIN_UPDATE_STATUS.path:
                return (
                    <RouteTo {...this.props}
                             comp={
                                 <DomainUpdateStatus
                                     onUpdateStatus={this.props.updateDomainStatusAction}
                                     hasRegistrarPermission={this.isTheSameRegistrar()}
                                     onCancel={this.back.bind(this)}
                                     reset={this.props.resetUpdatedDomainAction}
                                 />
                             }
                    />
                );
            case routes.DOMAIN_UPDATE_REGISTRANT.path:
                return (
                    <RouteTo  {...this.props}
                              comp={
                                  <DomainUpdateRegistrant
                                      hasRegistrarPermission={this.isTheSameRegistrar()}
                                      onCancel={this.back.bind(this)}
                                      onUpdateRegistrant={this.props.updateDomainRegistrantAction}
                                      reset={this.props.resetUpdatedDomainAction}
                                  />
                              }
                    />
                );
            case routes.DOMAIN_TRANSFER.path:
                return (
                    <RouteTo {...this.props}
                             comp={
                                 <DomainTransferContainer
                                     hasRegistrarPermission={this.isTheSameRegistrar()}
                                     onCancel={this.back.bind(this)}
                                 />
                             }
                    />
                );

            default:
                return <DomainInfo
                    hasRegistrarPermission={this.isTheSameRegistrar()}
                />

        }
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        infoDomainAction: (domain, authinfo) => {
            dispatch(infoDomainOp(domain, authinfo))
        },
        updateDomainHostAction: (domain, data) => {
            let successMesseage = 'Aggiornamento nameserver eseguito'
            dispatch(updateDomainOp_Host(domain, data, successMesseage,
                () => (ownProps.history.push({pathname: `${routes.DOMAIN_INFO.url}/${domain}`}))
            ))
        },
        updateDomainSimpleAction: (domain, data) => {
            let successMesseage = 'Aggiornamento eseguito ';
            dispatch(updateDomain_Simple(domain, data, successMesseage,
                () => (ownProps.history.push({pathname: `${routes.DOMAIN_INFO.url}/${domain}`}))
            ))
        },
        updateDomainRegistrantAction: (domain, data) => {
            let successMesseage = 'Aggiornamento registrante eseguito'
            dispatch(updateDomainOp_Registrant(domain, data, successMesseage,
                () => (ownProps.history.push({pathname: `${routes.DOMAIN_INFO.url}/${domain}`}))
            ))
        },
        updateDomainStatusAction: (domain, data) => {
            let successMesseage = 'Aggiornamento di stato eseguito'
            dispatch(updateDomainOp_Status(domain, data, successMesseage,
                () => (ownProps.history.push({pathname: `${routes.DOMAIN_INFO.url}/${domain}`}))
            ))
        },
        resetInfoDomainAction: () => {
            dispatch(resetInfoDomain())
        },
        resetUpdatedDomainAction: () => {
            dispatch(resetUpdateDomain())
        },
    };
}

const mapStateToProps = (state) => {
    return {
        infoDomain: state.domain.infoReducer,
        configuration: state.confReducer,
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(DomainInfoContainer)));

