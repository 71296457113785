import React from "react";
import AuthRoute from "../../authentication/components/AuthRoute";
import Template from "../components/Layout/Template/TemplateContainer";
// import PropTypes from 'prop-types'

const DefaultLayout = ({component: Component, ...rest}) => {
    return (
        <AuthRoute {...rest} component={matchProps => (
            <Template>
                <Component {...matchProps} />
            </Template>
        )}/>
    )
};
export default DefaultLayout;


// DefaultLayout.propTypes = {
//     component: PropTypes.object.isRequired,
//     path: PropTypes.string.isRequired,
// };
