import React, {Component} from 'react';
import {showLabelValueModal} from "../../commons/utils/common";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import * as routes from "../../routes";
import {withRouter} from "react-router-dom";
import DomainActionMenu from "../components/Pages/DomainInfo/DomainActionMenu";
import {deleteDomainOp, restoreDomainOp, tarnsferDomainOp_Status} from "../operations";
import {Modal} from 'antd';
const confirm = Modal.confirm;

class DomainActionMenuContainer extends Component {

    handleShowAuthInfo = (authinfo) => {
        if (authinfo) {
            showLabelValueModal(this.props.intl.formatMessage({id: 'modal.authinfo.title'}), authinfo)
        } else {
            console.log("Missing authinfo");
        }
    }

    handleEditHost = (domain) => {
        this.props.history.push({
            pathname: `${routes.DOMAIN_UPDATE_HOST.url}/${domain}`
        })
    }

    handleEditAuthinfo = (domain) => {
        this.props.history.push({
            pathname: `${routes.DOMAIN_UPDATE_AUTHINFO.url}/${domain}`
        })
    }

    handleEditRegistrant = (domain) => {
        this.props.history.push({
            pathname: `${routes.DOMAIN_UPDATE_REGISTRANT.url}/${domain}`
        })
    }

    handleEditStatus = (domain) => {
        this.props.history.push({
            pathname: `${routes.DOMAIN_UPDATE_STATUS.url}/${domain}`
        })
    }

    handleEditSimple = (domain) => {
        this.props.history.push({
            pathname: `${routes.DOMAIN_UPDATE_CONTACT.url}/${domain}`
        })
    }


    handleTransferDomainManage = (domain) => {
        const {history} = this.props
        let searchParams = new URLSearchParams(history.location.search);
        history.push({
            pathname: `${routes.DOMAIN_TRANSFER.url}/${domain}`,
            search: '?' + searchParams.toString()
        })
    }

    handleTransferDomainStatus = (domain, authinfo) => {
        function showTitle(val) {
            showLabelValueModal(formatMessage({id: 'modal.status.title'}),
                val
            )
        }

        const {formatMessage} = this.props.intl;
        this.props.resetDomainTransferStatus();
        this.props.transferStatusDomainAction(domain, authinfo, showTitle)
    }

    handleRestoreDomain = (domain, authinfo) => {
        const {formatMessage} = this.props.intl;
        const {restoreDomainAction} = this.props;
        confirm({
            title: formatMessage({id: 'domain.info.restore.title'}, {name: domain}),
            content: formatMessage({id: 'domain.info.restore.content'}, {name: domain}),
            okText: formatMessage({id: 'yes'}),
            okType: 'danger',
            cancelText: formatMessage({id: 'no'}),
            onOk() {
                restoreDomainAction(domain, authinfo)
            },
            onCancel() {
            },
        });
    }

    handleDeleteDomain = (domain, authinfo) => {
        const {formatMessage} = this.props.intl;
        const {deleteDomainAction} = this.props;
        confirm({
            title: formatMessage({id: 'domain.info.delete.title'}, {name: domain}),
            content: formatMessage({id: 'domain.info.delete.content'}, {name: domain}),
            okText: formatMessage({id: 'yes'}),
            okType: 'danger',
            cancelText: formatMessage({id: 'no'}),
            onOk() {
                deleteDomainAction(domain, authinfo)
            },
            onCancel() {
            },
        });
    }

    handleTransferTrade = (domain) => {
        this.props.history.push({
            pathname: `${routes.DOMAIN_TRANSFER_TRADE.url}/${domain}`
        })
    }

    render() {
        const {data} = this.props.infoDomainData;
        return (
            <div style={{marginRight: '10px'}}>
                {data &&
                <DomainActionMenu
                    hasRegistrarPermission={this.props.hasRegistrarPermission}
                    statuses={data.statuses}
                    onShowAuthInfo={data.authinfo && this.handleShowAuthInfo.bind(this, data.authinfo)}
                    onDeleteDomain={this.handleDeleteDomain.bind(this, data.name)}
                    onRestoreDomain={this.handleRestoreDomain.bind(this, data.name)}
                    onEditHost={this.handleEditHost.bind(this, data.name)}
                    onEditAuthInfo={this.handleEditAuthinfo.bind(this, data.name)}
                    onEditRegistrant={this.handleEditRegistrant.bind(this, data.name)}
                    onEditSimple={this.handleEditSimple.bind(this, data.name)}
                    onEditStatus={this.handleEditStatus.bind(this, data.name)}
                    onTransferDomainStatus={this.handleTransferDomainStatus.bind(this, data.name, data.authinfo)}
                    onTransferDomainManage={this.handleTransferDomainManage.bind(this, data.name)}
                    onTransferTrade={this.handleTransferTrade.bind(this, data.name)}
                />}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        infoDomainData: state.domain.infoReducer,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        deleteDomainAction: (domain) => {
            dispatch(deleteDomainOp(domain))
        },
        restoreDomainAction: (domain) => {
            dispatch(restoreDomainOp(domain))
        },
        transferStatusDomainAction: (domain, authinfo) => {
            dispatch(tarnsferDomainOp_Status(domain, authinfo))
        },
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(DomainActionMenuContainer)));


DomainActionMenuContainer.propTypes = {};
DomainActionMenuContainer.defaultProps = {};

