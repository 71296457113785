import * as types from "../actionTypes";
import {infoContact as apiInfoContact} from "../api";

// ** INFO **
export function infoContact(contactId) {
    const response = apiInfoContact(contactId)
    return {
        type: types.CONTACT_INFO,
        payload: response
    }
}

export function infoContactStart() {
    return {
        type: types.CONTACT_INFO_LOADER,
    }
}

export function infoContactFailure(error) {
    return {
        type: types.CONTACT_INFO_ERROR,
        payload: error
    }
}

export function infoContactSuccess(response, message) {
    return {
        type: types.CONTACT_INFO_SUCCESS,
        payload: {response, message: message}
    }
}

function resetInfoContact() {
    return {
        type: types.CONTACT_INFO_RESET, payload: {data: null, contactId: null, error: null}
    }
}

function resetNotificationsInfoContact() {
    return {
        type: types.CONTACT_INFO_NOTIFY_RESET
    }
}


export default {resetInfoContact, resetNotificationsInfoContact}