import {Form, Input} from "antd";
import React from "react";
import PropTypes from 'prop-types'

export const ContactEmailFormItem = ({formItemLayout, intl, form, items, isGov}) => (
    <Form.Item
        {...formItemLayout}
        label={intl.formatMessage({id: `contact.detail.form.${isGov?'pec':'email'}`})}
        hasFeedback
    >
        {form.getFieldDecorator('email', {
            initialValue: items && items.email,
            rules: [{
                type: 'email',
                message: intl.formatMessage({id: `contact.detail.form.${isGov ? 'pec' : 'email'}.invalid.message`}),
            }, {
                required: true,
                message: intl.formatMessage({id: `contact.detail.form.${isGov?'pec':'email'}.message`}),
            }],
        })(<Input placeholder={intl.formatMessage({id: `contact.detail.form.${isGov ? 'pec' : 'email'}.placeholder`})}/>)}
    </Form.Item>
)

export default ContactEmailFormItem

ContactEmailFormItem.propTypes = {
    items : PropTypes.object,
};
