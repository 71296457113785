import React from 'react';
import PropTypes from 'prop-types';
import {Form, Select} from 'antd';
import CountryCodedValidator from "../../commons/utils/validators/ConuntryCodeValidator";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {countriesOperations} from "app/countries/index";
import {printMessageError} from "../../commons/utils/common";

const Option = Select.Option;
const FormItem = Form.Item;

class CountryFormItem extends React.Component {

    componentDidMount() {
        if (this.props.initialValue && (this.props.countries.list.length > 0)) {
            this.props.form.validateFields([this.props.fieldDecorator])
        }

        if (this.props.countries.error){
            this.setIdFieldInvalid(printMessageError(this.props.countries.error));
        }
    }

    state = {
        status: null,
        help: null,
        data: this.props.countries.list || []
    }

    filterData = (value) => {
        this.setState({data: []});
        //Popola il select valorizzando data
        const data = this.props.countries.list.filter(item =>
            item.text.toUpperCase().includes(value.toUpperCase()) ||
            item.value.toUpperCase().includes(value.toUpperCase()));
        this.setState({match: (this.props.countries.list.filter(country => country.value === value).length > 0)})
        this.setState({data})
        this.props.form.validateFields([this.props.fieldDecorator])
    }

    //Controlla se il codice rispetta i vincoli
    checkCountryConstraints = (rule, value, callback) => {
        const {formatMessage} = this.props.intl;
        if (value) {
            let code = CountryCodedValidator.validate(value, true);

            if (!code.valid) {
                this.setIdFieldInvalid(formatMessage({id: code.error}))
            }
            else {
                if (this.state.data.filter(country => country.value === value).length > 0) {
                    this.setIdFieldValid()
                } else {
                    this.setIdFieldInvalid(formatMessage({id: 'invalidCountryCode'}))
                }
            }
        }
        else {
            this.setIdFieldInvalid(formatMessage({id: "requiredCountryCode"}))
        }
        callback()
    }

    setIdFieldInvalid = (message) => {
        this.setState({help: message})
        this.setState({status: 'error'})
    }

    setIdFieldValid = (message) => {
        this.setState({help: message})
        this.setState({status: 'success'})
    }


    render() {
        const {getFieldDecorator} = this.props.form



        return (
            <FormItem
                {...this.props.formItemLayout}
                label={this.props.label}
                hasFeedback
                validateStatus={this.state.status}
                help={this.state.help}
            >
                {getFieldDecorator(this.props.fieldDecorator, {
                    initialValue: this.props.initialValue,
                    rules: [
                        {required: this.props.required},
                        {validator: this.props.required && this.checkCountryConstraints},
                    ]
                })(
                    <Select
                        mode="combobox"
                        disabled={this.props.disabled}
                        placeholder={this.props.placeholder}
                        // notFoundContent={fetching ? <Spin size="small"/> : null}
                        filterOption={false}
                        onSearch={(value) => this.filterData(value, this.props.nationalityCodeFilter)}
                        onChange={this.props.onChange}
                        onBlur={this.props.onBlur}
                    >
                        {this.state.data.map(d => <Option key={d.value}>{d.text}</Option>)}
                    </Select>
                )}
            </FormItem>
        );
    }
}

/* Inject auth state and dispatch() into props */

CountryFormItem.propTypes = {
    form: PropTypes.object,
    initialValue: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    formItemLayout: PropTypes.object,
    nationalityCodeFilter: PropTypes.bool,   //??? -
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    required: PropTypes.bool,
    fieldDecorator: PropTypes.string
}

CountryFormItem.defaultProps = {
    nationalityCodeFilter: true,
}

const mapStateToProps = (state) => {
    return {
        countries: state.countryReducer
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadCountryCode: () => dispatch(countriesOperations.loadCountryCodeOp())
    };
}


/* Inject auth state and dispatch() into props */
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CountryFormItem));
