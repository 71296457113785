import * as types from "../actionTypes";
import {registryContacts} from "../api";

// ** LIST **

export function contactList(searchParams) {
    const response = registryContacts(searchParams)
    return {
        type: types.CONTACT_LIST,
        payload: response
    }
}

export function contactListStart() {
    return {
        type: types.CONTACT_LIST_START,
    }
}

export function contactListSuccess(response) {
    return {
        type: types.CONTACT_LIST_RESULT,
        payload: response
    }
}

export function contactListFailure(error) {
    return {
        type: types.CONTACT_LIST_ERROR,
        payload: error
    }
}

