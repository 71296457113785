import Contact from './Contact';

/*
created
:
lastupdate
:
*/
export default class RegistrantContact extends Contact {
  constructor(id, name,org, registrant, consentForPublish,address,email, voice,fax,statuses,crDate, clId, crId, roid) {
    super(id,name, org, consentForPublish, address, email, voice, fax,statuses,crDate);


    this.clId=clId;
    this.crId=crId;
    this.roid=roid;
    this.registrant=registrant;
  }

  // registrantId
  get registrant() {
    return this._registrant;
  }

  set registrant(registrant) {
    this._registrant=registrant;
  }

    // clId
    get clId() {
      return this._clId;
    }

    set clId(clId) {
      this._clId=clId;
    }

    // crId
    get crId() {
      return this._crId;
    }

    set crId(crId) {
      this._crId=crId
    }

    //roid
    get roid() {
      return this._roid;
    }

    set roid(roid) {
      this._roid=roid;
    }

    accept(visitor) {
      super.accept(visitor);
      visitor.visitRegistrantContact(this);
    }

}
