import * as types from "./actionTypes";

const INITIAL_STATE = {
    authenticated: false,
    userInfo: null,
    failed: false,
    error: null
}

const authReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case types.IS_AUTHENTICATED: {
            return {
                ...state,
                authenticated: true,
                userInfo: action.payload,
                failed: false,
                error: null
            }
        }
        case types.AUTHENTICATED_USER_INFO: {
            return {
                ...state,
                authenticated: true,
                userInfo: action.payload,
                failed: false,
                error: null
            }
        }
        case types.LOGIN_REQUEST: {
            return {
                ...state,
                authenticated: false,
                userInfo: action.payload,
                failed: false,
                error: null
            }
        }
        case types.LOGOUT: {
            return {
                ...state,
                authenticated: false,
                error: null,
                userInfo: null,
            }
        }
        case types.IS_NOT_AUTHENTICATED: {
            return {
                ...state,
                authenticated: false,
                data: null,
                error: null
            }
        }
        case types.LOGIN_SUCCESS: {
            return {
                ...state,
                authenticated: true,
                failed: false,
                error: null
            }
        }
        case types.LOGIN_FAILED: {
            return {
                ...state,
                authenticated: false,
                failed: true,
                error: action
            }
        }

        default:
            return state
    }
}

export default authReducer;