import {CLIENT_DELETE_PROHIBITED, CLIENT_UPDATE_PROHIBITED} from "app/commons/constants/statuses";
import StatusFItem from "app/commons/components/DataEntry/StatusFormItem/StatusFormItem";
import React from "react";

export const StatusesFormItem = (props) => {
    const {form, items, intl, formItemLayout} = props;
    const managedStatusList = [CLIENT_UPDATE_PROHIBITED, CLIENT_DELETE_PROHIBITED]
    return (
        <StatusFItem
            form={form}
            initialValue={(items && items.statuses) || []}
            formItemLayout={formItemLayout}
            managedStatusList={managedStatusList}
            label={intl.formatMessage({id: 'contact.detail.form.status'})}
            onChange={props.onChange}
        />
    )
}

export default StatusesFormItem;