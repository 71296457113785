import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";

export const StyledFAIcon = styled.i`
  display: inline-block;
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  line-height: 1;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const FAIcon = ({type }) =>
    <StyledFAIcon className={type}/>

FAIcon.propTypes = {
    /* indica il nome dell'icona Font Awesome da inserire */
    type: PropTypes.string,
};
FAIcon.defaultProps = {
};

export default FAIcon;
