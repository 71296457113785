//UPDATE (Common)

import * as types from "../actionTypes";
import * as api from "../api";

export function updateDomainStart() {
    return {
        type: types.DOMAIN_UPDATE_START,
    }
}

export function updateDomainSuccess(response, message) {
    return {
        type: types.DOMAIN_UPDATE_SUCCESSFUL,
        payload: {response, message: message}
    }
}

export function updateDomainFailure(error) {
    return {
        type: types.DOMAIN_UPDATE_ERROR,
        payload: error
    }
}

export function resetUpdateDomain() {
    return {
        type: types.DOMAIN_UPDATE_RESET,
        payload: {data: null, error: null, message: null}
    }
}

// ** UPDATE HOST **
export function updateDomainHost(domain, data) {
    const response = api.updateDomainHost(domain, data)
    return {
        type: types.DOMAIN_UPDATE_HOST,
        payload: response
    }
}

// ** UPDATE SIMPLE **

export function updateDomainSimple(domain, data) {
    const response = api.updateDomainSimple(domain, data)
    return {
        type: types.DOMAIN_UPDATE_SIMPLE,
        payload: response
    }
}

// ** UPDATE REGISTRANT **
export function updateDomainRegistrant(domain, data) {
    const response = api.updateDomainRegistrant(domain, data)
    return {
        type: types.DOMAIN_UPDATE_REGISTRANT,
        payload: response
    }
}

// ** UPDATE STATUS **
export function updateDomainStatus(domain, data) {
    const response = api.updateDomainStatus(domain, data)
    return {
        type: types.DOMAIN_UPDATE_STATUS,
        payload: response
    }
}