import * as types from "../actionTypes";

const INITIAL_STATE = {
    transferStatusDomain: {loading: true, data: null, error: null, message: null},
    transferRequestDomain: {loading: true, data: null, error: null, message: null},
    domainNotify: {message: null, error: null}
}
const transferReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        //TRANSFER_STATUS DOMAIN
        case types.TRANSFER_STATUS_DOMAIN_START: {
            return {...state, transferStatusDomain: {loading: true}}
        }

        case types.TRANSFER_STATUS_DOMAIN: {
            return {
                ...state,
                transferStatusDomain: {
                    loading: true, error: null, message: null
                }
            }
        }
        case types.TRANSFER_STATUS_DOMAIN_SUCCESS: {
            return {
                ...state,
                transferStatusDomain: {
                    loading: false, error: null, data: action.payload.data,
                    message: null,
                }
            }
        }
        case types.TRANSFER_STATUS_DOMAIN_ERROR : {
            return {
                ...state,
                transferStatusDomain: {
                    loading: false, error: action.payload, data: null, message: null
                }
            }
        }
        case types.TRANSFER_STATUS_DOMAIN_RESET : {
            return {
                ...state,
                transferStatusDomain: {
                    loading: false, error: null, data: null, message: null
                }
            }
        }

        case types.TRANSFER_STATUS_DOMAIN_RESULT: {
            return {
                ...state,
                transferStatusDomain: {
                    loading: false,
                    data: action.payload.data,
                    error: null, message: null
                }
            }
        }

        //TRANSFER_REQUEST DOMAIN
        case types.TRANSFER_REQUEST_DOMAIN_START: {
            return {...state, transferRequestDomain: {loading: true}}
        }

        case types.TRANSFER_REQUEST_DOMAIN: {
            return {
                ...state,
                transferRequestDomain: {
                    loading: true, error: null, message: null
                }
            }
        }
        case types.TRANSFER_REQUEST_DOMAIN_SUCCESS: {
            return {
                ...state,
                transferRequestDomain: {
                    loading: false,
                    error: null,
                    data: action.payload.data,
                    message: action.payload.message
                }
            }
        }
        case types.TRANSFER_REQUEST_DOMAIN_ERROR : {
            return {
                ...state,
                transferRequestDomain: {
                    loading: false, error: action.payload, data: null, message: null
                },
            }
        }
        case types.TRANSFER_REQUEST_DOMAIN_RESET : {
            return {
                ...state,
                transferRequestDomain: {
                    loading: false, error: null, data: null, message: null
                }
            }
        }

        case types.TRANSFER_REQUEST_DOMAIN_RESULT: {
            return {
                ...state,
                transferRequestDomain: {
                    loading: false,
                    data: action.payload.data,
                    error: null, message: null

                }
            }
        }

        case types.DOMAIN_TRANSFER_APPROVE: {
            return {
                ...state,
                transferRequestDomain: {
                    loading: false,
                    data: action.payload.data,
                    error: null, message: null
                }
            }
        }

        case types.DOMAIN_TRANSFER_REJECT: {
            return {
                ...state,
                transferRequestDomain: {
                    loading: false,
                    data: action.payload.data,
                    error: null, message: null
                }
            }
        }

        case types.DOMAIN_TRANSFER_DELETE: {
            return {
                ...state,
                transferRequestDomain: {
                    loading: false,
                    data: action.payload.data,
                    error: null, message: null
                }
            }
        }

        default:
            return state
    }
}

export default transferReducer;