import {notification} from "antd";
import axios from "axios/index";
import {AUTHENTICATE, CONFIGURATION, SETUP_USER} from "./constants/api";
import {loadEppConfiguration, updateEppConfiguration} from "./api";
import {authOperations} from "../authentication";
import actions from "./actions"
import {hanldeErrorDispatching} from "app/commons/utils/utils";


function getConfiguration() {
    let url = CONFIGURATION;
    return axios.get(`${url}`);
}

const authenticate = (that, step) => {

    const {formatMessage} = that.props.intl;

    let username = that.fields['adminName'];
    let password = that.fields['adminPassword'];

    axios
        .post(`${AUTHENTICATE}`, {
            username: username,
            password: password
        })
        .then((response) => {
            if (response.status === 200) {

                that.props.dispatch(authOperations.loginSuccessful(response.headers));

                notification.success({
                    message: formatMessage({id: 'userConfiguration.success.message'}),
                    description: formatMessage({id: 'userConfiguration.success.description'})
                });

                // Redirect che viene fatta per gestire situazioni intermedie
                const {pathname, search} = that.props.history.location;
                const {current} = that.state;
                let completePath = pathname + search + `&step=${current}`;
                that.props.history.push(completePath);
                that.setState({current: current + 1});
            }
        })
        .catch((error) => {
            // Errore della login
            notification.error({
                message: formatMessage({id: 'userConfiguration.login.error.message'}),
                description: formatMessage({id: 'userConfiguration.login.error.description'})
            });
            console.log("Authenticate error: ", error);
        })
}
const insertAdmin = (that, stepAction) => {

    const {formatMessage} = that.props.intl;
    const {configurationStatus} = that.state;

    if (configurationStatus.userExists === 'false') {

        let username = that.fields['adminName'];
        let password = that.fields['adminPassword'];
        let email = that.fields['adminEmail'];
        let name = that.fields['fullName'];

        notification.info({
            message: formatMessage({id: 'userConfiguration.info.message'}),
            description: formatMessage({id: 'userConfiguration.info.description'})
        });


        axios
            .post(`${SETUP_USER}`, {
                username: username,
                password: password,
                email: email,
                name: name
            })
            .then((response) => {


                if (response.status === 201) {
                    let step = stepAction + 1;

                    authenticate(that, step);
                }

            })
            .catch((error) => {
                console.log("Error:", error);
                notification.error({
                    message: formatMessage({id: 'userConfiguration.error.message'}),
                    description: formatMessage({id: 'userConfiguration.error.description'})
                });
            })
    } else {
        // Utente admin esiste già non bisogna inserirlo ma con i dati
        // (utente, password) bisogna effettuare accesso per prendere bearer token
        let step = stepAction + 1;
        authenticate(that, step);
    }
}
export const insertServer = (that, step) => {
    // console.log("Inetssever ", that)

    const {serverURI, username, password, deletedServerURI, language, dnsSecEnabled} = that.fields;
    const {steps} = that.state;

    const {formatMessage} = that.props.intl;

    notification.info({
        message: formatMessage({id: 'serverConfiguration.info.message'}),
        description: formatMessage({id: 'serverConfiguration.info.description'})
    });


    /*axios.patch("/config/epp", {
      username:username,
      serverURI:serverURI,
      deletedServerURI:deletedServerURI,
      password:password,
      language:language,
      dnsSecEnabled:dnsSecEnabled
    }, appendAuthorizationHeader())*/
    updateEppConfiguration(username, serverURI, deletedServerURI, password, language, dnsSecEnabled)
        .then((response) => {
            if (response.status === 200) {
                const {current} = that.state;

                notification.success({
                    message: formatMessage({id: 'serverConfiguration.success.message'}),
                    description: formatMessage({id: 'serverConfiguration.success.message'})
                });


                //  that.props.history.push({pathname:'/domain'});


                if (current < steps.length - 1)
                    that.setState({current: current + 1});

                that.context.store.dispatch(eppConfiguration({
                    username: username,
                    dnsSecEnabled: dnsSecEnabled,
                    language: language
                }));


                that.props.history.push("/");

            }

        })
        .catch((error) => {
            // Le condizioni per cui si può avere un errore in questo contesto è che ci sia
            // Un server error o errore autenticazione in ogni caso bisogna fare in modo
            // di tentare di fare reload autenticazione comunicando con il server.

            console.log("Error: ", that);
            console.log("Error: ", error);
            if (error.response && error.response.status === 401) {
                notification.error({
                    message: formatMessage({id: 'serverConfiguration.error.message'}),
                    description: formatMessage({id: 'serverConfiguration.error.description'})
                });

            }
            if (error.response && error.response.status === 500) {
                notification.error({
                    message: formatMessage({id: 'connectionOrServer.error.message'}),
                    description: formatMessage({id: 'connectionOrServer.error.description'})
                });

            }
            that.setState({error: error});
            //   const path = that.props.history.location.pathname+that.props.history.location.search;

            //that.props.dispatch(doLogout(that.props, path, true));

        })

}
export const loadConfiguration = () => {
    return function (dispatch) {
        dispatch(actions.requestConfiguration())
        return loadEppConfiguration()
            .then((configuration) => {
                if (!configuration.error) {
                    dispatch(eppConfiguration(configuration.data));
                } else {
                    throw  new Error(configuration.error);
                }
            })
            .catch((error) => {
                dispatch(hanldeErrorDispatching(error, actions.failureConfiguration))
            })
    }
}

const eppConfiguration = (configuration) => (actions.eppConfiguration(configuration))
const setConfigurationProfile = (configuration) => (actions.setConfigurationProfile(configuration))
const expandMenu = () => (actions.menuExpanded())
const collapseMenu = () => (actions.menuCollapsed())

export default {
    eppConfiguration,
    setConfigurationProfile,
    getConfiguration,
    authenticate,
    insertAdmin,
    insertServer,
    loadConfiguration,
    expandMenu,
    collapseMenu
}