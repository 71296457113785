import * as types from "./actionTypes";
import update from 'immutability-helper';


const INITIAL_STATE = {
    loading: true, data: null, error: null
}
const listReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        //LIST
        case types.EPP_MESSAGE_LIST: {
            return {
                ...state,
                loading: true,
                data: action.payload,
                error: null
            }
        }

        case types.EPP_MESSAGE_LIST_START: {
            return {
                ...state,
                loading: true,
                data: action.payload,
                error: null
            }
        }

        case types.EPP_MESSAGE_LIST_RESULT: {
            return {
                ...state,
                loading: false,
                data: action.payload.data,
                error: null
            }
        }
        case types.EPP_MESSAGE_LIST_ERROR : {
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            }
        }

        case types.EPP_MESSAGE_MARK : {
            return {
                ...state,
                loading: false,
                error: null
            }
        }

        case types.EPP_MESSAGE_MODIFY_READ : {

            //Recupero l'indice dell'oggetto da modificare
            let index;
            let messages = state.data._embedded.eppMessages;
            Object.keys(messages).forEach(function (key) {
                if (messages[key]['_links']['self']['href'] === action.payload.message) {
                    index = key;
                }
            });

            const toUpdate = update(state, {
                'data': {'_embedded': {'eppMessages': {[index]: {'read': {$set: action.payload.read}}}}}
            });

            return {
                ...state,
                data: toUpdate.data
            }
        }

        default:
            return state
    }
}

export default listReducer;