// ** LIST **

import * as api from "./api";
import * as types from "./actionTypes";

function eppMessageList(searchParams) {
    const response = api.eppMessages(searchParams)
    return {
        type: types.EPP_MESSAGE_LIST,
        payload: response
    }
}

function eppMessageListStart() {
    return {
        type: types.EPP_MESSAGE_LIST_START,
    }
}

function eppMessageListSuccess(response) {
    return {
        type: types.EPP_MESSAGE_LIST_RESULT,
        payload: response
    }
}

function eppMessageListFailure(error) {
    return {
        type: types.EPP_MESSAGE_LIST_ERROR,
        payload: error
    }
}

function eppMessageMarkAs(message, read) {
    const response = api.markMessage(message, read)
    return {
        type: types.EPP_MESSAGE_MARK,
        payload: response
    }
}

function modify_message(message, read) {
    return {
        type: types.EPP_MESSAGE_MODIFY_READ,
        payload: {message, read}
    }
}

function eppMessageDelete(message) {
    const response = api.deleteMessage(message)
    return {
        type: types.EPP_MESSAGE_DELETE,
        payload: response
    }
}

export default {
    eppMessageList,
    eppMessageListFailure,
    eppMessageListStart,
    eppMessageListSuccess,
    eppMessageMarkAs,
    modify_message,
    eppMessageDelete
}