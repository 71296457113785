import React, {Component} from 'react';
import * as routes from "../../../routes";
import {withRouter} from "react-router-dom";
import FiltersBar from "../../../commons/components/Navigation/FiltersBar/FiltersBar";


const filtersOptions = {
    domain: {
        label: 'Dominio',
        icon: 'fa-globe',
        options: {
            textSearch: true,
            placeholder: 'Nome dominio valido',
        },

    },
    message: {
        label: 'Messaggio contiene',
        icon: 'fa-envelope',
        options: {
            textSearch: true,
            placeholder: 'Testo...'
        },

    },
    /* Campo boolean */
    read: {
        label: 'Letto',
        icon: 'fa-star',
        options: {
            list: {
                true: {
                    label: 'Già letto'
                },
                false: {
                    label: 'Da leggere'
                },
            }
        },
        textSearch: false,
    },
    type: {
        label: 'Tipo',
        icon: 'fa-tag',
        options: {
            list: {
                CHANGE_STATUS_MESSAGE: {
                    icon: 'fa-exchange',
                    label: 'CHANGE_STATUS_MESSAGE'
                },
                CREDIT_MESSAGE: {
                    icon: 'fa-dollar-sign',
                    label: 'CREDIT_MESSAGE'
                },
                SIMPLE_MESSAGE: {
                    icon: 'fa-envelope',
                    label: 'SIMPLE_MESSAGE'
                },
                PASSWORD_REMINDER_MESSAGE: {
                    icon: 'fa-key',
                    label: 'PASSWORD_REMINDER_MESSAGE'
                },
                TRANSFER_MESSAGE: {
                    icon: 'fa-exchange-alt',
                    label: 'TRANSFER_MESSAGE'
                },
                WRONG_NAMESPACE_REMINDER_MESSAGE: {
                    icon: 'fa-times-octagon',
                    label: 'WRONG_NAMESPACE_REMINDER_MESSAGE'
                },
                REMAPPED_IDN_MESSAGE: {
                    icon: 'fa-envelope',
                    label: 'REMAPPED_IDN_MESSAGE'
                },
                DNS_ERROR_MESSAGE: {
                    icon: 'fa-exclamation',
                    label: 'DNS_ERROR_MESSAGE'
                },
                DNS_WARNING_MESSAGE: {
                    icon: 'fa-exclamation-triangle',
                    label: 'DNS_WARNING_MESSAGE'
                },
                DELAYED_DEBIT_AND_REFUND_MESSAGE: {
                    icon: 'fa-credit-card',
                    label: 'DELAYED_DEBIT_AND_REFUND_MESSAGE'
                },
                BROKEN_DELEGATION_MESSAGE: {
                    icon: 'fa-unlink',
                    label: 'BROKEN_DELEGATION_MESSAGE'
                },
                REFUND_RENEWS_FOR_BULKTRANSFER_MESSAGE: {
                    icon: 'fa-money-bill-alt',
                    label: 'REFUND_RENEWS_FOR_BULKTRANSFER_MESSAGE'
                },
            },
        },
    }
}

class EppMessaggeListFilterBar extends Component {

    state = {
        filters: {}
    }

    //Estraee dalla barra indirizzo i parametri e li mette nello stato
    componentWillMount() {
        let searchParams = new URLSearchParams(this.props.location.search.slice(1));
        let res = {};
        for (let pair of searchParams.entries()) {
            res[pair[0]] = pair[1]
        }
        this.setState({filters: res})
    }


    handleOnSubmit = (filters) => {
        let queryString = new URLSearchParams(filters);
        this.setState({filters: filters}); //Modifica l'attuale stato dei filtri
        //Reindirizza al'indirizzo con i filtri modificati
        this.props.history.push({
            pathname: routes.EPP_MESSAGE_LIST.url,
            search: `?sort=date,desc&${queryString}`
        })
    }

    render() {
        return (
            <FiltersBar
                initialFilters={this.state.filters}
                filtersOptions={filtersOptions}
                onSubmit={this.handleOnSubmit}
            />
        );
    }
}

EppMessaggeListFilterBar.propTypes = {};
EppMessaggeListFilterBar.defaultProps = {};

export default withRouter(EppMessaggeListFilterBar);
