import {combineReducers} from "redux";
import authReducer from "../../authentication/reducer";
import intlReducer from "../../intl/reducer";
import confReducer from "../../configuration/reducer";
import eppMessage from "../../eppMessages/reducer";
import countryReducer from "../../countries/reducer";
import domain from '../../domains/reducers/index'
import contact from '../../contacts/reducers/index'

export default combineReducers({
    authReducer,
    confReducer,
    intlReducer,
    countryReducer,
    eppMessage,
    domain,
    contact
})
