import * as types from './actionTypes';
import {CONFIG_RESULT, CONFIG_SET_PROFILE} from './actionTypes';
import {CONFIG_ERROR} from "./actionTypes";
import {MENU_COLLAPSED} from "./actionTypes";
import {MENU_EXPANDED} from "./actionTypes";

function resetConfiguration() {
    return {
        type: types.CONFIG_RESET
    }
}

function requestConfiguration() {
    return {
        type: types.CONFIG_LOADING
    }
}

const eppConfiguration = (configuration) => {
    return {
        type: CONFIG_RESULT,
        payload: {...configuration}
    }
}

const failureConfiguration = (error) => {
    return {
        type: CONFIG_ERROR,
        payload: error
    }
}

const setConfigurationProfile = (configuration) => {
    return {
        type: CONFIG_SET_PROFILE,
        payload: {...configuration}
    }
}

const menuCollapsed = () => {
    return {
        type: MENU_COLLAPSED
    }
}

const menuExpanded = () => {
    return {
        type: MENU_EXPANDED
    }
}

export default {
    eppConfiguration,
    setConfigurationProfile,
    resetConfiguration,
    requestConfiguration,
    failureConfiguration,
    menuCollapsed,
    menuExpanded
}