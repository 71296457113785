import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import React, {Component} from 'react';
import * as routes from "../../routes";
import {eppMessagesOperations} from "../index";
import EppMessageList from "../components/Page/EppMessageList";

class EppMessageListContainer extends Component {

    componentWillMount() {
        this.props.loadListAction(this.props.location.search.slice(1));
    }

    handleonShowSizeChange(page, size) {
        // console.log(" page,size", page, size)
        let searchParams = new URLSearchParams(this.props.location.search.slice(1));
        searchParams.set('page', page);
        searchParams.set('size', size);

        this.props.history.push({
            pathname: routes.EPP_MESSAGE_LIST.url,
            search: '?' + searchParams.toString()
        })
    }

    handleDeleteMessage(message) {
        let searchParams = new URLSearchParams(this.props.location.search.slice(1));
        this.props.deleteMessageAction(message, searchParams)
    }

    render() {
        return (
            <EppMessageList
                onShowSizeChange={this.handleonShowSizeChange.bind(this)}
                onReadMessage={this.props.markMessageAction}
                onDeleteMessage={this.handleDeleteMessage.bind(this)}
            />
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadListAction: (searchParams) => {
            dispatch(eppMessagesOperations.getEppMessages(searchParams))
        },
        markMessageAction: (message, read) => {
            dispatch(eppMessagesOperations.markMessage(message, read))
        },
        deleteMessageAction: (message, searchParams) => {
            dispatch(eppMessagesOperations.deleteMessage(message, searchParams))
        }
    };
};

export default withRouter(connect(null, mapDispatchToProps)(EppMessageListContainer));