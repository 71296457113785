import React, {Component} from 'react';
import {Form} from "antd";
import PropTypes from 'prop-types'
import {injectIntl} from "react-intl";
import {formItemLayout} from './const'
import ContactIdFormItemContainer from "app/commons/components/DataEntry/ContactIdFormItem/ContactIdFormItemContainer";
import RegistrantFormItemContainer from "app/contacts/components/BaseContactForm/FormITems/RegistrantFormItemContainer";
import {ContactNameFormItem} from "app/contacts/components/BaseContactForm/FormITems/ContactNameFormItem";
import ContactOrgFormItemContainer from "app/contacts/components/BaseContactForm/FormITems/ContactOrgFormItemContainer";
import {ContactAddressFormItem} from "app/contacts/components/BaseContactForm/FormITems/ContactAddressFormItem";
import ContactGroup2FormItems from "app/contacts/components/BaseContactForm/FormITems/ContactGroup2FormItems";
import styled from "styled-components";

class ContactFormWrapped extends Component {

    render() {
        const {intl, items, isRegistrant,isNewContact,onChangeName,onChangeEntityType,entityType,onChangeNationality,form} = this.props;
        return (
            <StyledForm onSubmit={this.handleSubmit} onChange={this.props.onChange}>

                <ContactIdFormItemContainer
                    {...this.props}
                    formItemLayout={formItemLayout}
                    hasSuccessIfAvailable={isNewContact}
                    disabled={!isNewContact}
                />
                {/*Nome*/}
                <ContactNameFormItem
                    {...this.props}
                    formItemLayout={formItemLayout}
                    disabled={(items && items.registrant && items.registrant.entityType === 1)}
                    placeholder={isNewContact ? intl.formatMessage({id: 'contact.detail.form.name.placeholder'}) : ''}
                    onChange={(e)=>onChangeName(e,form)}
                />
                {/*Organizzazione*/}
                <ContactOrgFormItemContainer
                    {...this.props}
                    formItemLayout={formItemLayout}
                    // disabled={!this.hasOrgEditPermission()}
                    placeholder={isNewContact ? intl.formatMessage({id: 'contact.detail.form.organization.placeholder'}) : ''}
                    entityType={entityType}
                />
                {/* Indirizzi*/}
                <ContactAddressFormItem
                    {...this.props}
                    formItemLayout={formItemLayout}
                    isEntityType1={false}
                    isNewContact={isNewContact}
                    defaultNationalCode={'IT'}
                    onChangeNationality={(e)=>onChangeNationality(e,form)}
                />
                {
                    isRegistrant &&
                    <RegistrantFormItemContainer
                        {...this.props}
                        formItemLayout={formItemLayout}
                        onChangeEntityType={(e)=>onChangeEntityType(e,form)}
                    />
                }
                <ContactGroup2FormItems
                    {...this.props}
                    formItemLayout={formItemLayout}
                />

            </StyledForm>
        );

    }
}

const BaseContactForm = Form.create()(injectIntl(ContactFormWrapped));
export default BaseContactForm;

BaseContactForm.propTypes = {
    handleSubmit: PropTypes.func,
    onChangeEntityType: PropTypes.func,
    entityType: PropTypes.number,
    onChangeName: PropTypes.func,
    onChangeNationality : PropTypes.func,
    items: PropTypes.object,
    isRegistrant: PropTypes.bool,
    isEdu: PropTypes.bool,
    isGov: PropTypes.bool,
    isNewContact : PropTypes.bool,
};

BaseContactForm.defaultProps = {
    isNewContact : false,
};

export const StyledForm = styled(Form)`
    @media (max-width: 992px) {
      .ant-row{
          .ant-form-item-label {
              text-align: left!important;
              //background-color: red;
          }
      } 
    }

`;
