// ** INFO **

import * as types from "../actionTypes";
import * as api from "../api";

export function infoDomain(domain, authinfo) {
    return {
        type: types.INFO_DOMAIN,
        payload: api.infoDomain(domain, authinfo)
    }
}

export function infoDomainLoader() {
    return {
        type: types.INFO_DOMAIN_LOADER,
    }
}

export function infoDomainSuccess(response, message) {
    return {
        type: types.INFO_DOMAIN_SUCCESS,
        payload: {response, message: message}
    }
}

export function infoDomainFailure(error) {
    return {
        type: types.INFO_DOMAIN_ERROR,
        payload: error
    }
}

export function resetInfoDomain() {
    return {
        type: types.RESET_INFO_DOMAIN
    }
}

export function notificationsReset() {
    return {
        type: types.CLEAN_NOTIFICATIONS_INFO_DOMAIN,
    }
}

export function setAuthinfo(authinfo) {
    return {
        payload: authinfo,
        type: types.SET_AUTHINFO,
    }
}