import React from "react";
import ReactDOM from "react-dom";
import App from "./app/App";
import registerServiceWorker from "./registerServiceWorker";
import "./index.css";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import configureStore from "./app/redux/store/index";
import {addLocaleData} from "react-intl";
import en from "react-intl/locale-data/en";
import it from "react-intl/locale-data/it";
import IntlProvider from "./app/commons/containers/IntlProviderContainer";
import {isTokenValid} from "./app/commons/utils/utils";
import {authOperations} from "./app/authentication";
import {configOperations} from "./app/configuration";

addLocaleData([...it, ...en]);

const store = configureStore();

let token = isTokenValid();

if (token.status) {
    store.dispatch(authOperations.authenticatedUserInfo(token.token))
    store.dispatch(configOperations.loadConfiguration())
}

const body = (
    <Provider store={store}>
        <IntlProvider>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </IntlProvider>
    </Provider>
);

ReactDOM.render(body, document.getElementById('root'));
registerServiceWorker();
