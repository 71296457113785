import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Modal} from "antd";
import * as routes from "../../routes";
import {injectIntl} from "react-intl";
import DomainInfoUpdateBox from "../components/Box/DomainInfoCustomableBox";
import DomainTransferStatus from "../components/Pages/DomainTransfer/DomainTransferStatus";
import DomainTransferTrade from "../components/Pages/DomainTransferTrade/DomainTransferTrade";
import {withRouter} from "react-router-dom";
import {RouteTo} from "./DomainInfoContainer";
import {hanldeErrorDispatching} from "../../commons/utils/utils";
import {
    domainTransferOp, domainTransferRejectOp, tarnsferDomainOp_Status,
    transferDomainApproveOp
} from "../operations";
import {
    transferRequestDomain,
    transferRequestDomainFailure,
    transferRequestDomainReset,
    transferRequestDomainStart,
    transferRequestDomainSuccess
} from "../actions/transferActions";

const confirm = Modal.confirm;

class DomainTransferContainer extends Component {


    isTheSameRegistrar() {
        const {configuration, infoDomain} = this.props;
        if (configuration.username && infoDomain.data && infoDomain.data.clId) {
            return (configuration.username === infoDomain.data.clId)
        } else {
            return false
        }
    }

    componentWillMount() {
        if (this.props.infoDomain.data) {
            const {name, authinfo} = this.props.infoDomain.data;
            this.props.transferStatusDomainAction(name, authinfo)
        }
    }


    handleDomainTransferApproval = ({name: domain, authinfo}) => {
        const {formatMessage} = this.props.intl;
        const {transferDomainApproveAction} = this.props;
        confirm({
            title: formatMessage({id: 'domain.approve.transfer.title'}, {name: domain}),
            content: formatMessage({id: 'domain.approve.transfer.content'}, {name: domain}),
            okText: formatMessage({id: 'yes'}),
            // okType: 'danger',
            cancelText: formatMessage({id: 'no'}),
            onOk() {
                transferDomainApproveAction(domain, authinfo)
            },
            onCancel() {
            },
        });
    }

    handleDomainTransferRejection = ({name: domain, authinfo}) => {
        const {formatMessage} = this.props.intl;
        const {transferDomainRejectAction} = this.props;
        confirm({
            title: formatMessage({id: 'domain.reject.transfer.title'}, {name: domain}),
            content: formatMessage({id: 'domain.reject.transfer.content'}, {name: domain}),
            okText: formatMessage({id: 'yes'}),
            okType: 'danger',
            cancelText: formatMessage({id: 'no'}),
            onOk() {
                transferDomainRejectAction(domain, authinfo)
            },
            onCancel() {
            },
        });
    }

    handleDomainTransferDeletetion = ({name: domain, authinfo}) => {
        const {formatMessage} = this.props.intl;
        const {transferDomainDeleteAction} = this.props;
        confirm({
            title: formatMessage({id: 'domain.info.delete.transfer.title'}, {name: domain}),
            content: formatMessage({id: 'domain.info.delete.transfer.content'}, {name: domain}),
            okText: formatMessage({id: 'yes'}),
            okType: 'danger',
            cancelText: formatMessage({id: 'no'}),
            onOk() {
                transferDomainDeleteAction(domain, authinfo)
            },
            onCancel() {
            },
        });
    }

    back() {
        this.props.history.push({
            pathname: `${routes.DOMAIN_INFO.url}/${this.props.infoDomain.data.name}`
        })
    }

    render() {
        const {formatMessage} = this.props.intl;
        const {transferStatusDomain, configuration, infoDomain, hasRegistrarPermission, transferRequestDomain} = this.props
        switch (this.props.match.path) {

            case routes.DOMAIN_TRANSFER_TRADE.path:
                return (
                    <RouteTo {...this.props}
                             comp={
                                 <DomainTransferTrade
                                     hasRegistrarPermission={this.isTheSameRegistrar()}
                                     onCancel={this.back.bind(this)}
                                     onTransferTrade={this.props.transferTradeAction}
                                     reset={this.props.resetDomainTransferRequestAction}
                                 />
                             }
                    />
                );
            default:

                return (
                    <DomainInfoUpdateBox
                        title={formatMessage({id: "domain.transfer.status.form.title"})}
                        hasRegistrarPermission={hasRegistrarPermission}
                        infoData={infoDomain.data}
                        error={transferRequestDomain.error || transferStatusDomain.error}
                        loading={transferRequestDomain.loading || transferStatusDomain.loading}
                        message={transferRequestDomain.message || transferStatusDomain.message}
                        reset={this.props.resetDomainTransferRequestAction}
                    >
                        <DomainTransferStatus
                            transferData={transferStatusDomain}
                            myRegistrar={configuration.username}
                            infoDomainData={infoDomain}
                            onTransferStatus={this.props.transferStatusDomainAction}
                            onCancel={this.props.onCancel}
                            onRejectTransfer={this.handleDomainTransferRejection}
                            onDeleteTransfer={this.handleDomainTransferDeletetion}
                            onApproveTransfer={this.handleDomainTransferApproval}
                        />
                    </DomainInfoUpdateBox>
                )
        }
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        transferDomainApproveAction: (domain, authinfo) => {
            let successMessage = 'Richiesta di trasferimento accettata'
            dispatch(transferDomainApproveOp(domain, authinfo, successMessage))
        },
        transferDomainRejectAction: (domain, authinfo) => {
            let successMessage = 'Rifiutata richiesta di trasferimento'
            dispatch(domainTransferRejectOp(domain, authinfo, successMessage))
        },
        transferDomainDeleteAction: (domain, authinfo) => {
            let successMessage = 'Annullata richiesta di trasferimento'
            dispatch(domainTransferOp(domain, authinfo, successMessage))
        },
        transferStatusDomainAction: (domain, authinfo) => {
            dispatch(tarnsferDomainOp_Status(domain, authinfo))
        },
        // resetDomainTransferStatusAction: () => {
        //     dispatch(transferDomainReset())
        // },
        resetDomainTransferRequestAction: () => {
            dispatch(transferRequestDomainReset())
        },

//TODO trasportare in Operations
        transferTradeAction: (domain, authinfo, data) => {
            let successMessage = 'Trasferimento eseguito correttamente'
            let searchParams;

            if (authinfo) {
                let sp = new URLSearchParams();
                sp.set('authinfo', authinfo);
                searchParams = sp.toString()
            }

            dispatch(
                function (dispatch) {
                    dispatch(transferRequestDomainStart())
                    dispatch(transferRequestDomain(domain, authinfo, data))
                        .then((response) => {
                                if (!response.error) {
                                    dispatch(transferRequestDomainSuccess(response.payload, successMessage))
                                    ownProps.history.push(
                                        {
                                            pathname: `${routes.DOMAIN_TRANSFER.url}/${domain}`,
                                            search: searchParams
                                        })
                                } else {
                                    if (response.payload.response.data.result.code === 2301) {
                                        dispatch(transferRequestDomainSuccess({data: response.payload.response.data}))
                                    }
                                    else {
                                        dispatch(hanldeErrorDispatching(response.payload, transferRequestDomainFailure))
                                    }
                                }
                            }
                        )
                }
            )
        }
    };
}

const mapStateToProps = (state) => {
    return {
        infoDomain: state.domain.infoReducer,
        transferStatusDomain: state.domain.transferReducer.transferStatusDomain,
        transferRequestDomain: state.domain.transferReducer.transferRequestDomain,
        configuration: state.confReducer,
        domainNotify: state.domain.transferReducer.domainNotify,
    };
}

DomainTransferContainer.propTypes = {
    hasRegistrarPermission: PropTypes.bool,
    onCancel: PropTypes.func
};
DomainTransferContainer.defaultProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(DomainTransferContainer)));
