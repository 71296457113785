import React, {Component} from 'react';
import './Splash.css';

import {injectIntl} from 'react-intl';


class Splash extends Component {

  render() {
    const {formatMessage} = this.props.intl;

    let message = formatMessage({id:this.props.message});
    return (
      <div className='splash'>
        <div className="logo">
          <img alt={'logo'} src="/logo-epic.svg"/>
        </div>
      <div className="message">{message}</div>
    </div>
    )
  }
}
export default injectIntl(Splash);
