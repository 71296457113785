import React from 'react';
import PropTypes from 'prop-types';
import {Card} from 'antd';
import styled from "styled-components";

const Container = styled(Card)`
`;

class CardDetail extends React.Component {
    render() {
        const {label,className} = this.props;
        return (
            <Container bordered={this.props.bordered} title={label} className={className}>
                {this.props.children}
            </Container>
        );
    };
}

export default CardDetail;

CardDetail.propTypes = {
    label: PropTypes.string,
    children: PropTypes.any
};
