import React from 'react';
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import CardDetailRow from "../../../../commons/components/DataDisplay/CardDetailRow/CardDetailRow";


const RegistrantDetail = (props) => {
    const {formatMessage} = props.intl;
    const {nationalityCode, entityType, regCode, schoolCode, gov} = props.data;
    return (
        <div>
            <CardDetailRow
                label={formatMessage({id: "detail.registrant.nationalityCode"})}
                content={nationalityCode}
            />
            <CardDetailRow
                label={formatMessage({id: "detail.registrant.entityType"})}
                content={formatMessage({id: "entityTypeSelect." + entityType})}
            />
            <CardDetailRow
                label={formatMessage({id: "detail.registrant.regCode"})}
                content={regCode}
            />
            {schoolCode &&
            <CardDetailRow
                label={formatMessage({id: "detail.registrant.schoolCode"})}
                content={schoolCode}/>
            }

            {gov &&
            <CardDetailRow
                label={formatMessage({id: "detail.registrant.gov.ipaCode"})}
                content={gov.ipaCode}/>
            }

            {gov && gov.uoCode &&
            <CardDetailRow
                label={formatMessage({id: "detail.registrant.gov.uoCode"})}
                content={gov.uoCode}/>
            }

        </div>
    );
};


RegistrantDetail.propTypes = {
    data: PropTypes.any.isRequired,
};

export default injectIntl(RegistrantDetail);

