import React from 'react';
import {Form, Icon, Input} from 'antd';
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import SearchContact from "app/domains/components/Pages/DomainNew/SearchContact";
import {withRouter} from "react-router-dom";
import ContactIdValidator from "app/commons/utils/validators/ContactIdValidator";
import {debounce} from "app/domains/components/Pages/DomainNew/Utils";
import ContactInfoBar from "app/domains/components/Pages/DomainNew/ContactInfoBar";
import {contactFromId} from "app/contacts/api";
import {getRegistrantType} from "app/commons/utils/utils";

const FormItem = Form.Item;

class ContactLookUpFormItem extends React.Component {

    state = {
        fieldStatus: null,
        fieldHelp: null,
        showModal: false,
        details: null
    }

    constructor(props) {
        super(props);
        this.contactLookUp = debounce(this.contactLookUp, 800)
    }

    componentWillMount() {
        this.initContact(this.props.initialValue)
    }

    contactLookUp = (id) => {
        const {formatMessage} = this.props.intl
        contactFromId(id)
            .then(result => {
                if (this.isRegistrant(result.data)) {
                    this.loadContactDetail(result.data)
                } else {
                    this._setIdFieldInvalid(formatMessage({id: 'contact.detail.form.id.error.message.notValidRegistrant'}))
                }
            })
            .catch(err => {
                this._setIdFieldInvalid(formatMessage({id: 'contact.detail.form.id.error.message.checkIderror'}, {error: err}))
            })
    }

    isRegistrant = (data) => {
        return data.registrant
    }

    onCancel = () => {
        this._closeModalWindow()
    }

    loadContactDetail = (data) => {
        this.setState({details: data})
        this._setIdFieldValid('')
        this.props.onSelectedRegistrant(data)

    }

    //Controlla se l'id contatto rispetta i vincoli
    checkContactIdConstraints = (e, check) => {
        const {formatMessage} = this.props.intl;
        const value = e.target.value

        if (value) {
            let cId = ContactIdValidator.validate(value, true);

            if (!cId.valid) {
                this._setIdFieldInvalid(formatMessage({id: cId.error}))
            } else {
                this._setIdFieldValidating()
                check(value)
            }

        } else {
            this._setIdFieldInvalid(formatMessage({id: "invalidContactId"}))
        }
    }


    initContact = (values) => {
        if (values) {
            if (values.id && values.details) {
                const {setFieldsValue} = this.props.form;
                setFieldsValue({'id': values.id.toUpperCase()})
                this.loadContactDetail(values.details)
            }
        }
    }

    handleSearchContactOk = (field, id, details) => {
        if (id && details) {
            this.initContact({id, details})
        }
        this._closeModalWindow();
    }


    _renderHelp = () => {
        if (this.state.fieldStatus === 'success') {
            return <ContactInfoBar data={this.state.details} showRegistrant={true}/>
        } else {
            return this.state.fieldHelp
        }

    }

    _setIdFieldInvalid = (message) => {
        this.setState({fieldHelp: message})
        this.setState({fieldStatus: 'error'})
    }

    _setIdFieldValid = (message) => {
        this.setState({fieldHelp: message})
        this.setState({fieldStatus: 'success'})
    }

    _setIdFieldValidating = () => {
        this.setState({fieldHelp: ''})
        this.setState({fieldStatus: 'validating'})
    }

    _closeModalWindow = () => {
        this.setState({showModal: false})
    }

    _showModalWindow = () => {
        this.setState({showModal: true})
    }

    handleOnChange=(e)=>{
        this.checkContactIdConstraints(e, this.contactLookUp)
    }

    render() {
        const {getFieldDecorator} = this.props.form
        const {formatMessage} = this.props.intl;

        return (
            <span>
                 <FormItem
                     {...this.props.formItemLayout}
                     label={this.props.labelContactId}
                     hasFeedback
                     validateStatus={this.state.fieldStatus}
                     help={this.props.showContactBar && this._renderHelp()}
                 >
                    {getFieldDecorator('id', {
                        initialValue: this.props.initialValue && this.props.initialValue.id,
                        rules: [{
                            required: true,
                        }
                        ]
                    })(
                        <Input disabled={this.props.disabled}
                               onChange={this.handleOnChange}
                               placeholder={formatMessage({id: 'contact.detail.form.id.placeholder'})}
                               addonAfter={<Icon onClick={this._showModalWindow.bind(this)} type='search'/>}
                        />
                    )}
                </FormItem>
                <SearchContact title="Cerca ID Contatto"
                               contactField="id"
                               contactKind={getRegistrantType(this.props.domainName)}
                               showRegistrant={true}
                               onCancel={this.onCancel}
                               onOk={this.handleSearchContactOk}
                               placeholder="Cerca Contatto"
                               visible={this.state.showModal}
                               onSelectedRegistrant={this.props.onSelectedRegistrant}
                               domainName={this.props.domainName}
                />
            </span>
        );
    }
};


export default withRouter(injectIntl(ContactLookUpFormItem));

ContactLookUpFormItem.propTypes = {
    domainName: PropTypes.string,
    form: PropTypes.object.isRequired,
    labelContactId: PropTypes.string,
    initialValue: PropTypes.object,
    formItemLayout: PropTypes.object,
    showContactBar: PropTypes.bool,
    onSelectedRegistrant: PropTypes.func,
}

ContactLookUpFormItem.defaultProps = {
    labelContactId: "Id",
    showContactBar: true,
}
