import React, {Component} from 'react';
import {Button, Col, Form, Icon, Input, Row} from 'antd';
import get from 'lodash/get';

import {injectIntl} from 'react-intl';


import ContactInfoBar from './ContactInfoBar';
import SearchContact from './SearchContact';

import WizardComponentHeader from './WizardComponentHeader';


import './DomainContacts.css';
import './Fields.css';
import {contactFromId} from "../../../../contacts/api";
import {CONTACT_TYPE} from "app/contacts/constants/const";
import {printMessageError} from "app/commons/utils/common";
import {GOV_SUFFIX} from "../../../../commons/constants/constants";

const FormItem = Form.Item;

const CONF = {
    MIN_TECHID: 1,
    MAX_TECHID: 6
};


const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 5},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 'contact'},
    },
};

const formItemLayoutWithLabel = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 5},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 'tech'},
    },
};


const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: {span: 24, offset: 0},
        sm: {span: 'tech', offset: 5},
    },
};


function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this, args = arguments;

        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};


/*
Nome: Brecher William
Organizzazione: 2000NET S.R.L.
Indirizzo: VIA XXV APRILE, 47
Città: BORGOSESIA
Provincia: VC
CAP: 13011
Nazione: Italy
Telefono: +39.0163209111
E-Mail: MNT@2000net.i
*/

let uuid = 2;


class DomainContactsWrapper extends Component {

    constructor(props) {
        super(props);
        //  this.debouncing=this.debouncing.bind(this);
        this.state = {

            adminContactId: null,
            registrantContactId: null,
            renderRegistrantContactId: true,
            renderAdminContactId: true,
            showModal: {status: false, field: ''}
        }
        this.techIdMap = {};
        this.selectedTechItems = [];
    }


    getContactFromId = (contactField, destState) => {
        const {fields} = this.props;
        let res = null;

        const contactId = fields[`${contactField}`];

        if (contactId != null) {

            contactFromId(contactId)
                .then((result) => {

                    let t = this.state[`${destState}`];
                    t.push({contactId: contactId, name: result.data.name, org: result.data.org});

                    let obj = {}
                    obj[`${destState}`] = t;

                    this.setState(obj);
                })
                .catch((error) => {
                    console.log("Error: ", error.response, error.status);
                    // TODO: redirect login form
                })
        }

        return res;

    }


    debouncingTechInput = (value, kind, field) => {
        const {formatMessage} = this.props.intl;

        let fieldValue = {};

        fieldValue[field] = {error: false, value: ''}

        this.setState(fieldValue);

        if (this.contactIdLengthIsValid(value, 3, 32)) {
            if (this.checkTechs(field, value)) {
                fieldValue[field] = {error: true, value: 'Contatto esistente'}
                this.setState(fieldValue);
            } else {
                this.debouncingInput(value, kind, field);
            }
        } else {
            fieldValue[field] = {error: true, value: formatMessage({id: 'domain.new.Wizard.contactIdLenght'})};
            this.setState(fieldValue);

        }
    }


    debouncingInput = (value, kind, field) => {
        const {getFieldDecorator, setFieldsValue} = this.props.form;
        const {formatMessage} = this.props.intl;
        let fieldValue = {}

        fieldValue[field] = {error: false, value: ''}

        this.setState(fieldValue);


        if (this.contactIdLengthIsValid(value, 3, 32)) {

            contactFromId(value)
                .then((result) => {

                    fieldValue[field] = {error: false, value: result.data};
                    let fieldObj = {};
                    fieldObj[field] = value;
                    getFieldDecorator(`${field}-Full`, {initialValue: this.props.fields[`${field}-Full`] ? this.props.fields[`${field}-Full`] : ''});
                    let fieldFull = {};
                    fieldFull[`${field}-Full`] = result.data;


                    if (kind === 'r') {

                        const domainName = this.props.fields['domainName'];


                        const govField = get(result.data, 'registrant.gov');

                        if (govField !== undefined) {
                            if (!domainName.endsWith(GOV_SUFFIX)) {
                                fieldValue[field] = {
                                    error: true,
                                    value: `Registrante non compatibile con dominio ${domainName}`
                                };
                            }
                        } else {
                            if (domainName.endsWith(GOV_SUFFIX)) {
                                fieldValue[field] = {
                                    error: true,
                                    value: `Registrante non compatibile con dominio ${domainName}`
                                };
                            }
                        }

                    }
                    setFieldsValue(fieldFull);
                    setFieldsValue(fieldObj);
                    this.setState(fieldValue);

                })
                .catch((error) => {
                    if (error.response) {
                        let errorString = (error.response.data.reason ? error.response.data.reason.message : (printMessageError(error) || 'error' ));
                        fieldValue[field] = {error: true, value: errorString};

                    } else {
                        fieldValue[field] = {error: true, value: error};

                    }
                    // TODO: Bisogna ridirigere alla login form?
                    this.setState(fieldValue);

                })
        } else {
            fieldValue[field] = {error: true, value: formatMessage({id: 'domain.new.Wizard.contactIdLenght'})};
            this.setState(fieldValue);

        }

    }


    componentWillMount() {
        this.debouncingInput = debounce(this.debouncingInput, 400, false);
        this.debouncingTechInput = debounce(this.debouncingTechInput, 400, false);
        const {getFieldDecorator} = this.props.form;
        const {fields} = this.props;

        getFieldDecorator('techIds', {initialValue: fields.techIds ? fields.techIds : [0]});
    }


    manageOk = (field, value, fullValue) => {
        const {setFieldsValue, getFieldDecorator} = this.props.form;

        let modalStatus = {};
        modalStatus[`showModal${field}`] = false;
        this.setState(modalStatus);
        let valueField = {};

        valueField[field] = value;

        setFieldsValue(valueField);

        let stateObj = {};

        stateObj[field] = {error: false, value: fullValue}

        getFieldDecorator(`${field}-Full`,
            {initialValue: this.props.fields[`${field}-Full`] ? this.props.fields[`${field}-Full`] : ''});

        let fieldFull = {};

        fieldFull[`${field}-Full`] = fullValue;

        setFieldsValue(fieldFull);

        this.setState(modalStatus);

        this.setState(stateObj);

    }


    componentDidMount() {
        const {fields} = this.props;
        const {setFieldsValue} = this.props.form;

        let adminObject = null;
        let registrantObject = null;
        let techObj = null;
        let techObjFull = null;
        let registrantObjectFull = null, adminObjectFull = null;

        if (fields.adminContactId) {
            adminObject = {}
            adminObjectFull = {}
            adminObject['adminContactId'] = fields.adminContactId;
            //this.fillContactInfo(fields.adminContactId, 'adminContactId');
            adminObjectFull["adminContactId"] = {error: false, value: fields["adminContactId-Full"]};
            this.setState(adminObjectFull);
        }

        if (fields.registrantContactId) {
            registrantObject = {};
            registrantObjectFull = {};
            registrantObject['registrantContactId'] = fields.registrantContactId;
            //setFieldsValue(registrantObject);
            //this.fillContactInfo(fields.registrantContactId, 'registrantContactId');
            registrantObjectFull["registrantContactId"] = {error: false, value: fields["registrantContactId-Full"]};
            this.setState(registrantObjectFull);
        }

        if (fields.techIds) {
            techObj = {};
            techObjFull = {};
            fields.techIds.forEach((t) => {

                techObj[`techId-${t}`] = fields[`techId-${t}`];
                techObjFull[`techId-${t}`] = {error: false, value: fields[`techId-${t}-Full`]};

                //this.fillContactInfo(fields[`techId-${t}`], `techId-${t}`);
                this.setState(techObjFull);

                //  this.fillContactInfo(`techId-${t}`, `techId-${t}`)

            })
        }
        // TODO: Controllare se questa condizione è valida anche nel caso non embedded;
        if (adminObject || registrantObject || techObj) {
            let obg = {...adminObject, ...techObj, ...registrantObject};

            setFieldsValue(obg);
        }
    }


    contactIdLengthIsValid(contactId, min, max) {
        return (contactId.length >= min && contactId.length <= max);
    }

    remove = (id) => {
        const {form} = this.props;
        // can use data-binding to get
        const techIds = form.getFieldValue('techIds');
        // We need at least one passenger
        if (techIds.length <= CONF.MIN_TECHID) {
            return;
        }

        // can use data-binding to set
        form.setFieldsValue({
            techIds: techIds.filter(techId => techId !== id),
        });
        this.setState({refresh: true});
    }

    add = () => {
        uuid++;
        const {form} = this.props;
        // can use data-binding to get
        const techIds = form.getFieldValue('techIds');
        const nextIds = techIds.concat(uuid);
        // can use data-binding to set
        // important! notify form to detect changes
        form.setFieldsValue({
            techIds: nextIds,
        });
    }


    renderHelp = (field) => {
        return this.state[field] ? <ContactInfoBar data={this.state[field]}/> : ''
    }

    renderHelpAndError = (field, showRegistrant) => {
        //console.log("renderErrorAndHelp: ", field);

        if (this.state[field]) {
            if (this.state[field].error) {
                return <span style={{color: 'red'}}>{this.state[field].value}</span>
            } else {
                return (this.state[field].value ?
                    <ContactInfoBar data={this.state[field].value} showRegistrant={showRegistrant}/> : '');
            }
        }
    }

    onCancel = (field) => {
        let stateField = {};
        stateField[`showModal${field}`] = false;
        this.setState(stateField);
    }

    checkTechs(formField, value) {
        const {getFieldValue} = this.props.form;
        let exists = false;
        let techIds = getFieldValue('techIds');

        let definedTechId;
        let field;
        techIds.some((k, i) => {
            field = `techId-${k}`;
            definedTechId = getFieldValue(field);
            exists = (field !== formField && definedTechId && value && definedTechId.toLowerCase() === value.toLowerCase());
            return exists;
        });
        return exists;
    }

    /*
      // "Estensione della validazione del contatto"
      techFieldValidator = (rule, value, callback) => {
        const {formatMessage}=this.props.intl;


        let exists = false;
        if (value) {
          exists = this.checkTechs(rule.field, value)
        }

        if (exists) {
          let error = {};
          error[rule.field]={error:true, value:formatMessage({id:'domain.new.Wizard.techContactExists'})};
          this.setState(error);
          callback(new Error("Contatto Tecnico già presente"))
        } else {
          // Eseguo la validazione classica sul contatto
          this.fieldValidator(rule,value,callback);
        }
      }
    */

    fieldValidator = (rule, value, callback) => {
        //console.log("FieldValidator:", this.state[rule.field]);
        const {formatMessage} = this.props.intl;

        if (this.state[rule.field]) {
            //console.log("field ", rule.field, " Field value ", this.state[rule.field]);
            if (this.state[rule.field].error) {

                callback(new Error(this.state[rule.field].value))
            } else {
                callback();
            }
        } else {
            let error = {};
            error[rule.field] = {error: true, value: formatMessage({id: 'domain.new.Wizard.emptyField'})};
            this.setState(error);
            callback(new Error(formatMessage({id: 'domain.new.Wizard.emptyField'})));
        }

    }

    validateStatus = (field, value) => {
        //  console.log("ValidateStatus::");
        let status = "";
        const {getFieldValue} = this.props.form;
        if (!this.state[`showModal${field}`]) {

            let formValue = getFieldValue(field);
            if (formValue) {
                if (value) {
                    if (value.error) {
                        status = 'error';
                    } else {
                        status = 'success';
                    }
                } else {
                    status = "error";
                }
            }
        }
        //  console.log("ValidateStatus: ", value, "vv: ", formValue," status: ", status);
        return status;
    }

    /*
      validateTechStatus = (field, value) => {
        const {getFieldValue} = this.props.form;
        console.log("ValidateTechStatus::", field, value);
        let status="";
        let formValue = getFieldValue(field);
        console.log("ValidateTechStatus::", field, value, formValue);


        if (formValue) {
          if (this.checkTechs(field,formValue)) {
            if (!value.error) {
              let error={};
              error[field]={error:true, value:'techId esistente'};
              this.setState(error);
            }
            status="error";
          } else {
            status = (value.error?"error":"success");
          }
        }
        return status;
      }

    */

    getRegistrantType = (domainName) => {
        if (domainName !== undefined) {
            if (domainName.endsWith('.edu.it')) {
                return CONTACT_TYPE.registrantEdu;
            }
            if (domainName.endsWith(GOV_SUFFIX)) {
                return CONTACT_TYPE.registrantGov;
            }
        }
        return CONTACT_TYPE.registrant
    }


    render() {

        const {getFieldDecorator, getFieldValue} = this.props.form;
        const {formatMessage} = this.props.intl;
        const {fields} = this.props;
        const domainName = this.props.fields['domainName']
        const registrantType = this.getRegistrantType(domainName);

        let techIds = getFieldValue('techIds');
        let techItems = [];
        techIds.forEach((k, index) => {

            let elem =
                <Row key={k} type="flex">

                    <Col span={19} offset={1}>

                        <FormItem
                            className="contacts"
                            {...(index === 0 ? formItemLayoutWithLabel : formItemLayoutWithOutLabel)}
                            label={index === 0 ? formatMessage({id: 'techContactIdLabel'}) : ''}
                            required={true}
                            hasFeedback
                            help={this.renderHelpAndError(`techId-${k}`, false)}
                            validateStatus={this.validateStatus(`techId-${k}`, this.state[`techId-${k}`])}
                        >

                            {getFieldDecorator(`techId-${k}`, {
                                rules: [{
                                    required: true,
                                    //message: formatMessage({id: 'techContactIdMessage'}),
                                }, {validator: this.fieldValidator}],
                            })(
                                <Input
                                    key={k}
                                    disabled={fields[`disabled-${k}`]}
                                    onChange={(e) => {
                                        e.persist();
                                        this.debouncingTechInput(e.target.value, "", `techId-${k}`)
                                    }}
                                    placeholder={formatMessage({id: 'techContactIdPlaceholder'})}
                                    addonAfter={<Icon disabled={`disabled-${k}`} onClick={() => {
                                        if (!fields[`disabled-${k}`]) {
                                            let stateField = {};
                                            stateField[`showModaltechId-${k}`] = true;
                                            //this.setState({showModal:{status:true, field:`techId-${k}`}})
                                            this.setState(stateField);
                                        }
                                    }} type='search'/>}
                                />
                            )}
                        </FormItem>

                        <SearchContact title="Cerca ID Contatto Tecnico"
                                       placeholder="Cerca Contatto"
                                       contactField={`techId-${k}`}
                                       showRegistrant={false}
                                       onOk={this.manageOk}
                                       onCancel={this.onCancel}
                                       contactKind="c"
                                       validate={this.checkTechs.bind(this)}
                                       visible={this.state[`showModaltechId-${k}`]}
                                       domainName={domainName}
                        />


                    </Col>

                    <Col span={1} style={{paddingTop: '5px'}}>
                        {techIds.length > CONF.MIN_TECHID ? (
                            <Icon
                                style={{fontSize: '12pt'}}
                                className="dynamic-delete-button"
                                type="minus-circle-o"
                                disabled={(techIds.length === 1 || fields[`disabled-${k}`])}
                                onClick={() => {
                                    if (!fields[`disabled-${k}`])
                                        this.remove(k)
                                }}
                            />
                        ) : null}

                    </Col>
                </Row>

            techItems.push(elem);
        })


        return (
            <div className="wizard-component-header">
                {
                    !this.props.fields.dnsEmbedded &&
                    <WizardComponentHeader {...this.props} />
                }
                <Form>
                    {!fields[`hide-registrant`] &&
                    <div className="riquadro">


                        <FormItem

                            {...formItemLayout}
                            hasFeedback
                            label={formatMessage({id: 'registrantContactIdLabel'})}
                            validateStatus={this.validateStatus(`registrantContactId`,
                                this.state[`registrantContactId`])}
                            help={this.renderHelpAndError('registrantContactId', true)}
                        >

                            {getFieldDecorator('registrantContactId', {

                                rules: [{
                                    required: true,
                                    //message: formatMessage({id: 'registrantContactIdMessage'})
                                },
                                    {validator: this.fieldValidator}
                                ],
                            })(
                                <Input placeholder={formatMessage({id: 'registrantContactIdPlaceholder'})}
                                       onChange={(e) => {
                                           e.persist();
                                           this.debouncingInput(e.target.value, "r", `registrantContactId`)
                                       }}
                                       addonAfter={<Icon onClick={() => {
                                           this.setState({showModalregistrantContactId: true})
                                       }} type='search'/>}/>
                            )}

                        </FormItem>

                        <SearchContact title="Cerca ID Contatto Registrante"
                                       contactField="registrantContactId"
                                       contactKind={registrantType}
                                       showRegistrant={true}
                                       onCancel={this.onCancel}
                                       onOk={this.manageOk}
                                       domainName={domainName}
                                       placeholder="Cerca Contatto" visible={this.state.showModalregistrantContactId}
                        />
                    </div>
                    }
                    <div className="riquadro">

                        <FormItem
                            {...formItemLayout}
                            hasFeedback
                            label={formatMessage({id: 'adminContactIdLabel'})}
                            validateStatus={this.validateStatus(`adminContactId`,
                                this.state[`adminContactId`])}
                            help={this.renderHelpAndError('adminContactId', false)}
                        >
                            {getFieldDecorator('adminContactId', {
                                rules: [{
                                    required: true,
                                    //message: formatMessage({id: 'adminContactIdMessage'})
                                }, {validator: this.fieldValidator}
                                ],
                            })(
                                <Input
                                    disabled={fields[`disabled-admin`]}
                                    placeholder={formatMessage({id: 'adminContactIdPlaceholder'})}
                                    onChange={(e) => {
                                        e.persist();
                                        this.debouncingInput(e.target.value, "", `adminContactId`)
                                    }}
                                    addonAfter={<Icon disabled={fields[`disabled-admin`]} onClick={() => {
                                        if (!fields[`disabled-admin`]) this.setState({showModaladminContactId: true})
                                    }} type='search'/>}
                                />
                            )}
                        </FormItem>
                        <SearchContact title="Cerca ID Contatto Amministratore"
                                       onOk={this.manageOk}
                                       contactKind="c"
                                       onCancel={this.onCancel}
                                       showRegistrant={false}
                                       contactField="adminContactId"
                                       placeholder="Cerca Contatto" visible={this.state.showModaladminContactId}
                                       domainName={domainName}
                        />
                    </div>
                    <div className="riquadro">
                        {techItems}
                        <Button disabled={(techIds.length >= CONF.MAX_TECHID)} type="dashed" onClick={this.add}>
                            <Icon type="plus"/>{formatMessage({id: 'addTechId'})}</Button>
                    </div>
                </Form>
            </div>
        )
    }
}


const DomainContacts = Form.create()(injectIntl(DomainContactsWrapper));
export default DomainContacts;
