import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button} from "antd";
import {injectIntl} from "react-intl";
import "./DomainTransferStatus.css"
import {CLIENT_APPROVED, CLIENT_CANCELLED, CLIENT_REJECTED, SERVER_APPROVED} from "../../../constants/statuses";
import TradeData from "./TradeData";
import TransferStatus from "./TransferStatus";
import RegistersInvolved from "./RegistersInvolved";

/**
 *
 * stati possibili:
 * pending clientApproved clientRejected clientCancelled serverApproved
 *
 *
 * reId: Client ID della richiesta - Identificativo del Registrar che ha sottomesso la richiesta di trasferimento
 * reDate: Data della richiesta - Data in cui è stata sottomessa la richiesta di trasferimento
 * acId: Client ID della accettazione della richiesta Identificativo del Registrar a cui è richiesta la possibile accettazione del trasferimento
 * acdate: Data della accettazione della - Data entro la quale il trasferimento può essere accettato
 */

const STATUSES_REJECTED = [CLIENT_REJECTED, CLIENT_CANCELLED]
const STATUSES_APPROVED = [CLIENT_APPROVED, SERVER_APPROVED]

const STYLE_APPROVED = {style: {statusIcon: 'fal fa-check-square', className: 'approved'}}
const STYLE_REJECTED = {style: {statusIcon: 'fal fa-ban', className: 'rejected'}}
const STYLE_DEFAULT = {style: {statusIcon: 'fal fa-exchange', className: 'default'}}

function isInEppManagedState(code) {
    return (code === 2301);
}

const NotInTransfer = (props) => (
    <div className='padded'>{props.intl.formatMessage({id: 'domain.transfer.status.notInTransfer'})}</div>
)


const ShowDomainTransferStatus = (props) => {
    const {formatMessage} = props.intl;
    const {isAccetable, isTransferDeletable} = props;
    const {transferData} = props;

    return (
        <div className='main-box'>

            {transferData &&
            <span>
                <RegistersInvolved {...transferData['data']} />
                <TransferStatus {...transferData['data']}/>
                <TradeData {...props}/>
            </span>
            }

            <div className='buttons'>
                <Button className={'cancel-button'} onClick={props.onCancel}
                >{formatMessage({id: 'cancel'})}</Button>
                {isAccetable &&
                <span>
                            <Button type="accept" className='accept-button'
                                    onClick={props.onApproveTransfer.bind(this, props.infoDomainData.data)}>
                            {formatMessage({id: 'domain.transfer.accept'})}</Button>
                            <Button type="danger"
                                    onClick={props.onRejectTransfer.bind(this, props.infoDomainData.data)}>
                            {formatMessage({id: 'domain.transfer.reject'})}</Button>
                            </span>
                }

                {isTransferDeletable &&
                <Button type="danger"
                        onClick={props.onDeleteTransfer.bind(this, props.infoDomainData.data)}>
                    {formatMessage({id: 'domain.transfer.cancelTransfer'})}</Button>
                }
            </div>
        </div>
    )
}


class DomainTransferStatus extends Component {

    state = {
        style: {
            statusIcon: STYLE_DEFAULT.style.statusIcon,
            className: STYLE_DEFAULT.style.className
        }
    }

    componentWillReceiveProps(nextProps) {
        const {data} = nextProps.transferData;
        this.setStyleAttributes(data)
    }

    setStyleAttributes(transferData) {
        //funzione per definire lo stile e icone da visualizzare
        if (transferData) {
            const {status} = transferData;
            if (STATUSES_APPROVED.includes(status)) {
                this.setState(STYLE_APPROVED)
            }
            else if (STATUSES_REJECTED.includes(status)) {
                this.setState(STYLE_REJECTED)
            }
        } else {
            this.setState(STYLE_DEFAULT)
        }
    }

    isRequestByMe() {
        const {myRegistrar} = this.props;
        const {data} = this.props.transferData;
        return (
            data &&
            data.reId &&
            myRegistrar === data.reId
        )
    }

    isRegisterFromMe() {
        const {myRegistrar} = this.props;
        const {data} = this.props.transferData;
        return (
            data &&
            data.acId &&
            myRegistrar === data.acId
        )
    }

    isAccetable() {
        const {data} = this.props.transferData;
        return (
            data &&
            data.status &&
            data.status === 'pending' &&
            this.isRegisterFromMe()
        )
    }

    isTransferDeletable() {
        const {data} = this.props.transferData;
        return (
            data && data.status &&
            !STATUSES_REJECTED.includes(data.status) &&
            !STATUSES_APPROVED.includes(data.status) &&
            this.isRequestByMe())
    }


    render() {
        const {data} = this.props.transferData;
        return (
            <div>
                {
                    data && data.result &&
                    isInEppManagedState(data.result.code) &&
                    <NotInTransfer {...this.props}/>
                }

                {
                    data && data.status &&
                    <ShowDomainTransferStatus
                        {...this.props}
                        isAccetable={this.isAccetable()}
                        isTransferDeletable={this.isTransferDeletable()}
                        style={this.state.style}
                    />
                }

            </div>
        );
    }
}

DomainTransferStatus.propTypes = {
    transferData: PropTypes.object,
    infoDomainData: PropTypes.object,
    myRegistrar: PropTypes.string,
    onApproveTransfer: PropTypes.func,
    onRejectTransfer: PropTypes.func,
    onDeleteTransfer: PropTypes.func,
    onCancel: PropTypes.func,

};
DomainTransferStatus.defaultProps = {};

export default injectIntl(DomainTransferStatus);
