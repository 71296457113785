// import axios from "axios/index";
import * as api from "./constants/api";
import http from 'axios'
import {splitStringAddPlus} from "../commons/utils/utils";

// let http = axios.create({
//     timeout: 20000,
//     // headers: {'Authorization': localStorage.getItem("token")}
// });

function appendAuthorizationHeader(params) {

    return {headers: {'Authorization': localStorage.getItem("token")}, params: params}
}


//CONTACT
export function infoContact(contact) {
    return http.get(`${api.EPP_CONTACT}/${encodeURIComponent(contact)}`, appendAuthorizationHeader());
}

export function checkContactId(contactId) {
    return http.get(`${api.SEARCH_CONTACT}/${encodeURIComponent(contactId)}/check`, appendAuthorizationHeader());
}

export function updateContact(contactId, data) {
    return http.patch(`${api.EPP_CONTACT}/${encodeURIComponent(contactId)}`, data, appendAuthorizationHeader());
}

export function newContact(contact) {
    return http.post(`${api.EPP_CONTACT}`, contact, appendAuthorizationHeader());
}

export function deleteContact(contact) {
    return http.delete(`${api.EPP_CONTACT}/${encodeURIComponent(contact)}`, appendAuthorizationHeader());
}

export function countriesLookUp(name, nationalityCode) {
    return http.get(`${api.REGISTRY_COUNTRIES}?${name ? `name=${encodeURIComponent(name)}` : ''}${encodeURIComponent(nationalityCode) ? '&enabled=true' : ''}`, appendAuthorizationHeader());
}

export function countries() {
    return http.get(`${api.REGISTRY_COUNTRIES}`, appendAuthorizationHeader());
}

export function searchContactsByName(name, type = "c", limit = 5) {

    let regParams = {}
    if (type === 'registrant' || 'r') {
        regParams = {
            type: 'registrant',
            org: splitStringAddPlus(name),
            // size: limit,
        }
    }
    if (type === 'gov') {
        regParams = {
            type: 'gov',
            org: splitStringAddPlus(name),
        }
    }

    if (type === 'edu') {
        regParams = {
            type: 'edu',
            org: splitStringAddPlus(name),
        }
    }


    const contactParams = {
        type: 'contact',
        name: splitStringAddPlus(name),
        // size: limit,
    }

    return http.get(`${api.REGISTRY_CONTACTS}/?size=50`, appendAuthorizationHeader(type === 'registrant' || type === 'gov' || type === 'r' ? regParams : contactParams));
}

export function registryContacts(searchParams) {

    let apiParams = new URLSearchParams(searchParams);
    if (apiParams.has('name')) {
        apiParams.set('name', splitStringAddPlus(apiParams.get('name')));
    }
    if (apiParams.has('org')) {
        apiParams.set('org', splitStringAddPlus(apiParams.get('org')));
    }
    //sottrae uno al numero di pagina per adattarlo alla numerazione dell'api
    apiParams.set('page', apiParams.has("page") ? apiParams.get("page") - 1 : '')
    return http.get(`${api.REGISTRY_CONTACTS}?${apiParams}`, appendAuthorizationHeader());
}

export function contactFromId(contactId) {
    return http.get(`${api.SEARCH_CONTACT}/${encodeURIComponent(contactId)}`, appendAuthorizationHeader());
}
