// ** RESTORE **

import * as types from "../actionTypes";
import * as api from "../api";

export function restoreDomainStart() {
    return {
        type: types.RESTORE_DOMAIN_START,
    }
}

export function restoreDomain(domain) {
    const response = api.restoreDomain(domain)
    return {
        type: types.RESTORE_DOMAIN,
        payload: response
    }
}

export function restoreDomainSuccess(response) {
    return {
        type: types.RESTORE_DOMAIN_SUCCESS,
        payload: response
    }
}

export function restoreDomainFailure(error) {
    return {
        type: types.RESTORE_DOMAIN_ERROR,
        payload: error
    }
}

export function resetRestoreDomain() {
    return {
        type: types.RESET_RESTORE_DOMAIN,
    }
}