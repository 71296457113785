import React, {Component} from 'react';
import * as routes from "../../../../routes";
import {injectIntl} from "react-intl";
import {Link} from "react-router-dom";
import ContactTypeTag from "../../DataDisplay/ContactTypeTag/ContactTypeTag";
import "./ContactListTable.css"
import HalPagination from "../../../../commons/components/Navigation/HalPagination/HalPagination";
import {StyledTable} from "app/domains/components/Pages/DomainList/DomainListTable";
import {CONTACT_TYPE} from "app/contacts/constants/const";


class ContactListTable extends Component {

    contactType(registrant) {
        console.log('ContactType: ', registrant);
        if (!registrant) return CONTACT_TYPE.contact;

        if (registrant) {
            if (registrant.gov !== undefined) {
                return CONTACT_TYPE.registrantGov;
            } else {
                if (registrant.schoolCode !== undefined) {
                    return CONTACT_TYPE.registrantEdu;
                } else {
                    return CONTACT_TYPE.registrant;
                }
            }
        }
    }


    render() {
        const {intl, data} = this.props;
        const {formatMessage} = intl;

        const columns = [
            {
                title: formatMessage({id: 'contact.list.header.contactId'}),
                dataIndex: 'contactId',
                key: 'contactId,',
                render: (text, record) => <Link to={`${routes.CONTACT_INFO.url}/${record.contactId}`}>{text}</Link>,
            },

            {
                title: formatMessage({id: 'contact.list.header.name'}),
                dataIndex: 'name',
                key: 'name',
                // width: '10%',
            },
            {
                title: formatMessage({id: 'contact.list.header.organization'}),
                dataIndex: 'org',
                key: 'org',
                // width: '10%'
            }, {
                title: formatMessage({id: 'contact.list.header.email'}),
                dataIndex: 'email',
                key: 'email',
                // width: '12%'
            },
            // {
            //     title: formatMessage({id: 'contact.list.header.telephone'}),
            //     dataIndex: 'voice',
            //     key: 'voice',
            //     // width: '5%'
            //
            // },
            {
                title: formatMessage({id: 'contact.list.header.address'}),
                children: [
                    {
                        title: formatMessage({id: 'contact.list.header.state'}),
                        dataIndex: 'state',
                        key: 'state',
                        // width: '2%'
                    },
                    {
                        title: formatMessage({id: 'contact.list.header.city'}),
                        dataIndex: 'city',
                        key: 'city',
                        // width: '4%'
                    },
                    {
                        title: formatMessage({id: 'contact.list.header.country'}),
                        dataIndex: 'country',
                        key: 'country',
                        render: (text, record) => (text.toString().toUpperCase()),
                        // width: '2%'

                    },
                ]
            },
            {
                title: formatMessage({id: 'contact.list.header.contactType'}),
                dataIndex: 'registrant',
                key: 'registrant',
                // width: '1%'
                render: (registrant, record) => (
                    <ContactTypeTag type={this.contactType(registrant)}/>
                )
            },

        ];

        const DisplayTable = () => (
            <div className='contact-list'>
                <StyledTable columns={columns}
                       dataSource={data.elements}
                       pagination={false}
                       rowKey={(record) => record.contactId}
                       bordered
                             scroll={{x:800}}
                       size="small"
                />
                <HalPagination
                    HAL_page={data.page}
                    onShowSizeChange={this.props.onChangePage}
                />
            </div>
        )

        return (
            <span>
                {(data && data.elements) ?
                    <DisplayTable/> :
                    formatMessage({id: 'contact.list.message.noResults'})
                }
            </span>
        );
    }
}

ContactListTable.propTypes = {};
ContactListTable.defaultProps = {};

export default injectIntl(ContactListTable);
