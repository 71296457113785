import {
    CONFIG_ERROR,
    CONFIG_LOADING,
    CONFIG_RESET,
    CONFIG_RESULT,
    CONFIG_SET_PROFILE
} from "./actionTypes";
import {MENU_COLLAPSED, MENU_EXPANDED} from "app/configuration/actionTypes";

const INITIAL_STATE = {
    loadingConfiguration: false,
    eppConfigExists: false,
    userExists: false,
    serverURI: "",
    deletedServerURI: "",
    username: "",
    password: "",
    language: "it",
    dnsSecEnabled: false,
    profile: null,
    error: null,
    menuCollapsed: false,
}

const eppConfigReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONFIG_LOADING: {
            return {
                ...state,
                loadingConfiguration: true,
            }
        }
        case CONFIG_RESULT: {
            return {
                ...state,
                ...action.payload,
                loadingConfiguration: false
            }
        }

        case CONFIG_SET_PROFILE: {
            return {
                ...state,
                profile: action.payload.profile,
            }
        }

        case CONFIG_ERROR: {
            return {
                ...state,
                loadingConfiguration: false,
                error: action.payload
            }
        }

        case MENU_COLLAPSED: {
            return {
                ...state,
                menuCollapsed: true
            }
        }

        case MENU_EXPANDED: {
            return {
                ...state,
                menuCollapsed: false
            }
        }

        case CONFIG_RESET: {
            return {
                ...state,
                loadingConfiguration: false,
                eppConfigExists: false,
                userExists: false,
                serverURI: "",
                deletedServerURI: "",
                username: "",
                password: "",
                language: "it",
                dnsSecEnabled: false,
                menuCollapsed: false
            }
        }

        default:
            return state
    }
}

export default eppConfigReducer;