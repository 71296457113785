import React from 'react';
import {Icon} from 'antd';

import './Fields.css';

const WizardComponentHeader = ({fields, current, steps}) => {

    if (!steps[current].showHeader) {
        return <span/>
    }

    return (
        <div className="wizard-component-header-data">
            <span className="wizard-component-header-underline">
                <Icon type="global"
                      className="wizard-component-header-icon"/>
                {fields.domainName}
            </span>

        </div>)
}
export default WizardComponentHeader;
