import {Form, Switch} from "antd";
import React from "react";

export const ConsentForPublishFormItem = ({form, intl, items, formItemLayout,onChange,disabled, isGov}) => {
    return <Form.Item
        {...formItemLayout}
        label={intl.formatMessage({id: 'contact.detail.form.consentForPublish'})}
    >
        {form.getFieldDecorator('consentForPublish', {
            valuePropName: 'checked',
            initialValue: isGov || (!!items && items.consentForPublish),
            rules: [{
                required: true,
            }],
        })(
            <Switch
                onChange={onChange}
                disabled={isGov || disabled}
                checkedChildren={intl.formatMessage({id: 'contact.detail.form.consent'})}
                unCheckedChildren={intl.formatMessage({id: 'contact.detail.form.notConsent'})}
                style={{float: 'left'}}
            />
        )}
    </Form.Item>
}

export default ConsentForPublishFormItem