import React from 'react';
import "./DomainTransferStatus.css"
import {Col, Icon, Row} from "antd";
import {injectIntl} from "react-intl";

const RegistersInvolved = ({acId, reId, style = {}, intl}) => {
    const {formatMessage} = intl;
    return (
        <div>
            <div className={'domain-status-box'}>
                <Row className={'domain-status-box-header'}>
                    <Col push={2} lg={8}><Icon
                        type="home"/> {formatMessage({id: 'domain.transfer.status.header.from'})}</Col>
                    <Col lg={8}/>
                    <Col lg={8} pull={2}><i
                        className='fal fa-flag-checkered'/> {formatMessage({id: 'domain.transfer.status.header.to'})}
                    </Col>
                </Row>
                <Row className={'domain-status-box-registrar'}>
                    <Col span={8} push={2}>{acId}</Col>
                    <Col span={8}>
                        <Icon type="arrow-right" className={`status-arrow ${style.className}`}/>
                    </Col> <Col span={8} pull={2}>{reId}</Col>
                </Row>
            </div>
        </div>
    )
}

export default injectIntl(RegistersInvolved)