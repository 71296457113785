import React from 'react';
import PropTypes from 'prop-types'
import "./EppChecked.css"
import {Icon} from "antd";
import styled from "styled-components";

const EppCheckedCard = styled.div`
    text-align: center;
    padding-left: 35%;
    padding-right: 35%;
    font-size: 12pt;
     @media (max-width: 900px) {
    padding-left: 5%;
    padding-right: 5%;
  }
`;

const EppChecked = (props) => {

    return (
        <EppCheckedCard>
            <div className={`eppChecked-box ${props.checked ? "eppChecked-box-success" : "eppChecked-box-fail"}`}>

                <Icon
                    className={`eppChecked-icon ${props.checked ? "eppChecked-icon-success" : "eppChecked-icon-fail"}`}
                    type={props.checked ? "check-circle-o" : "close-circle-o"}
                />
                <div
                    className={`eppChecked-box-message ${props.checked ? "eppChecked-box-message-success" : "eppChecked-box-message-fail"}`}>
                    {props.message}
                </div>
                <span className='eppChecked-box-body'>
                    <span className='eppChecked-title'>{props.name}</span>
                </span>

                {props.description &&
                <div className='eppChecked-box-extra'>
                    {props.description}
                </div>
                }
            </div>
        </EppCheckedCard>
    );
};

EppChecked.propTypes = {
    name: PropTypes.string,
    message: PropTypes.string,
    description: PropTypes.string,
    checked: PropTypes.bool
};
EppChecked.defaultProps = {};

export default EppChecked;
