import * as types from "../actionTypes";

const INITIAL_STATE = {
    loading: true,
    data: null,
    error: null
}
const checkReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case types.CHECK_DOMAIN_START: {
            return {
                ...state,
                loading: true
            }
        }

        case types.CHECK_DOMAIN: {
            return {
                ...state,
                loading: true,
                error: null
            }
        }
        case types.CHECK_DOMAIN_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload.data
            }
        }
        case types.CHECK_DOMAIN_ERROR : {
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: null
            }
        }
        case types.CHECK_DOMAIN_RESET : {
            return {
                ...state,
                loading: false,
                error: null,
                data: null
            }
        }

        default:
            return state
    }
}

export default checkReducer;