// ** CHECK **

import * as types from "../actionTypes";
import * as api from "../api";

export function checkDomainStart() {
    return {
        type: types.CHECK_DOMAIN_START,
    }
}

export function checkDomain(domain) {
    const response = api.checkDomain(domain)
    return {
        type: types.CHECK_DOMAIN,
        payload: response
    }
}

export function checkDomainSuccess(response) {
    return {
        type: types.CHECK_DOMAIN_SUCCESS,
        payload: response.payload
    }
}

export function checkDomainFailure(error) {
    return {
        type: types.CHECK_DOMAIN_ERROR,
        payload: error
    }
}

export function resetCheckDomain() {
    return {
        type: types.CHECK_DOMAIN_RESET,
    }
}