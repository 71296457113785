const regExp = /\+\d{1,3}\.\d{1,14}/;

export default class TelephoneValidator {


    static validate(telephoneNumber) {

        let errors = [];


        if (!regExp.test(telephoneNumber)) {
            errors.push("invalidTelphoneNumber");
        }

        if (telephoneNumber.includes(" ")) {
            errors.push("ContainsSpace");

        }

        return errors ? {"valid": false, "errors": errors, "value": telephoneNumber} : {valid: true}
    }
}