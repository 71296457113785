import {hanldeErrorDispatching} from "../commons/utils/utils";
import actions from "./actions"

function getEppMessages(searchParamas) {
    return function (dispatch) {
        dispatch(actions.eppMessageListStart())
        dispatch(actions.eppMessageList(searchParamas))
            .then((response) => {
                if (!response.error) {
                    dispatch(actions.eppMessageListSuccess(response.payload))
                } else {
                    dispatch(hanldeErrorDispatching(response.payload, actions.eppMessageListFailure))
                }
            })
    }
}

function markMessage(message, read) {
    return function (dispatch) {
        dispatch(actions.eppMessageMarkAs(message, read))
            .then((response) => {
                if (!response.error) {
                    dispatch(actions.modify_message(message, read))
                } else {
                    dispatch(hanldeErrorDispatching(response.payload, actions.eppMessageListFailure))
                }
            })
    }
}

function deleteMessage(message, searchParamas) {
    return function (dispatch) {
        dispatch(actions.eppMessageListStart())
        dispatch(actions.eppMessageDelete(message))
            .then((response) => {
                if (!response.error) {
                    dispatch(getEppMessages(searchParamas))
                } else {
                    dispatch(hanldeErrorDispatching(response.payload, actions.eppMessageListFailure))
                }
            })
    }
}

export default {getEppMessages, markMessage, deleteMessage}