import React from 'react';
import {Alert} from "antd";
import PropTypes from "prop-types";
import {Redirect} from "react-router-dom";
import {printMessageError} from "../../../../commons/utils/common";
import "./Error.css"

const ErrorBox = ({description, showIcon, banner}) => {
    return <Alert description={description}
                  message='Errore'
                  type="error"
                  showIcon={!!showIcon}
                  banner={!!banner}
                  className='error-box'/>
}

const Error = ({error}) => {

    const errorHandling = (error) => {
            const description = `${printMessageError(error)}`

            if (error.status === 401) {
                localStorage.removeItem("token")
                return <Redirect to="/login"/>;
            } else if (error.status >= 400 && error < 600) {
                return <ErrorBox description={description} showIcon/>;
            } else {
                return <ErrorBox description={description} showIcon/>;
            }
        }
    ;

    if (error) {
        return (
            <div className='card-error'>
                {errorHandling(error)}
            </div>)
    }
    else {
        return <span/>
    }
};

Error.propTypes = {
    error: PropTypes.object,
};
Error.defaultProps = {from: "/"};

export default Error;
