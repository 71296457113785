import React, {Component} from 'react';
import Template from "app/commons/components/Layout/Template/Template";
import {connect} from "react-redux";
import configOperations from "app/configuration/operations";
import PropTypes from 'prop-types'
import MediaQuery from 'react-responsive';

const mapDispatchToProps = (dispatch) => {
    return {
        handleMenuCollapsed: () => {
            dispatch(configOperations.collapseMenu())
        },
        handleMenuExpanded:
            () => {
                dispatch(configOperations.expandMenu())
            }
    };
}

const mapStateToProps = (state) => {
    return {
        menuCollapsed: state.confReducer.menuCollapsed,
    };
}

class TemplateContainer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            windowWidth: window.innerWidth
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = (e) => (this.setState({windowWidth: window.innerWidth}));


    onCollapse = (collapsed) => {
        console.log("ccoll ",collapsed)
        collapsed ? this.props.handleMenuCollapsed() : this.props.handleMenuExpanded()
    }

    render() {
        return (
            <MediaQuery maxWidth={1300}>
                {(matches) =>
                    <Template onCollapse={this.onCollapse} mediaCollapse={matches} {...this.props}>
                        {this.props.children}
                    </Template>
                }
            </MediaQuery>

        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateContainer);

TemplateContainer.propTypes = {
    children: PropTypes.any
};
