import React from 'react';
import "./DomainStatusBar.css"
import {Icon} from "antd";
import {injectIntl} from "react-intl";
import PropTypes from "prop-types";
import DomainName from "../DomainName/DomainName";
import ExpireDateContainer from "app/commons/components/DataDisplay/ExpiredDate/ExpireDateContainer";
import styled from 'styled-components'

const DomainStatusBar = (props) => {
    return (
        <StyleMain>
            <span>
                <Icon className='domain-status-bar-icon' type="global"/>
                <DomainName name={props.name} signed={props.signed}/>
            </span>
            <span className='break-line'/>
            <span>
                <Icon className='domain-status-bar-icon' type="clock-circle-o"/>
                <ExpireDateContainer exDate={props.expDate}/>
            </span>
            <span className='break-line'/>
            <span>
                <Icon
                    className='domain-status-bar-icon' type="tag-o"/>
                    <span className='domain-state'>{props.state && props.state.join(' / ')}</span>
            </span>
        </StyleMain>
    );
};
export default injectIntl(DomainStatusBar);

DomainStatusBar.propTypes = {
    name: PropTypes.string.isRequired,
    signed: PropTypes.bool,
    expDate: PropTypes.string.isRequired,
    state: PropTypes.array.isRequired,
};
DomainStatusBar.defaultProps = {
    signed: false
};



const StyleMain = styled.div`
    display:flex;
    @media (max-width: 900px) {
      flex-direction: column;
    }`