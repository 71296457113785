import React from 'react';
import {Button, Form, Input} from 'antd';
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import {withRouter} from "react-router-dom";
import {checkDomainName} from "../../../../commons/utils/form";
import {
    StyledButtonBox,
    StyledMain,
    StyledMain2,
    StyleInputBox
} from "app/contacts/components/ContactEppActionForm/ContactEppActionForm";
import LinkButton from "app/commons/components/Layout/LinkButton/LinkButton";
import {punyCodedDomain} from "../../../../commons/utils/utils";
import Tooltip from "antd/lib/tooltip";

const FormItem = Form.Item;

class DomainInfoWrapped extends React.Component {

    state = {
        value: null
    }

    //Valida e se tutti i controlli sono ok esegue la submit
    validateAndSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const domain = values.domain.trim();
                let authInfo;
                if (values.authinfo) {
                    authInfo = values.authinfo.trim();
                }
                if (domain) {
                    this.props.onSubmit(domain, authInfo);
                }
            }
        })
    }

    //Controlla se il nome a dominio rispetta i vincoli
    checkDomainNameWrapped = (rule, value, callback) => {
        const {formatMessage} = this.props.intl;
        checkDomainName(rule, value, callback, formatMessage)
    }


    handleOnChange = (e) => {
        this.setState({value: e.target.value})
    }

    render() {
        const {form, intl} = this.props
        const {getFieldDecorator} = form;
        const {formatMessage} = intl;
        const {domain} = this.props.match.params;

        return (
            <Form onSubmit={this.validateAndSubmit}>
                <StyledMain>
                    <StyledMain2>
                        <StyleInputBox>
                            <Tooltip
                                placement="topLeft" defaultVisible={true}
                                title={this.state.value &&  punyCodedDomain(this.state.value)} >
                                <FormItem hasFeedback style={{width: '98%'}}>
                                    {getFieldDecorator('domain', {
                                        initialValue: domain,
                                        rules: [{
                                            required: true,
                                            message: formatMessage({id: "domain.info.form.inputDomain.rule.message"})
                                        }, {validator: this.checkDomainNameWrapped}],
                                    })(
                                        <Input
                                            placeholder={formatMessage({id: 'domain.info.form.inputDomain.extra'})}
                                            onChange={this.handleOnChange}
                                        />
                                    )}
                                </FormItem>
                            </Tooltip>
                        </StyleInputBox>
                        <StyleInputBox>
                            <FormItem>
                                {getFieldDecorator('authinfo', {
                                    initialValue: this.props.authinfo,
                                    rules: [{
                                        required: false,
                                        message: formatMessage({id: "domain.info.form.authinfo.rule.message"})
                                    }],
                                })(
                                    <Input placeholder={formatMessage({id: "domain.info.form.authinfo.placeholder"})}
                                    />
                                )}

                            </FormItem>
                        </StyleInputBox>
                        <StyledButtonBox>
                            <FormItem>
                                <Button type='primary' htmlType="submit">
                                    {formatMessage({id: "domain.info.form.button.info"})}
                                </Button>
                                {
                                    (form.getFieldValue('domain') || form.getFieldValue('authinfo')) &&
                                    <span style={{borderLeft: '1px solid #ebedee', paddingLeft: '10px'}}>
                                        <LinkButton onClick={this.props.onReset}>
                                        {formatMessage({id: 'domain.info.form.button.reset'})}
                                        </LinkButton>
                                    </span>

                                }
                            </FormItem>
                        </StyledButtonBox>
                    </StyledMain2>
                </StyledMain>
            </Form>
        );
    }
};


const DomainInfoForm = Form.create()(withRouter(injectIntl(DomainInfoWrapped)));

export default (DomainInfoForm);
DomainInfoForm.propTypes = {
    onSubmit: PropTypes.func,
    onReset: PropTypes.func
}