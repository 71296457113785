import {Tooltip} from "antd";
import React from 'react'

const withTooltip = (Component, options = {}) => ({...props,}) => (
    <Tooltip {...options}>
        <Component {...props} />
    </Tooltip>
);

export default withTooltip;

