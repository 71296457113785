import {
    COUNTRY_CODE_LOADING,
    COUNTRY_CODE_LOADING_ERROR,
    COUNTRY_CODE_LOADING_START,
    COUNTRY_CODE_LOADING_SUCCESS
} from "./actionTypes";

const INITIAL_STATE = {
    loading: false, error: null, list: [],page: null
}

const countryReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case COUNTRY_CODE_LOADING: {
            return {
                ...state,
                loading: true,
                error: null,
                page: null
            }
        }
        case COUNTRY_CODE_LOADING_START: {
            return {
                ...state,
                loading: true,
            }
        }
        case COUNTRY_CODE_LOADING_SUCCESS: {
            return {
                ...state,
                list: action.payload.data.map(
                    country => ({
                        text: `${country.code.toUpperCase()} - ${country.name}`,
                        value: country.code.toUpperCase(),
                        enabled: country.enabled
                    }))
                ,
                page: action.payload.data.page,
                error: null,
                loading: false,

            }
        }
        case COUNTRY_CODE_LOADING_ERROR : {
            return {
                ...state,
                loading: false, list: [], error: action.payload,
                page: null
            }
        }

        default:
            return state
    }
}

export default countryReducer;