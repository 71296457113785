import React, {Component} from 'react';

import {Alert, AutoComplete, Form, Icon, Modal, notification} from 'antd';

import {injectIntl} from 'react-intl';


import {Address, Contact, Registrant, RegistrantContact, SerializeVisitor} from './models/index';
import ContactDetail from '../../../../contacts/components/DataDisplay/ContactDetail/ContactDetail';

import {debounce} from './Utils';

import './SearchContact.css'
import PropTypes from "prop-types";
import {searchContactsByName} from "../../../../contacts/api";
import {CONTACT_TYPE} from "app/contacts/constants/const";
import {printMessageError} from "app/commons/utils/common";
import {
    ErrorConsentForPublishText,
    showErrorConsentForPublish
} from "app/domains/components/Pages/ConsentForPublishChecker";
import {GOV_SUFFIX} from "../../../../commons/constants/constants";


const FormItem = Form.Item;
const Option = AutoComplete.Option;

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 6},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 16},
    },
};


function renderAllResults(value, type) {
    const field = type === 'registrant' ? "org=" : "name=";
    const _type = type === 'registrant' ? "r" : "c";
    const urlParams = field + value + "&type=" + _type;
    return <a href={`/contact/list?${urlParams}`}
              target="_blank"
              rel="noopener noreferrer" style={{float: "right"}}>
        Mostra tutti</a>
}

function renderOption(item) {
    return (
        <Option key={item.contactId} text={item.name + " [" + item.contactId + "]"}>
            {item.name} [{item.contactId}]
        </Option>
    );
}


class SearchContactWrapper extends Component {
    constructor(props) {
        super(props);
        // this.passReg = this.props.passReg.bind(this);
        this.state = {
            registrantIdSource: [],
            adminIdSource: [],
            techIdSource: [],
            help: null,
            status: null,
        }

    }

    isRegistrant = ([CONTACT_TYPE.registrantEdu, CONTACT_TYPE.registrantGov, CONTACT_TYPE.registrant, CONTACT_TYPE.r].includes(this.props.contactKind));

    setIdFieldInvalid = (message) => {
        this.setState({help: message})
        this.setState({status: 'error'})
    }

    setIdFieldValid = (message) => {
        this.setState({help: message})
        this.setState({status: 'success'})
    }


    debouncing = (value, kind, field) => {

        let obj = {}
        //  this.setState({selectedItem:null});
        // console.log("(SearchContact) Debouncing field: ", value, this.state.selectedItem && this.state.selectedItem.id);


        if (!value && this.state.selectedItem) {
            this.setState({selectedItem: null});
        }

        if (value && this.state.selectedItem && (this.state.selectedItem.id === value))
            return;

        obj[field] = []
        this.setState(obj);
        this.setState({error: false});
        //this.setState({error:false});

        if (value && value.length >= 3) {

            this.setIdFieldInvalid('Cercando...');

            searchContactsByName(value, kind, 1000)
                .then((result) => {

                    if (result.data.page.totalElements > 0) {
                        this.setIdFieldValid();
                        let obj = {};
                        obj[field] = result.data.elements;
                        obj['page'] = result.data.page;
                        obj['value'] = value;
                        obj['type'] = kind;
                        this.setState(obj);

                        this.setState({error: false});
                    } else {
                        this.setState({error: true});
                        this.setIdFieldInvalid('Nessuna corrispondenza trovata.');

                    }

                })
                .catch((error) => {
                    this.setIdFieldInvalid(printMessageError(error));
                    this.setState({error: true, message: `${error}`});
                    console.error("searchContactByName: ", printMessageError(error));
                })
        }

    }

    componentWillMount() {
        // console.log("(SearchContact) Mounting ...  ",this.props)
        this.debouncing = debounce(this.debouncing, 400, false);

    }


    resetValue = () => {
        // Azzerare la form: meccanismo hadler chiamato quando si chiude la modale.

    }


    convertContact = (selectedContact) => {

        const {
            name, org, contactId, city, state, postalCode, country, registrant, streets,
            consentForPublish, email, voice, fax, statuses, clId, crId, roid, created
        } = selectedContact;


        let address = new Address(city, state, postalCode, country, streets);


        let registrantObject = null;
        let contact = null;


        if (this.isRegistrant) {
            registrantObject = Registrant.create(registrant);
            contact = new RegistrantContact(
                contactId, name, org, registrantObject, consentForPublish, address,
                email, voice, fax, statuses, created, clId, crId, roid
            );
        } else {
            contact = new Contact(contactId, name, org, consentForPublish, address, email, voice, fax, statuses, created);
        }

        let resultObject = {};
        let serializeVisitor = new SerializeVisitor(resultObject);
        contact.accept(serializeVisitor);
        return resultObject;
    }


    manageSelect = (field, value, source) => {
        let hashTable = {};
        source.forEach((contactId) => {
            hashTable[contactId.contactId] = contactId;
        })
        let error = false;

        let obj = {};
        obj[`${field}Value`] = value;
        this.setState(obj);

        let map = {}
        map[`${field}Map`] = hashTable;
        this.setState(map);

        let selectedContact = hashTable[value];

        if (!error) {
            this.setState({selectedItem: this.convertContact(selectedContact)});
        }
    }


    resetField = (field) => {
        let source = {};

        source[`${field}Source`] = []
        this.setState({selectedItem: null});
        this.setState({status: null})
        this.setState({help: null})
        this.props.form.setFieldsValue({contactIdByName: ''});
        this.setState(source);

    }


    manageOk = (field, isRegistrant = false) => {
        let value = this.state[`${field}Value`];
        let map = this.state[`${field}Map`];
        let error = false;
        if (value && map) {

            let retValue = this.props.validate && this.props.validate("contactIdByName", value);

            if (retValue) {
                notification.error({
                    message: 'Contatto Duplicato',
                    description: `Il contatto ${value} è stato già selezionato.`
                })
            } else {
                let selectedContact = map[value];
                let resultObject = this.convertContact(selectedContact);


                if (this.props.domainName ) {

                    if (
                        (this.props.domainName.endsWith(GOV_SUFFIX) && isRegistrant && (selectedContact.registrant.gov === undefined)) ||
                        (!this.props.domainName.endsWith(GOV_SUFFIX) && (isRegistrant && selectedContact.registrant.gov !== undefined))
                    ) {

                            error=true;
                            this.setState({error: true})

                            this.setIdFieldInvalid(`Registrante non compatibile con dominio ${this.props.domainName}`)
                    }

                }

                if (!error) {
                    this.resetField(field);

                    this.props.onOk(field, value.toLowerCase(), resultObject);

                    this.onSelectedRegistrant(this.state.selectedItem)
                }
            }
        }
    }

    onCancel = (field) => {
        this.resetField(field);
        this.props.onCancel(field);
    }

    validateSearch = (role, value, callback) => {

        const {formatMessage} = this.props.intl;
        const {contactField} = this.props;

        //console.log("ValidateSearch");
        let searchField = `${contactField}Source`
        // console.log("Stato: ", searchField, this.state[searchField], this.state[searchField] && this.state[searchField].length)

        if (value.length < 3)
            this.setIdFieldInvalid(formatMessage({id: 'domain.new.Wizard.contactNameLength'}));
        // callback(new Error(formatMessage({id: 'domain.new.Wizard.contactNameLength'})));
        else {
            if (this.state[searchField]) {
                if (this.state[searchField].length > 0) {
                    this.setIdFieldValid();
                    callback()
                    // } else {
                    //     this.setIdFieldInvalid(formatMessage({id: "domain.new.Wizard.contactNotfound"}));
                    //     // callback(new Error(formatMessage({id: "domain.new.Wizard.contactNotfound"})));
                }
            }
        }
    }

    onSelectedRegistrant = (data) => {
        if (this.props.onSelectedRegistrant) {
            this.props.onSelectedRegistrant(data)
        }
    }

    render() {
        const {visible, title, contactField, contactKind} = this.props;

        const {getFieldDecorator} = this.props.form;
        const {formatMessage} = this.props.intl;

        let datasource = this.state[`${contactField}Source`] || [];

        let infoBar = null;


        if (this.state.selectedItem) {
            infoBar =
                <div>
                    {showErrorConsentForPublish(this.state.selectedItem) &&
                    <Alert
                        className='alert-message'
                        message="Attenzione" type="error"
                        description={ErrorConsentForPublishText(this.state.selectedItem.id)}
                        showIcon/>
                    }
                    <ContactDetail showRegistrantInfo={this.isRegistrant} data={this.state.selectedItem}
                                   linkable={false}/>
                </div>
        }

        return (
            <Modal
                closable={false}
                wrapClassName="vertical-center-modal"
                title={<span><Icon type="user" style={{color: "#108ee9", paddingRight: "5px"}}/>{title}</span>}
                visible={visible}
                onOk={() => {
                    this.manageOk(contactField, (contactKind !== 'c' && contactKind !== 'contact'))
                }}
                okButtonProps={{disabled: showErrorConsentForPublish(this.state.selectedItem)}}
                onCancel={() => {
                    this.onCancel(contactField)
                }}
                afterClose={this.resetValue}
                confirmLoading={!(this.state.selectedItem)}
            >
                <div style={{width: 'inherit'}}>
                    <Form>
            <span>
                <FormItem
                    {...formItemLayout}
                    label={formatMessage({id: 'domain.new.Wizard.searchContactByName'})}
                    hasFeedback
                    validateStatus={this.state.status}
                    help={this.state.help}
                >
              {getFieldDecorator('contactIdByName', {
                  rules: [
                      {message: 'Valore non nullo'},
                      {validator: this.validateSearch}
                  ],
              })(
                  <AutoComplete
                      dropdownRender={<div>aaaa</div>}
                      style={{width: '100%'}}
                      dataSource={datasource.length > 0 &&
                      datasource.map(renderOption).concat([
                          <Option disabled key="all" className="show-all">
                              {
                                  this.state['page'] &&
                                  <><i> Risultati {datasource.length} di {this.state['page'].totalElements}</i>
                                  {renderAllResults(this.state.value,this.state.type)}
                                  </>
                              }
                          </Option>
                      ])}
                      onChange={(value) => {
                          this.debouncing(value, contactKind, `${contactField}Source`)
                      }}

                      onSelect={(value) => {
                          this.manageSelect(contactField, value, datasource)
                      }}
                      optionLabelProp="text"

                  />
              )}
              </FormItem>
              <Icon
                  //style={{left:'94%', fontSize:'10pt', top:'-47px'}}
                  className="dynamic-delete-button reset-icon"
                  type="close-circle"
                  onClick={() => {

                      if (this.props.form.getFieldValue('contactIdByName')) {
                          this.props.form.resetFields(['contactIdByName'])

                          this.setState({selectedItem: false})
                      }
                  }}/>
              </span>
                        {infoBar}
                    </Form>
                </div>

            </Modal>
        )
    }
}


const SearchContact = Form.create()(injectIntl(SearchContactWrapper));

export default SearchContact;


SearchContact.propTypes = {
    form: PropTypes.object,
    contactKind: PropTypes.string,
    validate: PropTypes.PropTypes.func,
    onOK: PropTypes.func,
    onSelectedRegistrant: PropTypes.func,
    onCancel: PropTypes.func,
    title: PropTypes.string,
    contactField: PropTypes.string,
    placeholder: PropTypes.string,
    visible: PropTypes.bool,
    domainName: PropTypes.string
}
