import React from "react";
import styled from 'styled-components'
import CardDetail from "app/commons/components/DataDisplay/CardDetail/CardDetail";

const GridContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const GridItem = styled(CardDetail)`
    width : 49.6%!important;
    min-width: 49.6%!important;
    margin-top: 20px!important;`
;

export const GridView = ({listContents, cards}) => {
    return <GridContainer>
        {cards.map((c, index) => (
                listContents.hasOwnProperty(c.key) &&
                <GridItem key={index} label={c.title}>
                    {listContents[c.key]}
                </GridItem>
            )
        )}
    </GridContainer>
}


