import React from "react";
import ContactShortDetail from "app/contacts/components/DataDisplay/ContactDetailShort/ContactShortDetail";
import ContactAddressDetail from "app/contacts/components/DataDisplay/ContactAddressDetail/ContactAddressDetail";
import ContactsDetail from "app/contacts/components/DataDisplay/ContactsDetail/ContactsDetail";
import RegistrantDetail from "app/contacts/components/DataDisplay/RegistrantDetail/RegistrantDetail";
import {TabView} from "app/commons/components/Navigation/ViewSelector/TabView/TabView";
import {ListView} from "app/commons/components/Navigation/ViewSelector/ListView/ListView";
import {GridView} from "app/commons/components/Navigation/ViewSelector/GridView/GridView";

function contactContentsGenerator(data) {
    let contents = {};
    if (data) {
        const {registrant} = data;
        contents['contact'] = <ContactShortDetail data={data}/>
        contents['address'] = <ContactAddressDetail data={data}/>
        contents['contacts'] = <ContactsDetail data={data}/>

        if (registrant) {
            contents['registrant'] =
                <RegistrantDetail data={registrant}/>
        }
    }
    return contents
}

export const renderContactView = ({intl, data, viewType}) => {

    const {formatMessage} = intl;
    const cards = [
        {
            key: 'contact',
            title: formatMessage({id: "contact.detail.contact"})
        }, {
            key: 'address',
            title: formatMessage({id: "contact.detail.address"})
        }, {
            key: 'contacts',
            title: formatMessage({id: "contact.detail.contacts"})
        }, {
            key: 'registrant',
            title: formatMessage({id: "contact.detail.registrant"})
        }
    ]

    const listContents = contactContentsGenerator(data);

    switch (viewType) {
        case 'list':
            return <ListView listContents={listContents} cards={cards}/>
        case 'grid':
            return <GridView listContents={listContents} cards={cards}/>
        default: //tab
            return <TabView listContents={listContents} cards={cards}/>
    }
}



