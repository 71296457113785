import React from 'react';
import "../../../../../styles/DetailPanel.css"
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import CardDetailRow from "../../../../commons/components/DataDisplay/CardDetailRow/CardDetailRow";

const renderStreets = (streets) => {
    if (streets.length > 0) {
        return streets.map((street, key) => (<div key={key}>{street}</div>))
    }
}

const ContactAddressDetail = (props) => {
    const {formatMessage} = props.intl;
    const {address} = props.data;
    return (
        <div>
            <CardDetailRow label={formatMessage({id: "detail.contact.address.city"})}
                           content={address.city}/>
            <CardDetailRow label={formatMessage({id: "detail.contact.address.streets"})}
                           content={renderStreets(address.streets)}/>
            <CardDetailRow label={formatMessage({id: "detail.contact.address.state"})}
                           content={address.state}/>
            <CardDetailRow label={formatMessage({id: "detail.contact.address.postalCode"})}
                           content={address.postalCode}/>
            <CardDetailRow label={formatMessage({id: "detail.contact.address.countryCode"})}
                           content={address.countryCode}/>
        </div>
    );
};

ContactAddressDetail.propTypes = {
    data: PropTypes.any.isRequired,
};

export default injectIntl(ContactAddressDetail);
