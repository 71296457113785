import React from 'react';
import PropTypes from 'prop-types';
import {CONTACT_TYPE} from "app/contacts/constants/const";
import {Form, Radio} from 'antd'
import {injectIntl} from "react-intl";
import {formItemLayout} from "app/contacts/components/BaseContactForm/const";

/** Seleziona il tipo di contatto **/
const ContactSelectorButtons = ({intl,onChange,value}) => {
    return (
        <Form.Item
            {...formItemLayout}
            label={intl.formatMessage({id: 'contact.detail.form.contactType'})}
        >
        <Radio.Group value={value} style={{float: 'left'}} onChange={onChange}>
            <Radio.Button value={CONTACT_TYPE.contact}>
                {intl.formatMessage({id: 'contact'})}
            </Radio.Button>
            <Radio.Button value={CONTACT_TYPE.registrant}>
                {intl.formatMessage({id: 'Registrant'})}
            </Radio.Button>
            <Radio.Button value={CONTACT_TYPE.registrantEdu}>
                {intl.formatMessage({id: 'RegistrantEdu'})}
            </Radio.Button>
            <Radio.Button value={CONTACT_TYPE.registrantGov}>
                {intl.formatMessage({id: 'RegistrantGov'})}
            </Radio.Button>
        </Radio.Group>
        </Form.Item>
    );
};

ContactSelectorButtons.propTypes = {
    value : PropTypes.string,
    onChange : PropTypes.func,
};

ContactSelectorButtons.defaultProps = {
    value : ''
};

export default injectIntl(ContactSelectorButtons);
