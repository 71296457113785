import PropTypes from "prop-types";
import React from "react";
import {Form} from "antd";
import AddressFormItemWrapped from "app/contacts/components/BaseContactForm/FormITems/AddressFormItemWrapped";

export const ContactAddressFormItem = (props) => (
    <Form.Item
        {...props.formItemLayout}
        label={props.intl.formatMessage({id: 'contact.detail.form.address'})}
    >
        <AddressFormItemWrapped {...props} />
    </Form.Item>
)

ContactAddressFormItem.propTypes = {

    isEntityType1: PropTypes.bool,
    isRegistrant: PropTypes.bool,
    isNewContact: PropTypes.bool,
    entityType: PropTypes.number,
    fixedNationalCode: PropTypes.string,  //Definisce il valore fisso del campo NationalCode se isEdu è true
    fixedProvince: PropTypes.string,       //Definisce il valore fisso della provincia NationalCode se isEdu è true
    isEdu: PropTypes.bool,
}
