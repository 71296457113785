import React from 'react';
import {injectIntl} from "react-intl";
import ProptTypes from 'prop-types'
import EppChecked from "../../../../commons/components/DataDisplay/EppChecked/EppChecked";

const DomainChecked = ({available, name, description, intl}) => {
        const {formatMessage} = intl

        function showMessage() {
            return available ?
                formatMessage({id: "domain.check.domainAvailable"}) :
                formatMessage({id: "domain.check.domainNotAvailable"})
        }

        return <EppChecked name={name}
                           checked={available}
                           message={showMessage()}
                           description={description}
        />
    }
;

DomainChecked.propTypes = {
    available: ProptTypes.bool,
    name: ProptTypes.string,
    description: ProptTypes.string
};

DomainChecked.defaultProps = {};

export default injectIntl(DomainChecked);

