import React, {Component} from 'react';
import {Form, notification} from 'antd';
import {withRouter} from 'react-router';

import {injectIntl} from 'react-intl';
import {decodeToken} from '../../../commons/utils/utils';
import {connect as Connect} from "react-redux";
import {EppFormItems, EppFormLabels} from "../EppConfiguration/Epp/FormItems";
import {loadEppConfiguration} from "../../api";
import {authOperations} from "../../../authentication";
import {configOperations} from "../../index";

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 5},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 12},
    },
};

class EppConfigurationWrapped extends Component {

    passwordChecker = (rule, value, callback) => {

        const password = this.props.form.getFieldValue('password');
        const {formatMessage} = this.props.intl;

        if (password !== value) {
            callback(new Error(formatMessage({id: 'confirmEppPasswordMessage'})));
        } else {
            callback();
        }
    }

    componentDidMount() {
        const {formatMessage} = this.props.intl;
        const {setFieldsValue} = this.props.form;
        // Si carica in ogni caso la configurazione e solamente per i valori specificati si mettono
        // come default
        if (!this.props.error) {
            loadEppConfiguration()
                .then((result) => {
                    let token = decodeToken();

                    this.props.dispatch(configOperations.eppConfiguration.bind(this, result.data));
                    this.props.dispatch(authOperations.authenticatedUserInfo(token));
                    setFieldsValue(result.data);
                })
                .catch((error) => {

                    // Errore: In questo caso l'errore rigurada solo interazione con il server per
                    // reperire configurazione: casi token non valido (server non lo considera tale): Bisogna tornare alla root

                    if (error.response.data && error.response.data.status > 400 && error.response.data.status < 500) {

                        notification.error({
                            message: formatMessage({id: 'loadingServerConfiguration.error.message'}),
                            description: formatMessage({id: 'loadingServerConfiguration.error.description'})
                        });
                    }
                    if (error.response && error.response.status >= 500) {

                        notification.error({
                            message: formatMessage({id: 'loadingServerConfiguration.error.message'}),
                            description: "Errore "
                        })
                    }

                    if (error.response.data && error.response.data.status && error.response.data.status >= 500) {

                        notification.error({
                            message: formatMessage({id: 'loadingServerConfiguration.error.message'}),
                            description: formatMessage({id: 'loadingServerConfiguration.error.description'})
                        });


                    }

                    // Invalidare il token locale e rimandare sulla login con nuovo token

                    this.props.dispatch(authOperations.doLogout(this.props, "/", true));
                    //that.props.dispatch(loginFailure({message:"Credenziali Scadute o non valide"}));

                    //const path = that.props.history.location.pathname+that.props.history.location.search;
                    //that.props.dispatch(doLogout(that.props,path, true));

                    //that.props.dispatch(doLogout(that.props,"/", false));

                    //  window.location.reload()


                    // Nel caso di errore di autorizzazione
                    // TODO:  Eliminazione del token ed user info e reset dello stato
                    // Bisogna distinguere il tipo di errore. In generale dovrebbe essere
                    // un errore
                    // TODO: that.props.dispatch(logoutSuccessful());

                });
        } else {
            const {language, dnsSecEnabled, username, password} = this.props.fields;
            //console.log("C'è stato errore... ", serverURI, deletedServerURI, language, dnsSecEnabled, username, password);
            let fields = {
                language: language, username: username, password: password, dnsSecEnabled: dnsSecEnabled
            };
            setFieldsValue(fields);
        }
    }


    render() {
        const {loadingConfiguration} = this.props;
        const {formatMessage} = this.props.intl;

        const params = new URLSearchParams(this.props.history.location.search);
        const eppConfigParam = params.get("eppConfigExists");
        const eppConfigExists = (eppConfigParam === 'true');

        const showFormOrLabels = () => {
            if (this.props.eppConfigExists) {
                return <EppFormLabels formItemLayout={formItemLayout} {...this.props} />
            } else {
                return <EppFormItems formItemLayout={formItemLayout} {...this.props} />
            }
        }

        return (
            <Form>
                {
                    (
                        eppConfigExists && loadingConfiguration &&
                        <div>{formatMessage({id: 'configuration.loadingServerData'})}</div>
                    )
                    ||
                    (
                        (
                            !eppConfigExists || !loadingConfiguration) &&
                        <div>
                            {showFormOrLabels()}
                        </div>
                    )
                }
            </Form>
        )
    }
}


function mapStateToProps(state) {

    return {
        isAuthenticated: state.authReducer.authenticated,
        serverURI: state.confReducer.serverURI,
        deletedServerURI: state.confReducer.deletedServerURI,
        username: state.confReducer.username,
        password: state.confReducer.password,
        language: state.confReducer.language,
        loadingConfiguration: state.confReducer.loadingConfiguration,
        dnssec: state.confReducer.dnssec,
        profile: state.confReducer.profile,
    };
}


const EppConfiguration = Form.create()(withRouter(Connect(mapStateToProps)(injectIntl(EppConfigurationWrapped))));

export default EppConfiguration;
