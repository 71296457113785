import React, {Component} from 'react';
import {Button, Table, Tooltip} from "antd";
import {injectIntl} from "react-intl";
import "./EppMessageListTable.css"
import PropTypes from 'prop-types';
import EppMessageDetail from "../EppMessageDetail/EppMessageDetail";
import {getSelf} from "../../../commons/utils/hal";
import HalPagination from "app/commons/components/Navigation/HalPagination/HalPagination";
import {Modal} from "antd";

const confirm = Modal.confirm;

const ButtonGroup = Button.Group;

class EppMessageListTable extends Component {

    state = {
        detail: null,
        current: null,
        isDeleting: false,
        visible: false,
        modalReadStatus : true
    }


    showModal = () => {
        this.setState({
            visible: true,
        });
    }
    hideModal = () => {
        this.setState({
            visible: false,
        });
    }

    handleDeleteMessage = (record) => {
        const {formatMessage} = this.props.intl;
        const {onDeleteMessage} = this.props;
        confirm({
            // title: formatMessage({id: 'domain.info.restore.title'}, {name: domain}),
            // content: formatMessage({id: 'domain.info.restore.content'}, {name: domain}),
            // okText: formatMessage({id: 'yes'}),
            title: 'Elimina messaggio',
            content: 'Sei sicuro di voler eliminare il messaggio?',
            okText: formatMessage({id: 'yes'}),
            okType: 'danger',
            cancelText: formatMessage({id: 'no'}),
            onOk() {
                onDeleteMessage(getSelf(record))
            },
            onCancel() {
            },
        });
    }


    handleMarkMessage(detail) {
        if (detail) {
            const read = !detail.read;
            this.props.onReadMessage(getSelf(detail), read)
        }
    }

    handleMarkMessageRead(detail) {
        if (detail) {
            this.props.onReadMessage(getSelf(detail), true)
        }
    }

    handleShowMessageDetail(detail) {
        this.setState({detail: detail})
    }

    render() {
        const {intl, data} = this.props;
        const {formatMessage, formatDate} = intl;
        const formattingDate = {
            year: 'numeric', day: 'numeric', month: 'short',
        };

        const isMessageRead = (record, index) => {
            let className = record.read ? 'message-read' : 'message-unread';
            if (index === this.state.current) {
                className += '  current'
            }
            return className;
        }


        const markMessage = (event, record) => {
            event.stopPropagation();
            this.handleMarkMessage(record)
        }

        const deleteMessage = (event, record) => {
            event.stopPropagation();
            this.handleDeleteMessage(record)
        }

        const handleOnOkModal = (detail) => {
            this.props.onReadMessage(getSelf(detail), this.state.modalReadStatus)
            this.setState({read:true});
            this.hideModal();
        }

        const columns = [
            {
                title: formatMessage({id: 'eppMessages.filterkey.date'}),
                dataIndex: 'date',
                key: 'date',
                render: (text) => formatDate(text, formattingDate),
            },
            {
                title: formatMessage({id: 'eppMessages.filterkey.type'}),
                dataIndex: 'type',
                key: 'type',
                render: (text) => formatMessage({id: text})
            },
            {
                title: formatMessage({id: 'eppMessages.filterkey.domain'}),
                dataIndex: 'domain',
                key: 'domain',
            },
            {
                title: 'Azioni',
                dataIndex: '',
                key: 'x',
                width: '100px',
                render: (text, record) =>
                    <ButtonGroup>
                        <Tooltip title='Elimina'>
                            <Button icon="delete" onClick={
                                (e) => deleteMessage(e, record)
                            }/>
                        </Tooltip>
                        <Tooltip
                            title={`${record.read ? 'Segna come da leggere' : 'Segna come letto'}`}>
                            <Button
                                icon={record.read ? 'eye-o' : 'eye'}
                                onClick={(e) => markMessage(e, record)
                                }
                            />
                        </Tooltip>
                    </ButtonGroup>

            },
        ];


        const DisplayTable = () => {
            return (
                <div>
                    <div className='epp-messages-list '>
                        <Table
                            columns={columns}
                            dataSource={data._embedded.eppMessages}
                            pagination={false}
                            rowClassName={(record, index) => isMessageRead(record, index)}
                            rowKey={(record) => getSelf(record)}
                            size='small'
                            bordered
                            onRow={(record, index) => {

                                return {
                                    onClick: () => {
                                        this.showModal();
                                        this.setState({current: index})
                                        this.handleShowMessageDetail(record)
                                    },
                                };
                            }}
                        />
                        <HalPagination
                            HAL_page={data.page}
                            onShowSizeChange={this.props.onShowSizeChange}
                            pageSizeOptions={['20', '50', '100']}
                        />
                    </div>


                    <Modal
                        title="Dettaglio del messaggio"
                        visible={this.state.visible}
                        onCancel={this.hideModal}
                        wrapClassName="vertical-center-modal"
                        closable={false}
                        width={900}
                        footer={null}
                    >
                        <div className='message-detail'>
                            <EppMessageDetail
                                detail={this.state.detail}
                                read={this.state.modalReadStatus}
                                onDeleteMessage={this.handleDeleteMessage.bind(this, this.state.detail)}
                                onMarkMessage={()=>this.setState({modalReadStatus:!this.state.modalReadStatus})}
                            />
                            <div style={{textAlign:'right'}}>
                                <Button
                                    type={'primary'}
                                    onClick={handleOnOkModal.bind(this, this.state.detail)}>
                                    Ok
                                </Button>
                            </div>
                        </div>
                    </Modal>
                </div>
            )
        }

        return (
            <span>
                    <DisplayTable/>
            </span>
        );
    }
}

EppMessageListTable.propTypes = {
    onShowSizeChange: PropTypes.func,
    onReadMessage: PropTypes.func,
    onDeleteMessage: PropTypes.func,
    data: PropTypes.object
};
EppMessageListTable.defaultProps = {};


export default injectIntl(EppMessageListTable);
