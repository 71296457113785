// Versione EPP
/*
"id": "CCTL1",
    "name": "ccTLD '.it' Registry - IIT/CNR",
    "org": "ccTLD '.it' Registry - IIT/CNR",
    "voice": "+39.0503139811",
    "fax": "+39.050542420",
    "email": "hostmaster@nic.it",
    "address": {
      "streets": [
        "Via Giuseppe Moruzzi 1"
      ],
      "city": "Pisa",
      "state": "PI",
      "postalCode": "56124",
      "countryCode": "IT"
    },
    "registrant": {
      "nationalityCode": "IT",
      "entityType": 5,
      "regCode": "02118311006"
    },
    "consentForPublish": true,
    "statuses": [
      "ok",
      "linked"
    ],
    "crDate": "2007-03-01T10:26:04+01:00",
    "clId": "REGISTRY-REG",
    "crId": "REGISTRY-REG",
    "roid": "ITNIC-1036
*/

/*
"name": "Marco Moretti",
   "org": "Marco Moretti",
   "contactId": "MM35955",
   "consentForPublish": false,
   "created": "2007-03-01T10:33:40+01:00",
   "lastupdate": "2013-01-17T17:11:03+01:00",
   "email": "m.moretti@overlan.it",
   "voice": "+39.697848954",
   "fax": "+39.697848917",
   "streets": [
     "P.zza Aldo Moro, 61"
   ],
   "city": "Albano Laziale",
   "postalCode": "00041",
   "state": "RM",
   "registrant": {
     "regcode": "MRTMRC66P01H501L",
     "nationality": "it",
     "entityType": "1"
   },
   "_links": {
     "self": {
       "href": "http://localhost:3000/registry/contacts/1208670"
     },
     "contact": {
       "href": "http://localhost:3000/registry/contacts/1208670"
     }
   },
   "country": "it"
 }
 */

import Visitable from './Visitable';

export default class Contact extends Visitable {
  constructor(id, name,org,
              consentForPublish,address,email, voice,fax, statuses, crDate) {
    super();
    this.id=id;
    this.name=name;
    this.org=org;
    this.consentForPublish=consentForPublish;
    this.address=address;
    this.email=email;
    this.voice=voice;
    this.fax=fax;
    this.statuses=statuses;
    this.crDate=crDate;
  }
  // id
  get id() {
    return this._id;
  }

  set id(id) {
    this._id=id;
  }

  // name
  get name() {
    return this._name;
  }

  set name(name) {
    this._name=name;
  }

  // org
  get org() {
    return this._org
  }

  set org(org) {
    this._org=org;
  }

  // consentForPublish
  get consentForPublish() {
    return this._consentForPublish;
  }

  set consentForPublish(consentForPublish) {
    this._consentForPublish=consentForPublish;
  }

  //address
  get address() {
    return this._address;
  }

  set address(address) {
    this._address=address;
  }



  // email
  get email() {
    return this._email;
  }

  set email(email) {
    this._email=email;
  }

  // voice
  get voice() {
    return this._voice;
  }

  set voice(voice) {
    this._voice=voice;
  }

  // fax
  get fax() {
    return this._fax
  }

  set fax(fax) {
    this._fax=fax
  }

  // statuses
  get statuses() {
    return this._statuses;
  }

  set statuses(statuses) {
    this._statuses=statuses;
  }


  // crdate
  get crDate() {
    return this._crDate;
  }

  set crDate(crDate) {
    this._crDate=crDate;
  }

  accept(visitor) {
    visitor.visitContact(this);
  }

}
