import React from 'react';
import PropTypes from 'prop-types';
import './Spinner.css'

const Spinner = ({loading, embedded}) => {
    let classname = (!embedded && 'spinner') || 'embedded';
    return (
        <div>
            {loading && <span><img alt={'Loading..'} className={classname} src="/epic-spinner.svg"/></span>}
        </div>
    )
};

Spinner.propTypes = {
    loading: PropTypes.bool.isRequired,
    embedded: PropTypes.bool
};
Spinner.defaultProps = {
    loading: true,
    embedded:false
};

export default Spinner;
