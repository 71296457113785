import React from 'react';
import {Button, Form, Input} from 'antd';
import {injectIntl} from "react-intl";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {checkContactId} from "../../../commons/utils/form";
import styled from "styled-components";
import LinkButton from "app/commons/components/Layout/LinkButton/LinkButton";

const FormItem = Form.Item;

class ContactEppActionFormWrapped extends React.Component {

    validateAndSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const contact = values.contact.trim();
                if (contact) {
                    this.props.onSubmit(contact);
                }
            }
        })
    }
    //Controlla se l'id contatto rispetta i vincoli
    checkContactIdWrapped = (rule, value, callback) => {
        const {formatMessage} = this.props.intl;
        checkContactId(rule, value, callback, formatMessage)
    }

    render() {
        const {form, intl, match} = this.props
        const {formatMessage} = intl;
        const {contact} = match.params;
        const {getFieldDecorator} = form;

        return (
            <Form onSubmit={this.validateAndSubmit}>
                <StyledMain>
                    <StyledMain2>
                        <StyleInputBox>
                            <FormItem hasFeedback>
                                {getFieldDecorator('contact', {
                                    initialValue: contact,
                                    rules: [{
                                        required: true,
                                        message: formatMessage({id: "contact.info.input.field.contact.message"})
                                    }, {validator: this.checkContactIdWrapped}],
                                })(
                                    <Input placeholder={formatMessage({id: "contact.info.form.placeholder"})}/>
                                )}
                            </FormItem>
                        </StyleInputBox>
                        <StyledButtonBox>
                            <FormItem>
                                <Button type='primary'
                                        htmlType="submit">{this.props.submitLabel}
                                </Button>


                                {form.getFieldValue('contact') &&
                                <span
                                    style={{borderLeft: '1px solid #ebedee', marginLeft: '10px', paddingLeft: '10px'}}>
                                <LinkButton onClick={this.props.onReset.bind(this)}>
                                    {this.props.resetLabel}
                                </LinkButton>
                            </span>
                                }
                            </FormItem>
                        </StyledButtonBox>

                    </StyledMain2>
                </StyledMain>
            </Form>
        )
            ;
    };
}

const ContactEppActionForm = Form.create()(withRouter(injectIntl(ContactEppActionFormWrapped)));

export default (ContactEppActionForm);

ContactEppActionForm.propTypes = {
    onSubmit: PropTypes.func,
    submitLabel: PropTypes.string,
    onReset: PropTypes.func,
    resetLabel: PropTypes.string
};

export const StyledMain = styled.div`
  width:70%;
  @media (max-width: 1300px) {
    width:95%;
  }
`;
export const StyledMain2 = styled.div`display:flex;justify-content: space-between;
  @media (max-width: 800px) {
   flex-direction: column;
  };`
export const StyleInputBox = styled.div`width:60%;line-height: 1.8;
  @media (max-width: 1300px) {
    width:90%;
  }`;
export const StyledButtonBox = styled.div`width:40%;padding-left: 10px;
  @media (max-width: 800px) {
    width:90%;padding-left: 0px;
  };`
