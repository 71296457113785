import React from 'react';
import DomainCheckForm from "./DomainCheckForm";
import {injectIntl} from "react-intl";
import DomainChecked from "../../DataDisplay/DomainChecked/DomainChecked";
import PropTypes from 'prop-types';
import Error from "../../../../commons/components/FeedBack/Error/Error";
import EppOperationView from "app/commons/components/Pages/EppOperationView";

const DomainCheck = (props) => {
    const {intl, checkDomainData, onSubmitCheck, onCancelCheck} = props;
    const {data, error, loading} = checkDomainData;
    const {formatMessage} = intl;
    return (
        <EppOperationView
            title={formatMessage({id: "domain.check.title"})}
            operationForm={
                <DomainCheckForm
                    onSubmitCheck={onSubmitCheck.bind(this)}
                    onCancelCheck={onCancelCheck.bind(this)}
                />}
            loading={loading}
        >
            {data && <DomainChecked available={data.available} name={data.name} description={data.message}/>}
            {error && <Error error={error}/>}
        </EppOperationView>
    );
};

DomainCheck.propTypes = {
    onSubmitCheck: PropTypes.func,
    onCancelCheck: PropTypes.func,
    checkDomainData: PropTypes.object
};
DomainCheck.defaultProps = {};


export default injectIntl(DomainCheck);
