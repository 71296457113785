import React from 'react';
import ContactTelephoneFormItem from "app/contacts/components/BaseContactForm/FormITems/ContactPhoneFormItem";
import ContactFaxFormItem from "app/contacts/components/BaseContactForm/FormITems/ContactFaxFormItem";
import ContactEmailFormItem from "app/contacts/components/BaseContactForm/FormITems/ContactEmailFormItem";
import ConsentForPublishFormItem from "app/contacts/components/BaseContactForm/FormITems/ConsentForPublishFormItem";
import StatusesFormItem from "app/contacts/components/BaseContactForm/FormITems/StatusesFormItem";

function ContactGroup2FormItems(props) {

    function isConsentForPublishDisabled() {
        if (props.items && !props.items['consentForPublish']) {
            return props.entityType && props.entityType !== 1 && props.items.consentForPublish
        }
        return props.entityType && props.entityType !== 1
    }

    return (
        <div>
            <ContactTelephoneFormItem {...props}/>
            <ContactFaxFormItem  {...props}/>
            <ContactEmailFormItem  {...props}/>
            {!props.isNewContact && <StatusesFormItem {...props} />}
            <ConsentForPublishFormItem  {...props} disabled={isConsentForPublishDisabled()}/>
        </div>
    );
}

ContactGroup2FormItems.propTypes = {};
ContactGroup2FormItems.defaultProps = {};

export default ContactGroup2FormItems;

