import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router';

import {Button, Card, Col, Row} from 'antd';
import PageTitle from '../../../../commons/components/Layout/PageTitle/PageTitle'
import connect from '../../../../commons/utils/api-connector';
import EmailForm from './EmailForm';
import Spinner from "../../../../commons/components/FeedBack/Spinner/Spinner";
import Error from "../../../../commons/components/FeedBack/Error/Error";
import {ADMIN_EMAIL} from "../../../constants/api";
//import { connect, PromiseState } from 'react-refetch';

/*
host: obbligatorio, nome host dell'SMTP server
port: facoltativo, di default assume porta 25
auth: boolean (default false), attiva/disattiva l'autenticazione
username: nome utente visibile e obbligatorio se attiva autenticazione
password: password utente, visibile e obbligatorio se attiva autenticazione
startTLS: boolean (default false) che attiva/disattiva il TLS per l'invio delle emai
*/

class Email extends Component {

    state = {
        isFormTouched: false
    }

    convertValues(values) {
        let configuration = {};
        configuration['host'] = values['host'];

        if (values['port']) {
            configuration['port'] = parseInt(values['port'], 10);
        }

        configuration['auth'] = values['auth']

        if (values['auth']) {
            configuration['username'] = values['username'];
            configuration['password'] = values['password'];
        }


        configuration['startTLS'] = values['startTLS'];


        return configuration;
    }


    handleOnChangeForm = (props) => {
        this.setState({isFormTouched: true})
    }

    handleResetOnClick = () => {
        this.setState({isFormTouched: false})
        this.ref.getForm().resetFields()
    }


    render() {
        const {configuration} = this.props;
        const {location} = this.props;
        const {formatMessage} = this.props.intl;

        let component = null;

        if (configuration.pending) {
            component = <Spinner embedded={true}/>
        } else if (configuration.rejected) {
            let error = {};
            if (configuration.reason.cause) {

                error['status'] = configuration.reason.cause.status;
                error['data'] = configuration;
            } else {
                error['status'] = 500;
                error['data'] = {message: formatMessage({id: 'server.500'})};
            }
            component = <Error error={error} from={location.pathname}/>
        } else if (configuration.fulfilled) {

            component = <EmailForm
                onChangeForm={this.handleOnChangeForm}
                ref={(instance) => {
                    this.ref = instance
                }} {...this.props}/>
        }

        return (
            <div>
                <PageTitle title={formatMessage({id: "admin.email.title"})}/>
                <Card>
                    {component}
                    {configuration.fulfilled &&
                    <Row>
                        <Col xs={{span: 24, offset: 5}}
                             sm={{span: 12, offset: 5}}>
                            <Button
                                disabled={!this.state.isFormTouched}
                                onClick={this.handleResetOnClick}
                                style={{marginRight: '25px'}}
                            >
                                {formatMessage({id: 'cancel'})}
                            </Button>
                            <Button type="primary"
                                    disabled={!this.state.isFormTouched}
                                    onClick={() => {
                                        this.ref.getForm().validateFields(
                                            (err, values) => {
                                                if (!err) {

                                                    let configuration = this.convertValues(values);
                                                    this.props.updateEmail(JSON.stringify(configuration));
                                                    this.setState({isFormTouched: false})
                                                } else {
                                                    console.log("Errore: ", err);
                                                }
                                            }
                                        )

                                    }}>{formatMessage({id: 'admin.email.update'})}
                            </Button>
                        </Col>
                    </Row>
                    }
                </Card>
            </div>
        )
    }
}

export default connect(props => ({
    configuration: `${ADMIN_EMAIL}`,
    updateEmail: configuration => ({
        updateConfiguration: {
            url: `${ADMIN_EMAIL}`,
            method: 'PUT',
            body: configuration
        }
    })
}))(withRouter(injectIntl(Email)));
