import React from 'react';
import {Button, Form, Input} from 'antd';
import {injectIntl} from "react-intl";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {checkDomainName} from "../../../../commons/utils/form";
import {
    StyledButtonBox,
    StyledMain,
    StyledMain2,
    StyleInputBox
} from "app/contacts/components/ContactEppActionForm/ContactEppActionForm";
import LinkButton from "app/commons/components/Layout/LinkButton/LinkButton";

const FormItem = Form.Item;


class DomainCheckWrapped extends React.Component {

    validateAndSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const domain = values.domain.trim();
                if (domain) {
                    this.props.onSubmitCheck(domain);
                }
            }
        })
    }

    //Controlla se il nome a dominio rispetta i vincoli
    checkDomainNameWrapped = (rule, value, callback) => {
        const {formatMessage} = this.props.intl;
        checkDomainName(rule, value, callback, formatMessage)
    }

    render() {
        const {form, intl, match} = this.props
        const {formatMessage} = intl;
        const {domain} = match.params;
        const {getFieldDecorator} = form;

        return (
            <Form onSubmit={this.validateAndSubmit}>
                <StyledMain>
                    <StyledMain2>
                        <StyleInputBox>
                            <FormItem hasFeedback>
                                {getFieldDecorator('domain', {
                                    initialValue: domain,
                                    rules: [{
                                        required: true,
                                        message: formatMessage({id: "domain.check.input.field.domain.message"})
                                    }, {validator: this.checkDomainNameWrapped}],
                                })(
                                    <Input placeholder={formatMessage({id: 'domain.check.inputDomain.placeHolder'})}
                                    />
                                )}
                            </FormItem>
                        </StyleInputBox>
                        <StyledButtonBox>

                            <FormItem>
                                <Button type='primary' htmlType="submit">CHECK</Button>
                                {form.getFieldValue('domain') &&
                                <span style={{borderLeft: '1px solid #ebedee', paddingLeft: '10px'}}>
                                 <LinkButton onClick={this.props.onCancelCheck.bind(this)}>
                            {formatMessage({id: 'domain.info.form.button.reset'})}
                                </LinkButton>
                            </span>

                                }
                            </FormItem>
                        </StyledButtonBox>
                    </StyledMain2>
                </StyledMain>
            </Form>
        );
    };
}

const DomainCheckForm = Form.create()(withRouter(injectIntl(DomainCheckWrapped)));

export default (DomainCheckForm);
DomainCheckForm.propTypes = {
    onSubmitCheck: PropTypes.func,
    onCancelCheck: PropTypes.func,
};