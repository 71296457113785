import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import * as routes from "../../../../routes";
import FiltersBar from "../../../../commons/components/Navigation/FiltersBar/FiltersBar";

/*
    <chiave di ricerca>: {
        label: 'Valore della label mostrata nella barra',
        icon: 'icona mostrata nel menu a tendina, esempio con font-awesome es: fa-font',
        options: {
            textSearch: true,
            placeholder: 'Testo visualizzato nel placeholder',
        },

    },
 */

const filtersOptions = {
    // nameOrOrg: {
    //     label: 'Nome o Organizzazione',
    //     icon: 'fa-font',
    //     options: {
    //         textSearch: true,
    //         placeholder: 'Inserisci testo',
    //     },
    //
    // },
    city: {
        label: 'Città',
        icon: 'fa-university',
        options: {
            textSearch: true,
            placeholder: 'Inserisci testo',
        },

    },
    name: {
        label: 'Nome',
        icon: 'fa-user',
        options: {
            textSearch: true,
            placeholder: 'Inserisci testo',
        },

    },
    /* Campo boolean */
    org: {
        label: 'Organizzazione',
        icon: 'fa-building',
        options: {
            textSearch: true,
            placeholder: 'Inserisci nome organizzazione',
        },
    },
    schoolCode: {
        label: 'Codice scuola',
        icon: 'fa-graduation-cap    ',
        options: {
            textSearch: true,
            placeholder: 'Inserisci il codice scuola',
        },
    },
    contactId: {
        label: 'ID contatto',
        icon: 'fa-key',
        options: {
            textSearch: true,
            placeholder: 'ID contatto valido',
        },

    },
    email: {
        label: 'E-mail',
        icon: 'fa-envelope',
        options: {
            textSearch: true,
            placeholder: 'E-mail valida',
        },

    },
    type: {
        label: 'Tipo contatto',
        icon: 'fa-tag',
        options: {
            list: {
                r: {
                    icon: '',
                    label: 'Registrante'
                },
                e: {
                    icon: '',
                    label: 'Registrante Edu'
                },

                gov: {
                    icon: '',
                    label: 'Registrante Gov'
                },
                c: {
                    icon: '',
                    label: 'Contatto'
                },
            },
        },
    }
}

class ContactListFiltersBar extends Component {

    state = {
        filters: {}
    }

    //Estraee dalla barra indirizzo i parametri e li mette nello stato
    componentWillMount() {
        let searchParams = new URLSearchParams(this.props.location.search.slice(1));
        let res = {};
        for (let pair of searchParams.entries()) {
            res[pair[0]] = pair[1]
        }
        this.setState({filters: res})
    }


    handleOnSubmit = (filters) => {
        let queryString = new URLSearchParams(filters);
        this.setState({filters: filters}); //Modifica l'attuale stato dei filtri
        //Reindirizza al'indirizzo con i filtri modificati
        this.props.history.push({
            pathname: routes.CONTACT_LIST.url,
            search: `?${queryString}`
        })
    }

    render() {
        return (
            <FiltersBar
                initialFilters={this.state.filters}
                filtersOptions={filtersOptions}
                onSubmit={this.handleOnSubmit}
            />
        );
    }
}

ContactListFiltersBar.propTypes = {};
ContactListFiltersBar.defaultProps = {};

export default withRouter(ContactListFiltersBar);
