import React from 'react';
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import "./Home.css"
import {Button, Card, Col, Row} from "antd";
import * as routes from "../../../routes";

// import PropTypes from 'prop-types';

function Home(props) {
    const {userData, confData} = props
    return (
        <div className={'main'}>
            <img style={{display: 'block', margin: '0 auto', marginTop: '20px', marginBottom: '20px'}}
                 src="/logo-epic.svg" alt="Epic (Ep(p) i(nterface) c(lient))"/>
            <h1 style={{borderBottom: '1px solid #E9E9E9', paddingBottom: '20px'}}>
                <strong>EP</strong>p <strong>I</strong>nterface <strong>C</strong>lient
            </h1>
            {userData && confData &&
            <span className='welcome'>
                Benvenuto <strong>{userData.username}</strong> di <strong>{confData.username}</strong>
            </span>
            }

            <div style={{marginTop:20}}>
            <Link to={routes.DOMAIN_NEW.url}>
                <Button type="primary" style={{width:'auto'}} size='large' icon="plus"> Nuovo dominio </Button>
            </Link>
            </div>

            <div style={{padding: '30px'}}>
                <Row gutter={16}>
                    <Col offset={4} span={8}>
                        <Card title="Domini" bordered={true}><Link to={routes.DOMAIN_INFO.url}>
                            <div className='card-icon'><i className="fal fa-globe"/></div>
                        </Link></Card>
                    </Col>
                    <Col span={8}>
                        <Card title="Contatti" bordered={true}><Link to={routes.CONTACT_INFO.url}>
                            <div className='card-icon'><i className="fal fa-id-card"/></div>
                        </Link></Card>
                    </Col>

                </Row>
                <Row gutter={16} className={'home-row'}>
                    <Col offset={4} span={8}>
                        <Card title="Messaggi EPP" bordered={true}><Link to={routes.EPP_MESSAGE_LIST_FILTERED.url}>
                            <div className='card-icon'><i className="fal fa-envelope"/></div>
                        </Link></Card>
                    </Col>
                    <Col span={8}>
                        <Card title="Configurazione" bordered={true}><Link to={routes.ADMIN_EPP.url}>
                            <div className='card-icon'><i className="fal fa-wrench"/></div>
                        </Link></Card>
                    </Col>
                </Row>
            </div>

        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        authenticated: state.authReducer.authenticated,
        userData: state.authReducer.userInfo,
        confData: state.confReducer,
    };
}

/* Inject auth state and dispatch() into props */
export default withRouter(connect(mapStateToProps, null)(Home));