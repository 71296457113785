import styled from "styled-components";
import {Button} from "antd";

const LinkButton = styled(Button)`
  background-color: transparent;
  border: none;
  cursor: pointer;
  //text-decoration: underline;
  color: #1890ff;
  display: inline;
  margin: 0;
  padding: 0;

&:hover, :focus {
text-decoration: none;
}
`;

export default LinkButton;