import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import * as routes from "../../../routes";
import {resetCheckContact} from "../../actions/checkActions";
import {contactsOperations} from "../../index";
import ContactFormEppOperationContainer from "./ContactFormEppOperationContainer";
import ContactEppActionForm from "../../components/ContactEppActionForm/ContactEppActionForm";
import {injectIntl} from "react-intl";

class ContactCheckFormContainer extends Component {

    handleSubmitCheck = (contactId) => (
        this.props.history.push({pathname: `${routes.CONTACT_CHECK.url}/${contactId}`})
    )

    handleCancelCheck = () => (
        this.props.history.push({pathname: `${routes.CONTACT_CHECK.url}`})
    )

    render() {
        const {formatMessage} = this.props.intl;
        return (
            <ContactFormEppOperationContainer
                eppOperation={this.props.checkContactAction.bind(this)}
                resetOperation={this.props.resetCheckContactAction.bind(this)}
                component={
                    <ContactEppActionForm
                        submitLabel={formatMessage({id: 'contact.check.submit.button'})}
                        resetLabel={formatMessage({id: 'cancel'})}
                        onReset={this.handleCancelCheck.bind(this)}
                        onSubmit={this.handleSubmitCheck.bind(this)}/>
                }
                data={this.props.checkContactData.data}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        checkContactData: state.contact.checkReducer
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        checkContactAction: (contact) => {
            dispatch(contactsOperations.checkContactOp(contact))
        },
        resetCheckContactAction: () => {
            dispatch(resetCheckContact())
        },
    };
};

/* Inject auth state and dispatch() into props */
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ContactCheckFormContainer)));
