import React, {Component} from 'react';
import {Input, Menu, Switch} from 'antd';
import MenuItemRow from "./MenuItemRow";
import PropTypes from "prop-types";
import "./Filters.css"
import {Select} from 'antd';

const Search = Input.Search;
const Option = Select.Option;


class SelectInput extends Component {

    render() {
        const {select} = this.props.options;
        if (select) {
            return (

                <Select
                    showSearch
                    style={{width: 200}}
                    placeholder="Seleziona uno stato"
                    optionFilterProp="children"
                    onChange={this.props.onChange}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                    {
                        this.props.options.select &&
                        Object.keys(this.props.options.select).map(function (key, index) {
                            return (
                                <Option value={key} key={index}>{key}</Option>
                            )
                        })
                    }
                </Select>
            )
        } else {
            return <span/>
        }
    }
}

const BooleanFieldInput = (props) => {
    const {booleanField, labelTrue, labelFalse} = props.options;
    const {onChange} = props;
    if (booleanField) {
        return (
            <div className='filter-menu-input-search'>
                <Switch
                    defaultChecked
                    checkedChildren={labelTrue}
                    unCheckedChildren={labelFalse}
                    onChange={onChange}
                />
            </div>)
    } else {
        return <span/>
    }
}

const TextSearchInput = (props) => {
    const {textSearch, placeholder} = props;
    const {onSearch} = props;
    if (textSearch) {
        return (
            <div className='filter-menu-input-search'>
                <Search
                    placeholder={placeholder}
                    onSearch={onSearch}
                />
            </div>)
    } else {
        return <span/>
    }
};


class OptionList extends Component {

    state = {
        booleanValue: true
    }

    render() {
        const {options, onClick, onCancel, onBooleanConfirm} = this.props;
        const {list, booleanField, textSearch} = options;

        const hanldeBooleanOnChange = (checked) => {
            this.setState({booleanValue: checked})
        }

        const hanldeBooleanConfirm = () => {
            onBooleanConfirm(this.state.booleanValue)
        }


        // const hanldeSelectOnChange = (value) => {
        //     console.log(" e",value)
        //     // this.setState({booleanValue: checked})
        // }
        return (
            <div className={`filter-div filter-div-options ${textSearch ? 'extra' : ''}`}>
                <div className='div-exit'>
                    <div
                        onClick={onCancel}
                    >
                        <i className="fal fa-times filter-options-exit"/>
                    </div>
                </div>
                <TextSearchInput
                    {...options} onSearch={onClick}
                />
                <BooleanFieldInput
                    className={'filter-menu-wrapped'}
                    options={options}
                    onChange={hanldeBooleanOnChange.bind(this)}
                />
                <SelectInput
                    options={options}
                    onChange={onClick}
                />
                <Menu
                    className={'filter-menu'}
                    onClick={(opt) => onClick(opt.key)}
                >
                    {
                        list &&
                        Object.keys(list).map(function (key, index) {
                            return (
                                <Menu.Item key={key}>
                                    <MenuItemRow {...list[key]} />
                                </Menu.Item>
                            )
                        })
                    }
                </Menu>
                {
                    booleanField &&
                    <span
                        className={'operation'}
                        onClick={hanldeBooleanConfirm}
                    >Conferma</span>
                }
            </div>
        )
    }
}

export default OptionList


OptionList.propTypes = {
    options: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    onCancel: PropTypes.func,
    onBooleanConfirm: PropTypes.func
};

OptionList.defaultProps = {};