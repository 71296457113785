import React from 'react';
import {injectIntl} from "react-intl";
import PropTypes from "prop-types";
import EppChecked from "../../../../commons/components/DataDisplay/EppChecked/EppChecked";

const ContactChecked = ({id, available, description, intl}) => {
    const {formatMessage} = intl

    function showMessage(available) {
        return available ?
            formatMessage({id: "contact.check.contactAvailable"}) :
            formatMessage({id: "contact.check.contactNotAvailable"})
    }

    return (<EppChecked name={id}
                        checked={available}
                        message={showMessage(available)}
                        description={description}
    />)
}

ContactChecked.propTypes = {
    id: PropTypes.string,
    description: PropTypes.string,
    available: PropTypes.bool
};

ContactChecked.defaultProps = {};

export default injectIntl(ContactChecked);

