import React, {Component} from 'react';
import {Card, notification} from 'antd';
import {FormattedMessage, injectIntl} from "react-intl";
import DomainHosts from "../DomainNew/DomainHosts";
import PropTypes from "prop-types";
import Step1HostSelection from "./Steps/Step1HostSelection";
import Step3HostUpdateSummary from "./Steps/SummaryHostUpdateSummary";
import DefaultWizard from "../../../../commons/components/Navigation/Wizard/DefaultWizard";
import Step3DSSelection from "app/domains/components/Pages/DomainUpdateHost/Steps/Step3DSSelection";
import DNSSec from "app/domains/components/Pages/DomainNew/DNSSec";
import {connect} from "react-redux";
import _ from "lodash"
import {buildDsRecordsToAdd, similarObjects} from "app/domains/components/Pages/DomainUpdateHost/utils";


class DomainUpdateHostWizard extends Component {

    addAddress = (address, type) => {
        let addressObject = null
        if (address) {
            addressObject = {};
            addressObject['address'] = address;
            addressObject['type'] = type;
        }
        return addressObject;
    }

    buildNamesHash = (fields) => {
        const {hosts} = fields.domainData;
        let hash = {};
        if (hosts) {
            hosts.forEach((host) => {
                hash[host.name] = host;
            })
        }
        return hash;

    }


    compareAddress = (address, ipv4, ipv6) => {
        let res = true;

        switch (address.type) {
            case 'v4': {
                res = address.address === ipv4;
                break;
            }
            case 'v6': {
                res = address.address === ipv6;
                break;
            }
            default:
                res = false
        }

        return res;
    }

    buildChangedHosts = (fields) => {
        const {keys} = fields;

        let originalDnsTable = this.buildNamesHash(fields);


        let address;
        let changedHosts = [];
        let unchangedHosts = [];
        for (let k = 0; k < keys; k++) {
            let dns = {};
            let addresses = [];
            let hostName = fields[`names-${k}`];
            dns['name'] = hostName;

            address = this.addAddress(fields[`ipv4-${k}`], 'v4')
            if (address) addresses.push(address);

            address = this.addAddress(fields[`ipv6-${k}`], 'v6');
            if (address) addresses.push(address);

            if (addresses.length > 0)
                dns['addresses'] = addresses;

            if (!originalDnsTable[hostName]) {
                changedHosts.push(dns);
            } else {
                let changed = false;
                let dnsOrig = originalDnsTable[hostName];
                let origAddresses = dnsOrig.addresses;


                if (addresses && addresses.length > 0) {
                    let ipv4 = fields[`ipv4-${k}`];
                    let ipv6 = fields[`ipv6-${k}`];

                    if (origAddresses.length !== addresses.length) {
                        changed = true;
                    } else {
                        origAddresses.forEach((address) => {
                            changed = changed || !this.compareAddress(address, ipv4, ipv6);
                        })
                    }

                }
                if (changed) {
                    changedHosts.push(dns);
                } else {
                    unchangedHosts.push(dns);
                }
            }
        }
        return {changedHosts, unchangedHosts};
    }


    wizardSteps = [

        {
            id: 'step1',
            title: <FormattedMessage id='domain.update.host.step1.title'/>,
            description: <FormattedMessage id="domain.update.host.step1.description"/>,
            readOnly: false,
            showHeader: false,
            //checkFields : (fields)=>{return notification.error("aaaa")},
            content: Step1HostSelection,
        },
        {
            id: 'step2',
            title: <FormattedMessage id='domain.update.host.step2.title'/>,
            description: <FormattedMessage id="domain.update.host.step2.description"/>,
            readOnly: false,
            showHeader: true,
            content: DomainHosts
        },
        {
            id: 'step3',
            title: <FormattedMessage id='domain.update.host.step3.title'/>,
            description: <FormattedMessage id="domain.update.host.step3.description"/>,
            readOnly: false,
            showHeader: true,
            content: Step3DSSelection
        },
        {
            id: 'step4',
            title: <FormattedMessage id='domain.update.host.step4.title'/>,
            description: <FormattedMessage id="domain.update.host.step4.description"/>,
            readOnly: false,
            showHeader: false,
            content: DNSSec
        },
        {
            id: 'summary',
            title: <FormattedMessage id='domain.update.host.summary.title'/>,
            description: <FormattedMessage id="domain.update.host.summary.description"/>,
            readOnly: false,
            showHeader: false,
            doneButton: this.props.intl.formatMessage({id: 'domain.update.host.doneButton'}),
            dnssec: this.props.dnssec,
            error: (fields, props) => {

                let hostInfo = this.buildChangedHosts(fields);


                let dnsCollectedInfo = {hostsToAdd: hostInfo.changedHosts, hostsToRemove: fields.deletedDns};

                let dsRecordsCollectedInfo = {
                    dsRecordsToAdd: buildDsRecordsToAdd(fields),
                    dsRecordsToRemove: fields.dsRecordsToRemove
                }
                let collectedInfo = _.merge(dnsCollectedInfo, dsRecordsCollectedInfo);

                let res = this.validateUpdate(collectedInfo);


                return res;
            },
            content: Step3HostUpdateSummary
        },

    ];

    // Controlli da effetuare
    validateUpdate = ({hostsToAdd, hostsToRemove, dsRecordsToRemove =[], dsRecordsToAdd =[]}) => {

        const {formatMessage} = this.props.intl;
        const {domainData} = this.props.fields;
        let res = true;


        // console.log("hostsToAdd ", hostsToAdd)
        // console.log("hostsToRemove ", hostsToRemove)
        // console.log("dsRecordsToRemove ", dsRecordsToRemove)
        // console.log("dsRecordsToAdd ", dsRecordsToAdd)

        let noChangeCondition = (
            hostsToAdd.length === 0 &&
            hostsToRemove.length === 0 &&
            dsRecordsToAdd.length === 0 &&
            dsRecordsToRemove.length === 0
        );

        let errorMsg = '';

        if (noChangeCondition) {
            errorMsg = formatMessage({id: 'domainUpdate.dns.error.description.noAddNoRemove'});
            return ({status: false, msg: errorMsg});
        }


        let originalHostsLen = (domainData.hosts ? domainData.hosts.length : 0);

        let numConfHosts = originalHostsLen + hostsToAdd.length - hostsToRemove.length;

        let condition2 = (numConfHosts >= 2 && numConfHosts < 7);
        let condition1 = (!condition2 && hostsToRemove.length > 0);


        let dsRecrodsCond1 = similarObjects(dsRecordsToRemove, dsRecordsToAdd).length > 0;

        if (condition1) {
            errorMsg = formatMessage({id: 'domainUpdate.dns.error.description.areTheSame'});
            return ({status: false, msg: errorMsg});
        }

        if (dsRecrodsCond1) {
            errorMsg = formatMessage({id: 'domainUpdate.dsRecords.error.description.areTheSame'});
            return ({status: false, msg: errorMsg});
        }


        if (!condition2) {
            errorMsg = formatMessage({id: 'domainUpdate.dns.error.description.number'}, {
                num: numConfHosts,
                min: 2,
                max: 6
            });

            return ({status: false, msg: errorMsg});
        }

        return {status: res, msg: errorMsg};
    }


    convertFields = (fields) => {
        let res = {}
        res['hostsToRemove'] = fields.deletedDns;
        res['hostsToAdd'] = fields.modifiedHosts;
        res['dsRecordsToRemove'] = fields.dsRecordsToRemove;
        res['dsRecordsToAdd'] = buildDsRecordsToAdd(fields);
        return res;
    }


    render() {

        function stepsToShow(props, wizardSteps) {
            let steps = wizardSteps;

            //Rimuove dagli steps lo step steo3 : 'selezione dnssec records' perche nn ce ne sono
            if (props.fields.domainData.dsRecords === undefined) {
                steps = _.filter(steps, (obj) => obj.id !== 'step3')
            }

            //Rimuove dagli steps lo step step4 : 'aggiunta di dnsSec'
            if (!props.dnssec) {
                steps = _.filter(steps, (obj) => obj.id !== 'step4')
            }
            return steps;
        }


        return (
            <div>
                <Card>
                    <DefaultWizard
                        steps={stepsToShow(this.props, this.wizardSteps)}
                        fields={this.props.fields}
                        onCancel={this.props.onCancel}
                        onDone={(fields) => {
                            const {formatMessage} = this.props.intl;

                            let updateInfo = this.convertFields(fields);
                            let validate = this.validateUpdate(updateInfo)
                            if (validate.status) {
                                this.props.onDone(fields.domainData.name, updateInfo);
                            } else {
                                notification.error({
                                    duration: 10,
                                    message: formatMessage({id: 'domainUpdate.dns.error.message'}),
                                    description: validate.msg,
                                });
                            }
                        }}
                    />
                </Card>
            </div>
        )
    }
}


function mapStateToProps(state) {

    return {
        dnssec: state.confReducer.dnsSecEnabled
    };
}


export default connect(mapStateToProps)(injectIntl(DomainUpdateHostWizard));

DomainUpdateHostWizard.propTypes = {
    onDone: PropTypes.func,
    fields: PropTypes.object,
    onCancel: PropTypes.func,
};
