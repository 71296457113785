import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {Form} from "antd";

import DomainContactsTable, {
    mergeTechsAdminContacts,
    ADMIN,
    TECH
} from "../../../DataDisplay/DomainContatcsTable/DomainContactsTable";

const FormItem = Form.Item;


class Step1ContactSelection extends Component {


    /** IMPORTANTE!!!!!!
     *
     * Per convenzione la prima posizione, cioe' indice 0,  sara' sempre occupata dal contatto di tipo ADMIN
     *
     *
     *
     *

     La selezione dei conatti riporta i campi  (fai prima a vedere su console):

     - disabled-admin={true|false}, se il campo e' selezionato o meno
     - disabled-0...X={true|false}, se il campo tech e' selezionato o meno
     - deletedContatcs{[{},{}]} - lista degli oggeti da elimare

     -

     techId-admin
     techId-admin-Full

     techId-0
     techId-0-Full
     ..
     techId-x
     techId-x-Full
     *
     * ***/

    constructor(props) {
        super(props);
        this.rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                const {getFieldValue, resetFields, setFieldsValue} = this.props.form;

                //this._resetDisabledFields(1 + this.props.fields.domainData.techs.length);
                resetFields();

                selectedRowKeys.forEach((key) => {
                    this._setDisabledFields(key, false);
                    this._clearFormData(key);
                })

                let ids = getFieldValue('techIds');
                if (ids.length === 0) {
                    setFieldsValue({techIds: [0]})
                }


                setFieldsValue({'deletedContacts': selectedRows});

            },
            getCheckboxProps: record => ({
                disabled: record.name === 'Disabled User', // Column configuration not to be checked
            }),
        }
    }

    componentDidMount() {
        //Genere l'array contatti
        const contacts = mergeTechsAdminContacts(this.props.fields.domainData.techs, this.props.fields.domainData.admin);

        //Passa i campi che vanno di default valorizzati (non utili ma indispensabili)
        this._fillRegistrantContactFields(this.props.fields.domainData.registrant);

        //Passa i campi di interesse
        this._fillStep2Fields(contacts)
    }


    _clearFormData(key) {
        const {setFieldsValue, getFieldValue} = this.props.form;
        if (key === 0) { //Admin
            setFieldsValue({'adminContactId': null})
            setFieldsValue({'adminContactId-Full': null})
        } else {
            let tech = {};
            let id = key - 1;
            tech[`techId-${id}`] = null;
            tech[`techId-${id}-Full`] = null;
            let techIds = getFieldValue("techIds");

            techIds = techIds.filter(techId => techId !== id);

            tech['techIds'] = techIds;

            setFieldsValue(tech)
        }
    }

    _setDisabledFields(key, value) {
        const {setFieldsValue} = this.props.form;
        if (key === 0) { //Admin
            setFieldsValue({'disabled-admin': value})
        } else {
            let disabled = {};
            disabled[`disabled-${key - 1}`] = value
            setFieldsValue(disabled)
        }
    }

    //Mette a false tutti i campi disabled-xx
    _resetDisabledFields(fieldsNumber) {
        for (let k = 0; k < fieldsNumber; k++) {
            this._setDisabledFields(k, true)
        }
    }

    _fillAdminContactFields(contact) {
        const {getFieldDecorator} = this.props.form;
        getFieldDecorator(`adminContactId`, {initialValue: contact.id});
        getFieldDecorator(`adminContactId-Full`, {initialValue: contact});
        getFieldDecorator(`disabled-admin`, {initialValue: true});
    }

    _fillTechContactFields(contact, index) {
        const {getFieldDecorator} = this.props.form;
        getFieldDecorator(`techId-${index}`, {initialValue: contact.id});
        getFieldDecorator(`techId-${index}-Full`, {initialValue: contact});
        getFieldDecorator(`disabled-${index}`, {initialValue: true});

    }

    _fillRegistrantContactFields(contact) {
        const {getFieldDecorator} = this.props.form;
        getFieldDecorator(`registrantContactId`, {initialValue: contact.id});
        getFieldDecorator(`registrantContactId-Full`, {initialValue: contact});
        //Disabilita la visualizzazione del nome a dominio
        getFieldDecorator('dnsEmbedded', {initialValue: true})
    }

    _fillStep2Fields(contacts) {
        let techKeys = [];
        const {getFieldDecorator} = this.props.form;
        contacts.forEach((contact, k) => {
            if (contact.type === ADMIN) {
                this._fillAdminContactFields(contact)
            } else if (contact.type === TECH) {
                techKeys.push(k - 1);
                this._fillTechContactFields(contact, k - 1)
            }
        })
        //Tiene il conto degli elementi Tech (sottrago 2 per adattarlo allo step 2 , 1 tech equivale a indice 0)
        getFieldDecorator(`techIds`, {initialValue: techKeys});
        getFieldDecorator('deletedContacts', {initialValue: []});
        getFieldDecorator('hide-registrant', {initialValue: true});
    }

    render() {
        return (
            <Form>
                <FormItem>
                    <div>
                        <DomainContactsTable
                            contacts={mergeTechsAdminContacts(this.props.fields.domainData.techs, this.props.fields.domainData.admin)}
                            rowsSelectionHandler={this.rowSelection}
                        />
                    </div>
                </FormItem>
            </Form>
        )
    }


}


export default Form.create()(injectIntl(Step1ContactSelection));