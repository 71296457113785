import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DomainInfoUpdateBox from "../../Box/DomainInfoCustomableBox";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import TransferTradeForm from "./TransferTradeForm";

class DomainTransferTrade extends Component {

    render() {
        const {infoDomainData, intl} = this.props;
        const {formatMessage} = intl;

        const handleSubmit = (domain, data) => {
            if (domain && data && infoDomainData.data.authinfo) {
                this.props.onTransferTrade(domain, infoDomainData.data.authinfo, data)
            }
        }

        return (
            <div>
                <DomainInfoUpdateBox
                    title={formatMessage({id: "domain.transfer.trade.form.title"})}
                    hasRegistrarPermission={this.props.hasRegistrarPermission}
                    infoData={this.props.infoDomainData.data}
                    error={this.props.transferRequestDomain.error}
                    loading={this.props.transferRequestDomain.loading}
                    message={this.props.transferRequestDomain.message}
                    reset={this.props.reset}
                >
                    <TransferTradeForm
                        data={infoDomainData.data}
                        onCancel={this.props.onCancel.bind(this)}
                        onSubmit={handleSubmit}
                        submitLabel={formatMessage({id: "domain.tansfer.trade"})}
                    />
                </DomainInfoUpdateBox>
            </div>
        )
    }
}

DomainTransferTrade.propTypes = {
    hasRegistrarPermission: PropTypes.bool,
    onCancel: PropTypes.func,
    onTransferTrade: PropTypes.func,
};
DomainTransferTrade.defaultProps = {};


const mapStateToProps = (state) => {
    return {
        infoDomainData: state.domain.infoReducer,
        transferRequestDomain: state.domain.transferReducer.transferRequestDomain,
    };
}


export default connect(mapStateToProps)(injectIntl(DomainTransferTrade));

