import React from 'react';
import {Form, Input} from 'antd';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {countriesOperations} from "../../../../countries/index";
import CountryFormItemContainer from "app/countries/components/CountryFormItemContainer";
import {SubFormItemBox} from "app/contacts/components/BaseContactForm/FormITems/RegistrantFormItem";
import StreetsFormItem from "app/contacts/components/BaseContactForm/FormITems/StreetsFormItems";
import {SubformItemLayout} from "app/contacts/components/BaseContactForm/const";

const FormItem = Form.Item;


//TODO  ,rivedere la classe, togliere le cose inutili e scorporare in SMERT+DUMB. Togliere il css di StreetsFormItem.

class AddressFormItemWrapped extends React.Component {

    componentDidMount() {
        this.handleRules()
    }

    state = {
        len2RuleValidator: {},
        postalCodeMin: 1,
        postalCodeMax: 16,
        postalCodeMsg: 'contact.detail.form.address.postalCode.notItalianLength',
    }

    /* il campo Nazione è modificabile solo se il Registrante è una persona fisica (cioè EntityType = 1);
     * se è un nuovo contatto
     * non lo è se è un registrante edu (che vede predefinito quel valore)
      * */
    hasCountryCodeEditPermission() {
        const {isRegistrant,   isNewContact, isEdu, isGov} = this.props
        return this.isEntityType1() || (isNewContact && !isEdu && !isGov) || !isRegistrant
    }

    isEntityType1() {
        return this.props.items && this.props.items.registrant && (this.props.items.registrant.entityType === 1)
    }

    isItalian() {
        const {form, items} = this.props
        const country = form.getFieldValue('address.countryCode') || (items && items.address && items.address.countryCode);
        return country && country.toUpperCase() === 'IT'
    }

    handleRules() {
        this.applyStateLen2Rule()
        this.applyPostalCodeLenRule()
    }

    handleSetRegistrantNatCode = (e) => {
        this.props.onChangeNationality(e)
        this.props.onChange(e)
        // const {setFieldsValue} = this.props.form;
        // return this.props.isEntityType1 ? '' : setFieldsValue({'registrant.nationalityCode': e})
    }

    handleCountryCodeIntialValue() {
        //
        if ((this.props.isEdu || this.props.isGov) && this.props.defaultNationalCode) {
            return this.props.defaultNationalCode
        }

        return this.props.items && this.props.items.address && this.props.items.address.countryCode;
    }

    render() {
        const {intl, items} = this.props;
        const {getFieldDecorator} = this.props.form;
        const {formatMessage} = intl;

        // const formItemLayout = {
        //     labelCol: {
        //         // xs: {span: 24},
        //         md: {span: 7},
        //         lg: {span: 4},
        //     },
        //     wrapperCol: {
        //         // xs: {span: 24},
        //         md: {span: 24},
        //         lg: {span: 20},
        //     },
        // };
        return (
            <SubFormItemBox>
                <CountryFormItemContainer
                    {...this.props}
                    formItemLayout={SubformItemLayout}
                    data={this.props.countries.list}
                    label={formatMessage({id: 'contact.detail.form.address.countryCode'})}
                    fieldDecorator={'address.countryCode'}
                    initialValue={this.handleCountryCodeIntialValue()}
                    disabled={!this.hasCountryCodeEditPermission()}
                    placeholder={formatMessage({id: 'detail.contact.address.placeholder.countryCode'})}
                    required={true}
                    onChange={this.handleSetRegistrantNatCode}
                    onBlur={() => this.handleRules()}
                />

                <FormItem className="ant-row ant-from-item"
                          {...SubformItemLayout}
                          label={formatMessage({id: 'contact.detail.form.address.state'})}
                          hasFeedback
                >
                    {getFieldDecorator('address.state', {
                        initialValue: items && items.address && items.address['state'],
                        rules: [
                            {
                                required: true,
                                message: formatMessage({id: 'contact.detail.form.address.state.message'}),
                            },
                            this.state.len2RuleValidator
                        ],
                    })(<Input placeholder={formatMessage({id: 'detail.contact.address.placeholder.state'})}/>)}
                </FormItem>

                <FormItem className="ant-row ant-from-item"
                          {...SubformItemLayout}
                          label={formatMessage({id: 'contact.detail.form.address.city'})}
                          hasFeedback
                >
                    {getFieldDecorator('address.city', {
                        initialValue: items && items.address && items.address['city'],
                        rules: [{
                            required: true,
                            message: formatMessage({id: 'contact.detail.form.address.city.message'}),
                        }],
                    })(<Input placeholder={formatMessage({id: 'detail.contact.address.placeholder.city'})}/>)}
                </FormItem>

                <FormItem className="ant-row ant-from-item"
                          {...SubformItemLayout}
                          label={formatMessage({id: 'contact.detail.form.address.postalCode'})}
                          hasFeedback
                >
                    {getFieldDecorator('address.postalCode', {
                        initialValue: items && items.address && items.address['postalCode'],
                        rules: [
                            {
                                required: true,
                                message: formatMessage({id: 'contact.detail.form.address.postalCode.message'}),
                            },
                            {
                                min: this.state.postalCodeMin,
                                max: this.state.postalCodeMax,
                                message: formatMessage({id: this.state.postalCodeMsg}),
                            }
                        ],
                    })(<Input placeholder={formatMessage({id: 'detail.contact.address.placeholder.postalCode'})}/>)}
                </FormItem>

                <FormItem className="ant-row ant-from-item"
                          label={formatMessage({id: 'contact.detail.form.address.streets'})}
                          {...SubformItemLayout}
                    // hasFeedback
                >
                    {getFieldDecorator('address.streets', {
                        valuePropName: 'addresss',
                        initialValue: items && items.address && items.address['streets']

                        // rules: [{
                        //     required: true,
                        //     message: formatMessage({id: 'contact.detail.form.address.streets.message'}),
                        // }],
                    })(<StreetsFormItem {...this.props} type="object"/>)}
                </FormItem>
            </SubFormItemBox>
        );
    };


    applyStateLen2Rule() {
        const {getFieldValue, setFields} = this.props.form;
        const {formatMessage} = this.props.intl;
        const state = getFieldValue('address.state');
        if (this.isItalian()) {
            if (state && state.length !== 2) {
                setFields({
                    'address.state': {
                        value: state,
                        errors: [new Error(formatMessage({id: 'contact.detail.form.address.state.StateLen2'}))],
                    },
                });
            }
            this.setState({
                len2RuleValidator: {
                    type: "string",
                    len: 2,
                    message: formatMessage({id: 'contact.detail.form.address.state.StateLen2'})
                }
            })
        } else {
            this.setState({len2RuleValidator: {}})
        }
    }

    applyPostalCodeLenRule() {
        //Se il contatto è italiano il cap è accettabile solo se composto da 5 numeri.
        //Se straniero va bene più o meno tutto tra 1-16 caratteri
        const {getFieldValue, setFields} = this.props.form;
        const {formatMessage} = this.props.intl;
        const code = getFieldValue('address.postalCode');
        if (this.isItalian()) {
            if (code && code.length !== 5) {
                setFields({
                    'address.postalCode': {
                        value: code,
                        errors: [new Error(formatMessage({id: 'contact.detail.form.address.postalCode.italianLength'}))],
                    },
                });
            }
            this.setState({
                postalCodeMin: 5,
                postalCodeMax: 5,
                postalCodeMsg: 'contact.detail.form.address.postalCode.italianLength'
            })
        }
        else {
            if (code && code.length > 16) {
                setFields({
                    'address.postalCode': {
                        value: code,
                        errors: [new Error(formatMessage({id: 'contact.detail.form.address.postalCode.notItalianLength'}))],
                    },
                });
            }
            this.setState({
                postalCodeMin: 1,
                postalCodeMax: 16,
                postalCodeMsg: 'contact.detail.form.address.postalCode.notItalianLength'
            })
        }
    }
}

const mapStateToProps = (state) => {
    return {
        countries: state.countryReducer
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadCountryCode: () => dispatch(countriesOperations.loadCountryCodeOp())
    };
}


/* Inject auth state and dispatch() into props */
export default connect(mapStateToProps, mapDispatchToProps)(AddressFormItemWrapped);

AddressFormItemWrapped.propTypes = {
    isEntityType1: PropTypes.bool,
    isRegistrant: PropTypes.bool,
    isNewContact: PropTypes.bool,
    entityType: PropTypes.number,
    defaultNationalCode: PropTypes.string,  //Definisce il valore fisso del campo NationalCode se isEdu è true
    isEdu: PropTypes.bool,
    isGov: PropTypes.bool,
    onChangeNationality: PropTypes.func,
};

AddressFormItemWrapped.defaultProps = {
    isEdu: false,
    isGov: false,
    isEntityType1: false,
}


