import React from 'react';
import PropTypes from 'prop-types';
import "./EppMessagesType.css"

const SimpleMessage = (props) => {
    return (
        <div className='base-message'>
            {props.message}
        </div>
    );
};

SimpleMessage.propTypes = {
    message: PropTypes.string
};
SimpleMessage.defaultProps = {};

export default SimpleMessage;
