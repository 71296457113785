import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import ErrorAndNotification from "../../../../commons/containers/ErrorAndNotification";
import ViewSelector from "app/commons/components/Navigation/ViewSelector/ViewSelector";
import ContactStatusBar from "app/contacts/components/DataDisplay/ContactStatusBar/ContactStatusBar";
import {renderContactView} from "app/contacts/components/renderContactView";
import ContactActionMenuContainer from "app/contacts/containers/ContactActionMenuContainer";
import {toolBarWithDetailRender} from "app/commons/components/Navigation/ViewSelector/toolBarWithDetailRender";
import DetailToolbar from "app/commons/components/Layout/DetailToolbar/DetailToolbar";
import EppOperationView from "app/commons/components/Pages/EppOperationView";
import ContactInfoFormContainer from "app/contacts/containers/EppOperationForm/ContactInfoFormContainer";

const ContactDetailToolbar = ({onClick, data}) =>
    <DetailToolbar
        actionMenu={<ContactActionMenuContainer/>}
        showActionMenu={true}
        statusBar={<ContactStatusBar contactData={data}/>}
        viewSelector={<ViewSelector onClick={onClick}/>}
        onClick={onClick}
    />;

const DetailWithToolbar = toolBarWithDetailRender(ContactDetailToolbar, injectIntl(renderContactView))

class ContactInfo extends React.Component {

    showDetail = () => {
        const {infoContactData} = this.props;
        if (infoContactData.data) {
            return <div>
                <ErrorAndNotification
                    error={infoContactData.error}
                    message={infoContactData.message}
                    // reset={notificationsReset.bind(this)}
                />
                <DetailWithToolbar data={infoContactData.data}/>
            </div>
        } else if (infoContactData.error) {
            return <ErrorAndNotification
                error={infoContactData.error}
                message={infoContactData.message}
                reset={this.props.notificationsReset.bind(this)}
            />
        }
    }

    render() {
        const {infoContactData} = this.props;
        const {formatMessage} = this.props.intl;
        return (
            <EppOperationView
                title={formatMessage({id: "contact.info.form.title"})}
                loading={infoContactData.loading}
                operationForm={<ContactInfoFormContainer/>}
            >
                {this.showDetail()}
            </EppOperationView>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        infoContactData: state.contact.infoReducer,
        configurationData: state.confReducer,
    };
}

export default connect(mapStateToProps)(injectIntl(ContactInfo));

ContactInfo.propTypes = {
    notificationsReset: PropTypes.func,
};
ContactInfo.defaultProps = {};