import {applyMiddleware, createStore} from "redux";
import reducer from "../reducers/index";
import thunk from "redux-thunk";
import promise from "redux-promise";
import {createLogger} from "redux-logger";
import {composeWithDevTools} from "redux-devtools-extension";

const logger = createLogger();
export default function configureStore() {
    return createStore(
        reducer, composeWithDevTools(applyMiddleware(thunk, promise, logger)));
}