export const OK = 'ok'
export const LINKED = 'linked'
export const REVOKED = 'revoked'
export const TO_BE_REASSIGNED = 'toBeReassigned'
export const CLIENT_HOLD = 'clientHold'
export const CLIENT_UPDATE_PROHIBITED = 'clientUpdateProhibited'
export const CLIENT_DELETE_PROHIBITED = 'clientDeleteProhibited'
export const CLIENT_TRANSFER_PROHIBITED = 'clientTransferProhibited'
export const SERVER_HOLD = 'serverHold'
export const SERVER_TRANSFER_PROHIBITED = 'serverTransferProhibited'
export const SERVER_UPDATE_PROHIBITED = 'serverUpdateProhibited'
export const SERVER_DELETE_PROHIBITED = 'serverDeleteProhibited'
export const PENDING_DELETE = 'pendingDelete'
export const REDEMPTION_PERIOD = 'redemptionPeriod'
export const NO_REGISTRAR = 'noRegistrar'
export const PENDING_TRANSFER = 'pendingTransfer'
export const PENDING_UPDATE = 'pendingUpdate'


export const PENDING = 'pending';
export const CLIENT_APPROVED = 'clientApproved';
export const CLIENT_REJECTED = 'clientRejected';
export const CLIENT_CANCELLED = 'clientCancelled';
export const SERVER_APPROVED = 'serverApproved';