import React, {Component} from 'react';
import {injectIntl} from "react-intl";
import {Alert, Form} from "antd";
import DomainContactsTable, {
    mergeTechsAdminContacts,
    TECH, ADMIN
} from "../../../DataDisplay/DomainContatcsTable/DomainContactsTable";
import {buildSummaryRecords} from "app/domains/components/Pages/DomainUpdateHost/utils";


class Step3ContactUpdateSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            originalContacts: [],
            deletedContacts: [],
            newContacts: []
        };

    }


    generateNewContacts = (fields) => {
        const {techs} = fields.domainData;
        let originalTechsTable = {};
        let newElements = []

        techs.forEach((contact) => {
            originalTechsTable[contact.id] = contact;
        });

        let adminMod = fields[`adminContactId-Full`];

        if (adminMod.id && !fields[`disabled-admin`]) {
            adminMod['type'] = ADMIN;
            newElements.push(adminMod);
        }


        fields.techIds.forEach((id) => {

            if (!fields[`disabled-${id}`]) {
                let contact = fields[`techId-${id}-Full`];
                contact['type'] = TECH;
                newElements.push(contact);
            }

        });


        return newElements;

    }

    exclude = function (list) {
        return this.filter(function (el) {
            return list.indexOf(el) < 0;
        })
    }


    componentWillMount() {
        const {fields} = this.props;
        const {getFieldDecorator} = this.props.form;
        let originalContacts = mergeTechsAdminContacts(fields.domainData.techs, fields.domainData.admin)


        let deletedContacts = fields.deletedContacts;

        let newElements = this.generateNewContacts(fields);

        getFieldDecorator('originalContacts', {initialValue: originalContacts});
        getFieldDecorator('deletedContacts', {initialValue: deletedContacts});
        getFieldDecorator('newContacts', {initialValue: newElements});


        this.setState({originalContacts: originalContacts});
        this.setState({deletedContacts: deletedContacts});
        this.setState({newContacts: newElements});
    }


    render() {
        const {fields, steps, current} = this.props;
        const {error} = steps[current];
        let errorInfo = {status: true, msg: ''};
        const {originalContacts, deletedContacts, newContacts} = this.state;
        const {formatMessage} = this.props.intl;
        if (error) {
            errorInfo = error(fields, this.props);
        }

        let summaryContactsRecords = buildSummaryRecords(deletedContacts, newContacts);


        return (
            <div>
                {/*<div className="riquadro bordo with-title">*/}
                {/*<div className="title">{`Contatti Originali da Modificare`}</div>*/}
                {/*<DomainContactsTable contacts={originalContacts}/>*/}
                {/*</div>*/}
                {!errorInfo.status && (
                    <div style={{marginBottom: '40px'}}>
                        <Alert description={errorInfo.msg}
                               message='Errore'
                               type="error"
                               showIcon={true}
                               className='error-box'/>
                    </div>
                )}

                <div className="riquadro bordo with-title">
                    <div
                        className="title">{formatMessage({id: `domain.update.simple.step3.table.contact.preview`})}</div>
                    <DomainContactsTable
                        contacts={originalContacts.filter(x => !~deletedContacts.indexOf(x)).concat(newContacts)}/>
                </div>

                <div className="riquadro bordo with-title">
                    <div
                        className="title">{formatMessage({id: `domain.update.simple.step3.table.contact.edited`})}</div>
                    <DomainContactsTable
                        contacts={summaryContactsRecords}
                        summary={true}
                    />
                </div>
            </div>
        )
    }
}

export default Form.create()(injectIntl(Step3ContactUpdateSummary));