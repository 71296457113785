import {Form, Input} from "antd";
import React from "react";
import {checkTelephoneNumber} from "app/commons/utils/form";

export const ContactFaxFormItem = ({formItemLayout, intl, form, items}) => (
    <Form.Item
        {...formItemLayout}
        label={intl.formatMessage({id: 'contact.detail.form.fax'})}
        hasFeedback
    >
        {form.getFieldDecorator('fax', {
            initialValue: items && items.fax,
            rules: [{
                required: false,
                message: intl.formatMessage({id: 'contact.detail.form.fax.message'})
            },
                {                //Controlla se il numero di telefono rispetta i vincoli
                    validator: (rule, value, callback) => checkTelephoneNumber(rule, value, callback, intl.formatMessage)
                }
            ],
        })(<Input placeholder={intl.formatMessage({id: 'contact.detail.form.fax.placeholder'})}/>)}
    </Form.Item>
)

export default ContactFaxFormItem