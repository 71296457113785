import * as types from "../actionTypes";
import {updateContact as apiUpdateContact} from "../api";


// ** UPDATE **

export function updateContact(contactId, data) {
    const response = apiUpdateContact(contactId, data)
    return {
        type: types.CONTACT_UPDATE,
        payload: response
    }
}

export function updateContactStart() {
    return {
        type: types.CONTACT_UPDATE_START,
    }
}

export function updateContactSuccess(response) {
    return {
        type: types.CONTACT_UPDATE_SUCCESSFUL,
        payload: response
    }
}

export function updateContactFailure(error) {
    return {
        type: types.CONTACT_UPDATE_ERROR,
        payload: error
    }
}

export function resetUpdateContact() {
    return {
        type: types.CONTACT_UPDATE_RESET,
        payload: {data: null, contactId: null, error: null, showSuccessfullMessage: null}
    }
}
