import React from 'react';
import {Layout} from "antd";
import {FormattedMessage} from "react-intl";

const {Content, Header, Footer} = Layout;

const WizardTemplate = (props) => {
    return (
        <div className="align-left">
            <Layout>
                <Header className="wizard-header">
                    <img src="/logo-epic.svg"
                         className="wizard-header-logo"
                         alt="epic"/>
                </Header>
                <Content className="wizard-content">
                    {props.children}
                </Content>
                <Footer>
                    <div className="epic-footer">
                        <FormattedMessage id='footer.signature' values={{year: (new Date()).getFullYear()}}/>
                        <a className="footer-link" href="http://www.nic.it">Registro<img role="presentation"
                                                                                         src="/logo-it-bianco.svg"
                                                                                         className="itlogo-footer"
                                                                                         alt="registro.it"/></a>
                    </div>
                </Footer>
            </Layout>
        </div>
    );
};

WizardTemplate.propTypes = {};
WizardTemplate.defaultProps = {};

export default WizardTemplate;
