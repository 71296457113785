import * as api from "./constants/api";
import axios from "axios/index";

let instance = axios.create({
    timeout: 20000,
    // headers: {'Authorization': localStorage.getItem("token")}
});

function appendAuthorizationHeader() {
    return {headers: {'Authorization': localStorage.getItem("token")}}
}

export function countriesLookUp(name, nationalityCode) {
    return instance.get(`${api.REGISTRY_COUNTRIES}?${name ? `name=${encodeURIComponent(name)}` : ''}${encodeURIComponent(nationalityCode) ? '&enabled=true' : ''}`, appendAuthorizationHeader());
}

export function countries() {
    return instance.get(`${api.REGISTRY_COUNTRIES}?size=1000`, appendAuthorizationHeader());
}

