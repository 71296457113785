import React, {Component} from 'react';
import {Alert, Card, Collapse, Form, Icon, Table} from 'antd';

import {injectIntl} from 'react-intl';
import {hasIp} from './Utils';
import ContactDetail from '../../../../contacts/components/DataDisplay/ContactDetail/ContactDetail';


import './Fields.css';
import CardDetailRow from "../../../../commons/components/DataDisplay/CardDetailRow/CardDetailRow";
import {digestTypes, printDSRecordAlg, printDSRecordDigestType} from "app/domains/constants/common";


const {Column} = Table;
const {Panel} = Collapse;

class SummaryComponent extends Component {


    renderDSRecords = () => {
        const {fields} = this.props;
        const {formatMessage} = this.props.intl;

        let dsRecords = [];
        //console.log("FIELDS: ", this.props.fields.dsrecords);

        fields.dsrecords.forEach((ds, i) => {
            let digestT = fields[`digest-${ds}Type`];
            dsRecords.push(
                {
                    key: i,
                    keyTag: fields[`keyTag-${ds}`],
                    algorithm: printDSRecordAlg(fields[`algorithm-${ds}`]),
                    digestType: printDSRecordDigestType(digestTypes[digestT].id,),
                    digest: fields[`digest-${ds}`]
                }
            )
        })

        return (
            dsRecords.length > 0 &&
            <Table pagination={false} dataSource={dsRecords}>
                <Column
                    title={formatMessage({id: 'keyTag'})}
                    dataIndex="keyTag"
                    key="keyTag"
                    className="table-header"
                />

                <Column
                    title={formatMessage({id: 'algorithm'})}
                    dataIndex="algorithm"
                    key="algorithm"
                    className="table-header"
                />

                <Column
                    title={formatMessage({id: 'digestType'})}
                    dataIndex="digestType"
                    key="digestType"
                    className="table-header"
                />

                <Column
                    title={formatMessage({id: 'digest'})}
                    dataIndex="digest"
                    key="digest"
                    className="table-header"
                />

            </Table>

        )

    }

    renderDNS = (domainName) => {
        const {fields} = this.props;
        const {formatMessage} = this.props.intl;
        let hostsDetails = [];
        let dnsName;
        for (let k = 0; k < fields.keys; k++) {
            let ipv4 = null;
            let ipv6 = null;
            dnsName = fields[`names-${k}`];

            let dnsValue = fields[`names-${k}`];
            if (hasIp(domainName, dnsValue)) {
                if (fields[`ipv4-${k}`]) {
                    ipv4 = fields[`ipv4-${k}`];
                }

                if (fields[`ipv6-${k}`]) {
                    ipv6 = fields[`ipv6-${k}`]
                }

            }
            hostsDetails.push(
                {key: k, name: dnsName, ipv4: (ipv4 || ""), ipv6: (ipv6 || "")}
            )
        }

        return (
            <Table pagination={false} dataSource={hostsDetails}>
                <Column
                    title={formatMessage({id: 'detail.domain.nameserver.title'})}
                    dataIndex="name"
                    key="name"
                    className="table-header"
                />

                <Column
                    title={formatMessage({id: 'detail.domain.nameserver.ipv4'})}
                    dataIndex="ipv4"
                    key="ipv4"
                    className="table-header"
                />

                <Column
                    title={formatMessage({id: 'detail.domain.nameserver.ipv6'})}
                    dataIndex="ipv6"
                    key="ipv6"
                    className="table-header"
                />

            </Table>
        )
    }

    renderTechContacts = (from) => {
        const {fields} = this.props;
        let techDetails = []
        fields.techIds.forEach((k, i) => {
            techDetails.push(
                <Panel key={`${i + from}`}
                       header={<span><Icon type="user"/>{` Tecnico ${i + 1}`}</span>}
                >
                    <ContactDetail key={i + from} data={fields[`techId-${k}-Full`]} linkable={false}/>
                </Panel>
            )
        })
        return techDetails;

    }


    render() {
        const {formatMessage} = this.props.intl;
        const {fields, steps, current} = this.props;

        const domainName = fields['domainName'];

        let hostsTable = this.renderDNS(domainName);

        let dsrecordsTable = (fields.dsrecords && this.renderDSRecords())

        let techContacts = this.renderTechContacts(3);
        const {error} = steps[current];
        let errorInfo = {status: true, msg: ''};
        if (error) {
            errorInfo = error(fields, this.props);
        }
        console.log("erro ", error, errorInfo)
        return (
            <div>
                {!errorInfo.status && (
                    <div style={{marginBottom: '40px'}}>
                        <Alert description={errorInfo.msg}
                               message='Errore'
                               type="error"
                               showIcon={true}
                               className='error-box'/>
                    </div>
                )}
                <Card bordered={false} hoverable={false}>

                    <div className="riquadro bordo with-title">
                        <div className="title">{formatMessage({id: 'domain.detail.domain'})}</div>
                        <CardDetailRow label="Nome" content={fields['domainName']}/>
                        <CardDetailRow label="AuthInfo" content={fields['authInfo']}/>
                    </div>

                    <div className="riquadro bordo with-title">
                        <div className="title">{formatMessage({id: 'domain.detail.contacts'})}</div>
                        <Collapse bordered={false} defaultActiveKey={['1']}>
                            <Panel
                                header={<span><Icon
                                    type="user"/> {formatMessage({id: 'domain.detail.registrant'})}</span>}
                                key="1">
                                {fields[`registrantContactId-Full`] &&
                                <ContactDetail data={fields[`registrantContactId-Full`]} linkable={false}
                                               showRegistrantInfo={true}/>
                                }
                            </Panel>
                            <Panel header={<span><Icon
                                type="user"/> {formatMessage({id: 'domain.detail.adminContact'})}</span>} key="2">
                                {fields[`adminContactId-Full`] &&
                                <ContactDetail data={fields[`adminContactId-Full`]} linkable={false}/>
                                }
                            </Panel>

                            {techContacts}
                        </Collapse>

                    </div>

                    <div className="riquadro bordo with-title">
                        <div className="title">{formatMessage({id: 'detail.domain.nameserver'})}</div>
                        {hostsTable}
                    </div>
                    {dsrecordsTable &&
                    <div className="riquadro bordo with-title">
                        <div className="title">DNSSec</div>
                        {dsrecordsTable}
                    </div>
                    }
                </Card>
            </div>
        )
    }
}

const Summary = Form.create()(injectIntl(SummaryComponent));
export default Summary;
