export const CONTACT_INFO = 'CONTACT_INFO'
export const CONTACT_INFO_LOADER = 'CONTACT_INFO_LOADER'
export const CONTACT_INFO_SUCCESS = 'CONTACT_INFO_SUCCESS'
export const CONTACT_INFO_ERROR = 'CONTACT_INFO_ERROR'
export const CONTACT_INFO_RESET = 'CONTACT_INFO_RESET'
export const CONTACT_INFO_NOTIFY_RESET = 'CONTACT_INFO_ERROR_RESET'

export const CONTACT_UPDATE = 'CONTACT_UPDATE'
export const CONTACT_UPDATE_START = 'CONTACT_UPDATE_START'
export const CONTACT_UPDATE_SUCCESSFUL = 'CONTACT_UPDATE_SUCCESSFUL'
export const CONTACT_UPDATE_ERROR = 'CONTACT_UPDATE_ERROR'
export const CONTACT_UPDATE_RESET = 'CONTACT_UPDATE_RESET'

export const CONTACT_LIST = 'CONTACT_LIST'
export const CONTACT_LIST_START = 'CONTACT_LIST_START'
export const CONTACT_LIST_RESULT = 'CONTACT_LIST_RESULT'
export const CONTACT_LIST_ERROR = 'CONTACT_LIST_ERROR'

export const CHECK_CONTACT = 'CHECK_CONTACT'
export const CHECK_CONTACT_START = 'CHECK_CONTACT_START'
export const CHECK_CONTACT_SUCCESS = 'CHECK_CONTACT_SUCCESS'
export const CHECK_CONTACT_ERROR = 'CHECK_CONTACT_ERROR'
export const CHECK_CONTACT_RESET = 'CHECK_CONTACT_RESET'

export const CONTACT_NEW = 'CONTACT_NEW'
export const CONTACT_NEW_START = 'CONTACT_NEW_START'
export const CONTACT_NEW_SUCCESSFUL = 'CONTACT_NEW_SUCCESSFUL'
export const CONTACT_NEW_ERROR = 'CONTACT_NEW_ERROR'
export const CONTACT_NEW_RESET = 'CONTACT_NEW_RESET'

export const DELETE_CONTACT = 'DELETE_CONTACT'
export const DELETE_CONTACT_START = 'DELETE_CONTACT_START'
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS'
export const DELETE_CONTACT_ERROR = 'DELETE_CONTACT_ERROR'
export const RESET_DELETE_CONTACT = 'RESET_DELETE_CONTACT'