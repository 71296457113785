import React from 'react';
import {Popover} from "antd";
import {injectIntl} from "react-intl";

const RecordInIcon = (props) => {

    const {formatMessage} = props.intl;
    return (
        <Popover content={formatMessage({id:'inserted'})}>
            <i className={`fal fa-plus-circle`}
               style={{fontWeight: '700', color: '#74CC57'}}/>
        </Popover>
    );
};

RecordInIcon.propTypes = {};
RecordInIcon.defaultProps = {};

export default injectIntl(RecordInIcon);
