import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Icon, Input, Row} from "antd";
import styled from "styled-components";
import {injectIntl} from "react-intl";


const FormItem = Form.Item;

const StyledLoginForm = styled.form`
    max-width: 300px;
`;

const LoginFormButton = styled(Button)`
    width: 100%
`;

const LoginFormBox = styled.div`
    position: absolute;
    width: 320px;
    height: 320px;
    padding: 36px;
    box-shadow: 0 0 100px rgba(0, 0, 0, .08);
    margin: auto; /* this is needed */
    left: 0; /* this is needed */
    right: 0; /* this is needed */
    top: 0; /* this is needed */
    bottom: 0; /* this is needed */
`;

const LogoBox = styled.div`
    text-align: center;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    margin-bottom: 24px;
`;

const EpicLogo = styled.img.attrs({
    src: '/logo-epic.svg'
})`
    margin-right: 8px;
    width: 100px;
`;

const ErrorBox = styled.div`
    color: red
`;

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

export class LoginFormWrapped extends Component {

    render() {

        const {formatMessage} = this.props.intl;
        const {loginFailed, error} = this.props;
        const {getFieldDecorator, getFieldsError} = this.props.form;

        return (
            <LoginFormBox>
                <LogoBox>
                    <EpicLogo alt={'Epic logo'}/>
                </LogoBox>
                <StyledLoginForm id='loginForm' onSubmit={this.props.logIn}>
                    <FormItem>
                        {getFieldDecorator('userName', {
                            rules: [{
                                required: true,
                                message: formatMessage({id: 'login.form.message.username.required'})
                            }],
                        })(
                            <Input
                                prefix={<Icon type="user" style={{fontSize: 13}}
                                />}
                                placeholder={formatMessage({id: 'login.form.message.username.placeholder'})}/>
                        )}
                    </FormItem>
                    <FormItem>
                        {getFieldDecorator('password', {
                            rules: [{
                                required: true,
                                message: formatMessage({id: 'login.form.message.password.required'})
                            }],
                        })(
                            <Input prefix={<Icon type="lock" style={{fontSize: 13}}/>} type="password"
                                   placeholder={formatMessage({id: 'login.form.message.password.placeholder'})}/>
                        )}
                    </FormItem>
                    {(loginFailed && !!error) ? <ErrorBox id='errorBox'>{error}</ErrorBox> : ''}
                    <FormItem>
                        <Row>
                            <LoginFormButton type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>
                                Log in
                            </LoginFormButton>
                        </Row>
                    </FormItem>
                </StyledLoginForm>
            </LoginFormBox>
        );
    }
}

const LoginForm = Form.create()(injectIntl(LoginFormWrapped))
export default LoginForm;

LoginForm.propTypes = {
    logIn: PropTypes.func,
    error: PropTypes.any,
    loginFailed: PropTypes.any,
    loginError: PropTypes.any
};

LoginForm.defaultProps = {};