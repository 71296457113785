import {Form, Input} from "antd";
import PropTypes from "prop-types";
import React from "react";

export const ContactNameFormItem = ({formItemLayout, intl, form, items, disabled, onChange, placeholder}) => (
    <Form.Item
        {...formItemLayout}
        label={intl.formatMessage({id: 'contact.detail.form.name'})}
        hasFeedback
    >
        {form.getFieldDecorator('name', {
            initialValue: items && items.name,
            rules: [{
                required: true,
                message: intl.formatMessage({id: 'contact.detail.form.name.message'}),
                whitespace: true,
            }],
        })(
            <Input
                disabled={disabled}
                onChange={onChange}
                placeholder={placeholder}
            />
        )}
    </Form.Item>
);

ContactNameFormItem.propTypes = {
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    placeholder: PropTypes.string
};
ContactNameFormItem.defaultProps = {
    disabled: false,
};