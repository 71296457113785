import React, {Component} from 'react';
import {Card} from 'antd';
import {FormattedMessage, injectIntl} from "react-intl";
import PropTypes from "prop-types";
import Step3Summary from "./Steps/Step3ContactUpdateSummary";
import Step1 from "./Steps/Step1ContactSelection";
import Step2DomainContacts from "../DomainNew/DomainContacts";
import {
    ADMIN,
    TECH
} from "../../DataDisplay/DomainContatcsTable/DomainContactsTable";
import DefaultWizard from "../../../../commons/components/Navigation/Wizard/DefaultWizard";

function intersect(a, b) {
    let t;
    if (b.length > a.length) {
        t = b;
        b = a;
        a = t;
    }
    return a.filter(function (e) {
        return b.indexOf(e) > -1;
    });
}

class DomainUpdateContactWizard extends Component {




    generateNewContacts = (fields) => {
        const {techs} = fields.domainData;
        let originalTechsTable = {};
        let newElements = [];

        techs.forEach((contact) => {
            originalTechsTable[contact.id] = contact;
        });

        let adminMod = fields[`adminContactId-Full`];

        if (adminMod.id && !fields[`disabled-admin`]) {
            adminMod['type'] = ADMIN;
            newElements.push(adminMod);
        }


        fields.techIds.forEach((id) => {

            if (!fields[`disabled-${id}`]) {
                let contact = fields[`techId-${id}-Full`];
                contact['type'] = TECH;
                newElements.push(contact);
            }

        });


        return newElements;

    }


    wizardSteps = [

        {
            id: 'origin_contacts',
            title: <FormattedMessage id='domain.update.simple.step1.title'/>,
            description: <FormattedMessage id='domain.update.simple.step1.description'/>,
            readOnly: false,
            showHeader: false,
            content: Step1
        },


        {
            id: 'new_contacts',
            title: <FormattedMessage id='domain.update.simple.step2.title'/>,
            description: <FormattedMessage id='domain.update.simple.step2.description'/>,
            readOnly: false,
            showHeader: true,
            content: Step2DomainContacts
        },

        {
            id: 'dnsConfiguration',
            title: <FormattedMessage id='domain.update.simple.step3.title'/>,
            description: <FormattedMessage id='domain.update.simple.step3.description'/>,
            readOnly: false,
            showHeader: false,
            doneButton: this.props.intl.formatMessage({id: 'domain.update.contact.doneButton'}),
            error: (fields, props) => {

                // let originalContacts = mergeTechsAdminContacts(fields.domainData.techs, fields.domainData.admin)

                let deletedContacts = fields.deletedContacts;

                let newContacts = this.generateNewContacts(fields);

                let contacts = {newContacts: newContacts, deletedContacts: deletedContacts};

                let convertedContacts = this.convertFields(contacts);

                let checkedInfo = this.checkContacts(convertedContacts);

                return checkedInfo;
            },
            content: Step3Summary
        },

    ];

    convertFields = (fields) => {
        const {newContacts, deletedContacts} = fields;
        let newContactsByType = {};
        let deleteContactsByType = {};

        newContactsByType['techsId'] = [];
        newContactsByType['adminId'] = null;

        deleteContactsByType['techsId'] = [];
        deleteContactsByType['adminId'] = null;

        newContacts.forEach((contact) => {
            if (contact.type === ADMIN) {
                newContactsByType['adminId'] = contact.id;
            } else {
                newContactsByType['techsId'].push(contact.id);
            }

        });

        deletedContacts.forEach((contact) => {
            if (contact.type === ADMIN) {
                deleteContactsByType['adminId'] = contact.id;
            } else {
                deleteContactsByType['techsId'].push(contact.id);
            }
        });

        return {deletedContacts: deleteContactsByType, newContacts: newContactsByType}
    }

    checkContacts = (contacts) => {

        // console.log("checkContacts: ", contacts);

        const {deletedContacts, newContacts} = contacts;
        const {formatMessage} = this.props.intl;
        let res = {status: true, msg:''};

        let deletedTechsNum = deletedContacts['techsId'].length;
        let deletedAdminNum = (deletedContacts['adminId'] ? 1 : 0);
        let newTechsNum = newContacts['techsId'].length;
        let newAdminNum = (newContacts['adminId'] ? 1 : 0);

        // Controllo se per gli admin quello che si toglie è quello che si aggiunge.
        if (deletedAdminNum !== newAdminNum) {
            /* res = false;
            notification.error({
                duration: 10,
                message: formatMessage({id: 'domainUpdate.contacts.error.message'}),
                description: formatMessage({id: 'domainUpdate.contacts.error.description.adminMismatch'}),
            });
            */
            return {status: false, msg:formatMessage({id: 'domainUpdate.contacts.error.description.adminMismatch'})};
        }


        let noDeleted = (deletedTechsNum === 0 && deletedAdminNum === 0 );
        let noNew = (newTechsNum === 0 && newAdminNum === 0);

        if (noDeleted && noNew) {
            /*
            res = false;
            notification.error({
                duration: 10,
                message: formatMessage({id: 'domainUpdate.contacts.error.message'}),
                description: formatMessage({id: 'domainUpdate.contacts.error.description.noModification'}),
            });
            */
            return {status: false, msg:formatMessage({id: 'domainUpdate.contacts.error.description.noModification'})};
        } else {
            // Controllo se non ho rimpiazzato admin con lo stesso id
            if (deletedContacts['adminId'] && newContacts['adminId'] && deletedContacts['adminId'] === newContacts['adminId']) {
                /*
                res = false;
                notification.error({
                    duration: 10,
                    message: formatMessage({id: 'domainUpdate.contacts.error.message'}),
                    description: formatMessage({id: 'domainUpdate.contacts.error.description.sameAdmin'}),
                });
                */
                return {status: false, msg: formatMessage({id: 'domainUpdate.contacts.error.description.sameAdmin'})};
            }

            // Controllo se rimpiazzo contatti tecnici con stesso id

            let intersection = intersect(deletedContacts['techsId'], newContacts['techsId']);

            // console.log('Intersection: ', intersection);

            if (intersection.length > 0) {
                let messages = '';
                intersection.forEach((contact) => {
                    /*
                    notification.error({
                        duration: 10,
                        message: formatMessage({id: 'domainUpdate.contacts.error.message'}),
                        description: formatMessage({id: 'domainUpdate.contacts.error.description.sameTech'}, {techId: contact}),
                    });
                    */
                    messages += formatMessage({id: 'domainUpdate.contacts.error.description.sameTech'}, {techId: contact}) + ' ';
                });

                return {status: false, msg: messages};
            }
        }

        return res;
    }


    handleOnDone(fields) {
        // Prepare fields ..
        let contacts = this.convertFields(fields);
        // check error

        let check = this.checkContacts(contacts);
        if (check.status) {

            let request = {};

            if (contacts.deletedContacts.techsId && contacts.deletedContacts.techsId.length > 0) {
                request['techsToRemove'] = contacts.deletedContacts.techsId;
            }

            if (contacts.newContacts.techsId && contacts.newContacts.techsId.length > 0) {
                request['techsToAdd'] = contacts.newContacts.techsId;
            }

            if (contacts.deletedContacts.adminId) {
                request['adminToRemove'] = contacts.deletedContacts.adminId;
            }

            if (contacts.newContacts.adminId) {
                request['adminToAdd'] = contacts.newContacts.adminId;
            }

            this.props.onDone(fields.domainData.name, request);
        }
    }

    render() {

        return (
            <div>
                <Card>
                    <DefaultWizard
                        fields={this.props.fields}
                        onCancel={this.props.onCancel}
                        onDone={(fields) => this.handleOnDone(fields)}
                        steps={this.wizardSteps}
                    />
                </Card>
            </div>
        )
    }
}

export default injectIntl(DomainUpdateContactWizard);

DomainUpdateContactWizard.propTypes = {
    onDone: PropTypes.func,
    fields: PropTypes.object,
    onCancel: PropTypes.func
};
