import * as types from "../actionTypes";

const INITIAL_STATE = {
    loading: false, data: null, error: null, message: null
}

const infoReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case types.CONTACT_INFO_LOADER: {
            return {
                ...state,
                loading: true,
                // error: null,
                // message: null
            }
        }

        case types.CONTACT_INFO: {
            return {
                ...state,
                loading: true,
                // error: null
            }
        }

        case types.CONTACT_INFO_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload.response.data,
                message: action.payload.message
            }
        }
        case types.CONTACT_INFO_ERROR : {
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        }
        case types.CONTACT_INFO_RESET : {
            return {
                ...state,
                loading: false,
                ...action.payload
            }
        }

        case types.CONTACT_INFO_NOTIFY_RESET : {
            return {
                ...state,
                error: null,
            }
        }


        default:
            return state
    }

}

export default infoReducer;