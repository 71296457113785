import * as types from "../actionTypes";

const INITIAL_STATE = {
    loading: false, data: null, error: null, message: null
}
const restoreReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case types.RESTORE_DOMAIN : {
            return {
                ...state,
                loading: true,
                error: null,
                message: null
            }
        }
        case types.RESTORE_DOMAIN_START : {
            return {
                ...state,
                loading: true,
                error: null,
                message: null
            }
        }
        case types.RESTORE_DOMAIN_SUCCESS : {
            return {
                ...state,
                data: action.payload.data,
                error: null,
                loading: false,
                message: 'Dominio recuperato con successo'
            }
        }
        case types.RESTORE_DOMAIN_ERROR : {
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload,
                message: null
            }
        }
        case types.RESET_RESTORE_DOMAIN : {
            return {
                ...state,
                loading: false,
                data: null,
                error: null,
                message: null
            }
        }
        default:
            return state
    }
}

export default restoreReducer;