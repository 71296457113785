import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {notificationsReset} from "../../../actions/infoActions";
import ErrorAndNotification from "../../../../commons/containers/ErrorAndNotification";
import Error from "../../../../commons/components/FeedBack/Error/Error";
import DetailToolbar from "app/commons/components/Layout/DetailToolbar/DetailToolbar";
import ViewSelector from "app/commons/components/Navigation/ViewSelector/ViewSelector";
import {toolBarWithDetailRender} from "app/commons/components/Navigation/ViewSelector/toolBarWithDetailRender";
import DomainActionMenuContainer from "app/domains/containers/DomainActionMenuContainer";
import DomainStatusBar from "app/domains/components/DataDisplay/DomainStatusBar/DomainStatusBar";
import {renderDomainView} from "app/domains/components/Pages/renderDomainView";
import EppOperationView from "app/commons/components/Pages/EppOperationView";
import DomainInfoFormContainer from "app/domains/containers/DomainInfoFormContainer";


const DomainDetailToolbar = ({onClick, data, isRegistrar}) =>
    <DetailToolbar
        actionMenu={<DomainActionMenuContainer hasRegistrarPermission={isRegistrar} />}
        showActionMenu={true}
        statusBar={
            <DomainStatusBar
                clId={data.clId} name={data.name}
                signed={data.signed} state={data.statuses}
                expDate={data.exDate}
            />
        }
        viewSelector={<ViewSelector onClick={onClick}/>}
        onClick={onClick}
    />;

const ToolbarWithDetail = toolBarWithDetailRender(DomainDetailToolbar, injectIntl(renderDomainView))

class DomainInfo extends React.Component {

    showDetail = () => {
        const {infoDomainData, configurationData} = this.props;
        if (infoDomainData.data) {
            return <div>
                <ErrorAndNotification
                    error={infoDomainData.error}
                    message={infoDomainData.message}
                    reset={notificationsReset.bind(this)}
                />
                <ToolbarWithDetail
                    data={infoDomainData.data}
                    isRegistrar={this.props.hasRegistrarPermission}
                    dnsSecEnabled={configurationData.dnsSecEnabled}
                />
            </div>
        } else if (infoDomainData.error || this.props.error) {
            return <Error error={infoDomainData.error || this.props.error}/>
        }
    }

    render() {
        const {infoDomainData} = this.props;
        const {formatMessage} = this.props.intl;
        return (
            <EppOperationView
                title={formatMessage({id: "domain.info.form.title"})}
                loading={infoDomainData.loading}
                operationForm={<DomainInfoFormContainer/>}
            >
                {this.showDetail()}
            </EppOperationView>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        infoDomainData: state.domain.infoReducer,
        deletedDomainData: state.domain.deleteReducer,
        updateDomainHostData: state.domain.updateReducer,
        configurationData: state.confReducer,

    };
}

export default connect(mapStateToProps)(injectIntl(DomainInfo));

DomainInfo.propTypes = {
    hasRegistrarPermission: PropTypes.bool,
    resetNotification: PropTypes.func,
};
DomainInfo.defaultProps = {};