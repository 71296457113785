const CONF = {
    'LENGTH': '2',
}

export default class CountryCodedValidator {

    static validate(code) {

        if (!code || 0 === code.length) {
            return {"valid": false, "error": "countryCodeTooShort"};
        }

        if (code.length > CONF.LENGTH) {
            return {"valid": false, "error": "countryCodeTooLong"}
        }

        if (code.length < CONF.LENGTH) {
            return {"valid": false, "error": "countryCodeTooShort"}
        }


        return {"valid": true};
    }
}