import React from 'react';
import {Button, Form} from 'antd';
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import {withRouter} from "react-router-dom";
import AuthinfoFormItem from "../../../../commons/components/DataEntry/AuthinfoFormItem/AuthinfoFormItem";

const FormItem = Form.Item;

const formAuthInfoLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 5},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 18},
    },
};

class AuthinfoFormWrapped extends React.Component {

    //Valida e se tutti i controlli sono ok esegue la submit
    validateAndSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {domain} = this.props.match.params;
                let authInfo;
                if (values.authInfo) {
                    authInfo = values.authInfo.trim();
                }
                if (domain) {
                    this.props.onSubmit(domain, {authinfo: authInfo});
                }
            }
        })
    }


    canBeSubmitted = () => {
        return (this.props.value !== this.props.form.getFieldValue('authInfo') && !this.props.form.getFieldError('authInfo'))
    }

    render() {
        const {form, intl} = this.props
        const {formatMessage} = intl;

        return (
            <Form onSubmit={this.validateAndSubmit} style={{'marginTop': '40px'}}>
                <div className="riquadro bordo with-title">
                    <div className="title">{formatMessage({id: `domain.update.authinfo.new`})}</div>
                    <AuthinfoFormItem
                        form={form}
                        initialValue={this.props.data.authinfo}
                        formAuthInfoLayout={formAuthInfoLayout}
                    />
                </div>
                <FormItem style={{borderTop: '1px solid #e9e9e9'}}>
                    <div style={{paddingTop: '10px'}}>
                        <Button style={{marginRight: '25px'}}
                                onClick={this.props.onCancel}
                        >
                            {formatMessage({id: "cancel"})}
                        </Button>
                        <Button
                            type='primary'
                            htmlType="submit"
                            disabled={!this.canBeSubmitted()}
                        >
                            {formatMessage({id: "domain.update.authinfo"})}
                        </Button>
                    </div>
                </FormItem>
            </Form>
        );
    }
};


const AuthinfoFormForm = Form.create()(withRouter(injectIntl(AuthinfoFormWrapped)));

export default (AuthinfoFormForm);
AuthinfoFormForm.propTypes = {
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    data: PropTypes.object
}