import React from 'react';
import "./ContactStatusBar.css"
import {Icon} from "antd";
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import ContactTypeTag from "../ContactTypeTag/ContactTypeTag";
import styled from "styled-components"
import {contactType} from "app/commons/utils/utils";
import MediaQuery from 'react-responsive';

const Container = styled.div`
  font-weight: 600;
  display: flex;
  @media (max-width: 1200px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;
const StyledIcon = styled(Icon)`
  padding-left:10px;
  margin-right:5px;
  font-size: 12pt;
  font-weight: 100;
  color: ${props => props.registrant ? '#108ee9' : '#009688'};
  @media (max-width: 1200px) {
    padding-left:0px;
  }
`;

const StyledInfoBox = styled.div`
  border-left: 1px solid #b3b3b3;
  margin-left: 5px;
  margin-right: 5px;
  @media (max-width: 1200px) {
      border-left: 0px;
      margin-left: 0px;
      margin-right: 0px;
  }
`

const Glyph = ({type, registrant}) => (
    <StyledIcon type={type} registrant={registrant}/>
);

const Place = ({address}) => (
    <span>
        {
            address.countryCode === 'IT' ?
                `${address.city} ( ${address.state} )` :
                `${address.countryCode} - ${address.city} ( ${address.state} )`
        }
    </span>);

const ContactStatusBar = (props) => {
    const {id, name, email, address, registrant} = props.contactData;
    return (
        <MediaQuery query="(max-width: 1500px)">
            {(matches) => {
                if (matches) {
                    return (
                        <Container>
                            <ContactTypeTag type={contactType(props.contactData)}/>
                            <StyledInfoBox>
                                <Glyph type="key" registrant={registrant}/>
                                {id}
                            </StyledInfoBox>
                            <StyledInfoBox>
                                <Glyph type="user" registrant={registrant}/>
                                {name}
                            </StyledInfoBox>
                        </Container>
                    )
                } else {
                    return (
                        <Container>
                            <ContactTypeTag type={contactType(props.contactData)}/>
                            <StyledInfoBox>
                                <Glyph type="key" registrant={registrant}/>
                                {id}
                            </StyledInfoBox>
                            <StyledInfoBox>
                                <Glyph type="user" registrant={registrant}/>
                                {name}
                            </StyledInfoBox>
                            <StyledInfoBox>
                                <Glyph type="mail" registrant={registrant}/>
                                {email}
                            </StyledInfoBox>
                            <StyledInfoBox>
                                <Glyph type="environment-o" registrant={registrant}/>
                                <Place address={address}/>
                            </StyledInfoBox>

                        </Container>
                    );
                }
            }}
        </MediaQuery>
    );
};
//
ContactStatusBar.propTypes = {
    contactData: PropTypes.object
};
ContactStatusBar.defaultProps = {};

export default injectIntl(ContactStatusBar);
