import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import * as routes from "../../routes";
import {injectIntl} from "react-intl";
import DomainInfoForm from "../components/Pages/DomainInfo/DomainInfoForm";
import {infoDomainOp} from "../operations";
import {resetInfoDomain, setAuthinfo} from "../actions/infoActions";
import EppFormOperationContainer from "./DomainFormEppOperationContainer";

/**
 *
 * Container che gestisce il form Domain INFO Form
 *
 * Gestisce il routing e l'invocazione del comando associato, EPP INFO
 *
 */

class DomainInfoFormContainer extends React.Component {

    /** Submit che effettuata la form di Info Domain */
    handleSubmitInfo = (domain, authinfo) => {
        //Resetto i dati nel redux e richiamo il fetchData in maniera da obbligare il caricamento
        this.props.resetInfoDomainAction()
        this.props.setAuthinfoValue(authinfo)
        //Modifico la URL
        return this.props.history.push({
                pathname: `${routes.DOMAIN_INFO.url}/${domain}`,
            }
        )
    }

    /** Pulisco la form e modifco la URL*/
    handleResetButton = () => {
        this.props.setAuthinfoValue(null);
        this.props.history.push({pathname: `${routes.DOMAIN_INFO.url}`})
    }

    render() {
        return (
            <EppFormOperationContainer
                eppOperation={(domain) => this.props.infoDomainAction(domain, this.props.infoDomain.authinfo)}
                resetOperation={this.props.resetInfoDomainAction}
                succesfulRouteTo={routes.DOMAIN_INFO.url}
                component={
                    <DomainInfoForm
                        onReset={this.handleResetButton}
                        onSubmit={this.handleSubmitInfo}
                        authinfo={this.props.infoDomain.authinfo}
                    />

                }
                data={this.props.infoDomain.data}
            />
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAuthinfoValue: (authinfo) => {
            dispatch(setAuthinfo(authinfo))
        },
        resetInfoDomainAction: () => {
            dispatch(resetInfoDomain())
        },
        infoDomainAction: (domain, authinfo) => {
            dispatch(infoDomainOp(domain, authinfo))
        },
    };
}

const mapStateToProps = (state) => {
    return {
        infoDomain: state.domain.infoReducer,
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(DomainInfoFormContainer)));

