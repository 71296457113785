import React from 'react';
import {Tag} from "antd";

const TagNoCursor = (props) => {
    return (
        <Tag style={{cursor: 'default'}} {...props}>{props.children}</Tag>
    );
};

TagNoCursor.propTypes = {};
TagNoCursor.defaultProps = {};

export default TagNoCursor;
