import React from 'react';
import {Col, Row} from "antd";
import "./DomainTransferStatus.css"
import {injectIntl} from "react-intl";

const formattingDate = {
    year: 'numeric', day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric'
};

const TransferStatus = ({reDate, status, acDate, style = {}, intl}) => {
    const {formatMessage, formatDate} = intl;
    return (
        <div>
            <div className={'domain-status-box'}>
                <Row className={'domain-status-box-header'}>
                    <Col span={8} push={2}><i className="fal fa-arrow-alt-from-top"/> {formatMessage(
                        {id: 'domain.transfer.status.header.reqDate'})}</Col>
                    <Col span={8} className={style.className}/>
                    <Col span={8} pull={2}><i className='fal fa-trophy'/> {formatMessage(
                        {id: 'domain.transfer.status.header.acptDate'})}</Col>
                </Row>
                <Row className={'domain-status-box-date'}>
                    <Col span={8} push={2}> {`${formatDate(reDate, formattingDate)}`}</Col>

                    <Col span={8} className={style.className}>
                        <i className={style.statusIcon}/> {status}
                    </Col>
                    <Col span={8} pull={2}>{`${formatDate(acDate, formattingDate)}`}</Col>
                </Row>
            </div>
        </div>
    )
}

export default injectIntl(TransferStatus)