

export const LOGIN = {url: '/login', path: '/login'}
export const HOME = {url: '/home', path: '/home'}

export const DOMAIN = {url: '/domain', path: '/domain'}
export const DOMAIN_INFO = {url: '/domain/info', path: '/domain/info/:domain?'}
export const DOMAIN_CHECK = {url: '/domain/check', path: '/domain/check/:domain?'}
export const DOMAIN_NEW = {url: '/domain/new', path: '/domain/new'}
export const DOMAIN_UPDATE_HOST = {url: '/domain/update/host', path: '/domain/update/host/:domain?'}
export const DOMAIN_UPDATE_CONTACT = {url: '/domain/update/contact', path: '/domain/update/contact/:domain?'}
export const DOMAIN_UPDATE_AUTHINFO = {url: '/domain/update/authinfo', path: '/domain/update/authinfo/:domain?'}
export const DOMAIN_UPDATE_REGISTRANT = {url: '/domain/update/registrant', path: '/domain/update/registrant/:domain?'}
export const DOMAIN_UPDATE_STATUS = {url: '/domain/update/status', path: '/domain/update/status/:domain?'}
export const DOMAIN_LIST = {url: '/domain/list', path: '/domain/list'}
export const DOMAIN_TRANSFER = {url: '/domain/transfer', path: '/domain/transfer/:domain?'}
export const DOMAIN_TRANSFER_TRADE = {url: '/domain/transfer_trade', path: '/domain/transfer_trade/:domain?'}

export const CONTACT = {url: '/contact', path: '/contact'}
export const CONTACT_INFO = {url: '/contact/info', path: '/contact/info/:contact?'}
export const CONTACT_UPDATE = {url: '/contact/update', path: '/contact/update/:contact?'}
export const CONTACT_LIST = {url: '/contact/list', path: '/contact/list'}
export const CONTACT_CHECK = {url: '/contact/check', path: '/contact/check/:contact?'}
export const CONTACT_NEW = {url: '/contact/new', path: '/contact/new'}

export const EPP_MESSAGE_LIST = {url: '/epp_message/list', path: '/epp_message/list'}
export const EPP_MESSAGE_LIST_FILTERED = {url: '/epp_message/list?read=false', path: '/epp_message/list'}

export const ADMIN = {url:'/admin', path:'/admin'}
export const ADMIN_EPP = {url:'/admin/epp', path:'/admin/epp'}
export const ADMIN_EMAIL = {url:'/admin/email', path:'/admin/email'}
export const ADMIN_POLLING = {url:'/admin/polling', path:'/admin/polling'}
export const ADMIN_EPP_PASSWORD = {url: '/admin/epp/password', path: '/admin/epp/password'}
