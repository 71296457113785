import actions from "./actions";
import {decodeToken, hanldeErrorDispatching} from "../commons/utils/utils";
import * as api from "app/authentication/api";
import {loadConfiguration} from "app/configuration/operations";

function authenticatedUserInfo(decodedToken) {
    return function (dispatch) {
        dispatch(actions.checkAuthentication())
        return api.userInfo()
            .then((response) => {
                if (!response.error) {
                    if (decodedToken == null) {
                        throw new Error('Token is null')
                    }
                    let compoundResponse = {};
                    let data = response.data;
                    compoundResponse["data"] = {...data, ...decodedToken};

                    dispatch(actions.authenticated(compoundResponse))
                } else {
                    throw  new Error(response.payload);
                }
            })
            .catch((err) => {
                dispatch(hanldeErrorDispatching(err, actions.loginFailure))
            })
    }
}

function doLogin(username, password) {
    return function (dispatch) {
        dispatch(actions.loginRequest());
        return api.doLogin(username, password)
            .then((response) => {
                dispatch(loginSuccessful(response.headers));
                //Todo valutare se le due chiamate successive vanno rimosse da qui e inserite in altro contesto, per ora sono pre caricamenti
                dispatch(authenticatedUserInfo(decodeToken()))
                dispatch(loadConfiguration())
            }).catch(error => {
                if (error.response ) {
                    dispatch(loginFailure(error.response))
                } else {
                    dispatch(loginFailure(error))
                }

            })
    }
}

function doLogout(history, where = "/", doPush = true) {
    localStorage.removeItem("token");
    if (doPush)
        history.history.push(where);
    return function (dispatch) {
        dispatch(actions.logout())
    }
}


const loginFailure = (err) => (actions.loginFailure(err));
const loginEmpty = () => (actions.loginEmpty());
const authenticated = () => (actions.authenticated());
const notAuthenticated = (err) => (actions.notAuthenticated(err));
const loginSuccessful = (data) => (actions.loginSuccessful(data));

export default {
    authenticatedUserInfo,
    loginFailure,
    doLogin,
    doLogout,
    loginSuccessful,
    loginEmpty,
    authenticated,
    notAuthenticated,
}