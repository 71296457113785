import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from "react-intl";
import DomainListTable from "./DomainListTable";
import Error from "../../../../commons/components/FeedBack/Error/Error";
import DomainListFilterBar from "./DomainListFilterBar";
import EppOperationView from "app/commons/components/Pages/EppOperationView";

class DomainList extends Component {

    render() {
        const {formatMessage} = this.props.intl;
        const {data, loading, error} = this.props.domainsData
        return (
            <EppOperationView
                title={formatMessage({id: 'domain.list.title'})}
                operationForm={<DomainListFilterBar/>}
                loading={loading}

            >
                {(!loading && !error) &&
                <DomainListTable data={data}
                                 onChangePage={this.props.onChangePage}
                />
                }
                <Error error={error}/>
            </EppOperationView>
        );
    }
}

DomainList.propTypes = {
    domainsData: PropTypes.object,
    onLoadList: PropTypes.func,
    onChangePage: PropTypes.func,
    onResetFilters: PropTypes.func,
    onCloseFilter: PropTypes.func
};
DomainList.defaultProps = {};

export default injectIntl(DomainList);
