import Visitable from './Visitable';

export default class Address extends Visitable {
  constructor(city,state, postalCode, countryCode,streets) {
    super()
    this.city=city;
    this.state=state;
    this.postalCode=postalCode;
    this.countryCode=countryCode;
    this.streets=streets;
  }

  static create(address) {
    return new Address(address.city, address.state,
                      address.postalCode, address.streets);
  }

  // city
  get city() {
    return this._city;
  }

  set city(city) {
    this._city=city;
  }

  get state() {
    return this._state;
  }

  set state(state) {
    this._state=state;
  }

  get postalCode() {
    return this._postalCode;
  }

  set postalCode(postalCode) {
    this._postalCode=postalCode;
  }

  get countryCode() {
    return this._countryCode
  }

  set countryCode(countryCode) {
    this._countryCode=countryCode;
  }

  get streets() {
    return this._streets;
  }

  set streets(streets) {
    this._streets=streets;
  }

  accept(visitor) {
    visitor.visitAddress(this);
  }

}
