import React from "react";
import styled from 'styled-components'
import CardDetail from "app/commons/components/DataDisplay/CardDetail/CardDetail";

const ListContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const ListItem = styled(CardDetail)`
    width: 100%!important;
    margin-top: 20px!important;
`;

export const ListView = ({listContents, cards}) => {
    return <ListContainer>
        {cards.map((c, index) => (
                listContents.hasOwnProperty(c.key) &&
                <ListItem
                    key={index} label={c.title}>
                    {listContents[c.key]}
                </ListItem>
            )
        )}
    </ListContainer>
}


