import axios from "axios/index";
import * as api from "./constants/api";

let instance = axios.create({
    timeout: 20000,
    // headers: {'Authorization': localStorage.getItem("token")}
});

function appendAuthorizationHeader() {
    return {headers: {'Authorization': localStorage.getItem("token")}}
}

//DOMAIN

export function checkDomain(domain) {
    // const url = `${api.EPP_DOMAIN}/${domain}/check`
    return instance.get(`${api.EPP_DOMAIN}/${domain}/check`, appendAuthorizationHeader());
}

export function infoDomain(domain, authinfo) {
    return instance.get(`${api.EPP_DOMAIN}/${encodeURIComponent(domain)}?infContacts=ALL${authinfo ? '&authinfo=' + encodeURIComponent(authinfo) : "" }`, appendAuthorizationHeader());
}

export function newDomain(domainInfo) {
    return axios.post(`${api.EPP_DOMAIN}`, domainInfo, appendAuthorizationHeader());
}

export function deleteDomain(domain) {
    return instance.delete(`${api.EPP_DOMAIN}/${encodeURIComponent(domain)}`, appendAuthorizationHeader());
}

export function updateDomainHost(domain, data) {
    return instance.post(`${api.EPP_DOMAIN}/${encodeURIComponent(domain)}/update/host`, data, appendAuthorizationHeader());
}

export function updateDomainSimple(domain, data) {
    return instance.post(`${api.EPP_DOMAIN}/${encodeURIComponent(domain)}/update`, data, appendAuthorizationHeader());
}

export function updateDomainRegistrant(domain, data) {
    return instance.post(`${api.EPP_DOMAIN}/${encodeURIComponent(domain)}/update/registrant`, data, appendAuthorizationHeader());
}

export function updateDomainStatus(domain, data) {
    return instance.post(`${api.EPP_DOMAIN}/${encodeURIComponent(domain)}/update/status`, data, appendAuthorizationHeader());
}

export function restoreDomain(domain) {
    return instance.post(`${api.EPP_DOMAIN}/${encodeURIComponent(domain)}/update/restore`, {}, appendAuthorizationHeader());
}

export function registryDomains(searchParams) {
    let apiParams = new URLSearchParams(searchParams);

    //sottrae uno al numero di pagina per adattarlo alla numerazione dell'api
    apiParams.set('page', apiParams.has("page") ? apiParams.get("page") - 1 : '')
    return instance.get(`${api.REGISTRY_DOMAINS}?view=signed&${apiParams}`, appendAuthorizationHeader());
}

export function transferStatus(domain, authinfo) {
    return instance.get(`${api.EPP_DOMAIN}/${encodeURIComponent(domain)}/transfer/query?authinfo=${encodeURIComponent(authinfo)}`, appendAuthorizationHeader());
}

export function transferRequest(domain, authinfo, data) {
    return instance.post(`${api.EPP_DOMAIN}/${encodeURIComponent(domain)}/transfer?authinfo=${encodeURIComponent(authinfo)}`, data ? data : {}, appendAuthorizationHeader());
}

export function transferDomainApprove(domain, authinfo) {
    return instance.post(`${api.EPP_DOMAIN}/${encodeURIComponent(domain)}/transfer/approve`, {}, appendAuthorizationHeader());
}

export function transferDomainReject(domain, authinfo) {
    return instance.post(`${api.EPP_DOMAIN}/${encodeURIComponent(domain)}/transfer/reject`, {}, appendAuthorizationHeader());
}

export function transferDomainDelete(domain, authinfo) {
    return instance.post(`${api.EPP_DOMAIN}/${encodeURIComponent(domain)}/transfer/cancel?authinfo=${encodeURIComponent(authinfo)}`, {}, appendAuthorizationHeader());
}