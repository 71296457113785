import * as types from "../actionTypes";

const INITIAL_STATE = {
    loading: false, data: null, error: null, message: null
}

const deleteReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {


        //DELETE CONTACT
        case types.DELETE_CONTACT_START : {
            return {
                ...state,
                loading: true, data: null, error: null, message: null
            }
        }

        case types.DELETE_CONTACT : {
            return {
                ...state,
                loading: true, error: null, message: 'Contatto eliminato correttamente',
            }
        }
        case types.DELETE_CONTACT_SUCCESS : {
            return {
                ...state,
                data: action.payload.data, error: null, loading: false, message: null
            }
        }
        case types.DELETE_CONTACT_ERROR : {
            return {
                ...state,
                loading: false, data: null, error: action.payload, message: null
            }
        }
        case types.RESET_DELETE_CONTACT : {
            return {
                ...state,
                loading: false, data: null, error: null, message: null
            }
        }


        default:
            return state
    }

}

export default deleteReducer;