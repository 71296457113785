import React from "react";
import {Input, Layout} from "antd";
import {injectIntl} from "react-intl";
import {isTokenValid} from "../../../../commons/utils/utils";
import * as routes from "../../../../routes";
import EpicLogo from "app/commons/components/Layout/Header/EpicLogo";
import UserMenu from "app/commons/components/Layout/UserMenu/UserMenu";
import styled from "styled-components";
import withTooltip from "app/commons/hocs/withTooltip";
import {MAX_WIDTH_SIDER} from "app/commons/constants/settings";
import ClientProfile from "app/commons/components/Layout/Header/Profile/ClientProfile";

const Search = Input.Search;

const StyledHeader = styled(Layout.Header)`
  display: flex;
  justify-content: space-between;
  padding: 0px!important;
  background-color: white!important;
  line-height: normal!important;
  position: fixed!important;
  z-index: 1;
  width: 100%;
  border-bottom: 1px solid #5fbcd3;
`;

const LogoContainer = styled.div`
  min-width : ${MAX_WIDTH_SIDER}px;
  text-align: left;
  justify-content:center;
  padding-left: 25px;
`;

const RightHeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 400px;
`;

const MiniBox = styled.div`
  margin-left : 20px;
`;

const StyledSearch = styled(Search)`
  width: 400px;
  @media (max-width: 900px) {
    width: 200px;
  }
`;

const ClientProfileWithTooltip = withTooltip(ClientProfile,{title : 'Profilo client'})

class Header extends React.Component {

    componentWillMount() {
        if (!isTokenValid()) {
            this.props.tokenExpired()
        }
    }

    handleSubmit = (domain) => (
        this.props.history.push({pathname: `${routes.DOMAIN_INFO.url}/${domain}`})
    )

    render() {
        const {userData, confData} = this.props;
        return (
            <StyledHeader>
                <LogoContainer>
                    <EpicLogo/>
                </LogoContainer>
                <RightHeaderContainer>
                    <MiniBox>
                        <StyledSearch
                            placeholder={this.props.intl.formatMessage({id: 'header.search.placeholder'})}
                            onSearch={(domain) => this.handleSubmit(domain)}/>
                    </MiniBox>
                    <MiniBox>
                        {confData['profile'] && <ClientProfileWithTooltip type={confData.profile.name} {...this.props} />}
                    </MiniBox>
                    {userData &&
                    <MiniBox>
                        <UserMenu
                            userData={userData}
                            confData={confData}
                            onLogout={this.props.handleLogout}
                        />
                    </MiniBox>
                    }
                </RightHeaderContainer>
            </StyledHeader>
        )
    }
}

/* Inject auth state and dispatch() into props */
export default injectIntl(Header);
