import React from 'react';
import PropTypes from 'prop-types';
import {Form, Input} from "antd";

const EduSchoolCodeFormItem = (props) => {
    const {intl, form, isEntityType1, formItemLayout, disabled, initialValue} = props;
    const schoolCodeRules = () => {
        return [
            {
                required: true,
                message: intl.formatMessage({id: 'contact.detail.form.registrant.schoolCode.message'}),
            },
            {
                len: 10,
                message: intl.formatMessage({id: 'contact.detail.form.registrant.schoolCode.len'}),
            }
            ,
            {
                pattern: /^[A-Z]{2}[A-Z0-9]{5}[0-9]{2}[A-Z0-9]$/,
                message: intl.formatMessage({id: 'contact.detail.form.registrant.schoolCode.pattern'}),
            }
        ]
    }
    return (
        <Form.Item className="ant-row ant-from-item"
                   label={intl.formatMessage({id: 'contact.detail.form.registrant.schoolCode'})}
                   {...formItemLayout}
                   hasFeedback
        >
            {form.getFieldDecorator('registrant.schoolCode', {
                initialValue: initialValue ,
                rules: schoolCodeRules(),
            })(
                <Input
                    disabled={disabled}
                    style={isEntityType1 ? {textTransform: 'uppercase'} : {}}
                    //il campo una volta impostato, non è più modificabile
                />)
            }
        </Form.Item>
    );
};

EduSchoolCodeFormItem.propTypes = {
    intl: PropTypes.object,
    form: PropTypes.object,
    isEntityType1: PropTypes.bool,
    formItemLayout: PropTypes.object,
    disabled: PropTypes.bool,
    initialValue: PropTypes.string,

};
EduSchoolCodeFormItem.defaultProps = {
    disabled: false
};

export default EduSchoolCodeFormItem;
