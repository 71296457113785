import * as types from "../actionTypes";

const INITIAL_STATE = {
    loading: true, data: null, error: null
}
const listReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        //LIST
        case types.DOMAIN_LIST: {
            return {
                ...state,
                loading: true,
                data: action.payload,
                error: null
            }
        }

        case types.DOMAIN_LIST_START: {
            return {
                ...state,
                loading: true,
                data: action.payload,
                error: null
            }
        }

        case types.DOMAIN_LIST_RESULT: {
            return {
                ...state,
                loading: false,
                data: action.payload.data,
                error: null
            }
        }
        case types.DOMAIN_LIST_ERROR : {
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            }
        }


        default:
            return state
    }
}

export default listReducer;