import React from "react";
import {Tag} from "antd";
import * as statuses from "../constants/statuses";
import jwtLib from "jwt-decode";
import {authOperations} from "../../authentication/index";
import {ALERT_SIZE, EXPIRED_SIZE, EXPIRING_SIZE, INFO_SIZE} from "app/commons/constants/settings";
import {EX_DATE_ALERT, EX_DATE_EXPIRE, EX_DATE_EXPIRED, EX_DATE_INFO} from "app/commons/constants/constants";
import _ from "lodash";
import punycode from "punycode";
import {GOV_SUFFIX} from "../constants/constants";
import {CONTACT_TYPE} from "app/contacts/constants/const";

export function hanldeErrorDispatching(err, actionError) {
    //Gestisce la condizione dell'errore che se presenta un errore 401 scatena le azioni relative all'utente non autenticato
    if (err.response && err.response.status === 401) {
        return authOperations.notAuthenticated()
    }
    if (err.response) { //altrimenti gestisce l'errore con l'azione preposta * actionError
        // console.log("hanldeErrorDispatching Error: ", err);
        // console.log("Action Error: ", actionError);
        // return actionError(printMessageError(err))
        return actionError(err.response)
    } else {
        return actionError(err)
    }
}

export function displayStatusesTag(statusList) {
    return statusList.map((s, index) => {
        switch (s) {
            case statuses.OK:
                return <Tag key={'tag' + index} color="green">{statuses.OK.toUpperCase()}</Tag>
            case statuses.LINKED:
                return <Tag key={'tag' + index} color="blue">{statuses.LINKED.toUpperCase()}</Tag>
            case statuses.SERVER_UPDATE_PROHIBITED:
                return <Tag key={'tag' + index} color="purple">{statuses.SERVER_UPDATE_PROHIBITED.toUpperCase()}</Tag>
            case statuses.SERVER_DELETE_PROHIBITED:
                return <Tag key={'tag' + index} color="purple">{statuses.SERVER_DELETE_PROHIBITED.toUpperCase()}</Tag>
            case statuses.CLIENT_DELETE_PROHIBITED:
                return <Tag key={'tag' + index} color="orange">{statuses.CLIENT_DELETE_PROHIBITED.toUpperCase()}</Tag>
            case statuses.CLIENT_UPDATE_PROHIBITED:
                return <Tag key={'tag' + index} color="orange">{statuses.CLIENT_UPDATE_PROHIBITED.toUpperCase()}</Tag>
            default:
                return ""
        }
    })
}

export function decodeToken() {
    let decoded = null;
    if (localStorage.getItem('token')) {
        decoded = jwtLib(localStorage.getItem('token'));
    }
    return decoded;
}

export function isTokenValid() {
    let valid = {status: false, token: null};

    let decoded = decodeToken();
    if (decoded && decoded.exp > (new Date().getTime() / 1000)) {
        valid = {status: true, token: decoded};
    }

    return valid
}

export function sanitizeParams(searchParams) {
    let result = new URLSearchParams(searchParams.toString());
    for (let pair of result.entries()) {
        if (!pair[1]) {
            result.delete(pair[0])
        }
    }
    return result;
}


export function isEmpty(obj) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

var traverse = function (o, fn) {
    for (var i in o) {
        fn.apply(this, [i, o[i]]);
        if (o[i] !== null && typeof (o[i]) === "object") {
            traverse(o[i], fn);
        }
    }
}

export function findVal(object, key) {
    let res = undefined;
    traverse(object, function (k, v) {
        if (typeof v !== 'object' && k === key) {
            res = v;
        }
    });
    return res
}

//Ritorna i giorni trascorsi tra due date
export function getDaysBetweenDates(d0, d1) {

    let msPerDay = 8.64e7;

    // Copy dates so don't mess them up
    let x0 = new Date(d0);
    let x1 = new Date(d1);

    // Set to noon - avoid DST errors
    x0.setHours(12, 0, 0);
    x1.setHours(12, 0, 0);

    // Round to remove daylight saving errors
    return Math.round((x1 - x0) / msPerDay);
}

function getDateType(days) {
    if (days >= EXPIRED_SIZE) {
        return EX_DATE_EXPIRED;
    } else if (days >= EXPIRING_SIZE) {
        return EX_DATE_EXPIRE;
    } else if (days >= ALERT_SIZE) {
        return EX_DATE_ALERT;
    } else if (days >= INFO_SIZE) {
        return EX_DATE_INFO;
    }
}

export const isGovDomain = (domainName) => {
    return domainName && domainName.endsWith(GOV_SUFFIX);
}

export const contactType = ({registrant}) => (
    (!registrant)
        ? 'contact'
        : (registrant['schoolCode'])
        ? 'edu'
        : (registrant['gov'])
        ? 'gov'
        : 'registrant'
);



export const getRegistrantType = (domainName) => {
    if (domainName !== undefined) {
        if (domainName.endsWith('.edu.it')) {
            return CONTACT_TYPE.registrantEdu;
        }
        if (domainName.endsWith(GOV_SUFFIX)) {
            return CONTACT_TYPE.registrantGov;
        }
    }
    return CONTACT_TYPE.registrant
}


export function getExDateDetail(date) {
    const days = getDaysBetweenDates(date, new Date())
    const type = getDateType(days)
    return {days: days, type: type}
}

/** Aggiungi giorni ad una data, usate per testing **/
export function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result.toString();
}

export function difference(object, base) {
    function changes(object, base) {
        let arrayIndexCounter = 0;
        return _.transform(object, function (result, value, key) {
            // console.log("val,key ",value,key)
            // console.log("val,base [key] ",value,base[key])
            if (!_.isEqual(value, base[key])) {
                // console.log("[NOT EQ] - val,base [key] ",value,base[key])

                let resultKey = _.isArray(base) ? arrayIndexCounter++ : key;
                // console.log("resK ",resultKey)
                result[resultKey] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
                // console.log("Result: " + JSON.stringify(result));
            }
        });
    }

    return changes(object, base);
}


export function punyCodedDomain(domainName) {
    const punycodedDomain = punycode.toASCII(domainName);
    if (punycodedDomain !== domainName) {
        return punycodedDomain
    }
}


export function splitStringAddPlus(str){
    return "+"+str.replace(/ {2,}/g,' ').trim().split(' ').join('+');
}