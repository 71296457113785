import React from 'react';
import "./EppMessagesType.css"
import BaseMessage from "./BaseMessage";
import {injectIntl} from "react-intl";
import _ from "lodash"

const DnsErrorMessage = (props) => {

    const {formatDate, formatTime} = props.intl
    const formattingDate = {
        year: 'numeric', day: 'numeric', month: 'short',
    };

    const formattingTime = {
        hour: 'numeric',
        minute: 'numeric',
    }

    /*
    {
            "nameservers": [
              {
                "details": [
                  {
                    "value": "Unresolvable host pixel3.px-pixel.it.",
                    "status": "FAILED",
                    "queryId": 1
                  }
                ],
                "name": "pixel3.px-pixel.it.",
                "status": "FAILED"
              },
              {
                "details": [
                  {
                    "value": "Unresolvable host pixel4.px-pixel.it.",
                    "status": "FAILED",
                    "queryId": 3
                  }
                ],
                "name": "pixel4.px-pixel.it.",
                "status": "FAILED"
              }
            ],
            "details": [],
            "name": "NameserversResolvableTest",
            "status": "FAILED"
          }
     */
    //
    const Test = (props) => {
        return (
            <div>
                {props['tests']['name'] &&
                <div className={'dns-error-header'}> {props['tests']['name']}</div>
                }
                <div className='dns-error-test'>
                    {props['tests']['status'] &&
                    <div className={'dns-error-row'}>
                        <span className={'dns-error-label'}>Stato:</span>
                        <span className={'content'}> {props['tests']['status']}</span>
                    </div>
                    }
                    {props['tests']['details'] &&
                    <div className={'dns-error-row'}>
                        <span className={'dns-error-label'}>Dettagli:</span>
                        <span className={'content'}> {
                            <ul className={'unstyled-list'}>{
                                _.values(props['tests']['details']).map(function (value, index) {
                                        return <li key={index}>{value}</li>
                                    }
                                )
                            }</ul>
                        }</span>
                    </div>
                    }
                    <ul className={'unstyled-list'}>{
                        _.values(props['tests']['nameservers']).map(function (value, index) {
                                return <li key={index}><NameServer nameservers={value}/></li>
                            }
                        )
                    }</ul>
                </div>
            </div>
        )
    }


    const NameServer = (props) => (
        <div>
            {props['nameservers']['name'] &&
            <div className={'dns-error-header'}> {props['nameservers']['name']}</div>
            }
            <div className='dns-error-nameserver'>

                {props['nameservers']['status'] &&
                <div className={'dns-error-row'}>
                    <span className={'dns-error-label'}>Stato:</span>
                    <span className={'content'}> {props['nameservers']['status']}</span>
                </div>
                }
                {props['nameservers']['details'] &&
                <div className={'dns-error-row'}>
                    <span className={'dns-error-label'}>Dettagli:</span>
                    <span className={'content'}> {
                        <ul className={'unstyled-list'}>{
                            _.values(props['nameservers']['details']).map(function (value, index) {
                                    return JSON.stringify(value)
                                }
                            )
                        }</ul>
                    }</span>
                </div>
                }
            </div>
        </div>
    )

    const Query = (props) => {
        return (
            <div className='dns-error-test'>
                {props['queries']['queryFor'] &&
                <div className={'dns-error-row'}>
                    <span className={'dns-error-label'}>Query per:</span>
                    <span className={'content'}> {props['queries']['queryFor']}</span>
                </div>
                }
                {props['queries']['type'] &&
                <div className={'dns-error-row'}>
                    <span className={'dns-error-label'}>Tipo:</span>
                    <span className={'content'}> {props['queries']['type']}</span>
                </div>
                }
                {props['queries']['destination'] &&
                <div className={'dns-error-row'}>
                    <span className={'dns-error-label'}>Destinazione:</span>
                    <span className={'content'}> {props['queries']['destinazione']}</span>
                </div>
                }
                {props['queries']['id'] &&
                <div className={'dns-error-row'}>
                    <span className={'dns-error-label'}>Id:</span>
                    <span className={'content'}> {props['queries']['id']}</span>
                </div>
                }
                {props['queries']['result'] &&
                <div className={'dns-error-row'}>
                    <span className={'dns-error-label'}>Risultato:</span>
                    <span className={'content'}> {props['queries']['result']}</span>
                </div>
                }
            </div>
        )
    }


    function Detail({data}) {
        if (data) {
            return (
                <div className={'typedLayout-container'}>
                    {data &&
                    <div>
                        <div className={'typedLayout-data'}>
                            {data['dnsData']['domain'] &&
                            <div className={'typedLayout-row'}>
                                <span className={'label'}>Dominio:</span>
                                <span className={'content'}> {data['dnsData']['domain']}</span>
                            </div>
                            }
                            {data['dnsData']['status'] &&
                            <div className={'typedLayout-row'}>
                                <span className={'label'}>Stato:</span>
                                <span className={'content'}>{data['dnsData']['status']}</span>
                            </div>
                            }
                            {data['dnsData']['validationId'] &&
                            <div className={'typedLayout-row'}>
                                <span className={'label'}>Dominio:</span>
                                <span className={'content'}> {data['dnsData']['validationId']}</span>
                            </div>
                            }
                            {data['dnsData']['validationDate'] &&
                            <div className={'typedLayout-row'}>
                                <span className={'label'}>Dominio:</span>
                                <span className={'content'}>{
                                    `${formatDate(data['dnsData']['validationDate'], formattingDate)} ` +
                                    `${formatTime(data['dnsData']['validationDate'], formattingTime)}
                                    `}
                                    </span>
                            </div>
                            }
                            {data['dnsData']['tests']['tests'] &&
                            <div className={'typedLayout-row'}>
                                <span className={'label'}>Tests:</span>
                                <span className={'content'}> {
                                    <ul className={'unstyled-list'}>{
                                        _.values(data['dnsData']['tests']['tests']).map(function (value, index) {
                                                return <li key={index}><Test tests={value}/></li>
                                            }
                                        )
                                    }</ul>

                                }</span>
                            </div>
                            }
                            {data['dnsData']['queries']['queries'] &&
                            <div className={'typedLayout-row'}>
                                <span className={'label'}>Queries:</span>
                                <span className={'content'}> {
                                    <ul className={'unstyled-list'}>{
                                        _.values(data['dnsData']['queries']['queries']).map(function (value, index) {
                                                return <li key={index}><Query queries={value}/></li>
                                            }
                                        )
                                    }</ul>

                                }</span>
                            </div>
                            }
                        </div>
                    </div>}
                </div>
            )
        }
        else {
            return <span/>
        }
    }

    return (
        <BaseMessage
            detail={props.detail}
            component={<Detail data={props.detail.data}/>}
        />
    );
};

DnsErrorMessage.propTypes = {};
DnsErrorMessage.defaultProps = {};

export default injectIntl(DnsErrorMessage);
