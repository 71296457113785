import PropTypes from "prop-types";
import React, {Component} from 'react';
import {injectIntl} from "react-intl";
import {ContactOrgFormItem} from "app/contacts/components/BaseContactForm/FormITems/ContactOrgFormItem";

class ContactOrgFormItemContainer extends Component {

    constructor(props) {
        super(props)
        // console.log("PROPS contactorgcont", this.props);
        this.handleInitialValue = this.handleInitialValue.bind(this)
    }

    /* se il se il Registrante è una persona fisica (cioè EntityType = 1) il valore del campo Organizzazione deve essere uguale al campo Nome; */

    /* il campo Organizzazione, se inizialmente vuoto deve essere compilato;
   se il Registrante è una persona fisica (cioè EntityType = 1) il valore
   del campo Organizzazione deve essere uguale al campo Nome; */
    checkOrganization = (rule, value, callback) => {
        // if (hasRegistrantAndEntityType1) {
        //     if (!(form.getFieldValue('name') === value)) {
        //         callback(new Error(intl.formatMessage({id: 'contact.detail.form.organization.error.message.orgequalsname'})))
        //     }
        // }
        // if (this.hasRegistrantAndEntityType1()) {
        //     if (this.props.contactName !== value) {
        //         callback(new Error(this.props.intl.formatMessage({id: 'contact.detail.form.organization.error.message.orgequalsname'})))
        //     }
        // }
        callback();
    };

    hasRegistrantAndNotEntityType1() {
        return this.props.isRegistrant && this.props.entityType !== 1;
    }

    //E' registrante e persona fisica
    hasRegistrantAndEntityType1() {
        return this.props.isRegistrant && this.props.entityType === 1;
    }

    handleDisable() {
        if (this.props.disabled) {
            return this.props.disabled
        }

        if (!this.props.isRegistrant) {
            return false
        } else if (this.props.entityType === 1) {
            return true
        }

        return !!(this.props.items && this.props.items.org)
    }

    /* Obbligatorio solo se il Registrante non è persona fisica (EntityType <> 1). */
    handleRequired() {
        if (this.props.required !== undefined) {
            return this.props.required
        }
        else {
            if (this.hasRegistrantAndNotEntityType1()) {
                return true
            }
        }
    }

    handleInitialValue() {
        if (this.props.items && this.props.items.org) {
            return this.props.items.org;
        }

        if (this.props.initialValue) {
            return this.props.initialValue;
        }
        return undefined;
    }

    render() {
        return <ContactOrgFormItem
            {...this.props}
            checkOrganization={this.checkOrganization}
            disabled={this.handleDisable()}
            required={this.handleRequired()}
            // required={true}
            initialValue={this.handleInitialValue()}
        />
    }
}

export default injectIntl(ContactOrgFormItemContainer);

ContactOrgFormItemContainer.propTypes = {
    formItemLayout: PropTypes.object,
    form: PropTypes.object,
    items: PropTypes.object,
    disabled: PropTypes.bool, /* se presente forza il comportamento di disbaled rispetto ai vincoli implementati */
    required: PropTypes.bool, /* se presente forza il comportamento di disbaled rispetto ai vincoli implementati */
    placeholder: PropTypes.string,
    intl: PropTypes.object,
    entityType: PropTypes.number,
    isRegistrant: PropTypes.bool,
    initialValue: PropTypes.string,
}

ContactOrgFormItemContainer.default = {
    isRegistrant: false
}