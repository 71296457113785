import React from 'react';
import PropTypes from 'prop-types';
import PageTitle from "app/commons/components/Layout/PageTitle/PageTitle";
import SearchBarCard from "app/commons/components/Layout/SearchBarCard/SearchBarCard";
import Spinner from "app/commons/components/FeedBack/Spinner/Spinner";
import DetailPane from "app/commons/components/DataDisplay/DetailPane/DetailPane";

function EppOperationView(props) {
    return (
        <div>
            <PageTitle title={props.title}/>
            {props.operationForm && <SearchBarCard>
                {props.operationForm}
            </SearchBarCard>
            }
            <Spinner loading={props.loading}/>
            {props.children && props.children[0] !== null &&
            <DetailPane>
                {props.children}
            </DetailPane>
            }
        </div>
    );
}

EppOperationView.propTypes = {
    title: PropTypes.string.isRequired,
    operationForm: PropTypes.object,
    loading: PropTypes.bool,
    children: PropTypes.any
};
EppOperationView.defaultProps = {
    loading: false
};

export default EppOperationView;
