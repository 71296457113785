import React from 'react';
import {Button, Dropdown, Icon} from "antd";
import PropTypes from "prop-types";


const ActionMenu = (props) => {
    return (
        <Dropdown overlay={props.overlay}>
            <Button style={{marginLeft: 8}}>
                {props.title} <Icon type="down"/>
            </Button>
        </Dropdown>
    );
};

ActionMenu.propTypes = {
    overlay: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired
};
ActionMenu.defaultProps = {};

export default ActionMenu;
