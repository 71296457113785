import {hanldeErrorDispatching} from "../commons/utils/utils";
import * as deleteActions from "./actions/deleteActions";
import * as listActions from "./actions/listActions";
import * as infoActions from "./actions/infoActions";
import * as checkActions from "./actions/checkActions";
import * as restoreActions from "./actions/restoreActions";
import * as updateActions from "./actions/updateActions";
import * as transferActions from "./actions/transferActions";

export function infoDomainOp(domain, authinfo) {
    return function (dispatch) {
        dispatch(infoActions.infoDomainLoader())
        dispatch(infoActions.infoDomain(domain, authinfo))
            .then((response) => {
                if (!response.error) {
                    dispatch(infoActions.infoDomainSuccess(response.payload))
                } else {
                    dispatch(hanldeErrorDispatching(response.payload, infoActions.infoDomainFailure))
                }
            })
    }
}

export function infoDomainWithMessageOp(domain, authinfo, message) {
    return function (dispatch) {
        dispatch(infoActions.infoDomainLoader())
        dispatch(infoActions.infoDomain(domain, authinfo))
            .then((response) => {
                if (!response.error) {
                    dispatch(infoActions.infoDomainSuccess(response.payload, message))
                } else {
                    dispatch(hanldeErrorDispatching(response.payload, infoActions.infoDomainFailure))
                }
            })
    }
}

export function checkDomainOp(domain) {
    return function (dispatch) {
        dispatch(checkActions.checkDomainStart())
        dispatch(checkActions.checkDomain(domain))
            .then((response) => {
                if (!response.error) {
                    dispatch(checkActions.checkDomainSuccess(response))
                } else {
                    dispatch(hanldeErrorDispatching(response.payload, checkActions.checkDomainFailure))
                }
            })
    }
}

export function deleteDomainOp(domain) {
    return function (dispatch) {
        dispatch((infoActions.infoDomainLoader()))
        dispatch(deleteActions.deleteDomain(domain))
            .then((response) => {
                if (!response.error) {
                    dispatch((infoDomainWithMessageOp(response.payload.data.name, '', 'Dominio eliminato con successo')))
                } else {
                    dispatch(hanldeErrorDispatching(response.payload, infoActions.infoDomainFailure))
                }
            })
    }
}

export function restoreDomainOp(domain) {
    return function (dispatch) {
        dispatch(infoActions.infoDomainLoader())
        dispatch(restoreActions.restoreDomain(domain))
            .then((response) => {
                if (!response.error) {
                    dispatch((infoDomainWithMessageOp(response.payload.data.name, '', 'Dominio recuperato con successo')))
                } else {
                    dispatch(hanldeErrorDispatching(response.payload, infoActions.infoDomainFailure))
                }
            })
    }
}

export function listDomainOp(searchParams) {
    return function (dispatch) {
        dispatch(listActions.domainListStart())
        dispatch(listActions.domainList(searchParams))
            .then((response) => {
                if (!response.error) {
                    dispatch(listActions.domainListSuccess(response.payload))
                } else {
                    dispatch(hanldeErrorDispatching(response.payload, listActions.domainListFailure))
                }
            })
    }
}

export function updateDomainOp_Host(domain, data, message,onSuccess) {
    return function (dispatch) {
        dispatch(updateActions.updateDomainStart())
        dispatch(updateActions.updateDomainHost(domain, data))
            .then((response) => {
                if (!response.error) {
                    dispatch(updateActions.updateDomainSuccess(response.payload, message))
                    dispatch(infoDomainOp(domain))
                    onSuccess();
                } else {
                    dispatch(hanldeErrorDispatching(response.payload, updateActions.updateDomainFailure))
                }
            })
    }
}

export function updateDomain_Simple(domain, data, message,onSuccess) {
    return function (dispatch) {
        dispatch(updateActions.updateDomainStart())
        dispatch(updateActions.updateDomainSimple(domain, data))
            .then((response) => {
                if (!response.error) {
                    dispatch(updateActions.updateDomainSuccess(response.payload, message))
                    dispatch(infoDomainOp(domain))
                    onSuccess();
                } else {
                    dispatch(hanldeErrorDispatching(response.payload, updateActions.updateDomainFailure))
                }
            })
    }
}

export function updateDomainOp_Registrant(domain, data, message,onSuccess) {
    return function (dispatch) {
        dispatch(updateActions.updateDomainStart())
        dispatch(updateActions.updateDomainRegistrant(domain, data))
            .then((response) => {
                if (!response.error) {
                    dispatch(updateActions.updateDomainSuccess(response.payload, message))
                    dispatch(infoDomainOp(domain))
                    onSuccess();
                } else {
                    dispatch(hanldeErrorDispatching(response.payload, updateActions.updateDomainFailure))
                }
            })
    }
}

export function updateDomainOp_Status(domain, data, message,onSuccess) {
    return function (dispatch) {
        dispatch(updateActions.updateDomainStart())
        dispatch(updateActions.updateDomainStatus(domain, data))
            .then((response) => {
                if (!response.error) {
                    dispatch(updateActions.updateDomainSuccess(response.payload, message))
                    dispatch(infoDomainOp(domain))
                    onSuccess();
                } else {
                    dispatch(hanldeErrorDispatching(response.payload, updateActions.updateDomainFailure))
                }
            })
    }
}

export function tarnsferDomainOp_Status(domain, authinfo) {
    return function (dispatch) {
        dispatch(transferActions.transferStatusDomainStart())
        dispatch(transferActions.transferStatusDomain(domain, authinfo))
            .then((response) => {
                    if (!response.error) {
                        dispatch(transferActions.transferStatusDomainSuccess(response.payload))
                    } else {
                        if (response.payload.response.data.result.code === 2301) {
                            dispatch(transferActions.transferStatusDomainSuccess({data: response.payload.response.data}))
                        }
                        else {
                            dispatch(hanldeErrorDispatching(response.payload, transferActions.transferStatusDomainFailure))
                        }
                    }
                }
            )
    }
}

export function transferDomainApproveOp(domain, authinfo, message) {
    return function (dispatch) {
        dispatch(transferActions.transferRequestDomainStart())
        dispatch(transferActions.approveDomainTransfer(domain, authinfo))
            .then((response) => {
                if (!response.error) {
                    dispatch(transferActions.transferRequestDomainSuccess(response.payload, message))
                    dispatch(tarnsferDomainOp_Status(domain, authinfo))
                } else {
                    dispatch(hanldeErrorDispatching(response.payload, transferActions.transferRequestDomainFailure))
                }
            })
    }
}

export function domainTransferRejectOp(domain, authinfo, message) {
    return function (dispatch) {
        dispatch(transferActions.transferRequestDomainStart())
        dispatch(transferActions.rejectDomainTransfer(domain, authinfo))
            .then((response) => {
                if (!response.error) {
                    dispatch(transferActions.transferRequestDomainSuccess(response.payload, message))
                    dispatch(tarnsferDomainOp_Status(domain, authinfo))
                } else {
                    dispatch(hanldeErrorDispatching(response.payload, transferActions.transferRequestDomainFailure))
                }
            })
    }
}

export function domainTransferOp(domain, authinfo, successMessage) {
    return function (dispatch) {
        dispatch(transferActions.transferRequestDomainStart())
        dispatch(transferActions.deleteDomainTransfer(domain, authinfo))
            .then((response) => {
                if (!response.error) {
                    dispatch(transferActions.transferRequestDomainSuccess(response.payload, successMessage))
                    dispatch(tarnsferDomainOp_Status(domain, authinfo))
                } else {
                    dispatch(hanldeErrorDispatching(response.payload, transferActions.transferRequestDomainFailure))
                }
            })
    }
}