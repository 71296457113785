import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {countriesOperations} from "app/countries/index";
import {Spin} from "antd";
import CountryFormItem from "app/countries/components/CountryFormItem";

class CountryFormItemContainer extends Component {


    componentDidMount() {
        if (!this.props.skipLoading) {
            if (!this.props.countries.loading) {
                if (!(this.props.countries.list.length > 0)) {
                    this.props.loadCountryCode();
                }
            }
        }
    }


    render() {
        const {form,fieldDecorator,initialValue, disabled, label, placeholder, formItemLayout, onBlur, required,nationalityCodeFilter} = this.props;

        if (this.props.countries.loading) {
            return <div style={{textAlign:'center'}}><Spin size={'small'} /></div>
        } else {

            return <CountryFormItem
                {...this.props}
                disabled={disabled}
                initialValue={initialValue}
                form={form}
                label={label}
                placeholder={placeholder}
                formItemLayout={formItemLayout}
                onBlur={onBlur}
                required={required}
                nationalityCodeFilter={nationalityCodeFilter}
                fieldDecorator = {fieldDecorator}
            />
        }
    }
}


const mapStateToProps = (state) => {
    return {
        countries: state.countryReducer
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadCountryCode: () => dispatch(countriesOperations.loadCountryCodeOp())
    };
}


/* Inject auth state and dispatch() into props */
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CountryFormItemContainer));

CountryFormItemContainer.propTypes = {
    skipLoading: PropTypes.bool,
    form: PropTypes.object,
    initialValue: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    formItemLayout: PropTypes.object,
    nationalityCodeFilter: PropTypes.bool, //che fa?
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    required: PropTypes.bool,
};
CountryFormItemContainer.defaultProps = {
    skipLoading: false,
    nationalityCodeFilter:false
};
