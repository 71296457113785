import React from "react";
import {Redirect, Route, withRouter} from "react-router-dom";
import {connect} from "react-redux";


const AuthRoute = ({component: Component, isAuthenticated, ...rest}) => {

    const onComponentRender = props =>
        ( isAuthenticated
                ? <Component {...props} />
                : (<Redirect to={{
                    pathname: '/login',
                    state: {from: props.location}
                }}
                />)
        );
    return <Route {...rest} render={onComponentRender}/>
}

function mapStateToProps(state) {
    return {isAuthenticated: state.authReducer.authenticated};
}

/* Inject auth state and dispatch() into props */
export default withRouter(connect(mapStateToProps)(AuthRoute));