import React from 'react';
import {Popover} from "antd";
import {injectIntl} from "react-intl";

const RecordOutIcon = (props) => {

    const {formatMessage} = props.intl;
    return (
        <Popover content={formatMessage({id:'removed'})}>
            <i className={`fal fa-minus-circle`} style={{fontWeight: '700', color: '#F2636E'}}/>
        </Popover>
    );
};

RecordOutIcon.propTypes = {};
RecordOutIcon.defaultProps = {};

export default injectIntl(RecordOutIcon);
