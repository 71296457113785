import styled from "styled-components";
import LabelWithIcon from "app/commons/components/Layout/LabelWithIcon/LabelWithIcon";
import React from "react";

const StyledTitle = styled.div`
  font-size:16px;
  font-weight: 600;
`;
export const SubMenuTitle = ({onlyIcon, type, idMessage}) =>
    <StyledTitle>
        <LabelWithIcon onlyIcon={onlyIcon} type={type} idMessage={idMessage}/>
    </StyledTitle>;
