import React from "react";
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import AuthinfoForm from "./AuthinfoForm";
import {connect} from "react-redux";
import DomainInfoUpdateBox from "../../Box/DomainInfoCustomableBox";


class DomainUpdateAuthinfo extends React.Component {

    state = {
        viewType: 'tab',
        col: 1,

    }

    onChangeView(type, col) {
        this.setState({viewType: type})
        this.setState({col: col})
    }

    render() {
        const {infoDomainData, intl} = this.props;
        const {formatMessage} = intl;

        return (
            <DomainInfoUpdateBox
                title={formatMessage({id: "domain.update.authinfo.form.title"})}
                hasRegistrarPermission={this.props.hasRegistrarPermission}
                infoData={this.props.infoDomainData.data}
                error={this.props.updateDomainHostData.error}
                loading={this.props.updateDomainHostData.loading}
                message={this.props.updateDomainHostData.message}
                reset={this.props.reset}
            >
                <AuthinfoForm
                    onCancel={this.props.onCancel.bind(this)}
                    onSubmit={this.props.onAuthinfoUpdate.bind(this)}
                    data={infoDomainData.data}
                />
            </DomainInfoUpdateBox>
        )
    }
}


DomainUpdateAuthinfo.propTypes = {
    infoDomainData: PropTypes.object,
    deletedDomainData: PropTypes.object,
    hasRegistrarPermission: PropTypes.bool,
    updateDomainHostData: PropTypes.object,
    configurationData: PropTypes.object,
    onAuthinfoUpdate: PropTypes.func,
    back: PropTypes.func,
    reset: PropTypes.func
};

const mapStateToProps = (state) => {
    return {
        infoDomainData: state.domain.infoReducer,
        deletedDomainData: state.domain.deleteReducer,
        updateDomainHostData: state.domain.updateReducer
    };
}

export default connect(mapStateToProps)(injectIntl(DomainUpdateAuthinfo));