/*
"registrant": {
  "regcode": "MRTMRC66P01H501L",
  "nationality": "it",
  "entityType": "1"
},
*/
import Visitable from "./Visitable";

export default class Registrant extends Visitable {
    constructor(regcode,schoolCode, gov, nationalityCode, entityType) {
        super();

        this.regcode = regcode;
        this.schoolCode = schoolCode;
        this.nationalityCode = nationalityCode;
        this.entityType = entityType;
        this.gov = gov
    }

    static create(registrant) {
        return new Registrant(
            (registrant.regcode || registrant.regCode),
            (registrant.schoolCode),
            (registrant.gov),
            (registrant.nationality || registrant.nationalityCode),
            registrant.entityType)
    }

    get regcode() {
        return this._regcode;
    }

    set regcode(regcode) {
        this._regcode = regcode;
    }

    set gov(gov) {
        this._gov = gov;
    }

    get gov() {
        return this._gov;
    }

    get schoolCode() {
        return this._schoolCode;
    }

    set schoolCode(schoolCode) {
        this._schoolCode = schoolCode;
    }


    get nationalityCode() {
        return this._nationalityCode;
    }

    set nationalityCode(nationalityCode) {
        this._nationalityCode = nationalityCode;
    }

    get entityType() {
        return this._entityType;
    }

    set entityType(entityType) {
        this._entityType = entityType;
    }

    accept(visitor) {
        visitor.visitRegistrant(this);
    }
}
