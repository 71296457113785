import React from "react";
import {Menu} from "antd";
import {withRouter} from "react-router-dom";
import * as routes from "../../../../routes";
import styled from "styled-components";
import {injectIntl} from "react-intl";
import PropTypes from 'prop-types';
import {MAX_WIDTH_SIDER, MIN_WIDTH_SIDER} from "app/commons/constants/settings";
import {MenuItemLink} from "app/commons/components/Layout/SiderMenu/MenuItemLink";
import {SubMenuTitle} from "app/commons/components/Layout/SiderMenu/SubMenuTitle";



const antMenu = 'ant-menu';

const StyledMenu = styled(Menu)`
  min-width: ${props => props.collapsed ? MIN_WIDTH_SIDER : MAX_WIDTH_SIDER}px!important;
  background-color: transparent!important;
`

const StyledSubMenu = styled(Menu.SubMenu)`
  > .${antMenu} {
    background-color: #E0E0E0!important;
  }
`;

// submenu keys of first level
const rootSubmenuKeys = ['domain', 'contact', '/epp_message/list', 'admin'];

class SiderMenu extends React.Component {
    state = {
        current: this.props.match.path,
        openKeys: [this.props.match.url.split('/')[1]],
    }

    onOpenChange = (openKeys) => {
        const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            this.setState({openKeys});
        } else {
            this.setState({
                openKeys: latestOpenKey ? [latestOpenKey] : [],
            });
        }
    };

    render() {
        return (
            <StyledMenu
                theme={this.state.theme}
                openKeys={this.state.openKeys}
                onOpenChange={this.onOpenChange}
                selectedKeys={[this.state.current]}
                collapsed={this.props.collapsed}
                mode="inline"
            >
                {/* ++ DOMAIN MENU ++ */}
                <StyledSubMenu key="domain" title={<SubMenuTitle onlyIcon={this.props.collapsed} type='fal fa-globe'
                                                                 idMessage={'domains'}/>}>
                    <Menu.Item key={routes.DOMAIN_INFO.path}>
                        <MenuItemLink idMessage='info' type='fal fa-info-circle' linkTo={routes.DOMAIN_INFO.url}/>
                    </Menu.Item>
                    <Menu.Item key={routes.DOMAIN_CHECK.path}>
                        <MenuItemLink idMessage='check' type='fal fa-check-circle'
                                      linkTo={routes.DOMAIN_CHECK.url}/>
                    </Menu.Item>
                    <Menu.Item key={routes.DOMAIN_NEW.path}>
                        <MenuItemLink idMessage='new' type='fal fa-plus-circle' linkTo={routes.DOMAIN_NEW.url}/>
                    </Menu.Item>
                    <Menu.Item key={routes.DOMAIN_LIST.path}>
                        <MenuItemLink idMessage='list' type='fal fa-list' linkTo={routes.DOMAIN_LIST.url}/>
                    </Menu.Item>
                </StyledSubMenu>
                {/* ++ CONTACT MENU ++ */}
                <StyledSubMenu key="contact"
                               title={<SubMenuTitle onlyIcon={this.props.collapsed} type='fal fa-id-card'
                                                    idMessage={'contacts'}/>}>
                    <Menu.Item key={routes.CONTACT_INFO.path}>
                        <MenuItemLink idMessage='info' type='fal fa-info-circle' linkTo={routes.CONTACT_INFO.url}/>
                    </Menu.Item>
                    <Menu.Item key={routes.CONTACT_CHECK.path}>
                        <MenuItemLink idMessage='check' type='fal fa-check-circle'
                                      linkTo={routes.CONTACT_CHECK.url}/>
                    </Menu.Item>
                    <Menu.Item key={routes.CONTACT_NEW.path}>
                        <MenuItemLink idMessage='new' type='fal fa-plus-circle' linkTo={routes.CONTACT_NEW.url}/>
                    </Menu.Item>
                    <Menu.Item key={routes.CONTACT_LIST.path}>
                        <MenuItemLink idMessage='list' type='fal fa-list' linkTo={routes.CONTACT_LIST.url}/>
                    </Menu.Item>
                </StyledSubMenu>
                {/* ++ EPP MESSAGE MENU ++ */}
                <Menu.Item key="/epp_message/list">
                    <MenuItemLink
                        onlyIcon={this.props.collapsed}
                        idMessage='eppMessages'
                        type='fal fa-envelope'
                        linkTo={routes.EPP_MESSAGE_LIST.url} isMenuItem={true}/>
                </Menu.Item>
                {/* ++ CONFIGURATION MENU ++ */}
                <StyledSubMenu key="admin"
                               title={<SubMenuTitle onlyIcon={this.props.collapsed} type='fal fa-wrench'
                                                    idMessage='admin.menu.config'/>}>
                    <Menu.Item key={routes.ADMIN_EPP.path}>
                        <MenuItemLink idMessage='admin.menu.epp' type='fal fa-info-circle'
                                      linkTo={routes.ADMIN_EPP.url}/>
                    </Menu.Item>
                    <Menu.Item key={routes.ADMIN_EMAIL.path}>
                        <MenuItemLink idMessage='admin.menu.email' type='fal fa-check-circle'
                                      linkTo={routes.ADMIN_EMAIL.url}/>
                    </Menu.Item>
                    <Menu.Item key={routes.ADMIN_POLLING.path}>
                        <MenuItemLink idMessage='admin.menu.polling' type='fal fa-list'
                                      linkTo={routes.ADMIN_POLLING.url}/>
                    </Menu.Item>
                    <Menu.Item key={routes.ADMIN_EPP_PASSWORD.path}>
                        <MenuItemLink idMessage='admin.menu.password' type='fal fa-list'
                                      linkTo={routes.ADMIN_EPP_PASSWORD.url}/>
                    </Menu.Item>
                </StyledSubMenu>
            </StyledMenu>
        );
    }
}



export default withRouter(injectIntl(SiderMenu));

SiderMenu.propTypes = {
    /*indica se il menù deve collassarsi o meno */
    collapsed: PropTypes.bool,
};
SiderMenu.defaultProps = {
    collapsed: false
};
