import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import * as routes from "../../routes";
import {resetNewContact} from "../actions/newActions";
import {contactsOperations} from "../index";
import ContactNewPage from "app/contacts/components/Pages/ContactNew/ContactNewPage";

class ContactNewContainer extends Component {

    componentWillMount() {
        this.props.resetNewContactAction()
    }

    back = () => (
        this.props.history.push({pathname: `${routes.CONTACT_INFO.url}`})
    )

    render() {

        return (
            <div>
                <ContactNewPage
                    newContactData={this.props.newContactData}
                    onSubmit={this.props.newContactAction}
                    onCancel={this.back.bind(this)}
                />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        resetNewContactAction: () => {
            dispatch(resetNewContact())
        },

        newContactAction: (id, data) => {
            // console.log(" DATA", data) //TODO commentare
          dispatch(contactsOperations.newContactOp(data,
                () => (ownProps.history.push({pathname: `${routes.CONTACT_INFO.url}/${data.id}`}))
                )

            )
        },
    };
};

const mapStateToProps = (state) => {
    return {
        newContactData: state.contact.newReducer
    };
}

ContactNewContainer.propTypes = {};
ContactNewContainer.defaultProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactNewContainer));
