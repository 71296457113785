import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router';
import {connect as Connect} from "react-redux";
import {Button, Card, Col, notification, Row} from 'antd';

import PageTitle from '../../../../commons/components/Layout/PageTitle/PageTitle'
import connect from '../../../../commons/utils/api-connector';
import {isEmpty} from '../../../../commons/utils/validators/FormValidator';

import PropTypes from 'prop-types'
import EppForm from './EppForm';
import Spinner from "../../../../commons/components/FeedBack/Spinner/Spinner";
import Error from "../../../../commons/components/FeedBack/Error/Error";
import {changedPostStatus} from "../ConfigurationUtil";
import {ADMIN_EPP} from "../../../constants/api";
import {configOperations} from "../../../index";

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 5},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 12},
    },
};

class Epp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            buttonDisabled: true,
            buttonLoading: false,
            isUpdating: false,
            error: null,
            savingData: null,
            isFormTouched: false,
        }
    }

    checkFormValidity = (props) => {

        this.ref.getForm().validateFields((err) => {
            if (err) {
                    this.setState({buttonDisabled: true})
                } else {
                    this.setState({buttonDisabled: false})
                }
            }
        )
    }

    componentWillReceiveProps(nextProps) {

        const {postResponse} = nextProps;
        const {formatMessage} = nextProps.intl;

        let prevPostResponse = this.props.postResponse;

        if (postResponse) {
            if (changedPostStatus(postResponse, prevPostResponse)) {
                if (postResponse.pending) {

                    this.setState({buttonLoading: true})

                } else if (postResponse.rejected) {
                    let status = postResponse.meta.response.status;
                    let message = (status !== 500 ? postResponse.reason.message : postResponse.response.statusText);

                    if (postResponse.reason.cause.invalidParameters) {
                        message += " " + JSON.stringify(postResponse.reason.cause.invalidParameters);
                    }

                    this.setState({error: {status: status, message: message}});
                    this.setState({buttonLoading: false})
                } else if (postResponse.fulfilled) {
                    notification.success({
                        duration: 10,
                        message: formatMessage({id: 'admin.epp.saveConf.info.message'}),
                        description: formatMessage({id: 'admin.epp.saveConf.success.description'})
                    })
                    this.setState({buttonLoading: false})
                    this.setState({buttonDisabled: true})


                    // Bisogna notificare che è cambiata configurazione
                    this.context.store.dispatch(configOperations.eppConfiguration(this.state.savingData));
                }
            }
        }
    }

    submit() {
        const {formatMessage} = this.props.intl;
        this.setState({error: null});
        this.ref.getForm().validateFields(
            (err, values) => {
                if (!err) {
                    if (!isEmpty(values)) {
                        this.props.updateConfiguration(this.ref.getForm(), JSON.stringify(values), this.state);
                        // Bisogna notificare che è cambiata configurazione
                        //this.context.store.dispatch(eppConfiguration(values));
                        this.setState({savingData: values});
                    } else {

                        this.setState({buttonDisabled: true});
                        notification.warning({
                            message: formatMessage({id: 'admin.epp.saveConf.info.message'}),
                            description: 'Nessuna modifica'
                        })
                    }
                } else {
                    console.log("Errore..")
                }
            }
        )
    }

    handleOnChangeForm = (props) => {
        this.setState({isFormTouched: true})
        if (this.ref.getForm().getFieldValue(['password'])!==undefined){
            this.checkFormValidity(props)
        }
    }

    handleResetOnClick = () => {
        this.setState({error: null});
        this.ref.getForm().resetFields()
        this.setState({isFormTouched: false})
        this.setState({buttonDisabled: true})
    }


    render() {
        const {configuration} = this.props;
        const {location} = this.props;
        const {formatMessage} = this.props.intl;
        const {buttonDisabled, buttonLoading} = this.state;
        let component = null;
        if (configuration.pending) {
            component = <Spinner embedded={true}/>
        } else if (configuration.rejected) {
            let error = {};
            if (configuration.reason.cause) {

                error['status'] = configuration.reason.cause.status;
                error['data'] = configuration;
            } else {
                error['status'] = 500;
                error['data'] = {message: 'Problemi connessione con il server'};
            }
            component = <Error error={error} from={location.pathname}/>
        } else if (configuration.fulfilled) {
            const {dnsSecEnabled, language, username} = this.props.configuration.value;
            component =
                <EppForm
                    onChange={this.handleOnChangeForm}
                    onValuesChange={(props, fields) => this.handleOnChangeForm}
                    ref={(instance) => {
                        this.ref = instance
                    }}
                    {...this.props}
                    // onlyLabels={true}
                    language={language}
                    dnsSecEnabled={dnsSecEnabled}
                    username={username}
                    profile={this.props.profile}
                    formItemLayout={formItemLayout}
                />
        }

        return (
            <div>
                <PageTitle title={formatMessage({id: "admin.epp.title"})}/>
                <Card>
                    {
                        this.state.error &&
                        <div style={{margin: '20px'}}>

                            <Error error={this.state.error}/>
                        </div>
                    }
                    {component}
                    {
                        configuration.fulfilled &&
                        <Row>
                            <Col xs={{span: 24, offset: 5}}
                                 sm={{span: 12, offset: 5}}>
                                <Button
                                    disabled={!this.state.isFormTouched}
                                    onClick={this.handleResetOnClick}
                                    style={{marginRight: '25px'}}
                                >
                                    {formatMessage({id: 'cancel'})}
                                </Button>

                                <Button
                                    type="primary"
                                    loading={buttonLoading}
                                    disabled={buttonDisabled}
                                    onClick={() => {
                                        this.submit();
                                    }}
                                >
                                    {formatMessage({id: 'admin.epp.update'})}
                                </Button>
                            </Col>
                        </Row>
                    }

                    <Spinner loading={buttonLoading}/>
                </Card>
            </div>
        )
    }
}

Epp.contextTypes = {
    store: PropTypes.object
}

const mapStateToProps = (state) => {
    return {
        profile: state.confReducer.profile,
    };
}

export default connect(props => ({
    configuration: `${ADMIN_EPP}`,
    updateConfiguration: (form, configuration, state) => {
        return ({
            postResponse: {
                url: `${ADMIN_EPP}`,
                method: 'PUT',
                body: configuration
            }
        })
    }
}))(withRouter(Connect(mapStateToProps, null)(injectIntl(Epp))))

